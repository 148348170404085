import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  Select,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import { useParams } from "react-router-dom";
import NavigationLayout from "../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";
import EventList from "./EventList";

const EditUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const handleChangeFirstName = (newValue) => {
    setItem({ ...item, firstName: newValue });
    setFirstNameError("");
  };
  const [firstNameError, setFirstNameError] = useState("");
  const handleChangeLastName = (newValue) => {
    setItem({ ...item, lastName: newValue });
    setLastNameError("");
  };
  const [lastNameError, setLastNameError] = useState("");
  const handleChangeEmail = (newValue) => {
    setItem({ ...item, email: newValue });
    setEmailError("");
  };
  const [emailError, setEmailError] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const handleChangePassword = useCallback((newValue) => {
    setPasswordValue(newValue);
    setPasswordError("");
    setPasswordsError("");
  }, []);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const handleChangeConfirmPaswword = useCallback((newValue) => {
    setConfirmPasswordValue(newValue);
    setConfirmPasswordError("");
    setPasswordsError("");
  }, []);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedEventsError, setSelectedEventsError] = useState("");
  const handleEventChange = useCallback(
    (value) => setSelectedEvents(value),
    []
  );

  const handleSelectChange = (newValue) => {
    setItem({ ...item, role: newValue });
  };
  const options = [
    // { label: "IT Admin", value: "1" },
    { label: "Senior Admin", value: "2" },
    { label: "Registration Admin", value: "3" },
    { label: "LOC Admin", value: "4" },
    { label: "GCO Admin", value: "5" },
    { label: "QNA Admin", value: "6" },
    { label: "HAYYA Admin", value: "7" },
    // { label: "Booking Admin", value: "8" },
    // { label: "Events Calendar Admin", value: "9" },
    // { label: "Content Manager Admin", value: "10" },
    // { label: "Content Admin", value: "11" },
    // { label: "Viewer Admin", value: "12" },
    { label: "Organizer Manager", value: "13" }, // HR renamed to Organizer Manager
    // { label: "IMO Accreditation Admin", value: "14" },
    { label: "CRA Admin", value: "15" },
    { label: "Customs Admin", value: "16" },
    // { label: "Tester", value: "17" },
  ];
  const [isSaving, setIsSaving] = useState("");
  const [passwordsError, setPasswordsError] = useState("");
  const [item, setItem] = useState({
    fistName: "",
    lastName: "",
    email: "",
    role: "",
  });
  useEffect(() => {
    axios
      .get(`admin/v1/management/users/${id}`)
      .then((result) => {
        setIsSaving(false);
        setItem({
          firstName: result?.data?.data?.first_name
            ? result.data.data.first_name
            : "",
          lastName: result?.data?.data?.last_name
            ? result.data.data.last_name
            : "",
          email: result?.data?.data?.email ? result.data.data.email : "",
          role:
            result?.data?.data?.roles[0].id !== null
              ? String(result.data.data.roles[0].id)
              : "",
        });
        setSelectedEvents(
          result?.data?.data?.roles[0]?.pivot?.events !== null
            ? result.data.data.roles[0]?.pivot?.events
            : []
        );
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <NavigationLayout>
      <Page
        title="Edit User"
        breadcrumbs={[{ url: "/admin/manage-admin-users" }]}
      >
        {isSaving ? <Loading /> : null}
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">First Name</TextStyle>
                <TextField
                  value={item.firstName}
                  onChange={handleChangeFirstName}
                  align="left"
                  error={firstNameError}
                  disabled
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Last Name</TextStyle>
                <TextField
                  value={item.lastName}
                  onChange={handleChangeLastName}
                  align="left"
                  error={lastNameError}
                  disabled
                />
              </FormLayout>
            </FormLayout.Group>

            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Email</TextStyle>
                <TextField
                  value={item.email}
                  onChange={handleChangeEmail}
                  align="left"
                  error={emailError}
                  disabled
                />
              </FormLayout>
            </FormLayout.Group>
            {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Password</TextStyle>
              <TextField
                value={passwordValue}
                onChange={handleChangePassword}
                align="left"
                error={passwordError}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Confirm Password</TextStyle>
              <TextField
                value={confirmPasswordValue}
                onChange={handleChangeConfirmPaswword}
                align="left"
                error={confirmPasswordError}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={passwordsError} /> */}
            <TextStyle variation="strong">Role</TextStyle>
            <Select
              options={options}
              onChange={handleSelectChange}
              value={item.role}
              placeholder="Please select a role"
            />
          </FormLayout>

          <EventList
            selectedEvents={selectedEvents}
            handleEventChange={handleEventChange}
            selectedEventsError={selectedEventsError}
          />
        </Card>
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
          secondaryActions={[
            {
              content: <p style={{ color: "white" }}>Delete</p>,
              destructive: true,
              loading: isSaving && true,
              onClick: () => {
                setIsSaving(true);
                axios
                  .delete(`admin/v1/management/users/${id}/delete`)
                  .then((result) => {
                    navigate("/admin/manage-admin-users");
                  })
                  .catch((err) => console.log(err));
              },
            },
          ]}
        />
      </Page>
    </NavigationLayout>
  );

  function handleSave() {
    if (
      !item.firstName ||
      !item.lastName ||
      !item.email ||
      selectedEvents.length === 0
      // passwordValue !== confirmPasswordValue
    ) {
      !item.firstName && setFirstNameError("Please enter a first name");
      !item.lastName && setLastNameError("Please enter a last name");
      !item.email && setEmailError("Please enter an email");
      selectedEvents.length === 0 &&
        setSelectedEventsError("Please select at least one event");
      // passwordValue !== confirmPasswordValue &&
      //   setPasswordsError("The password confirmation does not match");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("_method", "PATCH");
      item.firstName && form_data.append("first_name", item.firstName);
      item.lastName && form_data.append("last_name", item.lastName);
      item.email && form_data.append("email", item.email);
      // passwordValue && form_data.append("password", passwordValue);
      // confirmPasswordValue &&
      //   form_data.append("confirm_password", confirmPasswordValue);
      form_data.append("role", item.role);
      form_data.append("user_events", selectedEvents);

      axios
        .post(`admin/v1/management/users/${id}/update`, form_data)
        .then((res) => {
          if (res.status == 200) {
            navigate("/admin/manage-admin-users");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default EditUser;
