import React, { useState, useEffect, Fragment } from "react";
import {
    TextField,
    TextStyle,
    Spinner,
    Button,
    Select,
    Popover,
    Checkbox,
    FormLayout,
    Modal
} from "@shopify/polaris";
import axios from "../Assets/Lib/axios";
import closeImage from "../Assets/Images/close-popup.svg";
import { useParams } from "react-router-dom";
import { countryList } from "../Assets/countries";
import { useNavigate } from "react-router-dom";
const EditOrganisationDetail = (props) => {
    const {
        isLoading,
        setIsLoading,
        handleCloseModal,
        orgId,
        associatedOrganisations
    } = props;

    const navigate = useNavigate()
    const [orgName, setOrgName] = useState("");
    const [orgArabicName, setOrgArabicName] = useState("");
    const [orgAddress, setOrgAddress] = useState("");
    const [orgCountry, setOrgCountry] = useState("");
    const [orgEmail, setOrgEmail] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(orgCountry);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [closeDeleteModal, setCloseDeleteModal] = useState(false);

    const fetchProfileDetails = () => {
        setIsLoading(true);
        axios
            .get(
                `admin/v1/organisations/${orgId}`
            )
            .then((res) => {
                setOrgName(res?.data?.data?.name);
                setOrgAddress(res?.data?.data?.address);
                setOrgCountry(res?.data?.data?.country);
                setOrgEmail(res?.data?.data?.email)

                setIsLoading(false);
            })
            .catch((err) => console.log(""))
            .finally(() => setIsLoading(false));
    };
    useEffect(() => {
        fetchProfileDetails();
    }, [orgId]);

    const handleCountryChange = (value) => {
        setSelectedCountry(value);
    };
    useEffect(() => {
        setSelectedCountry(orgCountry);
    }, [orgCountry]);

    const handleSubmit = () => {
        setIsLoading(true);
        const form_data = new FormData();

        form_data.append("email", orgEmail);
        form_data.append("organisation_name", orgName);
        form_data.append("address", orgAddress);
        form_data.append("country", selectedCountry);
        form_data.append("_method", "PATCH");

        axios
            .post(`admin/v1/organisations/${orgId}/edit`, form_data)
            .then((res) => {
                handleCloseModal();
                window.location.reload();
            })
            .catch((err) => console.log(""))
            .finally((e) => setIsLoading(false));
    };
    const handleDeleteOrganisation = () => {
        if (!associatedOrganisations.includes(Number(orgId))) {
            axios
                .delete(`admin/v1/organisations/${orgId}`)
                .then((res) => {
                    if (res.status === 200) {
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    setCloseDeleteModal(true);
                    // console.log(err)
                });
        } else {
            setCloseDeleteModal(true);
        }
    }
    return (
        <>
            <div >
                <FormLayout>
                    <div>
                        <div className="rowDiv">
                            <div className="popupText">
                                <TextStyle variation="strong">Edit Organisation Detail</TextStyle>
                            </div>

                            <img
                                src={closeImage}
                                onClick={handleCloseModal}
                                style={{
                                    width: "25px",
                                    marginLeft: "auto",
                                    marginRight: "0",
                                    cursor: "pointer",
                                }}
                            ></img>
                        </div>
                    </div>

                    <div className="row">
                        <Fragment>
                            <div className="col-sm-4 pb-2">
                                <div className="popupText">
                                    <TextStyle variation="strong">Organisation Name</TextStyle>
                                    {<TextField
                                        value={String(orgName)}
                                        onChange={(inputValue) =>
                                            setOrgName(inputValue)
                                        }
                                        autoComplete="off"
                                    />}
                                </div>
                            </div>
                            <div className="col-sm-4 pb-2">
                                <div className="popupText">
                                    <TextStyle variation="strong">Organisation Name (Arabic)</TextStyle>
                                    {<TextField
                                        value={String(orgArabicName)}
                                        onChange={(inputValue) =>
                                            setOrgArabicName(inputValue)
                                        }
                                        autoComplete="off"
                                    />}
                                </div>
                            </div>
                            <div className="col-sm-4 pb-2">
                                <div className="popupText">
                                    <TextStyle variation="strong">Organisation Country</TextStyle>
                                    {
                                        <Select
                                            id="countrySelect"
                                            options={countryList.map((country) => ({
                                                label: country.name,
                                                value: country.name,
                                            }))}
                                            value={selectedCountry}
                                            onChange={handleCountryChange}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="col-sm-4 pb-2">
                                <div className="popupText">
                                    <TextStyle variation="strong">Organisation Email</TextStyle>
                                    {<TextField
                                        value={String(orgEmail)}
                                        onChange={(inputValue) =>
                                            setOrgEmail(inputValue)
                                        }
                                        autoComplete="off"
                                    />}
                                </div>
                            </div>
                            <div className="col-sm-4 pb-2">
                                <div className="popupText">
                                    <TextStyle variation="strong">Organisation Address</TextStyle>
                                    {<TextField
                                        value={String(orgAddress)}
                                        onChange={(inputValue) =>
                                            setOrgAddress(inputValue)
                                        }
                                        autoComplete="off"
                                    />}
                                </div>
                            </div>
                            <div className="col-sm-4 pb-2">
                                <div className="popupText">
                                    <TextStyle variation="strong">Delete Organisation</TextStyle>
                                    <div>
                                        <Button onClick={() => setDeleteModalOpen(true)}>
                                            <span className="text-danger"> Delete</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    </div>
                    <Modal open={deleteModalOpen} small>
                        <Modal.Section>
                            {!closeDeleteModal &&
                                <div className="py-2 text-center">
                                    <span className="fw-bold my-3">Are you sure you want to delete this organisation?</span>
                                    <div className="my-5">
                                        <Button onClick={handleDeleteOrganisation}>
                                            <span className="text-danger fw-bold"> Delete</span>
                                        </Button>
                                        <span className="mx-3"></span>
                                        <Button onClick={() => setDeleteModalOpen(false)}>
                                            <span className=""> Cancel</span>
                                        </Button>
                                    </div>
                                </div>
                            }
                            {closeDeleteModal &&
                                <div>
                                    <p className="fw-bold mb-3">Failed to delete organization. The organization may be associated with active application or HR user.</p>
                                    <Button onClick={() => setDeleteModalOpen(false)}>
                                        <span className=""> Close</span>
                                    </Button>
                                </div>
                            }
                        </Modal.Section>
                    </Modal>
                    <Button onClick={handleSubmit}>
                        {isLoading ? <Spinner size="small" /> : "Submit"}
                    </Button>
                </FormLayout>
            </div>
        </>
    )
}
export default EditOrganisationDetail;