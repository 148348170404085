import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Button,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  Badge,
  Spinner,
  Thumbnail,
  Modal,
  Tag,
  ButtonGroup,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import emptyListImage from "../../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import EllipsisText from "react-ellipsis-text";
import {
  ArrowUpMinor,
  ArrowDownMinor,
  EditMajor,
} from "@shopify/polaris-icons";
import NavigationLayout from "../../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const ListOfMedias = () => {
  const refBoolPage = useRef(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const perPage = 100;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const handleChangePage = (event, value) => {
    refBoolPage.current = true;
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const handleEdit = (param) => {
    navigate(`/admin/photos-and-videos/albums/${id}/medias/${param}`);
  };
  const handleOptimize = (param) => {
    setPopupActive(true);
    axios
      .patch(`admin/v1/albums/${id}/media/${param}/optimize`)
      .then((res) => {
        setPopupActive(false);
      })
      .catch((err) => console.log(""));
  };
  const handleViews = (param) => {
    navigate(`/admin/photos-and-videos/albums/${id}/medias/${param}/viewers`);
  };
  const handleEditMedias = (param) => {
    navigate(`/admin/photos-and-videos/albums/${param}/medias/`);
  };
  const handleDown = (e) => {
    const bodyObj = {
      sort: "down",
    };
    axios
      .patch(`admin/v1/news/${e.currentTarget.id}/sort`, bodyObj)
      .then((res) => {
        getData();
      })
      .catch((err) => console.log(""));
  };
  const handleUp = (e) => {
    const bodyObj = {
      sort: "up",
    };
    axios
      .patch(`admin/v1/news/${e.currentTarget.id}/sort`, bodyObj)
      .then((res) => {
        getData();
      })
      .catch((err) => console.log(""));
  };
  const getData = () => {
    axios
      .get(
        `admin/v1/albums/${id}/media?per_page=${perPage}&page=${
          refBoolPage.current ? page : 1
        }&filter[${availability == "" ? "title" : availability}]=${queryValue}`
      )
      .then((result) => {
        setIsLoading(false);
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        result?.data?.data?.data &&
          setItems(
            result.data.data.data.map((item, index) => [
              <div className={`itemClick2`}>{item.id}</div>,
              <div className={`itemClick2`}>
                {item.thumbnail ? (
                  <Thumbnail source={item.thumbnail} size="small"></Thumbnail>
                ) : (
                  "-"
                )}
              </div>,
              <div className={`itemClick2`}>
                <Badge status={item?.type === "1" ? "warning" : "attention"}>
                  {item.type === "1" ? "Photo" : "Video"}
                </Badge>
              </div>,
              // <div className={`itemClick2`}>
              //   {item.type === "1" && item.path ? (
              //     <Thumbnail source={item.path} size="small"></Thumbnail>
              //   ) : (
              //     "-"
              //   )}
              // </div>,
              <div className={`itemClick2`}>
                {item.type === "2" && item.path ? item.path : "-"}
              </div>,
              <div className={`itemClick2`}>
                {item.title && item.title[Object.keys(item.title)[0]]
                  ? item.title[Object.keys(item.title)[0]]
                  : ""}
              </div>,
              <div className={`itemClick2`}>{item.date ? item.date : ""}</div>,
              <div
                className={`itemClick2`}
                onClick={() => handleViews(item.id)}
              >
                {item.downloads_count !== null ? (
                  <a style={{ cursor: "pointer" }}>
                    <u>{item.downloads_count}</u>
                  </a>
                ) : (
                  ""
                )}
              </div>,
              item?.tags?.length ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {item.tags.map((item) => {
                    return (
                      <div style={{ marginLeft: "7px" }} key={item.id}>
                        <Tag>{item.title.en}</Tag>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              ),
              <div style={{ marginRight: "2rem", float: "right" }}>
                {/* <span style={{ marginRight: "1rem" }}>
                  <Button primary onClick={() => handleEditMedias(item.id)}>
                    <p style={{ color: "white" }}>Medias</p>
                  </Button>
                </span> */}
                <ButtonGroup>
                  {/* <Button onClick={() => handleViews(item.id)} primary>
                    <p style={{ color: "white" }}>Viewers</p>
                  </Button> */}
                  <Button onClick={() => handleOptimize(item.id)}>
                    Optimize
                  </Button>
                  <Button onClick={() => handleEdit(item.id)}>Edit</Button>
                </ButtonGroup>
              </div>,
            ])
          );
      })
      .catch((err) => setIsLoading(false));
  };
  const [popupActive, setPopupActive] = useState(false);

  useEffect(() => {
    getData();
  }, [page, sortState, availability, queryValue]);

  function handleFiltersQueryChange(queryValue) {
    refBoolPage.current = false;
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = () => {
    setQueryValue("");
    refBoolPage.current = true;
  };
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Title", value: "title" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  return (
    <NavigationLayout>
      <Page
        fullWidth
        title="Photos and Videos"
        primaryAction={{
          content: <p style={{ color: "white" }}>Add Media</p>,
          onClick: () =>
            navigate(`/admin/photos-and-videos/albums/${id}/medias/new`),
        }}
        breadcrumbs={[
          {
            onAction: () => navigate(`/admin/photos-and-videos/albums`),
          },
        ]}
      >
        <Card>
          {loadingMarkup}
          <Card.Section>
            <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleFiltersClearAll}
            />
          </Card.Section>
          <DataTable
            columnContentTypes={["text", "text", "text", "text"]}
            headings={[
              <TextStyle>
                <div className="tableHeaderText">ID</div>
              </TextStyle>,
              <TextStyle>
                <div className="tableHeaderText">Thumbnail</div>
              </TextStyle>,
              <TextStyle>
                <div className="tableHeaderText">Type</div>
              </TextStyle>,
              // <TextStyle>
              //   <div className="tableHeaderText">Photo</div>
              // </TextStyle>,
              <TextStyle>
                <div className="tableHeaderText">Video ID</div>
              </TextStyle>,
              <TextStyle>
                <div className="tableHeaderText">Title</div>
              </TextStyle>,
              <TextStyle>
                <div className="tableHeaderText">Date</div>
              </TextStyle>,
              <TextStyle>
                <div className="tableHeaderText">Downloads</div>
              </TextStyle>,
              <TextStyle>
                <div className="tableHeaderText">Tags</div>
              </TextStyle>,
              <TextStyle></TextStyle>,
            ]}
            rows={items}
            sortable={[false, false, false, false]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            onSort={handleSort}
            verticalAlign="middle"
          />

          {emtyState}
        </Card>
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default ListOfMedias;
