import React, { useState, useEffect, useRef } from 'react'
import {
    DataTable,
    Button,
    Page,
    Filters,
    Card,
    TextStyle,
    Loading,
    Badge,
    Image,
    Spinner,
    DisplayText,
    Select,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import Pagination from "@material-ui/lab/Pagination";
import FilterParentDiv from "../../Components/FilterParentDiv";
import Cookies from "js-cookie";
import FilterCloseButton from "../../Components/FilterCloseButton";
import { isDefined, adminRole } from "../../Helpers";
import filterGrey from "../../Assets/Images/filter-grey.svg";
import filterOrange from "../../Assets/Images/filter-orange.svg";
import emptyListImage from "../../Assets/Images/emptyList.svg";

function AssociatedUsers() {
    const [hrUsers, setHrUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const perPage = 15;
    const [page, setPage] = useState(1);
    const [isListEmpty, setIsListEmpty] = useState(false);
    const [filterState, setFilterState] = useState(
        isDefined(Cookies.get("vmc-requests-filter-state"))
            ? Cookies.get("vmc-requests-filter-state")
            : ""
    );
    const [associatedOrganisationsName, setAssociatedOrganisationsName] = useState([]);
    const [totalUsers, setTotalUsers] = useState();
    const [filterClicked, setFilterClicked] = useState(
        isDefined(Cookies.get("vmc-requests-filter-clicked")) &&
            Cookies.get("vmc-requests-filter-clicked") === "true"
            ? true
            : false
    );
    const [searchValue, setSearchValue] = useState(
        isDefined(Cookies.get("vmc-requests-search-value"))
            ? Cookies.get("vmc-requests-search-value")
            : ""
    );
    const refBoolPage = useRef(true);
    const refHrId = useRef(null);
    const refHrName = useRef(null);
    const refHrEmail = useRef(null);
    const refHrOrg = useRef(null);

    const handleDeleteTextField = (param) => {
        param.current.value = "";
        setSearchValue("");
        refBoolPage.current = true;
        Cookies.set("vmc-requests-search-value", "");
    };
    const loadingMarkup = isLoading ? <Loading /> : null;
    const emtyState = isListEmpty ? (
        <div
            style={{
                display: "flex",
                justifyContent: "middle",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <div style={{ width: "25%" }}>
                <Image src={emptyListImage}></Image>
            </div>
            <DisplayText size="small">No results found</DisplayText>
            <br />
        </div>
    ) : (
        isLoading && (
            <div className="spinnerContainer">
                <div className="vertical-center">
                    <Spinner size="large" />
                </div>
            </div>
        )
    );
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    const resetValues = (param) => {
        param !== "refHrId" && (refHrId.current.value = "");
        param !== "refHrName" && (refHrName.current.value = "");
        param !== "refHrEmail" && (refHrEmail.current.value = "");
        param !== "refHrOrg" && (refHrOrg.current.value = "");

    }
    const handleChangeHrIdSearchValue = (event) => {
        resetValues("refHrId");
        setFilterState("id");
        Cookies.set("vmc-requests-filter-state", "id");
        setSearchValue(event.target.value);
        refBoolPage.current = false;
        Cookies.set("vmc-requests-search-value", event.target.value);
    };
    const handleChangeHrNameSearchValue = (event) => {
        resetValues("refHrName");
        setFilterState("first_name");
        Cookies.set("vmc-requests-filter-state", "first_name");
        setSearchValue(event.target.value);
        refBoolPage.current = false;
        Cookies.set("vmc-requests-search-value", event.target.value);
    };
    const handleChangeHrEmailSearchValue = (event) => {
        resetValues("refHrEmail");
        setFilterState("email");
        Cookies.set("vmc-requests-filter-state", "email");
        setSearchValue(event.target.value);
        refBoolPage.current = false;
        Cookies.set("vmc-requests-search-value", event.target.value);
    };
    const handleChangeHrOrganisationSearchValue = (event) => {
        resetValues("refHrOrg");
        setFilterState("organisation_id");
        Cookies.set("vmc-requests-filter-state", "organisation_id");
        console.log(event.target.value)
        setSearchValue(event.target.value);
        refBoolPage.current = false;
        Cookies.set("vmc-requests-search-value", event.target.value);
    };
    const handleFilter = () => {
        if (!filterClicked) {
            document.getElementById('firstTableItem').style.display = "block";
        } else {
            resetValues("refHrName");
            resetValues("refHrId");
            resetValues("refHrEmail");
            resetValues("refHrOrg");

            setSearchValue("");
            refBoolPage.current = true;

            document.getElementById('firstTableItem').style.display = "none";
        }
        setFilterClicked(!filterClicked);
    };
    const getData = () => {
        axios
            .get(`admin/v1/hr-users-organisations?per_page=${perPage}&page=${refBoolPage.current ? page : 1
                }${searchValue && filterState
                    ? `&filter[${filterState}]=${searchValue.toLocaleLowerCase()}`
                    : ""
                }`)
            .then((result) => 
            {
                setIsLoading(false);
                setTotalUsers(result?.data.data?.total);
                // setHrUsers(result.data.data.data);
                !result.data.data.data.length
                    ? setIsListEmpty(true)
                    : setIsListEmpty(false);
                setTotalPages(Math.ceil(result.data.data.total / perPage));
                let array = [];
                const filteredOrgs = result?.data?.data?.data
                    ?.filter(org => (org.organisation));
                setAssociatedOrganisationsName(filteredOrgs)
                for (var i = 0; i < result?.data?.data?.data.length; i++) {
                    const item = result?.data?.data?.data[i];
                    array.push([
                        <div
                        >
                            {loadingMarkup}
                            {item ? (
                                setHrUsers(
                                    result.data.data.data.map((item, index) => [
                                        <div className={`itemClick`}>{item.id}</div>,
                                        item?.first_name && (
                                            <div className={`itemClick`}>{item?.first_name}{" "}{item?.last_name}</div>
                                        ),
                                        // item?.last_name && (
                                        //     <div className={`itemClick`}>{item?.last_name}</div>
                                        // ),
                                        item?.email && (
                                            <div className={`itemClick`}>{item?.email}</div>
                                        ),
                                        item?.organisation && (
                                            <div className={`itemClick`}>
                                                <Badge>
                                                    {item?.organisation.name}
                                                </Badge>
                                            </div>
                                        ),

                                        <div className={`itemClick`}></div>
                                    ])
                                )
                            ) : (
                                console.log('empyy')
                            )}
                        </div>,
                    ])
                }

            }
            )
            .catch((err) => {
                setIsLoading(false);
                console.log("error fetching HR's Organisations", err)
            }
            );
    }
    useEffect(() => {
        getData();
    }, [page, filterState, searchValue])

    return (
        <Page
            fullWidth
            title=" Associated Users"
            primaryAction={{
                content: <p style={{ color: "white" }}>Associate HR</p>,
                url: "/admin/manage-organisation/associate-user",
            }}
        // breadcrumbs={[{ url: "/admin/content" }]}
        >
            <Card>
                {loadingMarkup}
                <div className="filter-organisation"
                    id="firstTableItem"
                    style={{ display: filterClicked ? "block" : "none" }}>
                    <FilterParentDiv filterClicked={filterClicked}>

                        <div className="Polaris-TextField" style={{ maxWidth: "120px" }}
                        >
                            <input
                                id="PolarisTextFieldId"
                                className="Polaris-TextField__Input"
                                placeholder="Search ID"
                                aria-labelledby="PolarisTextFieldIdLabel"
                                aria-invalid="false"
                                defaultValue={
                                    Cookies.get("vmc-requests-filter-state") === "id"
                                        ? searchValue
                                        : ""
                                }
                                onChange={handleChangeHrIdSearchValue}
                                ref={refHrId}
                            />
                            <FilterCloseButton
                                searchValue={searchValue}
                                filterState={filterState}
                                filterCondition="id"
                                handleDeleteTextField={handleDeleteTextField}
                                refr={refHrId}
                            ></FilterCloseButton>
                            <div className="Polaris-TextField__Backdrop" />
                        </div>
                        <div className="Polaris-TextField"
                        >
                            <input
                                id="PolarisTextFieldId"
                                className="Polaris-TextField__Input"
                                placeholder="Search by name"
                                aria-labelledby="PolarisTextFieldIdLabel"
                                aria-invalid="false"
                                defaultValue={
                                    Cookies.get("vmc-requests-filter-state") === "first_name"
                                        ? searchValue
                                        : ""
                                }
                                onChange={handleChangeHrNameSearchValue}
                                ref={refHrName}
                            />
                            <FilterCloseButton
                                searchValue={searchValue}
                                filterState={filterState}
                                filterCondition="id"
                                handleDeleteTextField={handleDeleteTextField}
                                refr={refHrName}
                            ></FilterCloseButton>
                            <div className="Polaris-TextField__Backdrop" />
                        </div>
                        <div className="Polaris-TextField"
                        >
                            <input
                                id="PolarisTextFieldId"
                                className="Polaris-TextField__Input"
                                placeholder="Search by email"
                                aria-labelledby="PolarisTextFieldIdLabel"
                                aria-invalid="false"
                                defaultValue={
                                    Cookies.get("vmc-requests-filter-state") === "email"
                                        ? searchValue
                                        : ""
                                }
                                onChange={handleChangeHrEmailSearchValue}
                                ref={refHrEmail}
                            />
                            <FilterCloseButton
                                searchValue={searchValue}
                                filterState={filterState}
                                filterCondition="id"
                                handleDeleteTextField={handleDeleteTextField}
                                refr={refHrEmail}
                            ></FilterCloseButton>
                            <div className="Polaris-TextField__Backdrop" />
                        </div>
                        <div className="Polaris-TextField"
                        >
                            <select
                                id="PolarisTextFieldId"
                                className="Polaris-TextField__Input"
                                aria-labelledby="PolarisTextFieldIdLabel"
                                aria-invalid="false"
                                defaultValue={
                                    Cookies.get("vmc-requests-filter-state") === "organisation_id"
                                        ? searchValue
                                        : ""
                                }
                                onChange={handleChangeHrOrganisationSearchValue}
                                ref={refHrOrg}
                            >
                                <option value="" disabled>Search by organisation</option>
                                {associatedOrganisationsName?.map((orgName, index) => (
                                    <option key={index} value={orgName.organisation?.id.toString()}>

                                        {orgName?.organisation?.name}
                                    </option>
                                ))}
                            </select>
                            <FilterCloseButton
                                searchValue={searchValue}
                                filterState={filterState}
                                filterCondition="organisation_id"
                                handleDeleteTextField={handleDeleteTextField}
                                refr={refHrOrg}
                            ></FilterCloseButton>
                            <div className="Polaris-TextField__Backdrop" />
                        </div>

                    </FilterParentDiv>
                </div>
                <DataTable
                    columnContentTypes={["text", "text", "text", "text"]}
                    headings={[
                        <TextStyle variation="strong">
                            <div style={{ padding: "1.6rem" }}>ID</div>
                        </TextStyle>,
                        <TextStyle variation="strong">
                            <div style={{ padding: "1.6rem" }}>Full Name</div>
                        </TextStyle>,
                        // <TextStyle variation="strong">
                        //     <div style={{ padding: "1.6rem" }}> Last Name</div>
                        // </TextStyle>,
                        <TextStyle variation="strong">
                            <div style={{ padding: "1.6rem" }}> Email</div>
                        </TextStyle>,
                        <TextStyle variation="strong">
                            <div style={{ padding: "1.6rem" }}>Associated Organisation</div>
                        </TextStyle>,
                        <TextStyle variation="strong">
                            <div style={{ padding: "1.6rem", float: "right" }}>
                                <img src={(filterClicked || searchValue) ? filterOrange : filterGrey} onClick={handleFilter}
                                    style={{ cursor: "pointer", width: "22px" }} />
                            </div>
                        </TextStyle>,

                    ]}
                    rows={hrUsers}
                    sortable={[false, false, false, false]}
                    defaultSortDirection="descending"
                    initialSortColumnIndex={0}
                    verticalAlign="middle"
                />

            </Card>
            <div className="totalDiv">Total: {totalUsers && totalUsers}</div>
            {totalPages >= 2 && (
                <div
                    style={{
                        paddingTop: "10px",
                        textAlign: "center",
                        paddingBottom: "10px",
                    }}
                >
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        size="large"
                    />
                </div>
            )}
            
            {emtyState}
        </Page>)
}

export default AssociatedUsers;