import React, { useEffect } from "react";
import axios from "axios";
import history from "./history";
import Cookies from "js-cookie";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "../../authConfig";
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
const msalInstance = new PublicClientApplication(msalConfig);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log(" error.response.status=", error.response.status);
      Cookies.set("vmc-accesstoken", null);
      // msalInstance.logoutRedirect({
      //   ...loginRequest,
      //   postLogoutRedirectUri: `/${!isNotAuth ? Router.pathname : "pending"}`,
      // });
      msalInstance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
      // Cookies.set("vmc-accesstoken", null);
    } else {
      return Promise.reject(error);
    }
  }
);
axios.defaults.proxy = process.env.REACT_APP_BASE_URL;
instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${Cookies.get("vmc-accesstoken")}`;

  if (config.method === "get") {
    const accreditationTypeId = Cookies.get("accreditation_type_id");
    config.params = {
      ...config.params,
      accreditation_type_id: accreditationTypeId,
    };
  }

  return config;
});

export default instance;
