import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Modal,
  Page,
  Loading,
  Card,
  ButtonGroup,
  TextField,
  FormLayout,
  Layout,
  Toast,
  Spinner,
  InlineError,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import axios from "../../Assets/Lib/axios";
import attachementsImage from "../../Assets/Images/attachments.svg";
import Cookies from "js-cookie";
import back from "../../Assets/Images/back.svg";
import flag from "../../Assets/Images/flag.svg";
import flagRed from "../../Assets/Images/flag-red.svg";
import history from "../../Assets/Lib/history";
import moment from "moment";
import previous from "../../Assets/Images/previous.svg";
import next from "../../Assets/Images/next.svg";
import ActionButtons from "../../Components/ActionButtons";
import editImg from "../../Assets/Images/edit.svg";
import NavigationLayout from "../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";
import { adminRole, isDefined } from "../../Helpers";
import FanIDPopup from "../../Components/FanIDPopup";
import { openBase64Image } from "../../Base64Image/base64Image";
import EditPopup from "../../Components/EditPopup";
import { countryList } from "../../Assets/countries";
import { moiSubmitApi } from "../../global";
import LoadingBtn from "../../Components/LoadingBtn";
import ProfileDetails from "./ProfileDetails";
import countryDialingCodes from '../../country-dialing-code.json';

const flagUrl = country_iso => `/flags/${country_iso.toLowerCase()}.svg`;

const RequestDetails = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showFanIDPopup, setShowFanIDPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const refClickedItem = useRef(null);

  const [passportModalOpen, setPassportModalOpen] = useState(false);
  const [facialModalOpen, setFacialModalOpen] = useState(false);
  const closeModal = () => {
    setPassportModalOpen(false);
    setFacialModalOpen(false);
    setDocModalOpen(false)
  };
  const base64toBlob = (base64Data, file) => {
    let extension = file.split(".").pop()?.toLowerCase();
    let type;

    switch (extension) {
      case "jpg":
      case "jpeg":
        type = "image/jpeg";
        break;
      case "png":
        type = "image/png";
        break;
      case "tiff":
        type = "image/tiff";
        break;
      case "pdf":
        type = "application/pdf";
        break;
      case "xlsx":
        type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      default:
        // Handle unsupported file types or set a default type
        type = "application/octet-stream";
    }

    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    return new Blob(byteArrays, { type });
  };
  const previewBase64toBlob = ({ type, base64 }) => {

    switch (type) {
      case "jpg":
      case "jpeg":
        type = "image/jpg";
        break;
      case "png":
        type = "image/png";
        break;
      case "tiff":
        type = "image/tiff";
        break;
      case "pdf":
        type = "application/pdf";
        break;
      case "xlsx":
        type =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
    }

    const sliceSize = 1024;
    const byteCharacters = atob(base64);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    const blob = new Blob(byteArrays, { type: type });

    return URL.createObjectURL(blob);
  };
  const handleChangeShowEditPopup = (e) => {
    if (showEditPopup === false) {
      console.log(e.currentTarget.id);
      refClickedItem.current = e.currentTarget.id;
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "visible";
      }
    }
    setShowEditPopup(!showEditPopup);
  };
  const handleChangeShowEditPopupWithChanges = (e) => {
    if (showEditPopup === false) {
      console.log(e.currentTarget.id);
      refClickedItem.current = e.currentTarget.id;
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      setToastContent(`Change has been successfully completed`);
      toggleActiveToast();
      setTs(+new Date());
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "visible";
      }
    }
    setShowEditPopup(!showEditPopup);
  };
  const handleChangeShowPopup = (e) => {
    if (showFanIDPopup === false) {
      // refClickedItem.current = e.currentTarget.id;
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
      //fetchData();
    } else {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "visible";
      }
    }

    setShowFanIDPopup(!showFanIDPopup);
  };

  const format = "YYYY-MM-DD HH:mm:ss";
  const reference = useRef("");
  const [isLoading, setIsLoading] = useState(true);
  const [activeAreYouSure, setActiveAreYouSure] = useState(false);
  const [activeEmail, setActiveEmail] = useState(false);
  const [questionValue, setQuestionValue] = useState(false);
  const loadingMarkup = isLoading ? <Loading /> : null;
  const [result, setResult] = useState("");
  const [rejectClicked, setRejectClicked] = useState(false);
  const [activeDelete, setActiveDelete] = useState(false);
  const [isSendingDelete, setIsSendingDelete] = useState(false);
  const [activeDeleteApp, setActiveDeleteApp] = useState(false);
  const [isFlagged, setIsFlagged] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [currentRequestId, setCurrentRequestId] = useState(id);
  const [isLoadingSpinner, setIsloadingSpinner] = useState(false);
  const loadingSpinner = isLoadingSpinner ? <Spinner /> : null;
  const toggleActiveToast = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );
  const [toastContent, setToastContent] = useState("");
  const toastMarkup = activeToast ? (
    <Toast
      content={toastContent}
      onDismiss={toggleActiveToast}
      duration="30000"
    />
  ) : null;
  const [reasonOfRejectionValue, setReasonOfRejectionValue] = useState("");

  const handleChangeReasonOfRejection = (newValue) => {
    setReasonOfRejectionValue(newValue);
    setReasonError("");
  };
  const [emailMessageValue, setEmailMessageValue] = useState("");
  const handleChangeEmailMessage = (e) => {
    setEmailMessageValue(e.target.value);
    setEmailError("");
  };
  const [status, setStatus] = useState("");
  const [allRequestsArray, setAllRequestsArray] = useState(null);
  const [ts, setTs] = useState("");
  const [refreshStatus, setRefreshStatus] = useState(true);

  useEffect(() => {
    let str = "";
    if (
      window.location.href.indexOf("/admin/users/incomplete-applications") > -1
    ) {
      str = "signed-up-requests-ids";
    }
    if (window.location.href.indexOf("/admin/requests/gco-requests") > -1) {
      str = "gco-requests-ids";
    }
    if (
      window.location.href.indexOf("/admin/requests/sc-requests") > -1 ||
      window.location.href.indexOf("/admin/users/completed-applications") > -1
    ) {
      str = "all-requests-ids";
    }
    if (window.location.href.indexOf("/admin/requests/qna-requests") > -1) {
      str = "qna-requests-ids";
    }
    if (window.location.href.indexOf("/admin/requests/hayya-requests") > -1) {
      str = "hayya-requests-ids";
    }
    if (window.location.href.indexOf("/admin/requests/imo-requests") > -1) {
      str = "gco-requests-imo-ids";
    }
    axios
      .get(`admin/v1/${str}`)
      .then((result) => {
        setAllRequestsArray(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  }, []);

  const [hayyaResponse, setHayyaResponse] = useState("");

  useEffect(() => {
    let str = "";
    if (window.location.href.indexOf("/admin/requests/gco-requests") > -1) {
      str = "gco-requests";
    }
    if (
      window.location.href.indexOf("/admin/users/incomplete-applications") > -1
    ) {
      str = "signed-up-requests";
    }
    if (
      window.location.href.indexOf("/admin/requests/sc-requests") > -1 ||
      window.location.href.indexOf("/admin/users/completed-applications") > -1
    ) {
      str = "all-requests";
    }
    if (window.location.href.indexOf("/admin/requests/qna-requests") > -1) {
      str = "qna-requests";
    }
    if (
      window.location.href.indexOf("/admin/requests/registration-requests") > -1
    ) {
      str = "registration-requests";
    }
    if (window.location.href.indexOf("/admin/requests/hayya-requests") > -1) {
      str = "hayya-requests";
    }
    if (window.location.href.indexOf("/admin/requests/imo-requests") > -1) {
      str = "gco-requests-imo";
    }
    // if (
    //   window.location.href.indexOf("/admin/requests/non-accredited-requests") >
    //   -1
    // ) {
    //   str = "non-accredited-requests";
    // }
    //
    axios
      .get(`admin/v1/${str}/${currentRequestId}`)
      .then((result) => {
        setResult(result.data.data);
        setIsFlagged(result.data.data.is_flagged ? true : false);
      })
      .catch((err) => console.log(err));
  }, [currentRequestId, status, ts]);

  useEffect(() => {
    if (refreshStatus && result?.hayya_status === "Pending") {
      handleSync();
      setRefreshStatus(false);
    }
  }, [result]);


  const [selectedImage, setSelectedImage] = useState(null);
  const [docModalOpen, setDocModalOpen] = useState(false);
  const [docTypeName, setDocTypeName] = useState("");
  const handleClickDocument = (e) => {
    const file = e.currentTarget.id;
    axios
      .get("admin/v1/generate-download-token")
      .then((res) => {
        if (res.status === 200) {
          const token = res.data.data;
          const url = `${process.env.REACT_APP_BASE_URL}admin/v1/download-document?application_id=${result.id}&file=${file}&token=${token}`;
          openBase64Image(url, file);
        }
      })
      .catch((err) => console.log("")
      );
  };

  const handleClickCredentialDocument = async (e) => {
    const fileId = e.currentTarget.id;
    const selectedDoc = result.documents.map((i) => i.document);
    setDocTypeName(fileId);
    if (selectedDoc) {
      try {
        setIsLoading(true);
        const res = await axios.get("admin/v1/generate-download-token");
        if (res.status === 200) {
          const token = res.data.data;
          const file = fileId;

          if (result.id && file) {
            const url = `${process.env.REACT_APP_BASE_URL}admin/v1/download-document?application_id=${result.id}&file=${file}&token=${token}`;

            const response = await axios.get(url);
            const blob = base64toBlob(response?.data?.data, file);
            const blobUrl = URL.createObjectURL(blob);

            setSelectedImage(blobUrl);
          } else {
            console.error(`undefined`);
          }
          setIsLoading(false);
        }
      } catch (err) {
        console.error(``, err);
        setIsLoading(false);
      }
      setDocModalOpen(!docModalOpen);
    } else {

      console.error(`Document not found for id: ${fileId}`);
    }
  };

  const handleRowApprove = (e) => {
    reference.current = JSON.parse(e.currentTarget.id).id + "_" + "approve";
    setQuestionValue("Are you sure you want to approve this request?");
    setActiveAreYouSure(true);
  };
  const handleRowReject = (e) => {
    reference.current = JSON.parse(e.currentTarget.id).id + "_" + "reject";
    setQuestionValue("Are you sure you want to reject this request?");
    setActiveAreYouSure(true);
  };
  const handleRowUnreject = (e) => {
    reference.current = JSON.parse(e.currentTarget.id).id + "_" + "unreject";
    setQuestionValue("Are you sure you want to unreject this request?");
    setActiveAreYouSure(true);
  };
  const handleEmail = (e) => {
    setActiveEmail(true);
  };
  const [emailError, setEmailError] = useState("");
  const handleDownloadAll = (e) => {
    const file = e.currentTarget.id;
    axios
      .get("admin/v1/generate-download-token")
      .then((res) => {
        window.open(
          `${process.env.REACT_APP_BASE_URL}admin/v1/download-all?application_id=${result.id}&token=${res.data.data}`
        );
      })
      .catch((err) => console.log(""));
  };
  const handleExportEntryPermit = (e) => {
    axios
      .get("admin/v1/generate-download-token")
      .then((res) => {
        window.open(
          `${process.env.REACT_APP_BASE_URL}admin/v1/export-entry-permit?application_id=${result.id}&token=${res.data.data}`
        );
      })
      .catch((err) => console.log(""));
  };
  const handleSync = () => {
    setIsLoading(true);
    axios
      .get(`admin/v1/registration-requests/${id}/status/hayya-sync`)
      .then((res) => {
        setToastContent(`Status : ${res.data.status}`);
        toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => console.log(""))
      .finally(() => setIsLoading(false));
  };
  const handleUpdateFanId = () => {
    const bodyObj = { users: id };
    axios
      .post(`admin/v1/registration-requests-fanid`, bodyObj)
      .then((res) => {
        // setToastContent(`Status : ${res.data.status}`);
        // toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => console.log(""));
  };
  const handleUpdateLiferay = () => {
    setIsLoading(true);
    const bodyObj = { users: id };
    axios
      .patch(`admin/v1/registration-requests/${id}/status/update/liferay`)
      .then((res) => {
        //res.data.status && setToastContent(`Status : ${res.data.status}`);
        // toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => console.log(""))
      .finally(() => setIsLoading(false));
  };
  const handleSendLiferay = () => {
    setIsLoading(true);
    axios
      .patch(`admin/v1/registration-requests/${id}/status/liferay`)
      .then((res) => {
        setToastContent(`Created Successfully`);
        toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => console.log(""))
      .finally(() => setIsLoading(false));
  };

  const handleMoiSync = () => {
    setIsLoading(true);
    axios
      .get(`admin/v1/registration-requests/${id}/moi-sync`)
      .then((res) => {
        setToastContent(`Sync Completed`);
        toggleActiveToast();
        setTs(+new Date());
        // setIsLoading(false)
      })
      .catch((err) => console.log())
      .finally(() => setIsLoading(false));
  };

  const [fanIdResponse, setFanIdResponse] = useState("");
  const [fanIdError, setFanIdError] = useState("");
  const handleGetFanId = () => {
    setShowFanIDPopup(!showFanIDPopup);
    setFanIdError("");
    setFanIdResponse("");
    const bodyObj = { users: id };
    axios
      .get(`admin/v1/registration-requests-fanid/${id}`)
      .then((res) => {
        setFanIdResponse(res.data);
        // console.log("res====", res);
        // setToastContent(`Fan ID : ${res.data.fanid.data.fanIdNo}`);
        // toggleActiveToast();
        // setTs(+new Date());
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          setFanIdError("Error: " + error.response.status);
        }
      });
  };
  const [orgDialingCode, setOrgDialingCode] = useState('');
  const [orgFlagCode, setOrgFlagCode] = useState('');
  const [personalDialingCode, setPersonalDialingCode] = useState('');
  const [personalFlagCode, setPersonalFlagCode] = useState('');
  useEffect(() => {
    const orgPhoneCode = result?.organisation?.phoneAreaCode;
    setOrgDialingCode(orgPhoneCode);

    const country = countryDialingCodes.find(
      (item) => item.dialing_code === "+" + orgPhoneCode
    );
    if (country) {
      setOrgFlagCode(country.alpha_2);
    } else {
      setOrgFlagCode('');
    }
  }, [result?.organisation?.phoneAreaCode]
  )
  useEffect(() => {
    const personalPhoneCode = result?.emergency_contact_PhoneAreaCode;
    setOrgDialingCode(personalPhoneCode);
    console.log(personalPhoneCode)
    const country = countryDialingCodes.find(
      (item) => item.dialing_code === "+" + personalPhoneCode
    );
    if (country) {
      setPersonalFlagCode(country.alpha_2);
    } else {
      setPersonalFlagCode('');
    }
  }, [result?.emergency_contact_PhoneAreaCode]
  )
  const handleResend = (e) => {
    let url = "";
    let message = "Created";
    if (e.currentTarget.id == "send") {
      url = `admin/v1/registration-requests/${id}/status/hayya`;
    } else {
      message = "Updated";
      url = `admin/v1/registration-requests/${id}/status/update/hayya`;
    }
    setIsLoading(true);
    axios
      .patch(url)
      .then((res) => {
        setToastContent(`${message} Successfully`);
        toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => setToastContent("Something went wrong"))
      .finally(() => setIsLoading(false));

    // if (
    //   (result?.hayya_status === "New" &&
    //     !result?.hayya_response?.response?.data?.fanIdNo) ||
    //   (result?.hayya_status === "400" &&
    //     !result?.hayya_response?.response?.data?.fanIdNo)
    // ) {
    //   url = `admin/v1/registration-requests/${id}/status/hayya`;
    // } else if (
    //   result?.hayya_status === "Data Error" &&
    //   result?.hayya_response?.response?.data?.fanIdNo
    // ) {
    //   url = `admin/v1/registration-requests/${id}/status/update/hayya`;
    // } else if (
    //   result?.hayya_status === "Rejected" &&
    //   result?.hayya_response?.response?.data?.fanIdNo
    // ) {
    //   url = `admin/v1/registration-requests/${id}/status/update/hayya`;
    // } else {
    //   console.log("nothing");
    // }
    // axios
    //   .patch(url)
    //   .then((res) => {
    //     setToastContent("Updated Successfully");
    //     toggleActiveToast();
    //   })
    //   .catch((err) => setToastContent("Something went wrong"));
  };
  const handleNo = () => {
    setActiveAreYouSure(false);
  };
  const handleCancel = () => {
    setActiveEmail(false);
  };

  const handleCancelDeleteApplication = () => {
    setActiveDeleteApp(false);
  };
  const handleCancelDeleteAccount = () => {
    setActiveDelete(false);
  };
  const handleSendDeleteAccount = () => {
    setIsSendingDelete(true);
    const form_data = new FormData();
    form_data.append("message", "");
    form_data.append("_method", "PATCH");
    axios
      .delete(`admin/v1/signed-up-requests/${result?.id}/delete`, form_data)
      .then((res) => {
        navigate(`/admin/users`);
      })
      .catch((err) => console.log(""));
  };

  const handleSendDeleteApplication = () => {
    setIsSendingDelete(true);

    axios
      .delete(`admin/v1/all-requests/${result?.id}/delete`)
      .then((res) => {
        navigate(`/admin/users`);
      })
      .catch((err) => console.log(""));
  };
  const handleDeleteAccount = () => {
    setActiveDelete(true);
  };
  const handleDeleteApplication = () => {
    setActiveDeleteApp(true);
  };

  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const handleSend = () => {
    if (!emailMessageValue) {
      setEmailError("Please enter a message");
    } else {
      setIsSendingEmail(true);
      const form_data = new FormData();

      form_data.append("message", emailMessageValue);
      form_data.append("_method", "PATCH");

      axios
        .post(`admin/v1/registration-requests/${result?.id}/email`, form_data)
        .then((res) => {
          setActiveEmail(false);
          setToastContent("Email Sent Successfully");
          toggleActiveToast();
          setTs(+new Date());
          setIsSendingEmail(false);
        })
        .catch((err) => console.log(""));
    }
  };

  const [reasonError, setReasonError] = useState("");

  const handleApprove = (form_data) => {
    let str = "";

    if (
      window.location.href.indexOf("/admin/requests/gco-requests") > -1 ||
      window.location.href.indexOf("/admin/requests/imo-requests") > -1
    ) {
      str = "gco";
    }

    if (window.location.href.indexOf("/admin/requests/qna-requests") > -1) {
      str = "qna";
    }

    if (window.location.href.indexOf("/admin/requests/hayya-requests") > -1) {
      str = "hayya";
    }

    if (
      window.location.href.indexOf("/admin/requests/sc-requests") > -1 &&
      parseInt(result.accreditation_type_id) !== 1 &&
      result.status_level === "SC"
    ) {
      str = "sc";
    }

    if (str) {
      axios
        .post(
          `admin/v1/registration-requests/${reference.current.split("_")[0]
          }/status/${str}`,
          form_data
        )
        .then((res) => {
          // Cookies.set("sumPendingRequests", "");

          // if (
          //   reference.current.split("_")[1] == "approve" &&
          //   window.location.href.indexOf("/admin/requests/qna-requests") > -1 &&
          //   parseInt(result.accreditation_type_id) !== 4
          // ) {
          //   let str = "";
          //   if (result?.hayya_response?.response?.data?.fanIdNo)
          //     str = "/status/update/hayya";
          //   else str = "/status/hayya";
          //   axios
          //     .patch(`admin/v1/registration-requests/${result?.id}${str}`)
          //     .then((res) => {
          //       setIsSendingEmail(false);
          //       setReasonError("");
          //       if (
          //         window.location.href.indexOf("/admin/requests/gco-requests") >
          //           -1 ||
          //         window.location.href.indexOf("/admin/requests/imo-requests") >
          //           -1 ||
          //         window.location.href.indexOf("/admin/requests/qna-requests") >
          //           -1
          //       ) {
          //         navigate("/admin/requests");
          //       } else {
          //         setRejectClicked(false);
          //         setActiveAreYouSure(false);
          //         setToastContent(
          //           reference.current.split("_")[1] === "approve"
          //             ? "Request Approved Successfully"
          //             : reference.current.split("_")[1] === "reject"
          //             ? "Request Rejected Successfully"
          //             : "Request Unrejected Successfully"
          //         );
          //         setTs(+new Date());
          //         toggleActiveToast();
          //       }
          //     })
          //     .catch((err) => console.log(""));
          // } else {

          if (res?.data?.status) {
            setIsSendingEmail(false);
            setReasonError("");
            if (
              window.location.href.indexOf("/admin/requests/gco-requests") >
              -1 ||
              window.location.href.indexOf("/admin/requests/imo-requests") >
              -1 ||
              window.location.href.indexOf("/admin/requests/qna-requests") > -1
            ) {
              navigate("/admin/requests");
            } else {
              setRejectClicked(false);
              setActiveAreYouSure(false);
              setToastContent(
                reference.current.split("_")[1] === "approve"
                  ? "Request Approved Successfully"
                  : reference.current.split("_")[1] === "reject"
                    ? "Request Rejected Successfully"
                    : "Request Unrejected Successfully"
              );
              setTs(+new Date());
              toggleActiveToast();
            }
          } else {
            setToastContent(res?.data?.message);
            setActiveAreYouSure(false);
            setTs(+new Date());
            toggleActiveToast();
            setIsSendingEmail(false);
          }
          //  }
        })
        .catch((err) => console.log(""));
    }
  };

  const handleYes = () => {
    if (
      reference.current.split("_")[1] == "reject" &&
      !reasonOfRejectionValue
    ) {
      setReasonError("Please enter a reason");
    } else {
      setIsSendingEmail(true);
      const form_data = new FormData();
      form_data.append(
        "status",
        reference.current.split("_")[1] === "approve"
          ? "approved"
          : reference.current.split("_")[1] === "reject"
            ? "rejected"
            : "unrejected"
      );
      reference.current.split("_")[1] === "reject" &&
        form_data.append("reason_of_rejection", reasonOfRejectionValue);
      form_data.append("_method", "PATCH");

      handleApprove(form_data);
    }
  };
  const handleFlag = () => {
    // isFlagged ? setIsFlagged(false) : setIsFlagged(true);
    const form_data = new FormData();
    form_data.append("_method", "PATCH");
    form_data.append("is_flagged", !isFlagged ? 1 : 0);
    axios
      .post(
        `admin/v1/registration-requests/${currentRequestId}/flag`,
        form_data
      )
      .then((res) => {
        !isFlagged ? setIsFlagged(true) : setIsFlagged(false);
      })
      .catch((err) => console.log(""));
  };

  const switchStatus = () => {
    let str = "";
    if (
      window.location.href.indexOf("/admin/requests/sc-requests") > -1 ||
      window.location.href.indexOf("/admin/users/completed-applications") > -1
    ) {
      str = "sc";
    }
    if (window.location.href.indexOf("/admin/requests/hayya-requests") > -1) {
      str = "hayya";
    }
    if (window.location.href.indexOf("/admin/requests/gco-requests") > -1) {
      str = "gco";
    }
    if (window.location.href.indexOf("/admin/requests/imo-requests") > -1) {
      str = "gco";
    }
    if (window.location.href.indexOf("/admin/requests/qna-requests") > -1) {
      str = "qna";
    }
    return str;
  };
  const switchStatusColor = (status) => {
    switch (status) {
      case "pending":
      case "pending_sc":
      case "pending_gco":
      case "pending_gco":
      case "pending_qna":
        return "#4B4B4B";
        break;

      case "approved":
      case "approved_sc":
      case "approved_gco":
      case "approved_qna":
      case "approved_lusail":
        return "#008000";
        break;
      case "rejected":
      case "rejected_sc":
      case "rejected_gco":
      case "rejected_qna":
      case "rejected_lusail":
        return "#B85555";
        break;
      case "updated":
        return "#FFBF00";
        break;
      case "new":
        return "#FF4700";
        break;
      default:
      // code block
    }
  };

  const switchGender = (param) => {
    switch (String(param)) {
      case "1":
        return "Male";
        break;
      case "2":
        return "Female";
        break;
      default:
      // code block
    }
  };
  const switchDocumentSubType = (param) => {
    switch (String(param)) {
      case "1":
        return "Normal";
        break;
      case "2":
        return "Private/Royal";
        break;
      case "3":
        return "Diplomatic";
        break;
      case "5":
        return "Mission";
        break;
      case "8":
        return "Travel Document";
        break;
      case "9":
        return "Official";
        break;
      default:
      // code block
    }
  };
  const switchDocumentType = (param) => {
    switch (String(param)) {
      case "1":
        return "Qatar Id Card (QID)";
        break;
      case "3":
        return "Passport";
        break;
      default:
      // code block
    }
  };

  const handlePrevious = () => {
    console.log(allRequestsArray.indexOf(currentRequestId));
    if (allRequestsArray.indexOf(currentRequestId) != 0) {
      setCurrentRequestId(
        allRequestsArray[allRequestsArray.indexOf(currentRequestId) - 1]
      );

      navigate(
        `/admin/${window.location.href.indexOf("/admin/requests/sc-requests") > -1
          ? "requests/sc-requests"
          : ""
        }${window.location.href.indexOf("/admin/requests/gco-requests") > -1
          ? "requests/gco-requests"
          : ""
        }${window.location.href.indexOf("/admin/requests/imo-requests") > -1
          ? "requests/imo-requests"
          : ""
        }${window.location.href.indexOf("/admin/users/completed-applications") >
          -1
          ? "users/completed-applications"
          : ""
        }${window.location.href.indexOf("/admin/requests/qna-requests") > -1
          ? "requests/qna-requests"
          : ""
        }${window.location.href.indexOf("/admin/requests/hayya-requests") > -1
          ? "requests/hayya-requests"
          : ""
        }${window.location.href.indexOf("/admin/users/incomplete-applications") >
          -1
          ? "users/incomplete-applications"
          : ""
        }/${allRequestsArray[allRequestsArray.indexOf(currentRequestId) - 1]}`
      );
    }
  };
  const handleNext = () => {
    if (
      allRequestsArray.indexOf(currentRequestId) !=
      allRequestsArray.length - 1
    ) {
      setCurrentRequestId(
        allRequestsArray[allRequestsArray.indexOf(currentRequestId) + 1]
      );

      navigate(
        `/admin/${window.location.href.indexOf("/admin/requests/sc-requests") > -1
          ? "requests/sc-requests"
          : ""
        }${window.location.href.indexOf("/admin/requests/gco-requests") > -1
          ? "requests/gco-requests"
          : ""
        }${window.location.href.indexOf("/admin/requests/imo-requests") > -1
          ? "requests/imo-requests"
          : ""
        }${window.location.href.indexOf("/admin/users/completed-applications") >
          -1
          ? "users/completed-applications"
          : ""
        }${window.location.href.indexOf("/admin/requests/qna-requests") > -1
          ? "requests/qna-requests"
          : ""
        }${window.location.href.indexOf("/admin/requests/hayya-requests") > -1
          ? "requests/hayya-requests"
          : ""
        }${window.location.href.indexOf("/admin/users/incomplete-applications") >
          -1
          ? "users/incomplete-applications"
          : ""
        }/${allRequestsArray[allRequestsArray.indexOf(currentRequestId) + 1]}`
      );
    }
  };
  const switchAcceditationType = (accreditationType) => {
    return accreditationType?.details?.label;
  };
  const handleImpersonate = () => {
    axios
      .get(`admin/v1/registration-requests/${id}/impersonate`)
      .then((result) => {
        result?.data?.link && window.open(result?.data?.link, "_blank");
      })
      .catch((err) => console.log(err));
  };
  const switchHayyaStatusColor = (item) => {
    let str = "";
    if (
      item?.hayya_status === "Data Error" &&
      item?.hayya_response?.response?.data?.fanIdNo
    ) {
      str = "#ff0000";
    } else if (item?.hayya_status === "New") {
      str = "#FF4700";
    } else if (item?.hayya_status === "Pending") {
      str = "#4B4B4B";
    } else if (item?.hayya_status === "Pending Entry Visa") {
      str = "#de9900";
    } else if (item?.hayya_status === "Approved") {
      str = "#00CFB7";
    } else if (item?.hayya_status === "400") {
      str = "#ff0000";
    } else if (item?.hayya_status === "Rejected") {
      str = "#B85555";
    }

    return str;
  };
  //a
  const switchHayyaStatus = (item) => {
    let str = "";
    if (
      item?.hayya_status === "Data Error" &&
      item?.hayya_response?.response?.data?.fanIdNo
    ) {
      str = "MOI Error";
    } else if (item?.hayya_status === "New") {
      str = "New";
    } else if (item?.hayya_status === "Pending") {
      str = "Pending MOI";
    } else if (item?.hayya_status === "Pending Entry Visa") {
      str = "Pending Entry Visa";
    } else if (item?.hayya_status === "Approved") {
      str = "MOI Approved";
    } else if (item?.hayya_status === "Rejected") {
      str = "MOI Rejected";
    } else if (item?.hayya_status === "400") {
      str = "Hayya Error";
    } else if (item?.hayya_status === null) {
      str = "-";
    }

    return str;
  };

  const isEditAccess = (window.location.href.indexOf("/admin/requests/hayya-requests") > -1
    || window.location.href.indexOf("/admin/requests/sc-requests") > -1)
    && ["Senior", "HAYYA"].includes(adminRole());

  return (
    <NavigationLayout>
      <Page
        fullWidth
      // primaryAction={{
      //   content: <p style={{ color: "white" }}>Impersonate</p>,
      //   onClick: handleImpersonate,
      // }}
      >
        {loadingMarkup}
        <div className="spinnerContainer-x is-loading">
          <div className="vertical-center">{isLoading && <LoadingBtn />}</div>
        </div>
        <div className="my-5">
          <p className="purpletitle">Accrediation Status</p>
        </div>
        <div style={{ backgroundColor: "white" }}>
          {!result && <div className="text-center py-5"> <Spinner size="large" /></div>}
          {result && allRequestsArray && (
            <div
              className="detailsPage"
              style={{
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              }}
            >
              <div className="headerDetailsDiv">
                <Layout sectioned>
                  {/* <img
                    src={back}
                    className="backImg"
                    onClick={() =>
                      window.location.href.indexOf("/admin/users") > -1
                        ? navigate(`/admin/users`)
                        : navigate(`/admin/requests`)
                    }
                  ></img> */}
                  {adminRole() !== "Viewer" && (
                    <img
                      src={isFlagged ? flagRed : flag}
                      className="flagImg"
                      onClick={handleFlag}
                    ></img>
                  )}
                  <span style={{ marginLeft: "2rem" }}>
                    <span className="application-detail-boxes">Application Ref No. <b>{result?.reference_number}</b></span>
                    <span className="application-detail-boxes">Time of Registration
                      <b>{moment(result.created_at).format(format)}</b></span>
                    <span className="application-detail-boxes">Application Type
                      <b
                        style={{
                          color:
                            parseInt(result?.accreditation_type_id) !== 1 &&
                            (parseInt(result?.accreditation_type_id) === 2
                              ? "#005391"
                              : parseInt(result?.accreditation_type_id) === 3
                                ? "#550065"
                                : "#00671d"),
                        }}
                      >{` ${switchAcceditationType(
                        result.accreditation_type
                      )}`}</b>
                    </span>
                    {window.location.href.indexOf(
                      "/admin/requests/hayya-requests"
                    ) > -1 ? (
                      <span className="application-detail-boxes">
                        Hayya Status&nbsp;
                        <b style={{ color: switchHayyaStatusColor(result) }}>
                          {switchHayyaStatus(result)}
                        </b>
                      </span>
                    ) : (
                      <span className="application-detail-boxes">
                        Registration Status&nbsp;
                        <b style={{ color: switchStatusColor(result.status) }}>
                          {window.location.href.indexOf(
                            "/admin/requests/imo-requests"
                          ) > -1
                            ? result?.status.charAt(0).toUpperCase() +
                            result?.status
                              .slice(1)
                              .replace("_", " ")
                              .replace("-", " ")
                              .replace("sc", "SC")
                              .replace("gco", "IMO")
                              .replace("qna", "QNA")
                            : result?.status.charAt(0).toUpperCase() +
                            result?.status
                              .slice(1)
                              .replace("_", " ")
                              .replace("-", " ")
                              .replace("sc", "SC")
                              .replace("gco", "GCO")
                              .replace("qna", "QNA")}
                        </b>
                      </span>
                    )}
                  </span>

                  <div className="headerDetailsRightText">
                    <span style={{ color: "#989897" }}>{`${allRequestsArray.indexOf(currentRequestId) + 1
                      } of ${allRequestsArray.length}`}</span>
                    <img
                      src={previous}
                      className="previousNextImg"
                      onClick={handlePrevious}
                      style={{
                        opacity:
                          allRequestsArray.indexOf(currentRequestId) == 0
                            ? 0.3
                            : 1,
                        cursor:
                          allRequestsArray.indexOf(currentRequestId) != 0 &&
                          "pointer",
                      }}
                    />
                    <img
                      src={next}
                      className="previousNextImg"
                      onClick={handleNext}
                      style={{
                        opacity:
                          allRequestsArray.indexOf(currentRequestId) ==
                            allRequestsArray.length - 1
                            ? 0.3
                            : 1,
                        cursor:
                          allRequestsArray.indexOf(currentRequestId) !=
                          allRequestsArray.length - 1 && "pointer",
                      }}
                    />
                  </div>
                </Layout>
              </div>
              <div className="progressDiv">
                {/* <p className="purpletitle">Accrediation Status</p> */}

                <div className="rowDiv">

                  <div style={{ width: "90%" }}>
                    <Layout>
                      <div className="containerProgress">
                        <ul className="progressbar">
                          <li
                            className={
                              result.status === "pending" ||
                                result.status === "updated" ||
                                result.status === "new" ||
                                result.status === "approved" ||
                                result.status === "approved_sc" ||
                                result.status === "rejected_sc" ||
                                result.status === "approved_gco" ||
                                result.status === "rejected_gco" ||
                                result.status === "approved_qna" ||
                                result.status === "rejected_qna" ||
                                result.status === "approved_hayya" ||
                                result.status === "rejected_hayya" ||
                                result.status === "approved_lusail"
                                ? "active"
                                : ""
                            }
                          >
                            <div className="li-text">Registered</div>
                            <div className="text-muted fs-6">{result?.status_timestamps?.registered}</div>
                          </li>
                          <li
                            className={`${(result.status === "approved_sc" ||
                              result.status === "approved_gco" ||
                              result.status === "approved_qna" ||
                              result.status === "rejected_gco" ||
                              result.status === "rejected_qna" ||
                              result.status === "approved_hayya" ||
                              result.status === "approved_lusail" ||
                              result.status === "rejected_hayya" ||
                              result.status === "approved" ||
                              (result.status === "approved" &&
                                parseInt(result.accreditation_type_id) ===
                                1) ||
                              (result.status === "approved" &&
                                parseInt(result.accreditation_type_id) ===
                                2) ||
                              (result.status === "approved" &&
                                parseInt(result.accreditation_type_id) ===
                                3) ||
                              (result.status === "approved" &&
                                parseInt(result.accreditation_type_id) ===
                                5)) &&
                              "active"
                              } ${result.status === "rejected_sc" && "rejected"}`}
                          >
                            <div className="li-text">LOC</div>
                            <div className="text-muted fs-6">{result?.status_timestamps?.sc}</div>
                          </li>
                          {parseInt(result.accreditation_type_id) !== 1 && (
                            <li
                              className={`${(result.status === "approved_gco" ||
                                result.status === "approved_qna" ||
                                result.status === "rejected_qna" ||
                                result.status === "approved_hayya" ||
                                result.status === "approved_lusail" ||
                                result.status === "rejected_hayya" ||
                                result.status === "approved" ||
                                (result.status === "approved" &&
                                  parseInt(result.accreditation_type_id) ===
                                  2) ||
                                (result.status === "approved" &&
                                  parseInt(result.accreditation_type_id) ===
                                  3) ||
                                (result.status === "approved" &&
                                  parseInt(result.accreditation_type_id) ===
                                  5)) &&
                                "active"
                                } ${result.status === "rejected_gco" && "rejected"
                                }`}
                            >
                              <div className="li-text">GCO</div>
                              <div className="text-muted fs-6">{result?.status_timestamps?.gco}</div>
                            </li>
                          )}

                          {parseInt(result.accreditation_type_id) !== 1 && (
                            <li
                              className={`${(result.status === "approved_qna" ||
                                result.status === "approved_hayya" ||
                                result.status === "approved_lusail" ||
                                result.status === "rejected_hayya" ||
                                result.status === "approved" ||
                                (result.status === "approved" &&
                                  parseInt(result.accreditation_type_id) ===
                                  2) ||
                                (result.status === "approved" &&
                                  parseInt(result.accreditation_type_id) ===
                                  3) ||
                                (result.status === "approved" &&
                                  parseInt(result.accreditation_type_id) ===
                                  5)) &&
                                "active"
                                } ${result.status === "rejected_qna" && "rejected"
                                }`}
                            >
                              <div className="li-text">QNA</div>
                              <div className="text-muted fs-6">{result?.status_timestamps?.hayya}</div>
                            </li>
                          )}

                          {parseInt(result.accreditation_type_id) !== 1 &&
                            parseInt(result.accreditation_type_id) !== 4 && (
                              <li
                                className={`${result.hayya_status === "Approved" &&
                                  result.status_level.toLowerCase() ===
                                  "hayya" &&
                                  "active"
                                  } `}
                              >
                                <div className="li-text">HAYYA</div>
                                <div className="text-muted fs-6">{result?.status_timestamps?.moi_approved}</div>
                              </li>
                            )}

                          {parseInt(result.accreditation_type_id) !== 1 &&
                            // parseInt(result.accreditation_type_id) !== 4 &&
                            result.status !== "approved_lusail" && (
                              <li
                                className={
                                  result?.hayya_status === "Approved" &&
                                  result.status_level.toLowerCase() ===
                                  "hayya" &&
                                  "activehc" //activefifa
                                }
                              >
                                <div className={`li-text`}>Accredited</div>
                                {
                                  result?.hayya_status === "Approved" &&
                                  result.status_level.toLowerCase() ===
                                  "hayya" && <div className="text-muted fs-6">DONE</div>
                                }
                              </li>
                            )}
                          {(parseInt(result.accreditation_type_id) === 1 ||
                            parseInt(result.accreditation_type_id) === 4) && (
                              <li style={{ visibility: "hidden" }}>
                                <div className="li-text">Accredited</div>
                              </li>
                            )}
                        </ul>
                      </div>
                    </Layout>
                  </div>
                </div>
              </div>
              {/* <Layout>
                <div
                  style={{
                    boxShadow: "0 0 10px rgb(0 0 0 / 0.2)",
                  }}
                >
                  <ul className="progressbar">
                    <li className="active">Registered</li>
                    <li className="active">SC</li>
                    <li>GCO</li>
                    <li>QNA</li>
                    <li>Hayya</li>
                    <li>Accredited</li>
                  </ul>
                </div>
              </Layout> */}

              <Layout>
                <Layout.Section>
                  <div
                    style={{
                      borderRight: "0.1rem solid rgba(225, 227, 229, 1)",
                      paddingBottom: "2rem",
                    }}
                  >
                    {/* <div style={{ maxWidth: "1000px" }}> */}
                    <div className="row">
                      <div class="col-md-8">
                        <FormLayout>
                          <ProfileDetails
                            isEditAccess={isEditAccess}
                            result={result}
                            setIsLoading={setIsLoading}
                            handleChangeShowEditPopup={handleChangeShowEditPopup}
                          />
                          <div className="purpletitle mt-5">Media Role</div>
                          <div className="row Polaris-FormLayout g-0">

                            {
                              result?.organisation?.role?.type?.en && <div className="col-sm-4 Polaris-FormLayout__Item">
                                <div>
                                  <div className="popupGreyText">Role</div>

                                  {result?.organisation?.role?.type?.en ? (
                                    <p className="popupBlackText maxWidthText">
                                      {`${result?.organisation?.role?.type?.en} ${result?.organisation
                                        ?.organisation_role_other
                                        ? `(${result?.organisation?.organisation_role_other})`
                                        : ""
                                        }`}
                                    </p>
                                  ) : (
                                    <p className="popupBlackText maxWidthText">
                                      N/A
                                    </p>
                                  )}
                                </div>
                              </div>
                            }

                          </div>
                          <div className="purpletitle mt-5">
                            Organisation Information
                          </div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Name</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.organisation &&
                                    result?.organisation?.name
                                    ? result?.organisation?.name
                                    : "N/A"}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Address</div>
                                <p className="popupBlackText">
                                  {result?.organisation &&
                                    result?.organisation?.address
                                    ? result?.organisation?.address
                                    : "N/A"}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Country</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.organisation &&
                                    result?.organisation?.country
                                    ? result?.organisation?.country
                                    : "N/A"}
                                  {/* {window.location.href.indexOf(
                                    "/admin/requests/hayya-requests"
                                  ) > -1 ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Country///country///${result.id}///${result?.country}`}
                                    />
                                  ) : null} */}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Email</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.organisation &&
                                    result?.organisation?.email
                                    ? result?.organisation?.email
                                    : "N/A"}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Phone</div>
                                <p
                                  className="popupBlackText maxWidthText"
                                // href={`tel:${`${
                                //   result?.organisation?.phoneAreaCode &&
                                //   result?.organisation?.phoneAreaCode
                                // } ${result?.organisation?.phone}`}`}
                                // style={{ textDecoration: "none" }}
                                >
                                  {orgFlagCode && result?.organisation?.phone && (
                                    <img
                                      src={flagUrl(orgFlagCode.toLocaleLowerCase())}
                                      alt={`${orgFlagCode} flag`}
                                      style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                    />
                                  )}
                                  {result?.organisation &&
                                    result?.organisation?.phone
                                    ? `${result?.organisation?.phoneAreaCode
                                      ? result?.organisation?.phoneAreaCode
                                      : ""
                                    } ${result?.organisation?.phone}`
                                    : "N/A"}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Website</div>
                                <p
                                  className="popupBlackText maxWidthText"
                                // style={{ cursor: "pointer" }}
                                // onClick={() =>
                                //   window.open(
                                //     `${result?.organisation?.website}`
                                //   )
                                // }
                                >
                                  {result?.organisation &&
                                    result?.organisation?.website
                                    ? result?.organisation?.website
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="purpletitle"
                            style={{ marginTop: "3rem" }}
                          >
                            Media Information
                          </div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Media Type</div>
                                <div className="popupBlackText maxWidthText">
                                  {result?.media_type &&
                                    result?.media_type?.ids.length
                                    ? result?.media_type?.ids.map(function (
                                      item,
                                      i
                                    ) {
                                      return (
                                        <ul key={i}>
                                          {result?.media_type.type_other &&
                                            item.media.type.en === "Other" ? (
                                            <li>{`${item.media.type.en} (${result?.media_type.type_other})`}</li>
                                          ) : (
                                            <li>{item?.media?.type?.en}</li>
                                          )}
                                        </ul>
                                      );
                                    })
                                    : "N/A"}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Published | On-air
                                </div>
                                {result?.media_type &&
                                  result?.media_type?.published_on_air ? (
                                  <p className="popupBlackText maxWidthText">
                                    {!result?.media_type?.published_on_air_other
                                      ? result?.media_type?.published_on_air
                                        .charAt(0)
                                        .toUpperCase() +
                                      result?.media_type?.published_on_air.slice(
                                        1
                                      )
                                      : `${result?.media_type?.published_on_air
                                        .charAt(0)
                                        .toUpperCase() +
                                      result?.media_type?.published_on_air.slice(
                                        1
                                      )
                                      } (${result?.media_type
                                        ?.published_on_air_other
                                      })`}
                                  </p>
                                ) : (
                                  <p className="popupBlackText maxWidthText">
                                    N/A
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Language</div>
                                {result?.media_type &&
                                  result?.media_type?.language ? (
                                  <p className="popupBlackText maxWidthText">
                                    {!result?.media_type?.language_other
                                      ? result?.media_type?.language
                                        .charAt(0)
                                        .toUpperCase() +
                                      result?.media_type?.language.slice(1)
                                      : `${result?.media_type?.language
                                        .charAt(0)
                                        .toUpperCase() +
                                      result?.media_type?.language.slice(1)
                                      } (${result?.media_type?.language_other
                                      })`}
                                  </p>
                                ) : (
                                  <p className="popupBlackText maxWidthText">
                                    N/A
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Reach</div>
                                {result?.media_type &&
                                  result?.media_type?.reach ? (
                                  <p className="popupBlackText maxWidthText">
                                    {result?.media_type?.reach
                                      .charAt(0)
                                      .toUpperCase() +
                                      result?.media_type?.reach.slice(1)}
                                  </p>
                                ) : (
                                  <p className="popupBlackText ">N/A</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="purpletitle mt-5">Personal Data</div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Given Name</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.user?.first_name
                                    ? result?.user?.first_name
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Given Name///first_name///${result.id}///${result?.user?.first_name}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Second Name</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.second_name
                                    ? result?.second_name
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Second Name///second_name///${result.id}///${result?.second_name}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Third Name</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.third_name
                                    ? result?.third_name
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Third Name///third_name///${result.id}///${result?.third_name}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Fourth Name</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.fourth_name
                                    ? result?.fourth_name
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Fourth Name///fourth_name///${result.id}///${result?.fourth_name}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Family Name</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.user?.last_name
                                    ? result?.user?.last_name
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Family Name///last_name///${result.id}///${result?.user?.last_name}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Application Email</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.application_email
                                    ? result?.application_email
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Application Email///application_email///${result.id}///${result?.application_email}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>

                            {/*<div className="col-sm-4 Polaris-FormLayout__Item">*/}
                            {/*   <div>*/}
                            {/*    <div className="popupGreyText">*/}
                            {/*      Arabic First Name*/}
                            {/*    </div>*/}
                            {/*    <p className="popupBlackText">*/}
                            {/*      {result?.first_name_ar*/}
                            {/*        ? result?.first_name_ar*/}
                            {/*        : "N/A"}*/}
                            {/*    </p>*/}
                            {/*  </div> */}
                            {/*</div>*/}
                          </div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Arabic First Name
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.first_name_ar
                                    ? result?.first_name_ar
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Arabic First Name///first_name_ar///${result.id}///${result?.first_name_ar}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Arabic Second Name
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.second_name_ar
                                    ? result?.second_name_ar
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Arabic Second Name///second_name_ar///${result.id}///${result?.second_name_ar}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Arabic Third Name
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.third_name_ar
                                    ? result?.third_name_ar
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Arabic Third Name///third_name_ar///${result.id}///${result?.third_name_ar}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Arabic Fourth Name
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.fourth_name_ar
                                    ? result?.fourth_name_ar
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Arabic Fourth Name///fourth_name_ar///${result.id}///${result?.fourth_name_ar}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Arabic Family Name
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.last_name_ar
                                    ? result?.last_name_ar
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Arabic Family Name///last_name_ar///${result.id}///${result?.last_name_ar}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Email</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.user?.email
                                    ? result?.user?.email
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                            {/* <div className="col-sm-4 Polaris-FormLayout__Item"></div> */}
                          </div>

                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Nationality</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.nationality
                                    ? findArrayElementByCode(
                                      countryList,
                                      String(result?.nationality)
                                    )?.name
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Nationality///nationality///${result.id}///${result?.nationality}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Date of Birth</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.user?.date_of_birth
                                    ? result.user?.date_of_birth
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Date of Birth///date_of_birth///${result.id}///${result?.user?.date_of_birth}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Sex</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.user?.gender !== null &&
                                    result?.user?.gender !== ""
                                    ? switchGender(result?.user?.gender)
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Gender///gender///${result.id}///${result?.user?.gender}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Emergency Contact Name
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.emergency_contact_name
                                    ? result?.emergency_contact_name
                                    : "N/A"}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Emergency Contact Mobile Number
                                </div>
                                <p
                                  className="popupBlackText maxWidthText"
                                // style={{ textDecoration: "none" }}
                                // href={`tel:${`${
                                //   result?.emergency_contact_PhoneAreaCode &&
                                //   result?.emergency_contact_PhoneAreaCode
                                // } ${result?.emergency_contact_number}`}`}
                                >
                                  {console.log(personalFlagCode)}
                                  {personalFlagCode && result?.emergency_contact_number && (
                                    <img
                                      src={flagUrl(personalFlagCode.toLocaleLowerCase())}
                                      alt={`${personalFlagCode} flag`}
                                      style={{ width: '24px', border: '1px solid #ececec', marginRight: '8px' }}
                                    />
                                  )}
                                  {result?.emergency_contact_number
                                    ? `${result?.emergency_contact_PhoneAreaCode
                                      ? result?.emergency_contact_PhoneAreaCode
                                      : ""
                                    } ${result?.emergency_contact_number}`
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-4 Polaris-FormLayout__Item"></div>
                          </div>
                          <div className="purpletitle mt-5">
                            Identification Data
                          </div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Document Name</div>
                                {/* only label changed from document type to document name*/}
                                <p className="popupBlackText maxWidthText">
                                  {result?.document_type !== null &&
                                    result?.document_type !== ""
                                    ? switchDocumentType(result?.document_type)
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Document Type///document_type///${result.id}///${result?.document_type}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Document Number
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.document_number !== null
                                    ? result?.document_number
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Document Number///document_number///${result.id}///${result?.document_number}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Document Type
                                  {/* only label changed from document subtype to document type*/}
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {(result?.document_sub_type !== null) &
                                    (result?.document_sub_type !== "")
                                    ? switchDocumentSubType(
                                      result?.document_sub_type
                                    )
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Document SubType///document_sub_type///${result.id}///${result?.document_sub_type}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Registration Reference Number</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.credential_reference_number !== null &&
                                    result?.credential_reference_number !== ""
                                    ? result?.credential_reference_number
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Passport Issuing Country
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.passport_issuing_country
                                    ? findArrayElementByCode(
                                      countryList,
                                      String(result?.passport_issuing_country)
                                    ).name
                                    : "N/A"}
                                  {isEditAccess ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={` Passport Issuing Country///passport_issuing_country///${result.id}///${result?.passport_issuing_country}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Document Expiry Date
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.document_expiry_date
                                    ? result?.document_expiry_date
                                    : "N/A"}
                                  {window.location.href.indexOf(
                                    "/admin/requests/hayya-requests"
                                  ) > -1 ? (
                                    <img
                                      src={editImg}
                                      className="editImg"
                                      onClick={handleChangeShowEditPopup}
                                      id={`Document Expiry Date///document_expiry_date///${result.id}///${result?.document_expiry_date}`}
                                    />
                                  ) : null}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div></div>
                            </div>
                          </div>
                          <div className="purpletitle mt-5">
                            Accrediation Data
                          </div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Subcategory</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.organisation?.role
                                    ?.media_accreditation_subcategory?.en
                                    ? result?.organisation?.role
                                      ?.media_accreditation_subcategory?.en
                                    : "N/A"}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">Function</div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.organisation?.role?.type?.en
                                    ? result?.organisation?.role?.type?.en
                                    : "N/A"}
                                </p>
                              </div>
                            </div>

                            {/* <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Accreditation Terms Acceptance
                                </div>
                                <p className="popupBlackText">Accepted</p>
                              </div>
                            </div> */}
                          </div>
                          <div className="purpletitle mt-5">Additional Data</div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Bringing equipment?
                                </div>
                                <p className="popupBlackText">
                                  {result?.additional_data?.bringing_equipment !==
                                    null
                                    ? result?.additional_data?.bringing_equipment
                                      ? "Yes"
                                      : "No"
                                    : "N/A"}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Bringing wireless equipment?
                                </div>
                                <p className="popupBlackText">
                                  {result?.wireless_equipment !== null
                                    ? result?.additional_data?.wireless_equipment
                                      ? "Yes"
                                      : "No"
                                    : "N/A"}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-4 Polaris-FormLayout__Item"></div>
                          </div>

                          {/* {result.accreditation_type_id ? (
                          <FormLayout.Group>
                            <div style={{ marginBottom: "2rem" }}>
                              <div className="purpletitle">
                                Accreditation Type
                              </div>

                              <p
                                className="popupBlackText"
                                style={{
                                  cursor: "pointer",
                                  maxWidth: "90%",
                                  wordWrap: "break-word",
                                }}
                              >
                                {switchAcceditationType(
                                  parseInt(result.accreditation_type_id)
                                )}
                              </p>
                            </div>
                          </FormLayout.Group>
                        ) : null} */}
                          {result?.reason_of_rejection && (
                            <FormLayout.Group>
                              <div style={{ marginBottom: "2rem" }}>
                                <div className="purpletitle">
                                  Reason of Rejection
                                </div>

                                <p
                                  className="popupBlackText"
                                  style={{ cursor: "pointer" }}
                                >
                                  {result?.reason_of_rejection}
                                </p>
                              </div>
                            </FormLayout.Group>
                          )}
                          {result?.email_message && (
                            <FormLayout.Group>
                              <div style={{ marginBottom: "2rem" }}>
                                <div className="purpletitle">Email Message</div>

                                <p
                                  className="popupBlackText"
                                  style={{
                                    cursor: "pointer",
                                    maxWidth: "90%",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {result.email_verified_at
                                    ? `${result?.email_message
                                    } ( Sent on ${moment(
                                      result.email_verified_at
                                    ).format(format)})`
                                    : result?.email_message}
                                </p>
                              </div>
                            </FormLayout.Group>
                          )}
                          {/* <FormLayout.Group>
                          <div style={{ marginBottom: "2rem" }}>
                            <div className="purpletitle mt-5">
                              Terms & Conditions
                            </div>

                            <p
                              className="popupBlackText"
                              style={{ cursor: "pointer" }}
                            >
                              Accepted
                            </p>
                          </div>
                        </FormLayout.Group> */}
                          {/* <FormLayout.Group>
                          <div style={{ marginBottom: "2rem" }}>
                            <div className="purpletitle" purpletitle>
                              FIFA Accredited
                            </div>

                            <p
                              className="popupBlackText"
                              style={{
                                cursor: "pointer",
                                maxWidth: "90%",
                                wordWrap: "break-word",
                              }}
                            >
                              {result.fifa_accredited ? "Yes" : "No"}
                            </p>
                          </div>
                        </FormLayout.Group> */}
                        </FormLayout>
                      </div>
                      <div className="col-md-4">
                        {result?.passport_scan || result?.facial_photo ? (
                          <div style={{
                            borderLeft: "1px solid #dedede"
                          }}>
                            <div className="row Polaris-FormLayout g-0">
                              <div className="col-sm-4 Polaris-FormLayout__Item">
                                <div
                                  style={{
                                    marginTop: "1.3rem",
                                    // marginBottom: "2rem",
                                  }}
                                >
                                  <ButtonGroup>

                                    <div className="position-relative">
                                      <div
                                        className="purpletitle mb-5"
                                      >
                                        Identification Uploads
                                      </div>


                                      {result.facial_photo && (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            lineHeight: "1",
                                          }}
                                        >
                                          <div
                                            style={
                                              {
                                                // marginBottom: ".5rem",
                                              }
                                            }
                                          // key={item.id}
                                          >
                                            <div className="popupGreyText mb-3">
                                              Facial Photo
                                            </div>
                                            <div
                                              id={result.facial_photo}
                                              onClick={() =>
                                                setFacialModalOpen(!facialModalOpen)
                                              }
                                              className=""
                                              style={{ width: "auto" }}
                                            >
                                              <div className="rowDiv">
                                                {!Object.keys(result?.facial_photo_base64).length > 0 ?
                                                  <Spinner size="small" /> :
                                                  <img src={previewBase64toBlob(result?.facial_photo_base64)}
                                                    className="details-img"

                                                    width={260}
                                                  />

                                                }

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      {isEditAccess &&
                                        result.facial_photo ? (
                                        <div className="position-absolute m-3 end-0 bottom-0 pt-0">
                                          <button
                                            className="edit-img-btn fw-bold m-0 border-0 text-light rounded-pill px-3"
                                            onClick={handleChangeShowEditPopup}
                                            id={`Facial Photo///facial_photo///${result.id}///${result?.facial_photo}`}
                                          >
                                            Edit
                                          </button>

                                          {/* <img
                                            src={editImg}
                                            className="editImg m-0 p-0"
                                            onClick={handleChangeShowEditPopup}
                                            id={`Facial Photo///facial_photo///${result.id}///${result?.facial_photo}`}
                                          /> */}
                                        </div>
                                      ) : null}
                                    </div>
                                  </ButtonGroup>
                                  <Modal
                                    open={facialModalOpen}
                                    onClose={closeModal}
                                    title="Facial Image"
                                    primaryAction={{
                                      content: 'Close',
                                      onAction: closeModal,
                                    }}
                                    large={true}
                                  >
                                    <Modal.Section>
                                      {loadingSpinner}
                                      {Object.keys(result?.facial_photo_base64).length > 0 &&
                                        <div style={{ textAlign: "center", display: "block" }}>
                                          <img
                                            src={previewBase64toBlob(result?.facial_photo_base64)}
                                            style={{ width: '90%', }}
                                          />
                                        </div>}
                                    </Modal.Section>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                            <div className="row Polaris-FormLayout g-0">
                              <div className="col-sm-4 Polaris-FormLayout__Item">
                                <div
                                  style={{
                                    marginTop: "1.3rem",
                                    // marginBottom: "2rem",
                                  }}
                                >
                                  <ButtonGroup>

                                    <div className="position-relative">
                                      {result.passport_scan && (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            lineHeight: "1",
                                          }}
                                        >
                                          <div
                                            style={
                                              {
                                                // marginBottom: ".5rem",
                                              }
                                            }
                                          // key={item.id}
                                          >
                                            <div className="popupGreyText mb-3">
                                              Passport Scan
                                            </div>
                                            <div
                                              id={result.passport_scan}
                                              onClick={() =>
                                                setPassportModalOpen(!passportModalOpen)
                                              }
                                              style={{ width: "auto" }}
                                            >
                                              <div className="rowDiv">
                                                {/* <img
                                                src={attachementsImage}
                                                width={15}
                                                className="credentialsImage"
                                                id="attachementsImage"
                                              /> */}
                                                {!Object.keys(result?.passport_scan_base64).length > 0 ?
                                                  <Spinner size="small" /> :
                                                  <img src={previewBase64toBlob(result?.passport_scan_base64)}
                                                    className="details-img"
                                                    width={260} />
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {isEditAccess &&
                                        result.passport_scan ? (
                                        <div className="position-absolute m-3 end-0 bottom-0 p-1 px-2 pt-0">
                                          {/* <img
                                            src={editImg}
                                            className="editImg p-0 m-0"
                                            onClick={handleChangeShowEditPopup}
                                            id={`Passport Scan///passport_scan///${result.id}///${result?.passport_scan}`}
                                          /> */}
                                          <button
                                            className="edit-img-btn fw-bold m-0 border-0 text-light rounded-pill px-3"
                                            onClick={handleChangeShowEditPopup}
                                            id={`Passport Scan///passport_scan///${result.id}///${result?.passport_scan}`}
                                          >
                                            Edit
                                          </button>
                                        </div>
                                      ) : null}
                                    </div>
                                  </ButtonGroup>
                                  <Modal
                                    open={passportModalOpen}
                                    onClose={closeModal}
                                    title="Passport Images"
                                    primaryAction={{
                                      content: 'Close',
                                      onAction: closeModal,
                                    }}
                                    large={true}
                                  >
                                    <Modal.Section>
                                      {loadingSpinner}
                                      {Object.keys(result?.passport_scan_base64).length > 0 &&
                                        <div style={{ textAlign: "center", display: "block" }}>
                                          <img
                                            src={previewBase64toBlob(result?.passport_scan_base64)}
                                            className="passport"
                                            style={{ width: '90%' }}
                                          />
                                          <a
                                            href={previewBase64toBlob(result?.passport_scan_base64)}
                                            download="document.png"
                                            className="fw-bold text-dark"
                                            style={{ display: 'block', marginTop: '10px' }}
                                          >
                                            Download Image
                                          </a>
                                        </div>
                                      }
                                    </Modal.Section>
                                  </Modal>
                                </div>
                              </div>
                            </div>

                          </div>
                        ) : null}
                        {result?.documents && result?.documents?.length ? (
                          <div style={{
                            borderLeft: "1px solid #dedede"
                          }}>


                            <div className="row Polaris-FormLayout g-0">
                              <div className="col-sm-4 Polaris-FormLayout__Item px-4">
                                <div
                                  className="purpletitle mx-5 px-5 my-0"
                                  style={{ paddingTop: "3rem" }}
                                >
                                  Credentials
                                </div>
                                <div
                                  style={{
                                    marginTop: "1.3rem",
                                    // marginBottom: "2rem",
                                  }}
                                >
                                  <ButtonGroup>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        lineHeight: "1",
                                        gap: "3px"
                                      }}
                                    >
                                      {result.documents.map((item, index) => {
                                        return (
                                          <div
                                            style={
                                              {
                                                // marginBottom: ".5rem",
                                              }
                                            }
                                            key={item.id}
                                          >
                                            <div
                                              id={item.document}
                                              onClick={(e) => handleClickCredentialDocument(e)}
                                              className="button buttonCredentials m-0"
                                              style={{ width: "auto" }}
                                            >
                                              <div className="rowDiv">
                                                {/* <img
                                                  src={
                                                    item.document.split(
                                                      "."
                                                    )[1] === "pdf"
                                                      ? attachementsImage
                                                      : attachementsImage
                                                  }
                                                  width={15}
                                                  className="credentialsImage"
                                                  id="attachementsImage"
                                                /> */}
                                                <span style={{ maxWidth: '250px' }}>
                                                  {`Document (${index + 1})`}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </ButtonGroup>
                                  {/* {result.document_base64.map((doc, index) => (
                                    <div key={index} style={{ marginBottom: '20px' }}>
                                      {doc.type.toLowerCase() === "jpeg" || doc.type.toLowerCase() === "jpg" || doc.type.toLowerCase() === "png" ? (
                                        <img
                                          src={previewBase64toBlob(doc)}
                                          style={{ maxWidth: '300px', height: 'auto' }}
                                          alt={`Document ${index + 1}`}
                                        />
                                      ) : (
                                        <iframe
                                          src={previewBase64toBlob(doc)}
                                          title={`Document ${index + 1}`}
                                          width="600"
                                          height="350"
                                        />
                                      )}
                                    </div>
                                  ))} */}
                                  {selectedImage && (
                                    <Modal
                                      open={docModalOpen}
                                      title="Document"
                                      onClose={closeModal}
                                      primaryAction={{
                                        content: 'Close',
                                        onAction: closeModal,
                                      }}
                                      large={true}
                                    >
                                      <Modal.Section>
                                        {loadingSpinner}
                                        <div style={{ textAlign: "center", display: "block" }}>
                                          {docTypeName.toLowerCase().endsWith('.pdf') ? (
                                            <span>
                                              <iframe
                                                src={selectedImage}
                                                title="Document Preview"
                                                height=""
                                                style={{ border: 'none', width: "95%", minHeight: '500px' }}
                                              />
                                              <a
                                                href={selectedImage}
                                                download="document.pdf"
                                                className="fw-bold text-dark"
                                                style={{ display: 'block', marginTop: '10px' }}
                                              >
                                                Download PDF
                                              </a>
                                            </span>
                                          ) : (
                                            <span>
                                              <img
                                                src={selectedImage}
                                                alt="Document Preview"
                                                style={{ width: '90%' }}
                                              />
                                              <a
                                                href={selectedImage}
                                                download="document.png"
                                                className="fw-bold text-dark"
                                                style={{ display: 'block', marginTop: '10px' }}
                                              >
                                                Download Image
                                              </a>
                                            </span>
                                          )}
                                        </div>
                                      </Modal.Section>
                                    </Modal>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {result?.equipments ? (
                          <div>
                            <div
                              className="purpletitle"
                              style={{ marginTop: "3rem" }}
                            >
                              Equipments
                            </div>

                            <div className="row Polaris-FormLayout g-0">
                              <div className="col-sm-4 Polaris-FormLayout__Item">
                                <div
                                  style={{
                                    marginTop: "1.3rem",
                                    // marginBottom: "2rem",
                                  }}
                                >
                                  <ButtonGroup>
                                    {result.equipments && (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          flexWrap: "wrap",
                                          lineHeight: "1",
                                        }}
                                      >
                                        <div
                                          style={
                                            {
                                              // marginBottom: ".5rem",
                                            }
                                          }
                                        // key={item.id}
                                        >
                                          <div
                                            id={result.equipments}
                                            onClick={(e) =>
                                              handleClickDocument(e)
                                            }
                                            className="button buttonCredentials"
                                            style={{ width: "auto" }}
                                          >
                                            <div className="rowDiv">
                                              {/* <img
                                                src={attachementsImage}
                                                width={15}
                                                className="credentialsImage"
                                                id="attachementsImage"
                                              /> */}
                                              <span>
                                                {
                                                  result.equipments.split(
                                                    "/"
                                                  )[2]
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </ButtonGroup>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Layout.Section>

                {(moiSubmitApi ||
                  window.location.href.indexOf(
                    "/admin/requests/hayya-requests"
                  ) === -1) && (
                    <ActionButtons
                      clickedItem={result}
                      handleRowApprove={handleRowApprove}
                      handleRowReject={handleRowReject}
                      handleRowUnreject={handleRowUnreject}
                      handleDownloadAll={handleDownloadAll}
                      handleExportEntryPermit={handleExportEntryPermit}
                      reasonOfRejectionValue={reasonOfRejectionValue}
                      handleChangeReasonOfRejection={
                        handleChangeReasonOfRejection
                      }
                      handleEmail={handleEmail}
                      status={switchStatus()}
                      handleSync={handleSync}
                      handleResend={handleResend}
                      // hayyaResponse={hayyaResponse}
                      handleDeleteAccount={handleDeleteAccount}
                      handleDeleteApplication={handleDeleteApplication}
                      handleGetFanId={handleGetFanId}
                      handleUpdateFanId={handleUpdateFanId}
                      handleSendLiferay={handleSendLiferay}
                      handleUpdateLiferay={handleUpdateLiferay}
                      handleMoiSync={handleMoiSync}
                    />
                  )}
              </Layout>
            </div>
          )}
        </div>
        {/* <Card sectioned> */}

        {/* </Card> */}
        <Modal open={activeAreYouSure}>
          <Modal.Section>
            <div
              className="popupBlackText"
              style={{ marginBottom: "4rem", fontSize: "1.6rem" }}
            >
              {questionValue}
            </div>
            {reference.current.split("_")[1] == "reject" && (
              <FormLayout>
                <div className="popupGreyText">Reason of Rejection</div>
                <TextField
                  multiline={4}
                  value={reasonOfRejectionValue}
                  onChange={handleChangeReasonOfRejection}
                  error={reasonError}
                ></TextField>
              </FormLayout>
            )}
            <div
              className="rowDiv"
              style={{
                justifyContent: "center",
                marginTop: "3rem",
                marginBottom: "2rem",
              }}
            >
              <button
                className={`button ${isSendingEmail ? "buttonDisabled" : "buttonApprove"
                  }`}
                onClick={handleYes}
              >
                Yes
              </button>
              <button className="button buttonEmail" onClick={handleNo}>
                No
              </button>
            </div>
          </Modal.Section>
        </Modal>

        <Modal open={activeDelete}>
          <Modal.Section>
            <FormLayout>
              <div className="popupGreyText">
                Are you sure you want to delete this user? <br />
                If yes, please send the user's email to the DevOps team and then
                click on "Yes"
              </div>
              {/* <TextField
              multiline={4}
              value={deleteMessageValue}
              onChange={handleChangeDeleteMessage}
              error={deleteError}
              maxLength={200}
            ></TextField> */}
            </FormLayout>

            <div
              className="rowDiv"
              style={{
                justifyContent: "center",
                marginTop: "3rem",
                marginBottom: "2rem",
              }}
            >
              <button
                className={`button ${isSendingDelete ? "buttonDisabled" : "buttonApprove"
                  }`}
                onClick={!isSendingDelete && handleSendDeleteAccount}
              >
                Yes
              </button>
              <button
                className="button buttonEmail"
                onClick={handleCancelDeleteAccount}
              >
                No
              </button>
            </div>
          </Modal.Section>
        </Modal>

        <Modal open={activeDeleteApp}>
          <Modal.Section>
            <FormLayout>
              <div className="popupGreyText">
                Are you sure you want to delete this user's application? <br />
                If yes, the user can submit a new one later using the same
                account.
              </div>
              {/* <TextField
              multiline={4}
              value={deleteMessageValue}
              onChange={handleChangeDeleteMessage}
              error={deleteError}
              maxLength={200}
            ></TextField> */}
            </FormLayout>

            <div
              className="rowDiv"
              style={{
                justifyContent: "center",
                marginTop: "3rem",
                marginBottom: "2rem",
              }}
            >
              <button
                className={`button ${isSendingDelete ? "buttonDisabled" : "buttonApprove"
                  }`}
                onClick={!isSendingDelete && handleSendDeleteApplication}
              >
                Yes
              </button>
              <button
                className="button buttonEmail"
                onClick={handleCancelDeleteApplication}
              >
                No
              </button>
            </div>
          </Modal.Section>
        </Modal>

        <Modal open={activeEmail}>
          <Modal.Section>
            <FormLayout>
              <div className="popupGreyText">Type your message here</div>
              {/* <TextField
              multiline={4}
              value={emailMessageValue}
              onChange={handleChangeEmailMessage}
              error={emailError}
              maxLength={200}
            ></TextField> */}

              <div className="Polaris-TextField Polaris-TextField--multiline">
                <textarea
                  id="PolarisTextField6"
                  className="Polaris-TextField__Input"
                  maxLength={200}
                  aria-labelledby="PolarisTextField6Label"
                  aria-invalid="false"
                  aria-multiline="true"
                  style={{ height: 106 }}
                  defaultValue={""}
                  value={emailMessageValue}
                  onChange={handleChangeEmailMessage}
                  dir={`${/[\u0600-\u06FF]/.test(emailMessageValue) ? "rtl" : "ltr"
                    }`}
                />
                <div className="Polaris-TextField__Backdrop" />
                <div aria-hidden="true" className="Polaris-TextField__Resizer">
                  <div className="Polaris-TextField__DummyInput">
                    <br />
                  </div>
                  <div className="Polaris-TextField__DummyInput">
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              {emailError ? (
                <InlineError message={emailError}></InlineError>
              ) : null}
            </FormLayout>

            <div
              className="rowDiv"
              style={{
                textAlign: "center",
                marginTop: "3rem",
                marginBottom: "2rem",
              }}
            >
              <button
                className={`button ${isSendingEmail ? "buttonDisabled" : "buttonApprove"
                  }`}
                onClick={!isSendingEmail && handleSend}
              >
                Send
              </button>
              <button className="button buttonEmail" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </Modal.Section>
        </Modal>

        {showFanIDPopup && (
          <FanIDPopup
            fanIDResponse={fanIdResponse}
            handleChangeShowPopup={handleChangeShowPopup}
            fanIDError={fanIdError}
          />
        )}
        {showEditPopup && (
          <EditPopup
            handleChangeShowEditPopupWithChanges={
              handleChangeShowEditPopupWithChanges
            }
            param={refClickedItem.current}
            result={result}
            handleChangeShowEditPopup={handleChangeShowEditPopup}
          />
        )}
        {toastMarkup}
      </Page>
    </NavigationLayout>
  );

  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }
  function findArrayElementByCode(array, id) {
    return array.find((element, index) => {
      return parseInt(element["country-code"]) === parseInt(id);
    });
  }
};
export default RequestDetails;
