import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";

const SocialMedia = () => {
  const [isSaving, setIsSaving] = useState(true);
  const handleChangeFacebook = (newValue) => {
    setItem({ ...item, facebook: newValue });
  };
  const handleChangeInstagram = (newValue) => {
    setItem({ ...item, instagram: newValue });
  };
  const handleChangeYoutube = (newValue) => {
    setItem({ ...item, youtube: newValue });
  };
  const handleChangeTwitter = (newValue) => {
    setItem({ ...item, twitter: newValue });
  };
  const [item, setItem] = useState({
    facebook: "",
    instagram: "",
    youtube: "",
    twitter: "",
  });
  useEffect(() => {
    axios
      .get(`admin/v1/our-team`)
      .then((result) => {
        setIsSaving(false);
        setItem({});
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page title="Social Media Links" breadcrumbs={[{ url: "/admin/content" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Facebook Link</TextStyle>
              <TextField
                value={item.facebook}
                onChange={handleChangeFacebook}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Instagram Link</TextStyle>
              <TextField
                value={item.instagram}
                onChange={handleChangeInstagram}
                align="left"
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Twitter Link</TextStyle>
              <TextField
                value={item.twitter}
                onChange={handleChangeTwitter}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Youtube Link</TextStyle>
              <TextField
                value={item.youtube}
                onChange={handleChangeYoutube}
                align="left"
              />
            </FormLayout>
          </FormLayout.Group>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    // setIsSaving(true);
    // const body = {
    //   social_media: {
    //     Facebook: item.facebook,
    //     Instagram: item.instagram,
    //     Twitter: item.twitter,
    //   },
    // };
    // axios
    //   .post(`admin/v1/news/${id}/update`, form_data)
    //   .then((res) => {
    //     if (res.status == 200) {
    //       history.push("/admin/content/news");
    //     }
    //   })
    //   .catch((err) => console.log(""));
  }
};
export default SocialMedia;
