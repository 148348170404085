import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Image,
  DisplayText,
  Loading,
  TextStyle,
  Spinner,
  Toast,
  Modal,
  TextField,
  FormLayout,
  Page,
  Card,
  Button,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import emptyListImage from "../../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import filterGrey from "../../../Assets/Images/filter-grey.svg";
import filterOrange from "../../../Assets/Images/filter-orange.svg";
import FilterCloseButton from "../../../Components/FilterCloseButton";
import FilterParentDivEvents from "../../../Components/FilterParentDivEvents";
import { useNavigate } from "react-router-dom";
import NavigationLayout from "../../../Components/NavigationLayout";

const TableOfInterviewees = (props) => {
  const navigate = useNavigate();
  const [ts, setTs] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const refName = useRef(null);
  const refType = useRef(null);
  const refTime = useRef(null);
  const refLocation = useRef("");
  const refCreatedAt = useRef(null);
  const format = "YY-MM-DD HH:mm";
  const reference = useRef("");
  const reference2 = useRef("");
  const mailIdRef = useRef("");
  const perPage = 20;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const [filterState, setFilterState] = useState("");
  const handleChangePage = (event, value) => {
    refBoolPage.current = true;
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const [searchValue, setSearchValue] = useState("");
  const [selectedEventLocation, setSelectedEventLocation] = useState("All");
  const [selectedEventType, setSelectedEventType] = useState("All");
  const [eventStartTimeValue, setEventStartTimeValue] = useState("");
  const handleChangeEventStartTime = useCallback(
    (newValue) => setEventStartTimeValue(newValue),
    []
  );
  const [locations, setLocations] = useState("");
  const [types, setTypes] = useState("");
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responseTypes = "";
    let responseLocations = "";
    try {
      responseTypes = await axios.get(`admin/v1/interviewees/types`);
      setTypes(responseTypes?.data?.data?.data);
      console.log("types=", responseTypes?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
    try {
      responseLocations = await axios.get(`admin/v1/interviewees/locations`);
      setLocations(responseLocations?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, [page, filterState, searchValue, ts]);
  const [isHovered, setIsHovered] = useState(false);
  const handleCancel = () => {
    setActiveEmail(false);
  };
  const [emailMessageValue, setEmailMessageValue] = useState("");
  const handleChangeEmailMessage = useCallback(
    (newValue) => setEmailMessageValue(newValue),
    []
  );

  const handleSend = () => {
    setIsSendingEmail(true);
    const form_data = new FormData();

    form_data.append("message", emailMessageValue);
    form_data.append("_method", "PATCH");

    axios
      .post(
        `admin/v1/registration-requests/${mailIdRef.current}/email`,
        form_data
      )
      .then((res) => {
        setActiveEmail(false);
        setIsSendingEmail(false);
        setToastContent("Email Sent Successfully");
        toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => console.log(""));
  };

  const handleFlag = (e) => {
    // isFlagged ? setIsFlagged(false) : setIsFlagged(true);
    const form_data = new FormData();
    form_data.append("_method", "PATCH");
    form_data.append(
      "is_flagged",
      !JSON.parse(e.currentTarget.id).is_flagged ? 1 : 0
    );
    axios
      .post(
        `admin/v1/registration-requests/${
          JSON.parse(e.currentTarget.id).id
        }/flag`,
        form_data
      )
      .then((res) => {
        setTs(+new Date());
        //getData();
      })
      .catch((err) => console.log(""));
  };

  const [activeEmail, setActiveEmail] = useState(false);
  const handleEmail = (e) => {
    mailIdRef.current = JSON.parse(e.currentTarget.id).id;
    setActiveEmail(true);
  };

  const handleExport = () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}admin/v1/users/export/${
        props.selectedTab === "0"
          ? "sc"
          : props.selectedTab === "1"
          ? "gco"
          : props.selectedTab === "2"
          ? "qna"
          : "hayya"
      }?per_page=${page}&page=${page}${
        searchValue
          ? `&filter[${filterState}]=${
              searchValue.toLowerCase() !== "all"
                ? searchValue.toLowerCase()
                : ""
            }`
          : ""
      }`
    );
  };

  const greyBackgroundCondition = (param) => {
    let bool = false;

    return bool;
  };
  const handleEdit = (param) => {
    navigate(`/admin/manage-interviewees/interviewees/${param}`);
  };
  const greyBackgroundColor = "rgba(196, 196, 188, 0.3)";
  const getData = () => {
    axios
      .get(
        `admin/v1/interviewees?per_page=${perPage}&page=${
          refBoolPage.current ? page : 1
        }${
          searchValue && filterState
            ? `&filter[${filterState}]=${
                searchValue.toLowerCase() !== "all"
                  ? searchValue.toLowerCase()
                  : ""
              }`
            : ""
        }`
      )
      .then((result) => {
        setIsLoading(false);
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        let array = [];
        console.log(result.data.data.data);
        if (result?.data?.data?.data.length)
          for (var i = 0; i < result?.data?.data?.data.length; i++) {
            const item = result?.data?.data?.data[i];
            array.push([
              <div
                className={`itemClick ${
                  !greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.date ? (
                  item?.date
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                className={`itemClick ${
                  !greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.title ? (
                  item.title[Object.keys(item.title)[0]]
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                className={`itemClick ${
                  !greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.start_time && item?.end_time ? (
                  item?.start_time + "-" + item?.end_time
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                className={`itemClick ${
                  !greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.location?.title ? (
                  item.location.title[Object.keys(item.location.title)[0]]
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                className={`itemClick ${
                  !greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.description ? (
                  item.description[Object.keys(item.description)[0]]
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                className={`itemClick ${
                  !greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.types[0]?.title ? (
                  item.types[0].title[Object.keys(item.types[0].title)[0]]
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                className={`itemClick ${
                  !greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.capacity !== null ? (
                  item?.capacity
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                className={`itemClick ${
                  !greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.capacity !== null
                  ? `${item?.booked_counter}/${item?.capacity}`
                  : item?.booked_counter}
              </div>,

              <div
                className={`itemClick ${
                  !greyBackgroundCondition(item.status)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.status) && greyBackgroundColor,
                }}
              >
                {item?.admin?.first_name ? (
                  <div>
                    <div>{item?.admin?.first_name}</div>
                    <div>
                      {moment(item.created_at).format(format).split(" ")[0]}
                      <span>&nbsp;&nbsp;</span>
                      {moment(item.created_at).format(format).split(" ")[1]}
                    </div>
                  </div>
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div style={{ marginRight: "2rem", float: "right" }}>
                <Button onClick={() => handleEdit(item.id)}>Edit</Button>
              </div>,
              // <div
              //   className={`itemClick ${
              //     !greyBackgroundCondition(item.status)
              //       ? "bookFont"
              //       : "mediumFont"
              //   }`}
              //   id={JSON.stringify(item)}
              //   onClick={(e) => handleRowClick(e)}
              //   style={{
              //     backgroundColor:
              //       greyBackgroundCondition(item.status) && greyBackgroundColor,
              //   }}
              // >
              //   <p style={{ visibility: "hidden" }}>empty</p>
              // </div>,
            ]);
          }
        array.unshift([
          <div
            id="firstTableItemDate"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDivEvents filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{
                  visibility: props.selectedTab === "3" ? "hidden" : "visible",
                }}
              >
                <input
                  id="PolarisTextField14"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField14Label"
                  aria-invalid="false"
                  defaultValue={""}
                  onChange={handleChangeDate}
                  type="date"
                  ref={refCreatedAt}
                />

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDivEvents>
          </div>,

          <div
            id="firstTableItemName"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDivEvents filterClicked={filterClicked}>
              <div className="Polaris-TextField">
                <input
                  id="PolarisTextField2"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField2Label"
                  aria-invalid="false"
                  defaultValue={""}
                  onChange={handleChangeNameSearchValue}
                  ref={refName}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition="name"
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refName}
                ></FilterCloseButton>

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDivEvents>
          </div>,

          <div
            id="firstTableItemTime"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDivEvents filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{ visibility: "hidden" }}
              >
                <input
                  id="PolarisTextField6"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField6Label"
                  aria-invalid="false"
                  defaultValue={""}
                  onChange={handleChangeTime}
                  ref={refTime}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition="organisation_role"
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refTime}
                ></FilterCloseButton>
                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDivEvents>
          </div>,
          <div
            id="firstTableItemLocation"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDivEvents filterClicked={filterClicked}>
              <div className="Polaris-Select">
                {locations && (
                  <select
                    id="PolarisSelect3"
                    className="Polaris-Select__Input"
                    aria-invalid="false"
                    onChange={handleChangeTypeSearchValue}
                    defaultValue={""}
                    ref={refType}
                  >
                    <option value="All">All</option>
                    {locations.map((location) => {
                      return (
                        <option
                          key={String(location.id)}
                          value={String(location.id)}
                        >
                          {location.title[Object.keys(location.title)[0]]}
                        </option>
                      );
                    })}
                  </select>
                )}
                <div className="Polaris-Select__Content" aria-hidden="true">
                  <span className="Polaris-Select__SelectedOption">
                    {selectedEventLocation === "All"
                      ? "All"
                      : findArrayElementByValue(
                          locations,
                          selectedEventLocation
                        ).title.en}
                  </span>
                  <span className="Polaris-Select__Icon">
                    <span className="Polaris-Icon">
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="Polaris-Select__Backdrop" />
              </div>
            </FilterParentDivEvents>
          </div>,

          <div
            id="firstTableItemDescription"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDivEvents filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{ visibility: "hidden" }}
              >
                <input
                  id="PolarisTextField14"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField14Label"
                  aria-invalid="false"
                  defaultValue={""}
                  onChange={handleChangeTimeOfRegistrationSearchValue}
                  type="date"
                  ref={refCreatedAt}
                />

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDivEvents>
          </div>,
          <div
            id="firstTableItemType"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDivEvents filterClicked={filterClicked}>
              <div className="Polaris-Select">
                {types && (
                  <select
                    id="PolarisSelect3"
                    className="Polaris-Select__Input"
                    aria-invalid="false"
                    onChange={handleChangeTypeSearchValue}
                    defaultValue={""}
                    ref={refType}
                  >
                    <option value="All">All</option>
                    {types.map((type) => {
                      return (
                        <option key={String(type.id)} value={String(type.id)}>
                          {type.title[Object.keys(type.title)[0]]}
                        </option>
                      );
                    })}
                  </select>
                )}
                <div className="Polaris-Select__Content" aria-hidden="true">
                  <span className="Polaris-Select__SelectedOption">
                    {selectedEventType === "All"
                      ? "All"
                      : findArrayElementByValue(types, selectedEventType).title
                          .en}
                  </span>
                  <span className="Polaris-Select__Icon">
                    <span className="Polaris-Icon">
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="Polaris-Select__Backdrop" />
              </div>
            </FilterParentDivEvents>
          </div>,

          <div
            id="firstTableItemCapacity"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDivEvents filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{
                  visibility: "hidden",
                }}
              >
                <input
                  id="PolarisTextField14"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField14Label"
                  aria-invalid="false"
                  defaultValue={""}
                  onChange={handleChangeDate}
                  type="date"
                  ref={refCreatedAt}
                />

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDivEvents>
          </div>,
          <div
            id="firstTableItemOccupancy"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDivEvents filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{
                  visibility: "hidden",
                }}
              >
                <input
                  id="PolarisTextField14"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField14Label"
                  aria-invalid="false"
                  defaultValue={""}
                  onChange={handleChangeDate}
                  type="date"
                  ref={refCreatedAt}
                />

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDivEvents>
          </div>,
          <div
            id="firstTableItemAdmin"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDivEvents filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{
                  visibility: "hidden",
                }}
              >
                <input
                  id="PolarisTextField14"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField14Label"
                  aria-invalid="false"
                  defaultValue={""}
                  onChange={handleChangeDate}
                  type="date"
                  ref={refCreatedAt}
                />

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDivEvents>
          </div>,
          <div
            id="firstTableItemExport"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDivEvents filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{ visibility: "hidden" }}
              >
                <input
                  id="PolarisTextField18"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField18Label"
                  aria-invalid="false"
                  defaultValue={searchValue}
                />
                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDivEvents>
          </div>,
        ]);

        setItems(array);

        props.selectedTab === "0" &&
          props.onChangerequestsNumber(
            result?.data?.data?.data
              .filter(({ status }) => status.toLowerCase().includes("new"))
              .reduce((accumulator) => accumulator + 1, 0)
          );
        props.selectedTab === "1" &&
          props.onChangerequestsNumber(
            result?.data?.data?.data
              .filter(({ status }) =>
                status.toLowerCase().includes("approved_sc")
              )
              .reduce((accumulator) => accumulator + 1, 0)
          );
      })
      .catch((err) => setIsLoading(false));
  };
  const switchIsWireless = (param) => {
    switch (String(param)) {
      case "no":
        return "No";
      case "yes":
        return "Yes";
      case "":
        return "All";
      case "All":
        return "All";
      default:
        return null;
      // code block
    }
  };
  const switchIsCamera = (param) => {
    switch (String(param)) {
      case "no":
        return "No";
      case "yes":
        return "Yes";
      case "":
        return "All";
      case "All":
        return "All";
      default:
        return null;
      // code block
    }
  };
  const switchType = (param) => {
    switch (param) {
      case "1":
        return "Non Accredited";
      case "2":
        return "FIFA";
      case "3":
        return "HC";
      case "4":
        return "Lusail";
      case "All":
        return "All";
      default:
        return null;
      // code block
    }
  };
  const handleDeleteTextField = (param) => {
    param.current.value = "";
    setSearchValue("");
  };

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );

  const handleRowClick = (e) => {
    let str = "";
    if (props.selectedTab === "all") str = "all-permits";
    else if (props.selectedTab === "cra") str = "cra-permits";
    else if (props.selectedTab === "qna") str = "qna-permits";
    else if (props.selectedTab === "customs") str = "customs-permits";
    window.open(
      `/admin/equipment-clearance/${str}/${JSON.parse(e.currentTarget.id).id}`,
      "_blank"
    );
  };
  const [active, setActive] = useState(false);
  const handleChangeReasonOfRejection = useCallback(
    (newValue) => setReasonOfRejectionValue(newValue),
    []
  );

  const [rejectClicked, setRejectClicked] = useState(false);
  const [reasonOfRejectionValue, setReasonOfRejectionValue] = useState("");

  const [activeToast, setActiveToast] = useState(false);
  const toggleActiveToast = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );
  const [toastContent, setToastContent] = useState("");
  const toastMarkup = activeToast ? (
    <Toast
      content={toastContent}
      onDismiss={toggleActiveToast}
      duration="10000"
    />
  ) : null;
  const [activeAreYouSure, setActiveAreYouSure] = useState(false);
  const [questionValue, setQuestionValue] = useState(false);
  const handleNo = () => {
    setActiveAreYouSure(false);
  };
  const handleRowApprove = (e) => {
    reference.current = JSON.parse(e.currentTarget.id).id + "_" + "approve";
    reference2.current =
      JSON.parse(e.currentTarget.id).accreditation_id +
      "_" +
      JSON.parse(e.currentTarget.id).status_level;
    setActiveAreYouSure(true);
    setQuestionValue("Are you sure you want to approve this request?");
  };
  const handleRowReject = (e) => {
    reference.current = JSON.parse(e.currentTarget.id).id + "_" + "reject";
    reference2.current =
      JSON.parse(e.currentTarget.id).accreditation_id +
      "_" +
      JSON.parse(e.currentTarget.id).status_level;
    setActiveAreYouSure(true);
    setQuestionValue("Are you sure you want to reject this request?");
  };
  const handleYes = () => {
    const form_data = new FormData();
    form_data.append(
      "status",
      reference.current.split("_")[1] === "approve" ? "approved" : "rejected"
    );
    reference.current.split("_")[1] === "reject" &&
      form_data.append("reason_of_rejection", reasonOfRejectionValue);
    form_data.append("_method", "PATCH");

    axios
      .post(
        `admin/v1/registration-requests/${
          reference.current.split("_")[0]
        }/status${
          props.selectedTab === "0" &&
          reference2.current.split("_")[0] !== 1 &&
          reference2.current.split("_")[1] === "SC"
            ? "/sc"
            : ""
        }${props.selectedTab === "1" ? "/gco" : ""}${
          props.selectedTab === "2" ? "/qna" : ""
        }`,
        form_data
      )
      .then((res) => {
        setRejectClicked(false);
        setActive(false);
        setActiveAreYouSure(false);
        setToastContent(
          reference.current.split("_")[1] === "approve"
            ? "Request Approved Successfully"
            : "Request Rejected Successfully"
        );
        toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => console.log(""));
  };

  const resetValues = (param) => {
    param !== "refName" && (refName.current.value = "");
    param !== "refLocation" && (refLocation.current = "");
    param !== "refType" && (refType.current = "");
  };

  const handleChangeNameSearchValue = (event) => {
    resetValues("refName");
    setFilterState("name");
    setSearchValue(event.target.value);
  };
  const handleChangeDate = (event) => {
    resetValues("refDate");
    setFilterState("created_at");
    setSearchValue(event.target.value);
  };
  const handleChangeTime = (event) => {
    resetValues("refTime");
    setFilterState("organisation_role");
    setSearchValue(event.target.value);
  };
  const handleChangeTimeOfRegistrationSearchValue = (event) => {
    resetValues("refCreatedAt");
    setFilterState("created_at");
    setSearchValue(event.target.value);
  };

  const handleChangeTypeSearchValue = (event) => {
    resetValues("refType");
    refType.current = event.target.value;
    setFilterState("type");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    setSelectedEventType(event.target.value);
  };
  const array = [
    "firstTableItemDate",
    "firstTableItemName",
    "firstTableItemTime",
    "firstTableItemLocation",
    "firstTableItemDescription",
    "firstTableItemType",
    "firstTableItemCapacity",
    "firstTableItemOccupancy",
    "firstTableItemAdmin",
    "firstTableItemExport",
  ];
  const [filterClicked, setFilterClicked] = useState(false);
  const handleFilter = () => {
    if (!filterClicked) {
      for (var i = 0; i < array.length; i++) {
        if (document.getElementById(array[i])) {
          document.getElementById(array[i]).style.display = "block";
        }
      }
    } else {
      resetValues("refDate");
      resetValues("refName");
      resetValues("refLocation");
      resetValues("refType");
      setSearchValue("");

      for (var i = 0; i < array.length; i++) {
        document.getElementById(array[i]).style.display = "none";
      }
    }

    setFilterClicked(!filterClicked);
  };
  const [selected, setSelected] = useState("today");

  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const refBoolPage = useRef(true);

  return (
    <NavigationLayout>
      <Page
        fullWidth
        title="Interviewees"
        primaryAction={{
          content: <p style={{ color: "white" }}>Add Interviewee</p>,
          onClick: () =>
            navigate("/admin/manage-interviewees/interviewees/new"),
        }}
        breadcrumbs={[
          { onAction: () => navigate("/admin/manage-interviewees") },
        ]}
      >
        <Card>
          {loadingMarkup}

          <DataTable
            columnContentTypes={["text", "text", "text", "text"]}
            headings={[
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Date</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Name</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Time</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Location</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Event Description</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Type</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Capacity</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Occupancy</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Added by</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ float: "right", marginRight: "4rem" }}>
                  {/* <img
                    className="filterImg"
                    src={!filterClicked ? filterGrey : filterOrange}
                    onClick={handleFilter}
                  ></img> */}
                </div>
              </TextStyle>,
            ]}
            rows={items}
            // sortable={[true, true, true, true]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            onSort={handleSort}
            verticalAlign="middle"
            style={{ color: "red" }}
            // hoverable={false}
          />
          {totalPages >= 2 && (
            <div
              style={{
                textAlign: "center",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChangePage}
                size="large"
              />
            </div>
          )}
          <Modal open={activeEmail}>
            <Modal.Section>
              <FormLayout>
                <div className="popupGreyText">Type your message here</div>
                <TextField
                  multiline={4}
                  value={emailMessageValue}
                  onChange={handleChangeEmailMessage}
                ></TextField>
              </FormLayout>

              <div
                className="rowDiv"
                style={{
                  float: "right",
                  marginTop: "3rem",
                  marginBottom: "2rem",
                }}
              >
                <button
                  className={`button ${
                    isSendingEmail ? "buttonDisabled" : "buttonApprove"
                  }`}
                  onClick={handleSend}
                >
                  Send
                </button>
                <button className="button buttonEmail" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </Modal.Section>
          </Modal>

          <Modal open={activeAreYouSure}>
            <Modal.Section>
              <div
                className="popupBlackText"
                style={{ marginBottom: "4rem", fontSize: "1.6rem" }}
              >
                {questionValue}
              </div>
              {reference.current.split("_")[1] == "reject" && (
                <FormLayout>
                  <div className="popupGreyText">Reason of Rejection</div>
                  <TextField
                    multiline={4}
                    value={reasonOfRejectionValue}
                    onChange={handleChangeReasonOfRejection}
                  ></TextField>
                </FormLayout>
              )}
              <div
                className="rowDiv"
                style={{
                  float: "right",
                  marginTop: "3rem",
                  marginBottom: "2rem",
                }}
              >
                <button className="button buttonApprove" onClick={handleYes}>
                  Yes
                </button>
                <button className="button buttonEmail" onClick={handleNo}>
                  No
                </button>
              </div>
            </Modal.Section>
          </Modal>
          {emtyState}
          {toastMarkup}
        </Card>
      </Page>
    </NavigationLayout>
  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function findArrayElementByValue(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }
};

export default TableOfInterviewees;
