import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  Toast,
  Modal,
  TextField,
  TextContainer,
  Tabs,
  Layout,
  Icon,
  FormLayout,
} from "@shopify/polaris";
import moment from "moment";
import axios from "../Assets/Lib/axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ActionButtons from "../Components/ActionButtons";

import { MobileCancelMajor } from "@shopify/polaris-icons";
import useOutsideClick from "./useOutsideClick";
import attachementsImage from "../Assets/Images/attachments.svg";
import closeImage from "../Assets/Images/close-popup.svg";
import { openBase64Image } from "../Base64Image/base64Image";

const RequestPopup = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const format = "YYYY-MM-DD HH:mm:ss";
  const [active, setActive] = useState(true);
  const handleClosePopup = () => {
    props.handleChangeShowPopup();
  };
  let request = JSON.parse(props.request);

  const countries = [
    {
      code: "004",
      ar: "افغانستان",
      en: "Afghanistan",
    },
    {
      code: "008",
      ar: "البانيا",
      en: "Albania",
    },
    {
      code: "012",
      ar: "الجزائر",
      en: "Algeria",
    },
    {
      code: "016",
      ar: "ساموا الامريكية",
      en: "American Samoa",
    },
    {
      code: "020",
      ar: "اندورا",
      en: "Andorra",
    },
    {
      code: "024",
      ar: "انجولا",
      en: "Angola",
    },
    {
      code: "660",
      ar: "جزيرة أنجويلا",
      en: "Anguilla",
    },
    {
      code: "028",
      ar: "انتيجوا و باربودا",
      en: "Antigua and Barbuda",
    },
    {
      code: "032",
      ar: "الارجنتين",
      en: "Argentina",
    },
    {
      code: "051",
      ar: "ارمينيا",
      en: "Armenia",
    },
    {
      code: "533",
      ar: "أروبا",
      en: "Aruba",
    },
    {
      code: "036",
      ar: "استراليا",
      en: "Australia",
    },
    {
      code: "040",
      ar: "النمسا",
      en: "Austria",
    },
    {
      code: "031",
      ar: "اذريبيجان",
      en: "Azerbaijan",
    },
    {
      code: "044",
      ar: "بهاماس​",
      en: "Bahamas",
    },
    {
      code: "048",
      ar: "البحرين",
      en: "Bahrain",
    },
    {
      code: "050",
      ar: "بنغلاديش​",
      en: "Bangladesh",
    },
    {
      code: "052",
      ar: "باربادوس​",
      en: "Barbados",
    },
    {
      code: "112",
      ar: "بيلروس​",
      en: "Belarus",
    },
    {
      code: "056",
      ar: "بلجيكا",
      en: "Belgium",
    },
    {
      code: "084",
      ar: "بيليز",
      en: "Belize",
    },
    {
      code: "204",
      ar: "بينين",
      en: "Benin",
    },
    {
      code: "060",
      ar: "برمودا",
      en: "Bermuda",
    },
    {
      code: "064",
      ar: "بوتان",
      en: "Bhutan",
    },
    {
      code: "068",
      ar: "بوليفيا",
      en: "Bolivia (Plurinational State of)",
    },
    {
      code: 544,
      ar: "البوسنة والهرسك",
      en: "Bosnia and Herzegovina",
    },
    {
      code: "072",
      ar: "بوتسوانا",
      en: "Botswana",
    },
    {
      code: "074",
      ar: "جزر بوفت",
      en: "Bouvet Island",
    },
    {
      code: "076",
      ar: "البرازيل",
      en: "Brazil",
    },
    {
      code: "086",
      ar: "جزر المحيط الهندي",
      en: "British Indian Ocean Territory",
    },
    {
      code: "096",
      ar: "بروناي دار السلام",
      en: "Brunei Darussalam",
    },
    {
      code: "100",
      ar: "بلغاريا",
      en: "Bulgaria",
    },
    {
      code: "854",
      ar: "بوركينافاسو",
      en: "Burkina Faso",
    },
    {
      code: "108",
      ar: "بروندي",
      en: "Burundi",
    },
    {
      code: "132",
      ar: "جمهوريةالرأس​الاخضر",
      en: "Cabo Verde",
    },
    {
      code: "116",
      ar: "كمبوديا",
      en: "Cambodia",
    },
    {
      code: "120",
      ar: "الكاميرون",
      en: "Cameroon",
    },
    {
      code: "124",
      ar: "كندا",
      en: "Canada",
    },
    {
      code: "136",
      ar: "جزر كيمان",
      en: "Cayman Islands",
    },
    {
      code: "140",
      ar: "افريقيا الوسطي",
      en: "Central African Republic",
    },
    {
      code: "148",
      ar: "تشاد",
      en: "Chad",
    },
    {
      code: "152",
      ar: "تشيلي",
      en: "Chile",
    },
    {
      code: "156",
      ar: "ج.الصين الشعبية",
      en: "China",
    },
    {
      code: "162",
      ar: "جزر كرسمس​",
      en: "Christmas Island",
    },
    {
      code: "166",
      ar: "جزر كوكوس​",
      en: "Cocos (Keeling) Islands",
    },
    {
      code: "170",
      ar: "كولومبيا",
      en: "Colombia",
    },
    {
      code: "174",
      ar: "جمهورية القمرالمتحدة",
      en: "Comoros",
    },
    {
      code: "178",
      ar: "جمهورية الكونغو",
      en: "Congo",
    },
    {
      code: "180",
      ar: "الكونغو الديمقراطية",
      en: "Congo, Democratic Republic of the",
    },
    {
      code: "184",
      ar: "جزر كوك",
      en: "Cook Islands",
    },
    {
      code: "188",
      ar: "كوستاريكا",
      en: "Costa Rica",
    },
    {
      code: "191",
      ar: "كرواتيا",
      en: "Croatia",
    },
    {
      code: "192",
      ar: "كوبا",
      en: "Cuba",
    },
    {
      code: "531",
      ar: "كوراساو",
      en: "Curaأ§ao",
    },
    {
      code: "196",
      ar: "قبرص​",
      en: "Cyprus",
    },
    {
      code: 200,
      ar: "تشيك",
      en: "Czechia",
    },
    {
      code: "384",
      ar: "كوت ديفوار",
      en: "Cأ´te d'Ivoire",
    },
    {
      code: "208",
      ar: "الدنمارك",
      en: "Denmark",
    },
    {
      code: "262",
      ar: "جيبوتي",
      en: "Djibouti",
    },
    {
      code: "212",
      ar: "دومينيكا",
      en: "Dominica",
    },
    {
      code: "214",
      ar: "جمهورية دومنيكان",
      en: "Dominican Republic",
    },
    {
      code: "218",
      ar: "الاكوادور",
      en: "Ecuador",
    },
    {
      code: "818",
      ar: "مصر",
      en: "Egypt",
    },
    {
      code: "222",
      ar: "السالفادور",
      en: "El Salvador",
    },
    {
      code: "226",
      ar: "غينيا الاستوائية",
      en: "Equatorial Guinea",
    },
    {
      code: "080",
      ar: "اريتريا",
      en: "Eritrea",
    },
    {
      code: "233",
      ar: "استونيا",
      en: "Estonia",
    },
    {
      code: "748",
      ar: "سوازيلاند",
      en: "Eswatini",
    },
    {
      code: 230,
      ar: "اثيوبيا",
      en: "Ethiopia",
    },
    {
      code: "238",
      ar: "جزر فوكلند",
      en: "Falkland Islands (Malvinas)",
    },
    {
      code: "234",
      ar: "فارو",
      en: "Faroe Islands",
    },
    {
      code: "242",
      ar: "فيجي",
      en: "Fiji",
    },
    {
      code: "246",
      ar: "فنلندا",
      en: "Finland",
    },
    {
      code: "250",
      ar: "فرنسا",
      en: "France",
    },
    {
      code: "254",
      ar: "غيانا الفرنسية",
      en: "French Guiana",
    },
    {
      code: "258",
      ar: "بولنيسياالفرنسية",
      en: "French Polynesia",
    },
    {
      code: "266",
      ar: "الغابون",
      en: "Gabon",
    },
    {
      code: "270",
      ar: "غامبيا",
      en: "Gambia",
    },
    {
      code: "268",
      ar: "جورجيا",
      en: "Georgia",
    },
    {
      code: 280,
      ar: "المانيا",
      en: "Germany",
    },
    {
      code: "288",
      ar: "غانا",
      en: "Ghana",
    },
    {
      code: "292",
      ar: "جبل طارق",
      en: "Gibraltar",
    },
    {
      code: "300",
      ar: "اليونان",
      en: "Greece",
    },
    {
      code: "304",
      ar: "جرينلند",
      en: "Greenland",
    },
    {
      code: "308",
      ar: "جرينادا",
      en: "Grenada",
    },
    {
      code: "312",
      ar: "جواديلوب",
      en: "Guadeloupe",
    },
    {
      code: "316",
      ar: "غوامي",
      en: "Guam",
    },
    {
      code: "320",
      ar: "جواتيمالا",
      en: "Guatemala",
    },
    {
      code: "831",
      ar: "جزيرة جيزنزي",
      en: "Guernsey",
    },
    {
      code: "324",
      ar: "غينيا",
      en: "Guinea",
    },
    {
      code: "624",
      ar: "غينيا بيساو",
      en: "Guinea-Bissau",
    },
    {
      code: "328",
      ar: "غيانيا",
      en: "Guyana",
    },
    {
      code: "332",
      ar: "هايتي",
      en: "Haiti",
    },
    {
      code: "334",
      ar: "جزر دونالد",
      en: "Heard Island and McDonald Islands",
    },
    {
      code: "336",
      ar: "الفاتيكان",
      en: "Holy See",
    },
    {
      code: "340",
      ar: "هوندوراس​",
      en: "Honduras",
    },
    {
      code: "344",
      ar: "هونج كونج",
      en: "Hong Kong",
    },
    {
      code: "348",
      ar: "المجر",
      en: "Hungary",
    },
    {
      code: "352",
      ar: "ايسلاندا",
      en: "Iceland",
    },
    {
      code: "356",
      ar: "الهند",
      en: "India",
    },
    {
      code: "360",
      ar: "اندونيسيا",
      en: "Indonesia",
    },
    {
      code: "364",
      ar: "ايران",
      en: "Iran (Islamic Republic of)",
    },
    {
      code: "368",
      ar: "العراق",
      en: "Iraq",
    },
    {
      code: "372",
      ar: "ايرلندا",
      en: "Ireland",
    },
    {
      code: "833",
      ar: "جزيرة مان",
      en: "Isle of Man",
    },
    {
      code: "376",
      ar: "اسرائيل",
      en: "Israel",
    },
    {
      code: "380",
      ar: "ايطاليا",
      en: "Italy",
    },
    {
      code: "388",
      ar: "جمايكا",
      en: "Jamaica",
    },
    {
      code: "392",
      ar: "اليابان",
      en: "Japan",
    },
    {
      code: "832",
      ar: "جزيرة جيرزي",
      en: "Jersey",
    },
    {
      code: "400",
      ar: "الاردن",
      en: "Jordan",
    },
    {
      code: "398",
      ar: "كزخستان",
      en: "Kazakhstan",
    },
    {
      code: "404",
      ar: "كينيا",
      en: "Kenya",
    },
    {
      code: "296",
      ar: "كريباتي",
      en: "Kiribati",
    },
    {
      code: "408",
      ar: "كوريا الشمالية",
      en: "Korea (Democratic People's Republic of)",
    },
    {
      code: "410",
      ar: "كوريا الجنوبية",
      en: "Korea, Republic of",
    },
    {
      code: "414",
      ar: "الكويت",
      en: "Kuwait",
    },
    {
      code: "417",
      ar: "كيرغيستان",
      en: "Kyrgyzstan",
    },
    {
      code: "418",
      ar: "لاو",
      en: "Lao People's Democratic Republic",
    },
    {
      code: "428",
      ar: "لاتفيا",
      en: "Latvia",
    },
    {
      code: "422",
      ar: "لبنان",
      en: "Lebanon",
    },
    {
      code: "426",
      ar: "ليسوتو",
      en: "Lesotho",
    },
    {
      code: "430",
      ar: "ليبيريا",
      en: "Liberia",
    },
    {
      code: "434",
      ar: "ليبيا",
      en: "Libya",
    },
    {
      code: "438",
      ar: "ليشتنشين",
      en: "Liechtenstein",
    },
    {
      code: "440",
      ar: "لتوانيا",
      en: "Lithuania",
    },
    {
      code: "442",
      ar: "لكسمبورج",
      en: "Luxembourg",
    },
    {
      code: "446",
      ar: "ماكاو",
      en: "Macao",
    },
    {
      code: "450",
      ar: "مداغاسكر",
      en: "Madagascar",
    },
    {
      code: "454",
      ar: "مالاوي",
      en: "Malawi",
    },
    {
      code: "458",
      ar: "ماليزيا",
      en: "Malaysia",
    },
    {
      code: "462",
      ar: "جزر المالديف",
      en: "Maldives",
    },
    {
      code: "466",
      ar: "مالي",
      en: "Mali",
    },
    {
      code: "470",
      ar: "مالطا",
      en: "Malta",
    },
    {
      code: "584",
      ar: "جزر المرشال",
      en: "Marshall Islands",
    },
    {
      code: "474",
      ar: "مارتنيك",
      en: "Martinique",
    },
    {
      code: "478",
      ar: "موريتانيا",
      en: "Mauritania",
    },
    {
      code: "480",
      ar: "موريشوس​",
      en: "Mauritius",
    },
    {
      code: "175",
      ar: "جزيرة مايوت",
      en: "Mayotte",
    },
    {
      code: "484",
      ar: "المكسيك",
      en: "Mexico",
    },
    {
      code: "583",
      ar: "مكرونسيا",
      en: "Micronesia (Federated States of)",
    },
    {
      code: "498",
      ar: "ملدوفيا",
      en: "Moldova, Republic of",
    },
    {
      code: "492",
      ar: "موناكو",
      en: "Monaco",
    },
    {
      code: "496",
      ar: "منغوليا",
      en: "Mongolia",
    },
    {
      code: "499",
      ar: "الجبل الأسود",
      en: "Montenegro",
    },
    {
      code: "500",
      ar: "مونتسرات",
      en: "Montserrat",
    },
    {
      code: "504",
      ar: "المغرب",
      en: "Morocco",
    },
    {
      code: "508",
      ar: "موزامبيق",
      en: "Mozambique",
    },
    {
      code: "104",
      ar: "ميانمار",
      en: "Myanmar",
    },
    {
      code: "516",
      ar: "ناميبيا",
      en: "Namibia",
    },
    {
      code: "520",
      ar: "نورو",
      en: "Nauru",
    },
    {
      code: "524",
      ar: "نيبال",
      en: "Nepal",
    },
    {
      code: "528",
      ar: "هولندا",
      en: "Netherlands",
    },
    {
      code: "540",
      ar: "نيو كاليدونيا",
      en: "New Caledonia",
    },
    {
      code: "554",
      ar: "نيوزيلاندا",
      en: "New Zealand",
    },
    {
      code: "558",
      ar: "نيكواراجوا",
      en: "Nicaragua",
    },
    {
      code: "562",
      ar: "النيجر",
      en: "Niger",
    },
    {
      code: "566",
      ar: "نيجيريا",
      en: "Nigeria",
    },
    {
      code: "570",
      ar: "نيو",
      en: "Niue",
    },
    {
      code: "574",
      ar: "جزيرة نورفوك",
      en: "Norfolk Island",
    },
    {
      code: "807",
      ar: "جمهورية شمال مقدونيا",
      en: "North Macedonia",
    },
    {
      code: "580",
      ar: "جزر مريانا",
      en: "Northern Mariana Islands",
    },
    {
      code: "578",
      ar: "النورويج",
      en: "Norway",
    },
    {
      code: "512",
      ar: "عمان",
      en: "Oman",
    },
    {
      code: "586",
      ar: "باكستان",
      en: "Pakistan",
    },
    {
      code: "585",
      ar: "بولا",
      en: "Palau",
    },
    {
      code: 999,
      ar: "فلسطين",
      en: "Palestine, State of",
    },
    {
      code: 590,
      ar: "بنما",
      en: "Panama",
    },
    {
      code: "598",
      ar: "بابوا نيو غينيا",
      en: "Papua New Guinea",
    },
    {
      code: "600",
      ar: "باراجواي",
      en: "Paraguay",
    },
    {
      code: "604",
      ar: "بيرو",
      en: "Peru",
    },
    {
      code: "608",
      ar: "الفلبين",
      en: "Philippines",
    },
    {
      code: "612",
      ar: "بيتكيرن",
      en: "Pitcairn",
    },
    {
      code: "616",
      ar: "بولندا",
      en: "Poland",
    },
    {
      code: "620",
      ar: "البرتغال",
      en: "Portugal",
    },
    {
      code: "630",
      ar: "بورتوريكو",
      en: "Puerto Rico",
    },
    {
      code: "634",
      ar: "قطر",
      en: "Qatar",
    },
    {
      code: "642",
      ar: "رومانيا",
      en: "Romania",
    },
    {
      code: "643",
      ar: "روسيا",
      en: "Russian Federation",
    },
    {
      code: "646",
      ar: "رواندا",
      en: "Rwanda",
    },
    {
      code: "638",
      ar: "ريونيون",
      en: "Rأ©union",
    },
    {
      code: "652",
      ar: "سان بارتليمي",
      en: "Saint Barthأ©lemy",
    },
    {
      code: "654",
      ar: "سنت هيلانا",
      en: "Saint Helena, Ascension and Tristan da Cunha[b]",
    },
    {
      code: "659",
      ar: "سانت كيتس​ونيفيس​",
      en: "Saint Kitts and Nevis",
    },
    {
      code: "662",
      ar: "سنت لوسيا",
      en: "Saint Lucia",
    },
    {
      code: "663",
      ar: "سالت مارتن",
      en: "Saint Martin (French part)",
    },
    {
      code: "666",
      ar: "سنت بير وميكليون",
      en: "Saint Pierre and Miquelon",
    },
    {
      code: "670",
      ar: "سنت فينسنت",
      en: "Saint Vincent and the Grenadines",
    },
    {
      code: "882",
      ar: "ساموا",
      en: "Samoa",
    },
    {
      code: "674",
      ar: "سان مارينو",
      en: "San Marino",
    },
    {
      code: "678",
      ar: "سافوتوم و برنسيت",
      en: "Sao Tome and Principe",
    },
    {
      code: "682",
      ar: "السعودية",
      en: "Saudi Arabia",
    },
    {
      code: "686",
      ar: "السنغال",
      en: "Senegal",
    },
    {
      code: "688",
      ar: "صربيا",
      en: "Serbia",
    },
    {
      code: "690",
      ar: "سيشيل",
      en: "Seychelles",
    },
    {
      code: "694",
      ar: "سيراليون",
      en: "Sierra Leone",
    },
    {
      code: "702",
      ar: "سنغافورة",
      en: "Singapore",
    },
    {
      code: "703",
      ar: "سلوفكيا",
      en: "Slovakia",
    },
    {
      code: "705",
      ar: "سلوفنيا",
      en: "Slovenia",
    },
    {
      code: "090",
      ar: "جزر سليمان",
      en: "Solomon Islands",
    },
    {
      code: "706",
      ar: "الصومال",
      en: "Somalia",
    },
    {
      code: "710",
      ar: "جنوب افريقيا",
      en: "South Africa",
    },
    {
      code: "728",
      ar: "جنوب السودان",
      en: "South Sudan",
    },
    {
      code: "724",
      ar: "اسبانيا",
      en: "Spain",
    },
    {
      code: "144",
      ar: "سريلانكا",
      en: "Sri Lanka",
    },
    {
      code: 736,
      ar: "السودان",
      en: "Sudan",
    },
    {
      code: "740",
      ar: "سورينام",
      en: "Suriname",
    },
    {
      code: "744",
      ar: "سفالبارد وجان ماين",
      en: "Svalbard and Jan Mayen[c]",
    },
    {
      code: "752",
      ar: "السويد",
      en: "Sweden",
    },
    {
      code: "756",
      ar: "سويسرا",
      en: "Switzerland",
    },
    {
      code: "760",
      ar: "سوريا",
      en: "Syrian Arab Republic",
    },
    {
      code: "158",
      ar: "ج. الصين (تايوان)",
      en: "Taiwan, Province of China",
    },
    {
      code: "762",
      ar: "طاجكستان",
      en: "Tajikistan",
    },
    {
      code: "834",
      ar: "تنزانيا",
      en: "Tanzania, United Republic of",
    },
    {
      code: "764",
      ar: "تايلاند",
      en: "Thailand",
    },
    {
      code: "626",
      ar: "تيمور الشرقية",
      en: "Timor-Leste",
    },
    {
      code: "768",
      ar: "توغو",
      en: "Togo",
    },
    {
      code: "772",
      ar: "طوكيلاو",
      en: "Tokelau",
    },
    {
      code: "776",
      ar: "تونجا",
      en: "Tonga",
    },
    {
      code: "780",
      ar: "ترينادا وتوباجو",
      en: "Trinidad and Tobago",
    },
    {
      code: "788",
      ar: "تونس​",
      en: "Tunisia",
    },
    {
      code: "792",
      ar: "تركيا",
      en: "Turkey",
    },
    {
      code: "795",
      ar: "تركمانستان",
      en: "Turkmenistan",
    },
    {
      code: "796",
      ar: "جزرالترك وكايكو",
      en: "Turks and Caicos Islands",
    },
    {
      code: "798",
      ar: "توفالو",
      en: "Tuvalu",
    },
    {
      code: "800",
      ar: "اوغندا",
      en: "Uganda",
    },
    {
      code: "804",
      ar: "اوكرانيا",
      en: "Ukraine",
    },
    {
      code: "784",
      ar: "الامارات",
      en: "United Arab Emirates",
    },
    {
      code: "826",
      ar: "بريطانيا",
      en: "United Kingdom",
    },
    {
      code: "840",
      ar: "الولايات المتحدة",
      en: "United States of America",
    },
    {
      code: "858",
      ar: "الاوروجواي",
      en: "Uruguay",
    },
    {
      code: "860",
      ar: "اوزبكستان",
      en: "Uzbekistan",
    },
    {
      code: "548",
      ar: "فانواتو",
      en: "Vanuatu",
    },
    {
      code: "862",
      ar: "فنيزويلا",
      en: "Venezuela (Bolivarian Republic of)",
    },
    {
      code: "704",
      ar: "فيتنام",
      en: "Viet Nam",
    },
    {
      code: "092",
      ar: "فرجين البريطانية",
      en: "Virgin Islands (British)",
    },
    {
      code: "850",
      ar: "جزر فيرجن",
      en: "Virgin Islands (U.S.)",
    },
    {
      code: "876",
      ar: "جزر والس​وفوتونا",
      en: "Wallis and Futuna",
    },
    {
      code: "732",
      ar: "الصحراء الغربية",
      en: "Western Sahara",
    },
    {
      code: "886",
      ar: "اليمن",
      en: "Yemen",
    },
    {
      code: "894",
      ar: "زامبيا",
      en: "Zambia",
    },
    {
      code: "716",
      ar: "زمبوابوي",
      en: "Zimbabwe",
    },
    {
      code: "248",
      ar: "جزيرة أولند",
      en: "Aland Islands",
    },
    {
      code: "991",
      ar: "Kosovo",
      en: "Kosovo",
    },
  ];
  const switchDocumentType = (param) => {
    switch (String(param)) {
      case "1":
        return "Qatar Id Card (QID)";
        break;
      case "3":
        return "Passport";
        break;
      default:
      // code block
    }
  };
  const switchStatusColor = (status) => {
    switch (status) {
      case "pending":
      case "pending_sc":
      case "pending_gco":
      case "pending_gco":
      case "pending_qna":
        return "#4B4B4B";
        break;

      case "approved":
      case "approved_sc":
      case "approved_gco":
      case "approved_qna":
      case "approved_lusail":
        return "#00CFB7";
        break;
      case "rejected":
      case "rejected_sc":
      case "rejected_gco":
      case "rejected_qna":
      case "rejected_lusail":
        return "#B85555";
        break;
      case "updated":
        return "#FFBF00";
        break;
      case "new":
        return "#FF4700";
        break;
      default:
      // code block
    }
  };
  const switchAcceditationType = (accreditationType) => {
    return accreditationType?.details?.label;
  };
  const switchGender = (param) => {
    switch (String(param)) {
      case "1":
        return "Male";
        break;
      case "2":
        return "Female";
        break;
      default:
      // code block
    }
  };
  const switchDocumentSubType = (param) => {
    switch (String(param)) {
      case "1":
        return "Normal";
        break;
      case "2":
        return "Private/Royal";
        break;
      case "3":
        return "Diplomatic";
        break;
      case "5":
        return "Mission";
        break;
      case "8":
        return "Travel Document";
        break;
      case "9":
        return "Official";
        break;
      default:
      // code block
    }
  };
  const handleClickDocument = (e) => {
    const file = e.currentTarget.id;
    axios
      .get("admin/v1/generate-download-token")
      .then((res) => {
        if (res.status == 200) {
          // window.open(
          //   `${process.env.REACT_APP_BASE_URL}admin/v1/download-document?application_id=${request.id}&file=${file}&token=${res.data.data}`
          // );
          const token = res.data.data;
          const url = `${process.env.REACT_APP_BASE_URL}admin/v1/download-document?application_id=${request.id}&file=${file}&token=${token}`;
          openBase64Image(url, file);
        }
      })
      .catch((err) => console.log(""));
  };
  const switchHayyaStatusColor = (item) => {
    let str = "";
    if (
      item?.hayya_status === "Data Error" &&
      item?.hayya_response?.response?.data?.fanIdNo
    ) {
      str = "#ff0000";
    } else if (item?.hayya_status === "New") {
      str = "#FF4700";
    } else if (item?.hayya_status === "Pending") {
      str = "#4B4B4B";
    } else if (item?.hayya_status === "Approved") {
      str = "#00CFB7";
    } else if (item?.hayya_status === "400") {
      str = "#ff0000";
    } else if (item?.hayya_status === "Rejected") {
      str = "#B85555";
    }

    return str;
  };
  //a
  const switchHayyaStatus = (item) => {
    let str = "";
    if (
      item?.hayya_status === "Data Error" &&
      item?.hayya_response?.response?.data?.fanIdNo
    ) {
      str = "MOI Error";
    } else if (item?.hayya_status === "New") {
      str = "New";
    } else if (item?.hayya_status === "Pending") {
      str = "Pending MOI";
    } else if (item?.hayya_status === "Approved") {
      str = "MOI Approved";
    } else if (item?.hayya_status === "Rejected") {
      str = "MOI Rejected";
    } else if (item?.hayya_status === "400") {
      str = "Hayya Error";
    } else if (item?.hayya_status === null) {
      str = "-";
    }

    return str;
  };
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );
  const [tabs, setTabs] = useState(null);

  // const tabs = [
  //   {
  //     id: "Passport",
  //     content: "Passport",
  //   },

  //   {
  //     id: "Facial Photo",
  //     content: "Facial Photo",
  //   },
  //   {
  //     id: "Credentials",
  //     content: "Credentials",
  //   },
  // ];
  const switchStatus = () => {
    let str = "";
    if (
      window.location.href.indexOf("/admin/requests/sc-requests") > -1 ||
      window.location.href.indexOf("/admin/users/completed-applications") > -1
    ) {
      str = "sc";
    }
    if (window.location.href.indexOf("/admin/requests/hayya-requests") > -1) {
      str = "hayya";
    }
    if (window.location.href.indexOf("/admin/requests/gco-requests") > -1) {
      str = "gco";
    }
    if (window.location.href.indexOf("/admin/requests/imo-requests") > -1) {
      str = "gco";
    }
    if (window.location.href.indexOf("/admin/requests/qna-requests") > -1) {
      str = "qna";
    }
    return str;
  };
  const [filesResponse, setFilesResponse] = useState({
    passport: "",
    facial: "",
    documents: "",
  });
  const ref = useRef();

  useOutsideClick(ref, () => {
    handleClosePopup();
    //alert("You clicked outside");
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responsePassport = "";
    let responseFacial = "";
    let responseDocuments = "";
    try {
      responsePassport = await axios.get(
        `admin/v1/registration-requests/${request.id}/passport-base64`
      );
      console.log(responsePassport);
    } catch (error) {
      console.log(error);
    }
    try {
      responseFacial = await axios.get(
        `admin/v1/registration-requests/${request.id}/facial-base64`
      );
      console.log(responsePassport);
    } catch (error) {
      console.log(error);
    }
    // try {
    //   responseDocuments = await axios.get(
    //     `admin/v1/registration-requests/${request.id}/documents-base64`
    //   );
    //   console.log(responsePassport);
    // } catch (error) {
    //   console.log(error);
    // }
    if (
      responsePassport?.data?.data &&
      responseFacial?.data?.data &&
      request?.documents &&
      request?.documents?.length
    ) {
      setTabs([
        {
          id: "Passport",
          content: "Passport",
        },

        {
          id: "Facial Photo",
          content: "Facial Photo",
        },
        {
          id: "Credentials",
          content: "Credentials",
        },
      ]);
    } else if (
      responsePassport?.data?.data &&
      responseFacial?.data?.data &&
      !(request?.documents && request?.documents?.length)
    ) {
      setTabs([
        {
          id: "Passport",
          content: "Passport",
        },

        {
          id: "Facial Photo",
          content: "Facial Photo",
        },
      ]);
    } else if (
      responsePassport?.data?.data &&
      !responseFacial?.data?.data &&
      request?.documents &&
      request?.documents?.length
    ) {
      setTabs([
        {
          id: "Passport",
          content: "Passport",
        },
        {
          id: "Credentials",
          content: "Credentials",
        },
      ]);
    } else if (
      !responsePassport?.data?.data &&
      responseFacial?.data?.data &&
      request?.documents &&
      request?.documents?.length
    ) {
      setTabs([
        {
          id: "Facial Photo",
          content: "Facial Photo",
        },
        {
          id: "Credentials",
          content: "Credentials",
        },
      ]);
    } else if (
      !responsePassport?.data?.data &&
      !responseFacial?.data?.data &&
      request?.documents &&
      request?.documents?.length
    ) {
      setTabs([
        {
          id: "Credentials",
          content: "Credentials",
        },
      ]);
    }
    // setTabs([
    //   {
    //     id: "Passport",
    //     content: "Passport",
    //   },

    //   {
    //     id: "Facial Photo",
    //     content: "Facial Photo",
    //   },
    //   {
    //     id: "Credentials",
    //     content: "Credentials",
    //   },
    // ]);
    setFilesResponse({
      ...filesResponse,

      passport: responsePassport?.data?.data
        ? responsePassport?.data?.data
        : "",
      facial: responseFacial?.data?.data ? responseFacial?.data?.data : "",
      // documents: responseDocuments?.data?.data
      //   ? responseDocuments?.data?.data
      //   : "",
    });
    setIsLoading(false);
  }

  return (
    <div style={{ height: "1000px", backgroundColor: "white" }}>
      <Modal
        open={active}
        // onClose={handleClosePopup}
        large
        instant

        // onScrolledToBottom={handleScrollBottom}
        // title="Reach more shoppers with Instagram product tags"
        // primaryAction={{
        //   content: "Add Instagram",
        //   onAction: handleClosePopup,
        // }}
        // secondaryActions={[
        //   {
        //     content: "Learn more",
        //     onAction: handleClosePopup,
        //   },
        // ]}
      >
        <Modal.Section>
          <div ref={ref}>
            <FormLayout>
              <div>
                <div className="rowDiv">
                  <div className="popupText">
                    <TextStyle>
                      Application Ref No. <b>{request.reference_number}</b>
                    </TextStyle>
                  </div>
                  <div className="popupText ms-5">
                    <TextStyle>
                      Time of Registration{" "}
                      <b>{moment(request.created_at).format(format)}</b>
                    </TextStyle>
                  </div>
                  <div className="popupText ms-5">
                    <TextStyle>
                      Application Type{" "}
                      <b
                        style={{
                          color:
                            parseInt(request?.accreditation_id) !== 1 &&
                            (parseInt(request?.accreditation_id) === 2
                              ? "#005391"
                              : parseInt(request?.accreditation_id) === 3
                              ? "#550065"
                              : "#00671d"),
                        }}
                      >
                       {` ${switchAcceditationType(
                      request.accreditation_type
                    )}`}</b>
                    </TextStyle>
                  </div>
                  {props.selectedTab === "3" ? (
                    <div className="popupText ms-5">
                      <TextStyle>
                        Hayya Status:{" "}
                        <b style={{ color: switchHayyaStatusColor(request) }}>
                          {switchHayyaStatus(request)}
                        </b>
                      </TextStyle>
                    </div>
                  ) : (
                    <div className="popupText ms-5">
                      <TextStyle>
                        Status:{" "}
                        <b style={{ color: switchStatusColor(request.status) }}>
                          {request?.status.charAt(0).toUpperCase() +
                            request?.status
                              .slice(1)
                              .replace("_", " ")
                              .replace("-", " ")
                              .replace("sc", "SC")
                              .replace("gco", "GCO")
                              .replace("qna", "QNA")}
                        </b>
                      </TextStyle>
                    </div>
                  )}
                  <img
                    src={closeImage}
                    onClick={handleClosePopup}
                    style={{
                      width: "25px",
                      marginLeft: "auto",
                      marginRight: "0",
                      cursor: "pointer",
                    }}
                  ></img>
                </div>
              </div>
              <div className="rowDiv popupText">
                <div>
                  <div>
                    <TextStyle>Media Role:</TextStyle>
                  </div>
                  {request?.hayya_response?.response?.data?.fanIdNo && (
                    <div>
                      <TextStyle>Hayya Number:</TextStyle>

                      {/* <TextStyle>Fan ID:</TextStyle> */}
                    </div>
                  )}

                  <div>
                    {request.hayya_status === "Data Error" && (
                      <TextStyle>MOI Error:</TextStyle>
                    )}
                    {request.hayya_status === "Rejected" && (
                      <TextStyle>MOI Rejection Message</TextStyle>
                    )}
                    {request.hayya_status === "400" && (
                      <TextStyle>Hayya Error</TextStyle>
                    )}
                    {request.hayya_response && request.status === 400 && (
                      <TextStyle>Hayya Error</TextStyle>
                    )}
                  </div>
                </div>
                <div className="ms-5">
                  <div>
                    <TextStyle>
                      {request?.organisation?.role?.type?.en
                        ? request?.organisation?.role?.type?.en
                        : "N/A"}
                    </TextStyle>
                  </div>
                  {request?.hayya_response?.response?.data?.fanIdNo && (
                    <div>
                      <TextStyle>
                        {request?.hayya_response?.response?.data?.fanIdNo
                          ? request?.hayya_response?.response?.data?.fanIdNo
                          : "N/A"}
                      </TextStyle>
                    </div>
                  )}

                  <div>
                    {request?.hayya_status === "Data Error" && (
                      <TextStyle>
                        {request?.hayya_response?.response?.data?.fanIdNo
                          ? request?.hayya_message
                              ?.replaceAll("[", "")
                              .replaceAll("]", "")
                          : "N/A"}
                      </TextStyle>
                    )}
                    {request.hayya_status === "Rejected" && (
                      <TextStyle>
                        {request.hayya_response?.response?.data?.fanIdNo &&
                        request.hayya_message ? (
                          <div>
                            <div className="popupGreyTextSmall">
                              MOI Rejection Message
                            </div>
                            <div className="popupBlackTextSmall">
                              {request.hayya_message
                                ?.replaceAll("[", "")
                                .replaceAll("]", "")}
                            </div>
                          </div>
                        ) : (
                          "N/A"
                        )}
                      </TextStyle>
                    )}
                    {request.hayya_status === "400" && (
                      <TextStyle>
                        {request.hayya_response?.response?.message ? (
                          <div>
                            <div className="popupGreyTextSmall">
                              Hayya Error
                            </div>
                            <div className="popupBlackTextSmall">
                              {request.hayya_response?.response?.message}
                            </div>
                          </div>
                        ) : request?.hayya_response?.response?.errors
                            && request?.hayya_response?.response?.errors[
                            Object.keys(
                              request?.hayya_response?.response?.errors
                            )[0]
                          ] ? (
                          request?.hayya_response?.response?.errors[
                            Object.keys(
                              request?.hayya_response?.response?.errors
                            )[0]
                          ]
                        ) : (
                          "N/A"
                        )}
                      </TextStyle>
                    )}
                    {request.hayya_response &&
                      request.status === 400 &&
                      request.message && (
                        <TextStyle>{request.message}</TextStyle>
                      )}
                  </div>
                </div>
              </div>
              <div>
                {/* <p className="purpletitle">Accrediation Status</p> */}

                <div className="rowDiv">
                  <div style={{ width: "100%" }}>
                    <div className="containerProgressPopup">
                    <ul className="progressbar">
                        <li
                          className={
                            request.status === "pending" ||
                            request.status === "updated" ||
                            request.status === "new" ||
                            request.status === "approved" ||
                            request.status === "approved_sc" ||
                            request.status === "rejected_sc" ||
                            request.status === "approved_gco" ||
                            request.status === "rejected_gco" ||
                            request.status === "approved_qna" ||
                            request.status === "rejected_qna" ||
                            request.status === "approved_hayya" ||
                            request.status === "rejected_hayya" ||
                            request.status === "approved_lusail"
                              ? "active"
                              : ""
                          }
                        >
                          <div className="li-text">Registered</div> 
                        </li>
                        <li
                          className={`${
                            (request.status === "approved_sc" ||
                              request.status === "approved_gco" ||
                              request.status === "approved_qna" ||
                              request.status === "rejected_gco" ||
                              request.status === "rejected_qna" ||
                              request.status === "approved_hayya" ||
                              request.status === "approved_lusail" ||
                              request.status === "rejected_hayya" ||
                              request.status === "approved" ||
                              (request.status === "approved" &&
                                  parseInt(request.accreditation_type_id) ===
                                  1) ||
                              (request.status === "approved" &&
                                  parseInt(request.accreditation_type_id) ===
                                  2) ||
                              (request.status === "approved" &&
                                  parseInt(request.accreditation_type_id) ===
                                  3) ||
                              (request.status === "approved" &&
                                  parseInt(request.accreditation_type_id) ===
                                  5)) &&
                            "active"
                          } ${request.status === "rejected_sc" && "rejected"}`}
                        >
                          <div className="li-text">LOC</div>
                        </li>
                            {parseInt(request.accreditation_type_id) !== 1 && (
                          <li
                            className={`${
                              (request.status === "approved_gco" ||
                                request.status === "approved_qna" ||
                                request.status === "rejected_qna" ||
                                request.status === "approved_hayya" ||
                                request.status === "approved_lusail" ||
                                request.status === "rejected_hayya" ||
                                request.status === "approved" ||
                                (request.status === "approved" &&
                                  parseInt(request.accreditation_type_id) ===
                                    2) ||
                                (request.status === "approved" &&
                                    parseInt(request.accreditation_type_id) ===
                                    3) ||
                                (request.status === "approved" &&
                                    parseInt(request.accreditation_type_id) ===
                                    5)) &&
                              "active"
                            } ${
                              request.status === "rejected_gco" && "rejected"
                            }`}
                          >
                            <div className="li-text">GCO</div>
                          </li>
                        )}

                          {parseInt(request.accreditation_type_id) !== 1 && (
                          <li
                            className={`${
                              (request.status === "approved_qna" ||
                                request.status === "approved_hayya" ||
                                request.status === "approved_lusail" ||
                                request.status === "rejected_hayya" ||
                                request.status === "approved" ||
                                (request.status === "approved" &&
                                    parseInt(request.accreditation_type_id) ===
                                      2) ||
                                  (request.status === "approved" &&
                                    parseInt(request.accreditation_type_id) ===
                                      3) ||
                                  (request.status === "approved" &&
                                    parseInt(request.accreditation_type_id) ===
                                      5)) &&
                                "active"
                              } ${
                              request.status === "rejected_qna" && "rejected"
                            }`}
                            >
                              <div className="li-text">QNA</div>
                          </li>
                        )}

                        {parseInt(request.accreditation_type_id) !== 1 &&
                            parseInt(request.accreditation_type_id) !== 4 && (
                            <li
                              className={`${
                                request.hayya_status === "Approved" &&
                                request.status_level.toLowerCase() ===
                                  "hayya" &&
                                  "active"
                                } `}
                              >
                                <div className="li-text">HAYYA</div>
                            </li>
                          )}

                          {parseInt(request.accreditation_type_id) !== 1 &&
                            // parseInt(request.accreditation_type_id) !== 4 &&
                            request.status !== "approved_lusail" && (
                            <li
                              className={
                                request?.hayya_status === "Approved" &&
                                request.status_level.toLowerCase() ===
                                  "hayya" &&
                                  "activehc" //activefifa
                              }
                            >
                                <div className={`li-text`}>Accredited</div>
                            </li>
                          )}
                            {(parseInt(request.accreditation_type_id) === 1 ||
                            parseInt(request.accreditation_type_id) === 4) && (
                          <li style={{ visibility: "hidden" }}>
                            <div className="li-text">Accredited</div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <FormLayout.Group>
                <FormLayout>
                  <div className="mainTitle mt-4">Organisation Information</div>
                  <div className="rowDiv popupText">
                    <div>
                      <div className="mt-2">
                        <TextStyle>Name:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Address:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Country:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Email:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Phone:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Website:</TextStyle>
                      </div>
                    </div>
                    <div className="ms-5">
                      <div className="mt-2">
                        <TextStyle>
                          {request?.organisation?.name
                            ? request?.organisation?.name
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.organisation?.address
                            ? request?.organisation?.address
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.organisation?.country
                            ? request?.organisation?.country
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.organisation?.email
                            ? request?.organisation?.email
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.organisation && request?.organisation?.phone
                            ? `${
                                request?.organisation?.phoneAreaCode
                                  ? request?.organisation?.phoneAreaCode
                                  : ""
                              } ${request?.organisation?.phone}`
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.organisation?.website
                            ? request?.organisation?.website
                            : "N/A"}
                        </TextStyle>
                      </div>
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <div className="mainTitle mt-4">Media Information</div>
                  <div className="rowDiv popupText">
                    <div>
                      <div className="mt-2">
                        <TextStyle>Media Type:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Published | On-air:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Language:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Reach:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Subcategory:</TextStyle>
                      </div>
                    </div>
                    <div className="ms-5">
                      <div className="mt-2">
                        <TextStyle>
                          {request?.media_type &&
                          request?.media_type?.ids.length
                            ? request?.media_type?.ids.map(function (item, i) {
                                return (
                                  <div key={i}>
                                    {request?.media_type.type_other &&
                                    item.media.type.en === "Other" ? (
                                      <span>{`${item.media.type.en} (${request?.media_type.type_other}) `}</span>
                                    ) : (
                                      <span>{item.media.type.en}</span>
                                    )}
                                  </div>
                                );
                              })
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.media_type &&
                          request?.media_type?.published_on_air
                            ? !request?.media_type?.published_on_air_other
                              ? request?.media_type?.published_on_air
                                  .charAt(0)
                                  .toUpperCase() +
                                request?.media_type?.published_on_air.slice(1)
                              : `${
                                  request?.media_type?.published_on_air
                                    .charAt(0)
                                    .toUpperCase() +
                                  request?.media_type?.published_on_air.slice(1)
                                } (${
                                  request?.media_type?.published_on_air_other
                                })`
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.media_type && request?.media_type?.language
                            ? !request?.media_type?.language_other
                              ? request?.media_type?.language
                                  .charAt(0)
                                  .toUpperCase() +
                                request?.media_type?.language.slice(1)
                              : `${
                                  request?.media_type?.language
                                    .charAt(0)
                                    .toUpperCase() +
                                  request?.media_type?.language.slice(1)
                                } (${request?.media_type?.language_other})`
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.media_type && request?.media_type?.reach
                            ? request?.media_type?.reach
                                .charAt(0)
                                .toUpperCase() +
                              request?.media_type?.reach.slice(1)
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.organisation?.role
                            ?.media_accreditation_subcategory?.en
                            ? request?.organisation?.role
                                ?.media_accreditation_subcategory?.en
                            : "N/A"}
                        </TextStyle>
                      </div>
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>

              <FormLayout.Group>
                <FormLayout>
                  <div className="rowDiv popupText">
                    <div className="mt-4">
                      <div className="mainTitle mb-4">
                        <TextStyle>Name</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Given Name:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Second Name:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Third Name:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Fourth Name:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Family Name:</TextStyle>
                      </div>
                    </div>
                    <div className="ms-5 mt-4">
                      <div className="mainTitle  mb-4">
                        <TextStyle>English</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.first_name ? request?.first_name : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.second_name ? request?.second_name : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.third_name ? request?.third_name : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.fourth_name ? request?.fourth_name : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.last_name ? request?.last_name : "N/A"}
                        </TextStyle>
                      </div>
                    </div>
                    <div className="ms-5 mt-4">
                      <div className="mainTitle  mb-4">
                        <TextStyle>Arabic</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.first_name_ar
                            ? request?.first_name_ar
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.second_name_ar
                            ? request?.second_name_ar
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.third_name_ar
                            ? request?.third_name_ar
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.fourth_name_ar
                            ? request?.fourth_name_ar
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.last_name_ar
                            ? request?.last_name_ar
                            : "N/A"}
                        </TextStyle>
                      </div>
                    </div>
                  </div>
                </FormLayout>

                <FormLayout>
                  <div className="rowDiv popupText">
                    <div>
                      <div className="mainTitle  mb-4 mt-4">
                        <TextStyle>Others</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Emergency Contact Name:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Emergency Contact Mobile Number:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Accreditation Terms Acceptance:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Bringing equipment?</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Bringing wireless equipment?</TextStyle>
                      </div>
                    </div>
                    <div className="ms-5">
                      <div
                        className="mainTitle  mb-4 mt-4"
                        style={{ visibility: "hidden" }}
                      >
                        <TextStyle>empty</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.emergency_contact_name
                            ? request?.emergency_contact_name
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.emergency_contact_number
                            ? `${
                                request?.emergency_contact_PhoneAreaCode
                                  ? request?.emergency_contact_PhoneAreaCode
                                  : ""
                              } ${request?.emergency_contact_number}`
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Accepted</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.additional_data?.bringing_equipment !== null
                            ? request?.additional_data?.bringing_equipment
                              ? "Yes"
                              : "No"
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.wireless_equipment !== null
                            ? request?.additional_data?.wireless_equipment
                              ? "Yes"
                              : "No"
                            : "N/A"}
                        </TextStyle>
                      </div>
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>
              <FormLayout.Group>
                <FormLayout>
                  <div className="mainTitle mt-5">Identification Data</div>
                  <div className="rowDiv popupText">
                    <div>
                      <div>
                        <TextStyle>Nationality:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Document Type:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Documents Number:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Document Expiry:</TextStyle>
                      </div>
                    </div>
                    <div className="ms-5">
                      <div className="mt-2">
                        <TextStyle>
                          {request?.nationality &&
                          findArrayElementByCode(
                            countries,
                            String(request?.nationality)
                          )?.en
                            ? findArrayElementByCode(
                                countries,
                                String(request?.nationality)
                              )?.en
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.document_type !== null &&
                          request?.document_type !== ""
                            ? switchDocumentType(request?.document_type)
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.document_number !== null
                            ? request?.document_number
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.document_expiry_date
                            ? request?.document_expiry_date
                            : "N/A"}
                        </TextStyle>
                      </div>
                    </div>
                  </div>
                </FormLayout>
                <FormLayout>
                  <div
                    className="mainTitle mt-5"
                    style={{ visibility: "hidden" }}
                  >
                    empty
                  </div>
                  <div className="rowDiv popupText">
                    <div>
                      <div>
                        <TextStyle>Date of Birth:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Sex:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Document SubType:</TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>Passport Issuing Country:</TextStyle>
                      </div>
                    </div>
                    <div className="ms-5">
                      <div className="mt-2">
                        <TextStyle>
                          {request?.date_of_birth
                            ? request.date_of_birth
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.gender !== null && request?.gender !== ""
                            ? switchGender(request?.gender)
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {(request?.document_sub_type !== null) &
                          (request?.document_sub_type !== "")
                            ? switchDocumentSubType(request?.document_sub_type)
                            : "N/A"}
                        </TextStyle>
                      </div>
                      <div className="mt-2">
                        <TextStyle>
                          {request?.passport_issuing_country
                            ? findArrayElementByCode(
                                countries,
                                String(request?.passport_issuing_country)
                              ).en
                            : "N/A"}
                        </TextStyle>
                      </div>
                    </div>
                  </div>
                </FormLayout>
              </FormLayout.Group>
              {request?.reason_of_rejection && (
                <div>
                  <div className="mainTitle mt-5">Reason of Rejection</div>
                  <div className="mt-2">
                    <TextStyle>
                      {request?.reason_of_rejection
                        ? request.reason_of_rejection
                        : "N/A"}
                    </TextStyle>
                  </div>
                </div>
              )}
              {request?.email_message && (
                <div>
                  <div className="mainTitle mt-5">Email Message</div>
                  <div className="mt-2">
                    <TextStyle>
                      {request?.email_message ? request.email_message : "N/A"}
                    </TextStyle>
                  </div>
                </div>
              )}
              {isLoading && <Spinner />}
              {tabs && (
                <Tabs
                  tabs={tabs}
                  selected={selected}
                  onSelect={handleTabChange}
                >
                  <Card.Section>
                    {/* <p>Tab {selected} selected</p> */}
                    {tabs[selected].content === "Passport" &&
                      filesResponse.passport && (
                        <img
                          src={`data:image/jpeg;base64,${filesResponse.passport}`}
                          className="tabsImage"
                        />
                      )}
                    {tabs[selected].content === "Facial Photo" &&
                      filesResponse.facial && (
                        <img
                          src={`data:image/jpeg;base64,${filesResponse.facial}`}
                          className="tabsImage"
                        />
                      )}
                    {tabs[selected].content === "Credentials" &&
                      (request?.documents && request?.documents?.length ? (
                        <div>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              {request?.organisation &&
                                request?.organisation?.role?.type?.en && (
                                  <div
                                    style={{
                                      marginTop: "1.3rem",
                                      // marginBottom: "2rem",
                                    }}
                                  >
                                    <ButtonGroup>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          flexWrap: "wrap",
                                          lineHeight: "1",
                                        }}
                                      >
                                        {request.documents.map((item) => {
                                          return (
                                            <div
                                              style={
                                                {
                                                  // marginBottom: ".5rem",
                                                }
                                              }
                                              key={item.id}
                                            >
                                              <div
                                                id={item.document}
                                                onClick={(e) =>
                                                  handleClickDocument(e)
                                                }
                                                className="button buttonCredentials"
                                                style={{ width: "auto" }}
                                              >
                                                <div className="rowDiv">
                                                  <img
                                                    src={
                                                      item.document.split(
                                                        "."
                                                      )[1] === "pdf"
                                                        ? attachementsImage
                                                        : attachementsImage
                                                    }
                                                    width={15}
                                                    className="credentialsImage"
                                                    id="attachementsImage"
                                                  />
                                                  <span>
                                                    {
                                                      item.document.split(
                                                        "/"
                                                      )[2]
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </ButtonGroup>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      ) : null)}
                  </Card.Section>
                </Tabs>
              )}
              {/* <ActionButtons
              clickedItem={request}
              handleRowApprove={handleRowApprove}
              handleRowReject={handleRowReject}
              handleRowUnreject={handleRowUnreject}
              handleDownloadAll={handleDownloadAll}
              reasonOfRejectionValue={reasonOfRejectionValue}
              handleClosePopupReasonOfRejection={handleClosePopupReasonOfRejection}
              handleEmail={handleEmail}
              status={switchStatus()}
              handleSync={handleSync}
              handleResend={handleResend}
              // hayyaResponse={hayyaResponse}
              handleDeleteAccount={handleDeleteAccount}
              handleDeleteApplication={handleDeleteApplication}
            /> */}
            </FormLayout>
          </div>
        </Modal.Section>
      </Modal>
    </div>
  );
  function findArrayElementByCode(array, id) {
    return array.find((element, index) => {
      return parseInt(element.code) === parseInt(id);
    });
  }
};

export default RequestPopup;
