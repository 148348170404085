import React, { useState, useEffect } from "react";

import axios from "../../Assets/Lib/axios";

const ProfileDetails = (props) => {
  const { result } = props;
  const {
    profile_id,
    profile_belongings,
    profile_organizations,
    profile_services,
    profile_venues,
    profile_zones,
    organisation,
    accreditation_type_id,
  } = result;

  const accreditationTypeId = accreditation_type_id;

  const [profileId, setProfileId] = useState();
  const [profileBelonging, setProfileBelonging] = useState([]);
  const [profileOrganization, setProfileOrganization] = useState();
  const [profileService, setProfileService] = useState();
  const [profileVenue, setProfileVenue] = useState([]);
  const [profileZone, setProfileZone] = useState();

  useEffect(() => {
    fetchProfileDetails();
  }, [profile_id, profile_organizations]);

  const fetchProfileDetails = () => {
    // setIsLoading(true);
    axios
      .get(`admin/v1/registration-requests/profile-details`, {
        params: {
          accreditationTypeId,
        },
      })
      .then((res) => {
        const { profiles, profileDetails } =
          res?.data?.data?.profileListDetails;

        const pId = profiles.find((el) => +el.value === +profile_id);

        const {
          zones,
          venues,
          services,
          belongings,
          organizations,
        } = profileDetails[pId.key];

        setProfileId(pId.label);

        setProfileBelonging(
            profile_belongings
                .map(value => belongings.find(item => +item.value === +value))
                .filter(item => item !== undefined)
                .map(item => item.label || '')
        );
        setProfileVenue(
            profile_venues
                .map(value => venues.find(item => +item.value === +value))
                .filter(item => item !== undefined)
                .map(item => item.label || '')
        );
        setProfileService(
          services.find((item) => +item.value === +profile_services)?.label
        );
        setProfileZone(
          zones.find((item) => +item.value === +profile_zones)?.label
        );

        organizations.length > 0
          ? setProfileOrganization(
              organizations.find(
                (item) => +item.value === +profile_organizations
              )?.label
            )
          : setProfileOrganization(
              profile_organizations.length && profile_organizations[0]
            );
      })
      .catch((err) => console.log(""))
  };

  return (
    <>
      <div className="purpletitle mt-5">
        Profile Information
      </div>

      <div className="row Polaris-FormLayout g-0 pt-3">
        <div className="col-sm-4 Polaris-FormLayout__Item">
          <div>
            <div className="popupGreyText">Profile</div>
            <p className="popupBlackText maxWidthText">
              {profileId && profileId ? profileId : "N/A"}
            </p>
          </div>
        </div>

        <div className="col-sm-4 Polaris-FormLayout__Item">
          <div>
            <div className="popupGreyText">Service</div>
            <p className="popupBlackText">
              {profileService ? profileService : "N/A"}
            </p>
          </div>
        </div>

        <div className="col-sm-4 Polaris-FormLayout__Item">
          <div>
            <div className="popupGreyText">Venue</div>
            <p className="popupBlackText maxWidthText">
              {profileVenue.length
                  ? <ul dangerouslySetInnerHTML={{ __html: profileVenue.map(item => `<li>${item}</li>`).join('') }} />
                  : "N/A"
              }
            </p>
          </div>
        </div>
      </div>

      <div className="row Polaris-FormLayout g-0 pt-3">
        <div className="col-sm-4 Polaris-FormLayout__Item">
          <div>
            <div className="popupGreyText">Belonging</div>
            <p className="popupBlackText maxWidthText">
              {profileBelonging.length
                  ? <ul dangerouslySetInnerHTML={{ __html: profileBelonging.map(item => `<li>${item}</li>`).join('') }} />
                  : "N/A"
              }
            </p>
          </div>
        </div>

        <div className="col-sm-4 Polaris-FormLayout__Item">
          <div>
            <div className="popupGreyText">Organization</div>
            <p className="popupBlackText">
              {profileOrganization ? profileOrganization : "N/A"}
            </p>
          </div>
        </div>

        <div className="col-sm-4 Polaris-FormLayout__Item">
          <div>
            <div className="popupGreyText">Zones</div>
            <p className="popupBlackText maxWidthText">
              {profileZone ? profileZone : "N/A"}
            </p>
          </div>
        </div>
      </div>

      <div className="row Polaris-FormLayout g-0 pt-3">
        <div className="col-sm-4 Polaris-FormLayout__Item">
          <div>
            <div className="popupGreyText">Category</div>
            <p className="popupBlackText maxWidthText">
              {organisation?.role?.media_accreditation_subcategory?.en
                ? organisation?.role?.media_accreditation_subcategory?.en
                : "N/A"}
            </p>
          </div>
        </div>
        <div className="col-sm-4 Polaris-FormLayout__Item">
          <div>
            <div className="popupGreyText">Role</div>
            <p className="popupBlackText maxWidthText">
              {organisation?.role?.label ? organisation?.role?.label : "N/A"}
            </p>
          </div>
        </div>

        <div className="col-sm-4 Polaris-FormLayout__Item">
          <div></div>
        </div>
      </div>
    </>
  );
};
export default ProfileDetails;
