import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  DataTable,
  DisplayText,
  Image,
  Loading,
  Spinner,
  TextStyle,
} from "@shopify/polaris";
import Pagination from "@material-ui/lab/Pagination";
import Cookies from "js-cookie";
import axios from "../../Assets/Lib/axios";
import emptyListImage from "../../Assets/Images/emptyList.svg";
import LoadingBtn from "../../Components/LoadingBtn";
import FilterParentDiv from "../../Components/FilterParentDiv";
import FilterCloseButton from "../../Components/FilterCloseButton";
import filterGrey from "../../Assets/Images/filter-grey.svg";
import filterOrange from "../../Assets/Images/filter-orange.svg";

const ApplicationShortList = (props) => {
  const perPage = 100;
  const { setApplicationCount } = props;
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [requestTotal, setRequestTotal] = useState();

  const [filterState, setFilterState] = useState();
  const [searchValue, setSearchValue] = useState();
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [ts, setTs] = useState("");
  const refBoolPage = useRef(true);
  const loadingMarkup = isLoading ? <Loading /> : null;

  const emptyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  const handleChangePage = (event, value) => {
    Cookies.set("vmc-requests-filter-page", value);
    refBoolPage.current = true;
    setPage(value);
  };

  useEffect(() => {
    getData();
  }, [page, filterState, searchValue, ts]);

  const handleRowClick = (e) => {
    window.open(
      `/admin/applications/${JSON.parse(e.currentTarget.id).id}`,
      "_blank"
    );
  };

  // filter
  const refReferenceNumber = useRef(null);
  const refName = useRef(null);
  const refDocumentNumber = useRef(null);
  const refEmail = useRef(null);
  const refStatus = useRef(null);
  const refHayyaStatus = useRef(null);
  const refHayyaFanIdNo = useRef(null);
  const [filterClicked, setFilterClicked] = useState(true);
  const [statusSearchValue, setStatusSearchValue] = useState("All");
  const [statusHayyaSearchValue, setStatusHayyaSearchValue] = useState("All");
  const handleChangeReferenceSearchValue = (event) => {
    resetValues("refReferenceNumber");
    setFilterState("reference_number");
    setSearchValue(event.target.value);
    refBoolPage.current = false;
  };
  const handleChangeNameSearchValue = (event) => {
    resetValues("refName");
    setFilterState("name");
    setSearchValue(event.target.value);
    refBoolPage.current = false;
  };
  const handleChangeDocumentNumberSearchValue = (event) => {
    resetValues("refDocumentNumber");
    setFilterState("document_number");
    setSearchValue(event.target.value);
    refBoolPage.current = false;
  };
  const handleChangeEmailSearchValue = (event) => {
    resetValues("refEmail");
    setFilterState("email");
    setSearchValue(event.target.value);
    refBoolPage.current = false;
  };
  const handleChangeStatusSearchValue = (event) => {
    resetValues("refStatus");
    refStatus.current = event.target.value;
    setFilterState("status");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    refBoolPage.current = false;
    setStatusSearchValue(event.target.value);
  };

  const handleChangeHayyaStatusSearchValue = (event) => {
    resetValues("refHayyaStatus");
    refHayyaStatus.current = event.target.value;
    setFilterState("hayya_status");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    refBoolPage.current = false;
    setStatusHayyaSearchValue(event.target.value);
  };
  const handleChangeHayyaFanIdNumberSearchValue = (event) => {
    resetValues("refHayyaFanIdNo");
    setFilterState("hayya_fanid_number");
    setSearchValue(event.target.value);
    refBoolPage.current = false;
  };

  const resetValues = (param) => {
    // param !== "refReferenceNumber" && (refReferenceNumber.current.value = "");
    // param !== "refName" && (refName.current.value = "");
    param !== "refDocumentNumber" && (refDocumentNumber.current.value = "");
    param !== "refEmail" && (refEmail.current.value = "");
    // param !== "refHayyaFanIdNo" && (refHayyaFanIdNo.current.value = "");
    param !== "refStatus" && (refStatus.current = "");
    param !== "refHayyaStatus" && (refHayyaStatus.current = "");
  };

  const handleDeleteTextField = (param) => {
    param.current.value = "";
    setSearchValue("");
    refBoolPage.current = true;
  };

  const switchStatus = (param, isHayyaStatus) => {
    if (isHayyaStatus) {
      switch (param) {
        case "error":
          return "MOI Error";
        case "New":
          return "New";
        case "0":
          return "No Status";
        case "Pending":
          return "Pending MOI";
        case "Pending Entry Visa":
          return "Pending Entry Visa";
        case "Approved":
          return "MOI Approved";
        case "Rejected":
          return "MOI Rejected";
        case "400":
          return "Hayya Error";
        case "":
          return "All";
        case "All":
          return "All";
        default:
          return null;
        // code block
      }
    } else {
      switch (param) {
        case "New":
          return "New";
        case "Updated":
          return "Updated";
        case "Rejected":
          return "Rejected";
        case "approved":
          return "Approved QNA";
        case "approved_imo":
          return "Approved IMO";
        case "rejected_qna":
          return "Rejected QNA";
        case "Pending":
          return "Pending";
        case "approved_sc":
          return "Approved SC";
        case "approved_gco":
          return "Approved GCO";
        case "rejected_sc":
          return "Rejected SC";
        case "rejected_gco":
          return "Rejected GCO";
        case "rejected_imo":
          return "Rejected IMO";
        case "approved_non_accredited":
          return "Approved";
        case "":
          return "All";
        case "All":
          return "All";
        default:
          return null;
        // code block
      }
    }
  };

  const array = [
    // "firstTableItem0",
    // "firstTableItem1",
    "firstTableItem2",
    "firstTableItem3",
    "firstTableItem4",
    "firstTableItem5",
    // "firstTableItem6",
  ];
  const handleFilter = () => {
    if (!filterClicked) {
      for (var i = 0; i < array.length; i++) {
        if (document.getElementById(array[i])) {
          document.getElementById(array[i]).style.display = "block";
        }
      }
    } else {
      // resetValues("refReferenceNumber");
      // resetValues("refName");
      resetValues("refDocumentNumber");
      resetValues("refEmail");
      // resetValues("refHayyaFanIdNo");
      resetValues("refStatus");
      resetValues("refHayyaStatus");

      setSearchValue("");
      refBoolPage.current = true;

      for (let i = 0; i < array.length; i++) {
        document.getElementById(array[i]).style.display = "none";
      }
    }

    setFilterClicked(!filterClicked);
  };

  const getData = () => {
    setIsLoading(true);

    axios
      .get(
        `admin/v1/applications?per_page=${perPage}&page=${
          refBoolPage.current ? page : 1
        }${
          searchValue && filterState
            ? `&filter[${filterState}]=${
                searchValue.toLowerCase() !== "all"
                  ? searchValue.toLowerCase()
                  : ""
              }`
            : ""
        }`
      )
      .then((res) => {
        const applications = res?.data?.data;
        setApplicationCount(applications?.total);
        setRequestTotal(applications.total);
        setTotalPages(Math.ceil(applications.total / perPage));

        !applications?.data?.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);

        let array = [];
        if (applications?.data && applications?.data?.length) {
          for (let i = 0; i < applications?.data?.length; i++) {
            const item = applications?.data[i];
            array.push([
              // <div
              //   id={JSON.stringify(item)}
              //   className="itemClick bookFont"
              //   onClick={(e) => handleRowClick(e)}
              // >
              //   {item?.reference_number ? (
              //     item?.reference_number
              //   ) : (
              //     <p style={{ visibility: "hidden" }}>empty</p>
              //   )}
              // </div>,
              // <div
              //   id={JSON.stringify(item)}
              //   className="itemClick bookFont"
              //   onClick={(e) => handleRowClick(e)}
              // >
              //   {item?.first_name || item?.last_name ? (
              //     `${item?.first_name && item?.first_name} ${
              //       item?.last_name && item?.last_name
              //     }`
              //   ) : (
              //     <p style={{ visibility: "hidden" }}>empty</p>
              //   )}
              // </div>,
              <div
                id={JSON.stringify(item)}
                className="itemClick bookFont"
                onClick={(e) => handleRowClick(e)}
              >
                {item?.document_number ? (
                  item?.document_number
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                id={JSON.stringify(item)}
                className="itemClick bookFont"
                onClick={(e) => handleRowClick(e)}
              >
                {item?.email ? (
                  item?.email
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                id={JSON.stringify(item)}
                className="itemClick bookFont"
                onClick={(e) => handleRowClick(e)}
              >
                {item?.status ? (
                  item?.status
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                id={JSON.stringify(item)}
                className="itemClick bookFont"
                onClick={(e) => handleRowClick(e)}
              >
                {item?.hayya_status ? (
                  item?.hayya_status
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              // <div
              //   id={JSON.stringify(item)}
              //   className="itemClick bookFont"
              //   onClick={(e) => handleRowClick(e)}
              // >
              //   {item?.hayya_fanid_number ? (
              //     item?.hayya_fanid_number
              //   ) : (
              //     <p style={{ visibility: "hidden" }}>empty</p>
              //   )}
              // </div>,
            ]);
          }
        }

        array.unshift([
          // <div
          //   id="firstTableItem0"
          //   style={{ display: filterClicked ? "block" : "none" }}
          // >
          //   <FilterParentDiv filterClicked={filterClicked}>
          //     <div className="Polaris-TextField">
          //       <input
          //         id="PolarisTextField2"
          //         className="Polaris-TextField__Input"
          //         aria-labelledby="PolarisTextField2Label"
          //         aria-invalid="false"
          //         onChange={handleChangeReferenceSearchValue}
          //         ref={refReferenceNumber}
          //       />
          //       <FilterCloseButton
          //         searchValue={searchValue}
          //         filterState={filterState}
          //         filterCondition="reference_number"
          //         handleDeleteTextField={handleDeleteTextField}
          //         refr={refReferenceNumber}
          //       ></FilterCloseButton>

          //       <div className="Polaris-TextField__Backdrop" />
          //     </div>
          //   </FilterParentDiv>
          // </div>,

          // <div
          //   id="firstTableItem1"
          //   style={{ display: filterClicked ? "block" : "none" }}
          // >
          //   <FilterParentDiv filterClicked={filterClicked}>
          //     <div className="Polaris-TextField">
          //       <input
          //         id="PolarisTextField2"
          //         className="Polaris-TextField__Input"
          //         aria-labelledby="PolarisTextField2Label"
          //         aria-invalid="false"
          //         onChange={handleChangeNameSearchValue}
          //         ref={refName}
          //       />
          //       <FilterCloseButton
          //         searchValue={searchValue}
          //         filterState={filterState}
          //         filterCondition="name"
          //         handleDeleteTextField={handleDeleteTextField}
          //         refr={refName}
          //       ></FilterCloseButton>

          //       <div className="Polaris-TextField__Backdrop" />
          //     </div>
          //   </FilterParentDiv>
          // </div>,
          <div
            id="firstTableItem2"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-TextField">
                <input
                  id="PolarisTextField2"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField2Label"
                  aria-invalid="false"
                  onChange={handleChangeDocumentNumberSearchValue}
                  ref={refDocumentNumber}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition="document_number"
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refDocumentNumber}
                ></FilterCloseButton>

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItem3"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-TextField">
                <input
                  id="PolarisTextField4"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField4Label"
                  aria-invalid="false"
                  onChange={handleChangeEmailSearchValue}
                  ref={refEmail}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition="email"
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refEmail}
                ></FilterCloseButton>
                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItem4"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-Select">
                <select
                  id="PolarisSelect2"
                  className="Polaris-Select__Input"
                  aria-invalid="false"
                  onChange={handleChangeStatusSearchValue}
                  ref={refStatus}
                >
                  <option value="All">All</option>
                  <option value="New">New</option>
                  <option value="Updated">Updated</option>
                  <option value="Pending">Pending</option>
                  {/* <option value="approved_non_accredited">Approved</option> */}
                  <option value="approved_sc">Approved SC</option>
                  <option value="approved_gco">Approved GCO</option>
                  {/* <option value="approved_gco">Approved IMO</option> */}
                  <option value="approved">Approved QNA</option>
                  <option value="approved_imo">Approved IMO</option>
                  <option value="rejected_sc">Rejected SC</option>
                  <option value="rejected_gco">Rejected GCO</option>
                  <option value="rejected_imo">Rejected IMO</option>
                  {/* <option value="rejected_gco">Rejected IMO</option> */}
                  <option value="rejected_qna">Rejected QNA</option>
                </select>

                <div className="Polaris-Select__Content" aria-hidden="true">
                  <span className="Polaris-Select__SelectedOption">
                    {!refStatus.current
                      ? "All"
                      : switchStatus(statusSearchValue)}
                  </span>
                  <span className="Polaris-Select__Icon">
                    <span className="Polaris-Icon">
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="Polaris-Select__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItem5"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-Select">
                <select
                  id="PolarisSelect2"
                  className="Polaris-Select__Input"
                  aria-invalid="false"
                  onChange={handleChangeHayyaStatusSearchValue}
                  ref={refHayyaStatus}
                >
                  <option value="All">All</option>
                  <option value="error">MOI Error</option>
                  <option value="New">New</option>
                  <option value="Pending">Pending MOI</option>
                  <option value="Pending Entry Visa">Pending Entry Visa</option>
                  <option value="Approved">MOI Approved</option>
                  <option value="Rejected">MOI Rejected</option>
                  <option value="400">Hayya Error</option>
                  <option value="0">No Status</option>
                </select>

                <div className="Polaris-Select__Content" aria-hidden="true">
                  <span className="Polaris-Select__SelectedOption">
                    {!refHayyaStatus.current
                      ? "All"
                      : switchStatus(statusHayyaSearchValue, true)}
                  </span>
                  <span className="Polaris-Select__Icon">
                    <span className="Polaris-Icon">
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="Polaris-Select__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          // <div
          //   id="firstTableItem6"
          //   style={{ display: filterClicked ? "block" : "none" }}
          // >
          //   <FilterParentDiv filterClicked={filterClicked}>
          //     <div className="Polaris-TextField">
          //       <input
          //         id="PolarisTextField6"
          //         autoComplete="off"
          //         className="Polaris-TextField__Input"
          //         aria-labelledby="PolarisTextField6Label"
          //         aria-invalid="false"
          //         onChange={handleChangeHayyaFanIdNumberSearchValue}
          //         ref={refHayyaFanIdNo}
          //       />
          //       <FilterCloseButton
          //         searchValue={searchValue}
          //         filterState={filterState}
          //         filterCondition="hayya_fanid_number"
          //         handleDeleteTextField={handleDeleteTextField}
          //         refr={refHayyaFanIdNo}
          //       ></FilterCloseButton>
          //       <div className="Polaris-TextField__Backdrop" />
          //     </div>
          //   </FilterParentDiv>
          // </div>,
        ]);

        setItems(array);
        setIsLoading(false);
      })
      .catch((error) => console.log(""));
  };

  return (
    <div>
      <Fragment>
        {loadingMarkup}
        <div className="spinnerContainer-x is-loading">
          <div className="vertical-center">{isLoading && <LoadingBtn />}</div>
        </div>

        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            // <TextStyle>
            //   <div style={{ padding: "1.6rem" }}>Reference No.</div>
            // </TextStyle>,
            // <TextStyle>
            //   <div style={{ padding: "1.6rem" }}>Name</div>
            // </TextStyle>,
            <TextStyle>
              <div style={{ padding: "1.6rem" }}>Document Number</div>
            </TextStyle>,
            <TextStyle>
              <div style={{ padding: "1.6rem" }}>Email</div>
            </TextStyle>,
            <TextStyle>
              <div style={{ padding: "1.6rem" }}>Status</div>
            </TextStyle>,
            <TextStyle>
              <div style={{ padding: "1.6rem" }}>
                Hayya Status
              <div style={{ float: "right" }}>
                  <img
                    className="filterImg"
                    src={!filterClicked ? filterGrey : filterOrange}
                    onClick={handleFilter}
                  ></img>
                </div>

              </div>
            </TextStyle>,
            // <TextStyle>
            //   <div style={{ padding: "1.6rem" }}>
            //     Hayya Number
            //   </div>
            // </TextStyle>,
          ]}
          rows={items}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          verticalAlign="middle"
          style={{ color: "red" }}
        />
        {requestTotal >= 1 && (
          <div>
            <div className="totalDiv">Total: {requestTotal}</div>

            {totalPages >= 2 && (
              <div style={{ float: "right" }}>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChangePage}
                    size="large"
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {emptyState}
      </Fragment>
    </div>
  );
};
export default ApplicationShortList;
