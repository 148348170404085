import React, { useState, useCallback } from "react";
import axios from "../Assets/Lib/axios";
import {
  Button,
  Page,
  Loading,
  Card,
  ButtonGroup,
  TextField,
  FormLayout,
  Layout,
  Toast,
  Spinner,
  InlineError,
  TextStyle,
} from "@shopify/polaris";
import NavigationLayout from "../Components/NavigationLayout";

function Fanid() {
  const [value, setValue] = useState("");
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const [toastContent, setToastContent] = useState("");
  const toastMarkup = active ? (
    <Toast content={toastContent} onDismiss={toggleActive} duration={10000} />
  ) : null;
  const handleChange = useCallback((newValue) => setValue(newValue), []);
  // alert("Hello! I am an alert box!!");

  const [result, setResult] = useState("");
  const handleSubmit = () => {
    const bodyObj = { users: value };
    console.log("clicked");
    /// alert("Hello! I am an alert box!!");

    axios
      .post(`admin/v1/registration-requests-fanid`, bodyObj)
      .then((res) => {
        console.log("res====", JSON.stringify(res));
        setResult(res.data.fanid.data.fanIdNo);
        // console.log("aaaaaa");
        // console.log("res1=", res);
        // console.log("res0=", res.data);
        // console.log("res2=", res.fanid);
        // console.log("res3=", res.fanid.data);
        // console.log("res4=", res.fanid.data.fanIdNo);
        // alert(res.fanid.data.fanIdNo);

        // setToastContent(`Fan ID : ${res.fanid.data.fanIdNo}`);
        // toggleActive();
      })
      .catch((err) => console.log(""));
  };

  return (
    // <NavigationLayout>
    <FormLayout>
      <TextField value={value} onChange={handleChange} autoComplete="off" />
      <Button onClick={handleSubmit}>Submit</Button>
      {/* <p>Response</p> */}
      {result && <TextStyle>Result:{result}</TextStyle>}
    </FormLayout>
    // {toastMarkup}
    // </NavigationLayout>
  );
}

export default Fanid;
