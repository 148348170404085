import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import axios from "axios";
import Cookies from "js-cookie";
import history from "../Assets/Lib/history";
import {
  Modal,
  Page,
  Button,
  FormLayout,
  TextStyle,
  Card,
} from "@shopify/polaris";
import React, { useCallback, useState, useEffect } from "react";
import instance from "./../Assets/Lib/axios";
import { Routes, Route, useNavigate } from "react-router-dom";
import { adminRole } from "../Helpers";
import {forgotPasswordCode, forgotPasswordUri} from "../global";

const GetToken = () => {
  const navigate = useNavigate();
  const { instance, inProgress, accounts } = useMsal();
  const [popupActive, setPopupActive] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash);
    const errorCode =  urlParams.get('error_description');

    if (errorCode && errorCode.includes(forgotPasswordCode)) {
      setIsForgotPassword(true)
      window.location.href = forgotPasswordUri;
    }
  }, []);

  useEffect(() => {

    if (!isForgotPassword) {
      if (inProgress === InteractionStatus.None) {
        if (accounts.length) {
          requestProfileData();
        } else {
          instance.loginRedirect(loginRequest).catch((e) => {
            console.error(e);
          });
        }
      }
    }

  }, [inProgress, instance]);

  const handleSignOut = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const requestProfileData = async () => {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        const { accessToken, idToken } = response;

        const bodyObj = { id_token: response.idToken };
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}admin/v1/auth/login/microsoft`,
            bodyObj
          )
          .then((res) => {
            if (adminRole() === "CRA" || adminRole() === "Customs")
              navigate("/admin/equipment-clearance");
            else if (adminRole() === "Content")
              navigate("/admin/photos-and-videos/albums");
            else navigate("/admin/requests");
            
            Cookies.set("vmc-accesstoken", res.data.data.token);
            res?.data?.data?.user?.first_name &&
              Cookies.set("vmc-firstname", res.data.data.user.first_name);
            res?.data?.data?.user?.first_name &&
              Cookies.set("vmc-lastname", res.data.data.user.last_name);
            res?.data?.data?.user?.roles[0].id !== null &&
              Cookies.set("vmc-role", res.data.data.user.roles[0].id);
            res?.data?.data?.user?.type &&
              Cookies.set("vmc-type", res.data.data.user.type);

            res?.data?.data?.user?.roles[0]?.pivot?.events[0] &&
            Cookies.set("accreditation_type_id", res?.data?.data?.user?.roles[0]?.pivot?.events[0]);

            
            // history.push("admin/requests");

            // if (res.status == 200) {
            //   history.push("/admin/seasons");
            // }
          })
          .catch(function (error) {
            setPopupActive(false);
            console.log(error);

            // Cookies.set(
            //   "error",
            //   "Sorry, you are not allowed to access the admin panel"
            // );
            // // history.push("/");
            // instance.logoutRedirect({
            //   postLogoutRedirectUri: "/",
            // });
            // history.push("/");
          });
      });
  };

  return (
    <Page>
      {popupActive ? (
        <Modal open={popupActive} loading={true}></Modal>
      ) : (
        <div style={{ marginTop: "40%" }}>
          <Card sectioned>
            <FormLayout>
              <div style={{ textAlign: "center" }}>
                <p>Access denied. Please contact support.</p>
                <button className="loginButton mt-5" onClick={handleSignOut}>
                  Sign out
                </button>
              </div>
            </FormLayout>
          </Card>
        </div>
      )}
    </Page>
  );
};
export default GetToken;
