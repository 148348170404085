import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Select,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  TextStyle,
  Spinner,
  Toast,
  Modal,
  TextField,
  FormLayout,
  Button,
  Page,
  Card,
  PageActions,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import NavigationLayout from "../../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";

const AddInterviewee = () => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(true);
  const [intervieweeDateValue, setIntervieweeDateValue] = useState("");
  const handleChangeIntervieweeDate = (newValue) => {
    setIntervieweeDateValue(newValue);
    setDateError("");
  };

  const [dateError, setDateError] = useState("");

  const [intervieweeNameValue, setIntervieweeNameValue] = useState("");
  const handleChangeIntervieweeName = (newValue) => {
    setIntervieweeNameValue(newValue);
    setTitleError("");
  };
  const [intervieweeNameArValue, setIntervieweeNameArValue] = useState("");
  const handleChangeIntervieweeNameAr = (newValue) => {
    setIntervieweeNameArValue(newValue);
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const [selectedIntervieweeType, setSelectedIntervieweeType] = useState("");
  const handleSelectChangeIntervieweeType = useCallback(
    (newValue) => setSelectedIntervieweeType(newValue),
    []
  );
  const [optionsIntervieweeType, setOptionsIntervieweeType] = useState("");
  const [selectedIntervieweePosition, setSelectedIntervieweePosition] =
    useState("");
  const handleSelectChangeIntervieweePosition = useCallback(
    (newValue) => setSelectedIntervieweePosition(newValue),
    []
  );
  const [optionsIntervieweePosition, setOptionsIntervieweePosition] =
    useState("");
  const [intervieweeStartTimeValue, setIntervieweeStartTimeValue] =
    useState("");
  const handleChangeIntervieweeStartTime = useCallback(
    (newValue) => setIntervieweeStartTimeValue(newValue),
    []
  );
  const [intervieweeEndTimeValue, setIntervieweeEndTimeValue] = useState("");
  const handleChangeIntervieweeEndTime = useCallback(
    (newValue) => setIntervieweeEndTimeValue(newValue),
    []
  );
  const [capacityValue, setCapacityValue] = useState("");
  const handleChangeCapacity = useCallback(
    (newValue) => setCapacityValue(newValue),
    []
  );
  const [intervieweeDescriptionValue, setIntervieweeDescriptionValue] =
    useState("");
  const handleChangeIntervieweeDescription = useCallback(
    (newValue) => setIntervieweeDescriptionValue(newValue),
    []
  );
  const [intervieweeDescriptionArValue, setIntervieweeDescriptionArValue] =
    useState("");
  const handleChangeIntervieweeDescriptionAr = useCallback(
    (newValue) => setIntervieweeDescriptionArValue(newValue),
    []
  );
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responseTypes = "";
    let responsePositions = "";
    try {
      responseTypes = await axios.get(`admin/v1/interviewees/types`);
      responseTypes?.data?.data?.data &&
        setOptionsIntervieweeType(
          responseTypes.data.data.data.map((item, index) => {
            return {
              label: item.title[Object.keys(item.title)[0]],
              value: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responsePositions = await axios.get(`admin/v1/interviewees/types`);
      console.log("loca=", responsePositions.data.data.data);
      responsePositions?.data?.data?.data &&
        setOptionsIntervieweePosition(
          responsePositions.data.data.data.map((item, index) => {
            return {
              label: item.title[Object.keys(item.title)[0]],
              value: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }
  return (
    <NavigationLayout>
      <Page
        fullWidth
        breadcrumbs={[
          {
            onAction: () => navigate("/admin/manage-interviewees/interviewees"),
          },
        ]}
        title="Add Interviewee"
      >
        {isSaving ? <Loading /> : null}
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Interviewee Name En</TextStyle>
              </p>
              <TextField
                value={intervieweeNameValue}
                onChange={handleChangeIntervieweeName}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Interviewee Name Ar</TextStyle>
              </p>
              <TextField
                value={intervieweeNameArValue}
                onChange={handleChangeIntervieweeNameAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Interviewee Position</TextStyle>
              </p>
              <Select
                options={optionsIntervieweePosition}
                onChange={handleSelectChangeIntervieweePosition}
                value={selectedIntervieweePosition}
                placeholder="Please select a position"
              />
            </FormLayout>
          </FormLayout.Group>

          {/* <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Interviewee Start Time</TextStyle>
              </p>
              <TextField
                value={intervieweeStartTimeValue}
                onChange={handleChangeIntervieweeStartTime}
                type="time"
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Interviewee End Time</TextStyle>
              </p>
              <TextField
                value={intervieweeEndTimeValue}
                onChange={handleChangeIntervieweeEndTime}
                type="time"
              />
            </FormLayout>

            <FormLayout>
              <p className="greyText">
                <TextStyle>
                  Capacity of the Interviewee/Number of Seats
                </TextStyle>
              </p>
              <TextField
                value={capacityValue}
                onChange={handleChangeCapacity}
                type="number"
              />
            </FormLayout>
          </FormLayout.Group> */}

          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Interviewee Description En</TextStyle>
              </p>
              <TextField
                value={intervieweeDescriptionValue}
                onChange={handleChangeIntervieweeDescription}
                multiline={4}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Interviewee Description Ar</TextStyle>
              </p>
              <TextField
                value={intervieweeDescriptionArValue}
                onChange={handleChangeIntervieweeDescriptionAr}
                multiline={4}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
        </FormLayout>{" "}
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
        />
      </Page>
    </NavigationLayout>
  );
  function handleSave() {
    if (
      (!intervieweeNameValue && !intervieweeNameArValue) ||
      !intervieweeDateValue
    ) {
      !intervieweeNameValue &&
        !intervieweeNameArValue &&
        setTitleError("Please enter an interviewee name");
      !intervieweeDateValue && setDateError("Please select a date");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      intervieweeNameValue &&
        form_data.append("title[en]", intervieweeNameValue);
      intervieweeNameArValue &&
        form_data.append("title[ar]", intervieweeNameArValue);
      intervieweeDateValue && form_data.append("date", intervieweeDateValue);
      intervieweeDescriptionValue &&
        form_data.append("description[en]", intervieweeDescriptionValue);
      intervieweeDescriptionArValue &&
        form_data.append("description[ar]", intervieweeDescriptionArValue);
      intervieweeStartTimeValue &&
        form_data.append("start_time", intervieweeStartTimeValue);
      intervieweeEndTimeValue &&
        form_data.append("end_time", intervieweeEndTimeValue);
      capacityValue !== "" && form_data.append("capacity", capacityValue);
      selectedIntervieweeType !== "" &&
        form_data.append("types[0]", selectedIntervieweeType);
      selectedIntervieweePosition !== "" &&
        form_data.append("position", selectedIntervieweePosition);
      axios
        .post("admin/v1/interviewees", form_data)
        .then((res) => {
          navigate("/admin/manage-interviewees/interviewees");
        })
        .catch((err) => console.log(""));
    }
  }
};

export default AddInterviewee;
