import React, { useCallback, useState, useEffect } from "react";
import {
    DataTable,
    Button,
    Page,
    Loading,
    Card,
    Tabs,
} from "@shopify/polaris";
import NavigationLayout from "../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";
import OrganisationList from "./OrganisationList";
import AssociatedUsers from "./AssociatedUsers";

const OrgainsationDetail = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [isListEmpty, setIsListEmpty] = useState(false);
    const [items, setItems] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [queryValue, setQueryValue] = useState("");
    const [page, setPage] = useState(1);

    const [selected, setSelected] = useState(0); // Initially, first tab is selected
    const loadingMarkup = isLoading ? <Loading /> : null;

    const tabs = [
        {
            id: 'allAdmins',
            content: 'Organisation',
        },
        {
            id: 'activeAdmins',
            content: 'Associated Users',
        }
    ];

    const handleTabChange = (selectedTabIndex) => {
        setSelected(selectedTabIndex);
    };
    return (
        <NavigationLayout>
            <Page
                fullWidth
            >

                <Tabs
                    tabs={tabs}
                    selected={selected}
                    onSelect={handleTabChange}
                >

                    {selected === 0 && (
                        <OrganisationList selectedTab="0" /> // Pass selected tab index as prop to your component
                    )}
                    {selected === 1 && (
                        <AssociatedUsers selectedTab="1" />
                    )}
                </Tabs>

            </Page>
        </NavigationLayout>
    );

};

export default OrgainsationDetail;
