import React from "react";

const FilterCloseButton = (props) => {
  return (
    <button
      type="button"
      className="Polaris-TextField__ClearButton"
      tabIndex={0}
      onClick={() => props.handleDeleteTextField(props.refr)}
      style={{
        visibility:
          props.searchValue !== "" &&
          props.filterState === props.filterCondition
            ? "visible"
            : "hidden",
      }}
    >
      <span className="Polaris-VisuallyHidden" />
      <span className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
        <svg
          viewBox="0 0 20 20"
          className="Polaris-Icon__Svg"
          focusable="false"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM7.707 6.293a1 1 0 0 0-1.414 1.414L8.586 10l-2.293 2.293a1 1 0 1 0 1.414 1.414L10 11.414l2.293 2.293a1 1 0 1 0 1.414-1.414L11.414 10l2.293-2.293a1 1 0 0 0-1.414-1.414L10 8.586 7.707 6.293z"
          />
        </svg>
      </span>
    </button>
  );
};

export default FilterCloseButton;
