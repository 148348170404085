import React, { useState, useCallback } from "react";
import axios from "../Assets/Lib/axios";
import {
  Button,
  Page,
  Loading,
  Card,
  ButtonGroup,
  TextField,
  FormLayout,
  Layout,
  Toast,
  Spinner,
  InlineError,
} from "@shopify/polaris";

function IdUserPage() {
  const [value, setValue] = useState("");
  const handleChange = useCallback((newValue) => setValue(newValue), []);
  const handleSubmit = () => {
    axios
      .patch(`admin/v1/registration-requests/${value}/status/update/hayya`)
      .then((res) => {})
      .catch((err) => console.log(""));
  };
  return (
    <FormLayout>
      <TextField value={value} onChange={handleChange} autoComplete="off" />
      <Button onClick={handleSubmit}>Submit</Button>
    </FormLayout>
  );
}

export default IdUserPage;
