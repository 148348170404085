import React, { useState, useEffect } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";
import NavigationLayout from "../../Components/NavigationLayout";
import PermitsTabImage from "../../Assets/Images/permits-tab.svg";
import TableOfPermits from "./TableOfPermits";
import { adminRole } from "../../Helpers";

const PermitsTabs = () => {
  const [selected, setSelected] = useState(
    parseInt(localStorage.getItem("permits-selected-tab"))
      ? parseInt(localStorage.getItem("permits-selected-tab"))
      : 0
  );
  const handleTabChange = (selected) => {
    localStorage.setItem("permits-selected-tab", selected);
    setSelected(selected);
  };

  useEffect(() => {
    // console.log("permitsssss");
  }, []);

  const switchTabs = (param) => {
    switch (String(param)) {
      case "Senior":
      case "Viewer":
      case "HAYYA":
        return [
          {
            id: "t1",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img
                  src={PermitsTabImage}
                  color="highlight"
                  style={{ width: "2rem" }}
                /> */}
                <span
                  style={{ marginLeft: "10px" }}
                  className={"TabTitleRegular"}
                >
                  {`All Equipment Forms`}
                </span>
              </div>
            ),
          },
          {
            id: "t2",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img
                  src={PermitsTabImage}
                  color="highlight"
                  style={{ width: "2rem" }}
                /> */}
                <span
                  style={{ marginLeft: "10px" }}
                  className={"TabTitleRegular"}
                >
                  {`CRA Approval`}
                </span>
              </div>
            ),
          },
          {
            id: "t3",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img
                  src={PermitsTabImage}
                  color="highlight"
                  style={{ width: "2rem" }}
                /> */}
                <span
                  style={{ marginLeft: "10px" }}
                  className={"TabTitleRegular"}
                >
                  {`QNA Approval`}
                </span>
              </div>
            ),
          },
          {
            id: "t4",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img
                  src={PermitsTabImage}
                  color="highlight"
                  style={{ width: "2rem" }}
                /> */}
                <span
                  style={{ marginLeft: "10px" }}
                  className={"TabTitleRegular"}
                >
                  {`Customs`}
                </span>
              </div>
            ),
          },
        ];
      case "CRA":
        return [
          {
            id: "t1",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img
                  src={PermitsTabImage}
                  color="highlight"
                  style={{ width: "2rem" }}
                /> */}
                <span
                  style={{ marginLeft: "10px" }}
                  className={"TabTitleRegular"}
                >
                  {`CRA Approval`}
                </span>
              </div>
            ),
          },
        ];
      case "QNA":
        return [
          {
            id: "t1",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img
                  src={PermitsTabImage}
                  color="highlight"
                  style={{ width: "2rem" }}
                /> */}
                <span
                  style={{ marginLeft: "10px" }}
                  className={"TabTitleRegular"}
                >
                  {`QNA Approval`}
                </span>
              </div>
            ),
          },
        ];
      case "Customs":
        return [
          {
            id: "t1",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img
                  src={PermitsTabImage}
                  color="highlight"
                  style={{ width: "2rem" }}
                /> */}
                <span
                  style={{ marginLeft: "10px" }}
                  className={"TabTitleRegular"}
                >
                  {`Customs`}
                </span>
              </div>
            ),
          },
        ];

      default:
      // code block
    }
  };
  const tabs = switchTabs(adminRole());
  return (
    <NavigationLayout>
      <Page fullWidth>
        <Card>
          {(adminRole() === "Senior" || adminRole() === "Viewer" || adminRole() === "HAYYA") && (
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              {selected === 0 && <TableOfPermits selectedTab="all" />}
              {selected === 1 && <TableOfPermits selectedTab="cra" />}
              {selected === 2 && <TableOfPermits selectedTab="qna" />}
              {selected === 3 && <TableOfPermits selectedTab="customs" />}
            </Tabs>
          )}
          {adminRole() === "CRA" && (
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <TableOfPermits selectedTab="cra" />
            </Tabs>
          )}
          {adminRole() === "QNA" && (
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <TableOfPermits selectedTab="qna" />
            </Tabs>
          )}
          {adminRole() === "Customs" && (
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <TableOfPermits selectedTab="customs" />
            </Tabs>
          )}
        </Card>
      </Page>
    </NavigationLayout>
  );
};

export default PermitsTabs;
