import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import history from "../../Assets/Lib/history";
import NavigationLayout from "../../Components/NavigationLayout";
import GridView from "./Grid";
import News from "./News/Main";
import Footer from "./../../Components/Footer";
import SocialMedia from "./SocialMedia";

const Content = () => {
  return (
    <NavigationLayout>
      <BrowserRouter history={history}>
        <Routes>
          <Route exact path="/admin/content" element={GridView} />
          <Route path="/admin/content/news" element={News} />
          <Route path="/admin/content/social-media" element={SocialMedia} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </NavigationLayout>
  );
};
export default Content;
