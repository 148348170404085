import Cookies from "js-cookie";

export function isDefined(param) {
  let bool = false;
  if (
    bool !== "undefined" &&
    bool !== undefined &&
    bool !== null &&
    bool !== ""
  )
    bool = true;
  return true;
}
export function adminRole() {
  switch (String(Cookies.get("vmc-role"))) {
    case "1":
      return "IT";
    case "2":
      return "Senior";
    case "3":
      return "Registration";
    case "4":
      return "SC";
    case "5":
      return "GCO";
    case "6":
      return "QNA";
    case "7":
      return "HAYYA";
    case "8":
      return "Booking";
    case "9":
      return "Events Calendar";
    case "10":
      return "Content Manager";
    case "11":
      return "Content";
    case "12":
      return "Viewer";
    case "13":
      return "HR";
    case "14":
      return "IMO";
    case "15":
      return "CRA";
    case "16":
      return "Customs";
    default:
      return "foo";
  }
}
