import React from "react";
import Grid from "@material-ui/core/Grid";
import { Page, Icon, Card, TextStyle } from "@shopify/polaris";
import {
  ListMajor,
  EmailNewsletterMajor,
  LinkMinor,
  JobsMajor,
  LanguageMinor,
} from "@shopify/polaris-icons";
import history from "../../Assets/Lib/history";

const GridView = () => {
  return (
    <Page>
      <Card>
        <div style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="gridItem"
                onMouseDown={(e) => history.push("/admin/content/news")}
              >
                <div className="iconDiv">
                  <Icon
                    source={EmailNewsletterMajor}
                    style={{ margin: "0 5px" }}
                    color="subdued"
                  ></Icon>
                </div>
                <div style={{ flexDirection: "column", marginLeft: "10" }}>
                  <div className="titleDev">
                    <TextStyle variation="strong">News</TextStyle>
                  </div>
                  <div className="description">
                    <TextStyle></TextStyle>
                  </div>
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={4}>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="gridItem"
                onMouseDown={(e) => history.push("/admin/content/social-media")}
              >
                <div className="iconDiv">
                  <Icon
                    source={LinkMinor}
                    style={{ margin: "0 5px" }}
                    color="subdued"
                  ></Icon>
                </div>
                <div style={{ flexDirection: "column", marginLeft: "10" }}>
                  <div className="titleDev">
                    <TextStyle variation="strong">Social Media Links</TextStyle>
                  </div>
                  <div className="description">
                    <TextStyle></TextStyle>
                  </div>
                </div>
              </div>
            </Grid> */}
          </Grid>
        </div>
      </Card>
    </Page>
  );
};
export default GridView;
