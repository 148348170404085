import React, { useCallback, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Dashboard from "./Pages/Dashboard";
import "@shopify/polaris/dist/styles.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import history from "./Assets/Lib/history";
import { AppProvider, Toast } from "@shopify/polaris";
import "./Assets/Styles/globals.css";
import "./Assets/Styles/react-tagsinput.css";
import Login from "./Pages/Authentication/Login";
import ForgotPassword from "./Pages/Authentication/ForgotPassword";
import ResetPassword from "./Pages/Authentication/ResetPassword";
import Requests from "./Pages/Requests/TabsRequests";
import Settings from "./Pages/Settings";
import Content from "./Pages/Content/Main";
import LoginMicrosoft from "./Pages/Authentication/LoginMicrosoft";
import Cookies from "js-cookie";
import Articles from "./Pages/Articles";
import List from "./Pages/Users/List";
import CompletedApplicationDetails from "./Pages/Users/CompletedApplicationDetails";
import IncompleteApplicationDetails from "./Pages/Users/IncompleteApplicationDetails";
import IncompleteApplications from "./Pages/Users/IncompleteApplications";
import GetToken from "./Pages/GetToken";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import Audits from "./Pages/Audits";
import ListOfAlbums from "./Pages/PhotosAndVideos/Albums/ListOfAlbums";
import RequestsTabs from "./Pages/Requests/TabsRequests";
import RequestDetails from "./Pages/Requests/RequestDetails";
import ListAdminUsers from "./Pages/AdminUsers/ListAdminUsers";
import AddUser from "./Pages/AdminUsers/AddUser";
import EditUser from "./Pages/AdminUsers/EditUser";
import PermitsTabs from "./Pages/Permits/TabsPermits";
import PermitDetails from "./Pages/Permits/PermitDetails";
import IdUserPage from "./Pages/IdUserPage";
import IduserPage2 from "./Pages/IdUserPage2";
import FnLn from "./Pages/FnLn";
import AddAlbum from "./Pages/PhotosAndVideos/Albums/AddAlbum";
import GridPhotosAndVideos from "./Pages/PhotosAndVideos/GridPhotosAndVideos";
import AddTag from "./Pages/PhotosAndVideos/Tags/AddTag";
import ListOfTags from "./Pages/PhotosAndVideos/Tags/ListOfTags";
import EditTag from "./Pages/PhotosAndVideos/Tags/EditTag";
import EditAlbum from "./Pages/PhotosAndVideos/Albums/EditAlbum";
import ListOfMedias from "./Pages/PhotosAndVideos/Media/ListOfMedia";
import AddMedia from "./Pages/PhotosAndVideos/Media/AddMedia";
import EditMedia from "./Pages/PhotosAndVideos/Media/EditMedia";
import GridEvents from "./Pages/ManageEvents/GridEvents";
import AddEvent from "./Pages/ManageEvents/Events/AddEvent";
import TableOfEvents from "./Pages/ManageEvents/Events/TableOfEvents";
import ListOfEventTypes from "./Pages/ManageEvents/Types/ListOfEventTypes";
import AddEventType from "./Pages/ManageEvents/Types/AddEventType";
import EditEventType from "./Pages/ManageEvents/Types/EditEventType";
import ListOfEventLocations from "./Pages/ManageEvents/Locations/ListOfEventLocations";
import AddEventLocation from "./Pages/ManageEvents/Locations/AddEventLocation";
import EditEventLocation from "./Pages/ManageEvents/Locations/EditEventLocation";
import RequestPopup from "./Components/RequestPopup";
import Fanid from "./Pages/fanid";
import EditEvent from "./Pages/ManageEvents/Events/EditEvent";
import { useIsAuthenticated } from "@azure/msal-react";
import ChannelTag from "./Pages/ChannelTag";
import Only5 from "./Pages/Only5";
import TableOfPermits from "./Pages/Permits/TableOfPermits";
import Gridinterviewees from "./Pages/ManageInterviewees/GridInterviewees";
import ListOfPositions from "./Pages/ManageInterviewees/Positions/ListOfPositions";
import AddPosition from "./Pages/ManageInterviewees/Positions/AddPosition";
import TableOfInterviewees from "./Pages/ManageInterviewees/Interviewees/TableOfInterviewees";
import AddInterviewee from "./Pages/ManageInterviewees/Interviewees/AddInterviewee";
import TableOfStudios from "./Pages/ManageStudios/Studios/TableOfStudios";
import AddStudio from "./Pages/ManageStudios/Studios/AddStudio";
import EditStudio from "./Pages/ManageStudios/Studios/EditStudio";
import AddStudioType from "./Pages/ManageStudios/Types/AddStudioType";
import ListOfStudioTypes from "./Pages/ManageStudios/Types/ListOfStudioTypes";
import EditStudioType from "./Pages/ManageStudios/Types/EditStudioType";
import TableOfSubscribers from "./Pages/ManageEvents/Events/TableOfSubscribers";
import Only6 from "./Pages/Only6";
import ListOfCategories from "./Pages/PhotosAndVideos/Categories/ListOfCategories";
import AddCategory from "./Pages/PhotosAndVideos/Categories/AddCategory";
import EditCategory from "./Pages/PhotosAndVideos/Categories/EditCategory";
import AddMultipleFiles from "./Pages/PhotosAndVideos/AddMultipleFiles";
import Viewers from "./Pages/PhotosAndVideos/Media/Viewers";
import ApplicationTabs from "./Pages/Applications/ApplicationTabs";
import ApplicationsDetails from "./Pages/Applications/ApplicationsDetails";
import AddOrganisations from "./Pages/ManageOrganisations/AddOrganisations";
import OrgainsationDetail from "./Pages/ManageOrganisations/OrganisationDetail";
import AssociateUserForm from "./Pages/ManageOrganisations/AssociateUserForm";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  // if (inProgress === InteractionStatus.None && !isAuthenticated) {
  //   instance.loginRedirect(loginRequest);
  // }
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
  }

  const handleSignIn = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <AppProvider
      theme={{
        colors: {
          surface: "#eeeee4",
          onSurface: "#111213",
          interactive: "#2e72d2",
          secondary: "#111213",
          // primary: "#8a1538",
          critical: "#d82c0d",
          warning: "#ffc453",
          highlight: "#ff4700",
          success: "#008060",
          decorative: "#ffffff",
        },
      }}
    >
      <link href="https://api.fontshare.com/v2/css?f[]=satoshi@300,400&display=swap" rel="stylesheet" />
      {/* <link
        rel="preload"
        href="./Assets/Fonts/Qatar2022-Bold.otf"
        as="font"
        type="font/otf"
      />
      <link
        rel="preload"
        href="./Assets/Fonts/Qatar2022-Book.otf"
        as="font"
        type="font/otf"
      />
      <link
        rel="preload"
        href="./Assets/Fonts/Qatar2022-Medium.otf"
        as="font"
        type="font/otf"
      />
      <link
        rel="preload"
        href="./Assets/Fonts/Graphik-Semibold.otf"
        as="font"
        type="font/otf"
      />
      <link
        rel="preload"
        href="./Assets/Fonts/Graphik-Light.otf"
        as="font"
        type="font/otf"
      />
      <link
        rel="preload"
        href="./Assets/Fonts/Graphik-Medium.otf"
        as="font"
        type="font/otf"
      />
      <link
        rel="preload"
        href="./Assets/Fonts/Graphik-Regular.otf"
        as="font"
        type="font/otf"
      />
      <link
        rel="preload"
        href="./Assets/Fonts/GraphikArabic-Medium.otf"
        as="font"
        type="font/otf"
      /> */}
      {/* <link
        rel="preload"
        href="./Asstes/Images/login-background.jpg"
        as="image"
      /> */}
      <link rel="preload" href="./Asstes/Images/back.svg" as="image" />
      <link rel="preload" href="./Asstes/Images/back.svg" as="image" />
      <link rel="preload" href="./Asstes/Images/reject.svg" as="image" />
      <link rel="preload" href="./Asstes/Images/approve.svg" as="image" />
      <link rel="preload" href="./Asstes/Images/reject.svg" as="image" />
      <link rel="preload" href="./Asstes/Images/approve-white.svg" as="image" />
      <link rel="preload" href="./Asstes/Images/reject-white.svg" as="image" />
      <link rel="preload" href="./Asstes/Images/email-white.svg" as="image" />
      <link rel="preload" href="./Asstes/Images/email.svg" as="image" />
      <link rel="preload" href="./Asstes/Images/forward.svg" as="image" />
      <link rel="preload" href="./Asstes/Images/forward-white.svg" as="image" />
      <link rel="preload" href="./Asstes/Images/download.svg" as="image" />
      <link
        rel="preload"
        href="./Asstes/Images/download-white.svg"
        as="image"
      />
      <link rel="preload" href="./Asstes/Images/attachments.svg" as="image" />
      <link
        rel="preload"
        href="./Asstes/Images/attachments-white.svg"
        as="image"
      />
      {/* <BrowserRouter> */}
      {/* <AuthenticatedTemplate> */}
      {useIsAuthenticated && (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/password/reset" element={<ResetPassword />} />
          <Route path="/admin/requests" element={<RequestsTabs />} />
          <Route
            path="/admin/requests/gco-requests/:id"
            element={<RequestDetails />}
          />
          <Route
            path="/admin/requests/imo-requests/:id"
            element={<RequestDetails />}
          />
          <Route
            path="/admin/requests/hayya-requests/:id"
            element={<RequestDetails />}
          />
          <Route
            path="/admin/requests/qna-requests/:id"
            element={<RequestDetails />}
          />
          <Route
            path="/admin/requests/sc-requests/:id"
            element={<RequestDetails />}
          />

          <Route path="/admin/content" element={<Content />} />

          {/* {String(Cookies.get("vmc-role")) !== "5" &&
            String(Cookies.get("vmc-role")) !== "6" && ( */}
          <Route path="/admin/dashboard" element={<Dashboard />} />
          {/* )} */}
          <Route
            path="/admin/requests/registration-requests/:id"
            element={<Requests />}
          />

          {/* {String(Cookies.get("vmc-role")) === "2" && ( */}
          <Route
            path="/admin/manage-admin-users"
            element={<ListAdminUsers />}
          />
          {/* )} */}
          {/* {String(Cookies.get("vmc-role")) === "2" && ( */}
          <Route path="/admin/manage-admin-users/new" element={<AddUser />} />
          {/* )} */}
          {/* {String(Cookies.get("vmc-role")) === "2" && ( */}
          <Route path="/admin/manage-admin-users/:id" element={<EditUser />} />
          {/* )} */}

          <Route path="/getToken" element={<GetToken />} />

          {/* {String(Cookies.get("vmc-role")) === "2" && ( */}
          <Route path="/admin/audits" element={<Audits />} />
          {/* )} */}
          {/* {String(Cookies.get("vmc-role")) === "2" && ( */}
          <Route path="/admin/equipment-clearance" element={<PermitsTabs />} />

          {/* PHOTOS AND VIDEOS */}
          {/* <Route
            path="/admin/photos-and-videos"
            element={<GridPhotosAndVideos />}
          /> */}
          <Route
            path="/admin/photos-and-videos/albums"
            element={<ListOfAlbums />}
          />
          <Route
            path="/admin/photos-and-videos/albums/new"
            element={<AddAlbum />}
          />
          <Route
            path="/admin/photos-and-videos/albums/:id"
            element={<EditAlbum />}
          />
          <Route
            path="/admin/photos-and-videos/albums/:id/medias"
            element={<ListOfMedias />}
          />
          <Route
            path="/admin/photos-and-videos/albums/:id/bulk-medias"
            element={<AddMultipleFiles />}
          />
          <Route
            path="/admin/photos-and-videos/albums/:id/medias/new"
            element={<AddMedia />}
          />
          <Route
            path="/admin/photos-and-videos/albums/:albumId/medias/:mediaId"
            element={<EditMedia />}
          />
          <Route
            path="/admin/photos-and-videos/albums/:albumId/medias/:mediaId/viewers"
            element={<Viewers />}
          />
          <Route
            path="/admin/photos-and-videos/tags"
            element={<ListOfTags />}
          />
          <Route
            path="/admin/photos-and-videos/tags/new"
            element={<AddTag />}
          />
          <Route
            path="/admin/photos-and-videos/tags/:id"
            element={<EditTag />}
          />
          <Route
            path="/admin/photos-and-videos/categories"
            element={<ListOfCategories />}
          />
          <Route
            path="/admin/photos-and-videos/categories/new"
            element={<AddCategory />}
          />
          <Route
            path="/admin/photos-and-videos/categories/:id"
            element={<EditCategory />}
          />

          {/* MANAGE EVENTS */}
          <Route path="/admin/manage-events" element={<GridEvents />} />
          <Route
            path="/admin/manage-events/events"
            element={<TableOfEvents />}
          />
          <Route
            path="/admin/manage-events/events/new"
            element={<AddEvent />}
          />
          <Route
            path="/admin/manage-events/types"
            element={<ListOfEventTypes />}
          />
          <Route
            path="/admin/manage-events/types/new"
            element={<AddEventType />}
          />
          <Route
            path="/admin/manage-events/types/:id"
            element={<EditEventType />}
          />
          <Route
            path="/admin/manage-events/locations"
            element={<ListOfEventLocations />}
          />
          <Route
            path="/admin/manage-events/locations/new"
            element={<AddEventLocation />}
          />
          <Route
            path="/admin/manage-events/locations/:id"
            element={<EditEventLocation />}
          />
          <Route
            path="/admin/manage-events/events/:id"
            element={<EditEvent />}
          />
          <Route
            path="/admin/manage-events/events/:id/bookings"
            element={<TableOfSubscribers />}
          />

          {/* MANAGE INTERVIEWESS */}
          <Route
            path="/admin/manage-interviewees"
            element={<Gridinterviewees />}
          />
          <Route
            path="/admin/manage-interviewees/interviewees"
            element={<TableOfInterviewees />}
          />
          <Route
            path="/admin/manage-interviewees/interviewees/new"
            element={<AddInterviewee />}
          />

          <Route
            path="/admin/manage-interviewees/positions/new"
            element={<AddPosition />}
          />
          <Route
            path="/admin/manage-interviewees/positions"
            element={<ListOfPositions />}
          />

          <Route
            path="/admin/manage-interviewees/positions/:id"
            element={<EditEvent />}
          />

          {/* PERMITS */}

          <Route
            path="/admin/equipment-clearance/:id"
            element={<PermitDetails />}
          />
          <Route
            path="/admin/equipment-clearance/cra-permits/:id"
            element={<PermitDetails />}
          />
          <Route
            path="/admin/equipment-clearance/all-permits/:id"
            element={<PermitDetails />}
          />
          <Route
            path="/admin/equipment-clearance/qna-permits/:id"
            element={<PermitDetails />}
          />
          <Route
            path="/admin/equipment-clearance/customs-permits/:id"
            element={<PermitDetails />}
          />

          {/* )} */}

          {/* {String(Cookies.get("vmc-role")) === "2" && ( */}
          <Route path="/admin/users" element={<List />} />
          {/* )} */}

          <Route
            path="/admin/users/completed-applications/:id"
            element={<CompletedApplicationDetails />}
          />
          <Route
            path="/admin/users/incomplete-applications/:id"
            element={<IncompleteApplicationDetails />}
          />
          <Route path="/admin/only-for-me" element={<IdUserPage />} />
          <Route path="/admin/only-for-me-2" element={<FnLn />} />
          <Route path="/admin/only-for-me-3" element={<Fanid />} />

          <Route path="/admin/only-for-me-4" element={<ChannelTag />} />
          <Route path="/admin/only-for-me-5" element={<Only5 />} />
          <Route path="/admin/only-for-me-6" element={<Only6 />} />
          <Route path="/admin/refresh" element={<IduserPage2 />} />
          {/* {String(Cookies.get("vmc-role")) === "2" && ( */}
          <Route path="/admin/news" element={<Articles />} />
          {/* )} */}

          {/* MANAGE STUDIOS */}
          {/* <Route path="/admin/manage-studios" element={<GridEvents />} /> */}
          <Route
            path="/admin/manage-studios/studios"
            element={<TableOfStudios />}
          />
          <Route
            path="/admin/manage-studios/studios/new"
            element={<AddStudio />}
          />
          <Route
            path="/admin/manage-studios/studios/:id"
            element={<EditStudio />}
          />
          <Route
            path="/admin/manage-studios/types"
            element={<ListOfStudioTypes />}
          />
          <Route
            path="/admin/manage-studios/types/new"
            element={<AddStudioType />}
          />
          <Route
            path="/admin/manage-studios/types/:id"
            element={<EditStudioType />}
          />

        {/*  Application Listings */}
          <Route
              path="/admin/applications"
              element={<ApplicationTabs />}
          />

          <Route
              path="/admin/applications/:id"
              element={<ApplicationsDetails />}
          />
          <Route
              path="/admin/manage-organisation"
              element={<OrgainsationDetail />}
          />
          <Route
            path="/admin/manage-organisation/add"
            element={<AddOrganisations />}
          />
           <Route
            path="/admin/manage-organisation/associate-user"
            element={<AssociateUserForm />}
          />
        </Routes>
      )}
      {/* </AuthenticatedTemplate> */}
      {/* </BrowserRouter>{" "} */}
      {/* <BrowserRouter> */}
      {/* <UnauthenticatedTemplate> */}
      {!useIsAuthenticated && (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/settings" element={<Login />} />
          <Route path="/forgotpassword" element={<Login />} />
          <Route path="/password/reset" element={<Login />} />
          <Route path="/admin/requests" element={<Login />} />
          <Route path="/admin/content" element={<Login />} />
          <Route path="/admin/dashboard" element={<Login />} />
          <Route path="/admin/requests/gco-requests/:id" element={<Login />} />
          <Route path="/admin/requests/qna-requests/:id" element={<Login />} />
          <Route path="/admin/requests/sc-requests/:id" element={<Login />} />
          <Route
            path="/admin/requests/hayya-requests/:id"
            element={<Login />}
          />

          <Route
            path="/admin/users/completed-applications/:id"
            element={<Login />}
          />
          <Route
            path="/admin/users/incomplete-applications/:id"
            element={<Login />}
          />

          <Route path="/admin/manage-admin-users" element={<Login />} />
          <Route path="/admin/news" element={<Login />} />

          <Route path="/getToken" element={<Login />} />
          <Route path="/admin/audits" element={<Login />} />
          <Route path="/admin/users" element={<Login />} />
          <Route path="/admin/equipment-clearance" element={<Login />} />
          <Route path="/admin/manage-events" element={<Login />} />
        </Routes>
      )}
      {/* </UnauthenticatedTemplate> */}
      {/* </BrowserRouter> */}
    </AppProvider>
  );
}
export default App;
