import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  Select,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import NavigationLayout from "../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";
import EventList from "./EventList";

const AddUser = () => {
  const navigate = useNavigate();
  const [firstNameValue, setFirstNameValue] = useState("");
  const handleChangeFirstName = useCallback((newValue) => {
    setFirstNameValue(newValue);
    setFirstNameError("");
  }, []);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const handleChangeLastName = useCallback((newValue) => {
    setLastNameValue(newValue);
    setLastNameError("");
  }, []);
  const [lastNameError, setLastNameError] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const handleChangeEmail = useCallback((newValue) => {
    setEmailValue(newValue);
    setEmailError("");
  }, []);
  const [emailError, setEmailError] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const handleChangePassword = useCallback((newValue) => {
    setPasswordValue(newValue);
    setPasswordError("");
    setPasswordsError("");
  }, []);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const handleChangeConfirmPaswword = useCallback((newValue) => {
    setConfirmPasswordValue(newValue);
    setConfirmPasswordError("");
    setPasswordsError("");
  }, []);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [selected, setSelected] = useState("");
  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedEventsError, setSelectedEventsError] = useState("");
  const handleEventChange = useCallback(
    (value) => setSelectedEvents(value),
    []
  );

  const options = [
    // { label: "IT Admin", value: "1" },
    { label: "Senior Admin", value: "2" },
    { label: "Registration Admin", value: "3" },
    { label: "LOC Admin", value: "4" },
    { label: "GCO Admin", value: "5" },
    { label: "QNA Admin", value: "6" },
    { label: "HAYYA Admin", value: "7" },
    // { label: "Booking Admin", value: "8" },
    // { label: "Events Calendar Admin", value: "9" },
    // { label: "Content Manager Admin", value: "10" },
    // { label: "Content Admin", value: "11" },
    // { label: "Viewer Admin", value: "12" },
    { label: "Organizer Manager", value: "13" }, // HR renamed to Organizer Manager
    // { label: "IMO Accreditation Admin", value: "14" },
    { label: "CRA Admin", value: "15" },
    { label: "Customs Admin", value: "16" },
    // { label: "Tester", value: "17" },
  ];
  const [isSaving, setIsSaving] = useState(false);
  const [passwordsError, setPasswordsError] = useState("");
  return (
    <NavigationLayout>
      <Page
        title="Add User"
        breadcrumbs={[{ url: "/admin/manage-admin-users" }]}
      >
        {isSaving ? <Loading /> : null}
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">First Name</TextStyle>
                <TextField
                  value={firstNameValue}
                  onChange={handleChangeFirstName}
                  align="left"
                  error={firstNameError}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Last Name</TextStyle>
                <TextField
                  value={lastNameValue}
                  onChange={handleChangeLastName}
                  align="left"
                  error={lastNameError}
                />
              </FormLayout>
            </FormLayout.Group>

            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Email</TextStyle>
                <TextField
                  value={emailValue}
                  onChange={handleChangeEmail}
                  align="left"
                  error={emailError}
                />
              </FormLayout>
            </FormLayout.Group>
            {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Password</TextStyle>
              <TextField
                value={passwordValue}
                onChange={handleChangePassword}
                align="left"
                error={passwordError}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Confirm Password</TextStyle>
              <TextField
                value={confirmPasswordValue}
                onChange={handleChangeConfirmPaswword}
                align="left"
                error={confirmPasswordError}
              />
            </FormLayout>
          </FormLayout.Group> */}
            <InlineError message={passwordsError} />
            <TextStyle variation="strong">Role</TextStyle>
            <Select
              options={options}
              onChange={handleSelectChange}
              value={selected}
              placeholder="Please select a role"
            />
          </FormLayout>

          <EventList
            selectedEvents={selectedEvents}
            handleEventChange={handleEventChange}
            selectedEventsError={selectedEventsError}
          />
        </Card>
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
        />
      </Page>
    </NavigationLayout>
  );

  function handleSave() {
    if (
      !firstNameValue ||
      !lastNameValue ||
      !emailValue ||
      selectedEvents.length === 0
      // passwordValue !== confirmPasswordValue
    ) {
      !firstNameValue && setFirstNameError("Please enter a first name");
      !lastNameValue && setLastNameError("Please enter a last name");
      !emailValue && setEmailError("Please enter an email");
      !passwordValue && setPasswordError("Please enter a password");
      selectedEvents.length === 0 &&
        setSelectedEventsError("Please select at least one event");
      // !confirmPasswordValue &&
      //   setConfirmPasswordError("Please enter a password confirmation");
      // passwordValue !== confirmPasswordValue &&
      //   setPasswordsError("The password confirmation does not match");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      firstNameValue && form_data.append("first_name", firstNameValue);
      lastNameValue && form_data.append("last_name", lastNameValue);
      emailValue && form_data.append("email", emailValue);
      // passwordValue && form_data.append("password", passwordValue);
      // confirmPasswordValue &&
      //   form_data.append("confirm_password", confirmPasswordValue);
      form_data.append("role", selected);
      form_data.append("user_events", selectedEvents);
      axios
        .post("admin/v1/management/users", form_data)
        .then((res) => {
          if (res.status == 200) {
            navigate("/admin/manage-admin-users");
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log(error.response.status);
            if (error.response.status === 422) {
              setEmailError("Email already exists");
              setIsSaving(false);
            }
          }
        });
    }
  }
};
export default AddUser;
