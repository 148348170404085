import React, { useState, useEffect, Fragment } from "react";
import { FormLayout, TextStyle, ChoiceList } from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";

const EventList = (props) => {
  const [accreditationTypesList, setAccreditationTypesList] = useState([]);
  const { selectedEvents, handleEventChange, selectedEventsError } = props;

  useEffect(() => {
    fetchAccreditationTypes();
  }, []);

  const fetchAccreditationTypes = () => {
    axios
      .get(`admin/v1/accreditationTypes/list`)
      .then((res) => {
        const response = res?.data?.data;
        setAccreditationTypesList(response);
      })
      .catch((err) => console.log(""));
  };

  return (
    <FormLayout.Group>
      <FormLayout>
        {accreditationTypesList?.hasAccess &&
          accreditationTypesList?.accreditationTypes?.length && (
            <Fragment>
              <TextStyle variation="strong">Events</TextStyle>
              <ChoiceList
                title="Please select the events this user has accessed to:"
                allowMultiple
                choices={accreditationTypesList?.accreditationTypes?.map(
                  ({ id, details }) => ({
                    label: details.label,
                    value: id,
                  })
                )}
                selected={selectedEvents}
                onChange={handleEventChange}
                error={selectedEventsError}
              />
            </Fragment>
          )}
      </FormLayout>
    </FormLayout.Group>
  );
};
export default EventList;
