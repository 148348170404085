import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import { Editor } from "@tinymce/tinymce-react";
const Add = () => {
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
    setTitleError("");
  }, []);
  const [dateValue, setDateValue] = useState("");
  const handleChangeDate = useCallback((newValue) => {
    setDateValue(newValue);
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleError("");
  }, []);
  const [titleError, setTitleError] = useState("");
  const [descriptionEnValue, setDescriptionEnValue] = useState("");
  const handleChangeDescriptionEn = useCallback((newValue) => {
    setDescriptionEnValue(newValue);
    setDescriptionError("");
  }, []);
  const [descriptionArValue, setDescriptionArValue] = useState("");
  const handleChangeDescriptionAr = useCallback((newValue) => {
    setDescriptionArValue(newValue);
    setDescriptionError("");
  }, []);
  const [descriptionError, setDescriptionError] = useState("");
  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = (newValue) => {
    setTextEnValue(newValue);
    setTextError("");
  };
  const [textArValue, setTextArValue] = useState("");
  const handleChangeTextAr = (newValue) => {
    setTextArValue(newValue);
    setTextError("");
  };

  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [afterImagePreview, setAfterImagePreview] = useState("");
  const [beforeImagePreview, setBeforeImagePreview] = useState("");
  const [afterImageError, setAfterImageError] = useState("");
  const [beforeImageError, setBeforeImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setAfterImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setAfterImageError("");
  };

  const [filesBefore, setFilesBefore] = useState([]);
  const handleDropZoneBefore = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesBefore((filesBefore) => [...filesBefore, ...acceptedFiles]),
    []
  );
  const fileUploadBefore = !filesBefore.length && <DropZone.FileUpload />;
  let uploadedFilesBefore = filesBefore.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesBefore[filesBefore.length - 1].name}
        source={
          validImageTypes.indexOf(filesBefore[filesBefore.length - 1].type) > 0
            ? window.URL.createObjectURL(filesBefore[filesBefore.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesBefore[filesBefore.length - 1].name}{" "}
        <Caption>{filesBefore[filesBefore.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageBefore = (filesBefore) => {
    setBeforeImagePreview(
      validImageTypes.indexOf(filesBefore[filesBefore.length - 1].type) > 0
        ? window.URL.createObjectURL(filesBefore[filesBefore.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setBeforeImageError("");
  };
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );

  return (
    <Page title="Add News" breadcrumbs={[{ url: "/admin/content/news" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Date</TextStyle>
          <TextField
            value={dateValue}
            onChange={handleChangeDate}
            type="date"
          />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={titleEnValue}
                onChange={handleChangeTitleEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={titleArValue}
                onChange={handleChangeTitleAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Subtitle En</TextStyle>
              <TextField
                value={descriptionEnValue}
                onChange={handleChangeDescriptionEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Subtitle Ar</TextStyle>
              <TextField
                value={descriptionArValue}
                onChange={handleChangeDescriptionAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={descriptionError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Text En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textEnValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Text Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textArValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Thumbnail Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={beforeImagePreview ? beforeImagePreview : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone
                    onDrop={handleDropZoneBefore}
                    onDropAccepted={sendImageBefore}
                  >
                    {uploadedFilesBefore}
                    {fileUploadBefore}
                  </DropZone>
                </div>
              </div>
              <InlineError message={beforeImageError} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={afterImagePreview ? afterImagePreview : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={afterImageError} />
            </FormLayout>
          </FormLayout.Group>

          <Checkbox
            label="Is Active"
            checked={checkedShow}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: <p style={{ color: "white" }}>Save</p>,
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (
      (!titleEnValue && !titleArValue) ||
      (!descriptionEnValue && !descriptionArValue) ||
      (!textEnValue && !textArValue) ||
      !files.length ||
      !filesBefore
    ) {
      !titleEnValue && !titleArValue && setTitleError("Please enter a title");
      !textEnValue && !textArValue && setTextError("Please enter a text");
      !descriptionEnValue &&
        !descriptionArValue &&
        setDescriptionError("Please enter a description");
      !files.length && setAfterImageError("Please upload an image");
      !filesBefore.length && setBeforeImageError("Please upload an image");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("is_active", checkedShow ? 1 : 0);
      titleEnValue && form_data.append("title[en]", titleEnValue);
      titleArValue && form_data.append("title[ar]", titleArValue);
      descriptionEnValue &&
        form_data.append("sub_title[en]", descriptionEnValue);
      descriptionArValue &&
        form_data.append("sub_title[ar]", descriptionArValue);
      textEnValue && form_data.append("description[en]", textEnValue);
      textArValue && form_data.append("description[ar]", textArValue);
      filesBefore.length &&
        form_data.append("thumbnail", filesBefore[filesBefore.length - 1]);
      files.length && form_data.append("image", files[files.length - 1]);
      dateValue && form_data.append("date", dateValue);
      axios
        .post("admin/v1/news", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/content/news");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Add;
