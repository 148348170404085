import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Select,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  TextStyle,
  DropZone,
  Thumbnail,
  PageActions,
  TextField,
  FormLayout,
  Button,
  Page,
  Card,
  Stack,
  Caption,
  Modal,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import NavigationLayout from "../../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";
import uploadFileToBlob, {
  isStorageConfigured,
} from "../../../azure-storage-blob";
import Cookies from "js-cookie";

const AddAlbum = () => {
  const { BlobServiceClient } = require("@azure/storage-blob");
  const [popupActive, setPopupActive] = useState(false);
  const navigate = useNavigate();
  const [imageKey, setImageKey] = useState("");
  const refImage = useRef("");
  const [isSaving, setIsSaving] = useState(true);
  const [titleValue, setTitleValue] = useState("");
  const handleChangeTitle = useCallback((newValue) => {
    setTitleValue(newValue);
    setTitleError("");
  }, []);
  const [titleArValue, setTitleArValue] = useState("");

  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleArError("");
  }, []);
  const [titleError, setTitleError] = useState("");
  const [titleArError, setTitleArError] = useState("");

  const [dateValue, setDateValue] = useState("");
  const handleChangeDate = useCallback((newValue) => {
    setDateValue(newValue);
    setDateError("");
  }, []);
  const [dateError, setDateError] = useState("");
  const [imageError, setImageError] = useState("");
  const [filesMain, setFilesMain] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const handleDropZoneMain = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesMain((filesMain) => [...filesMain, ...acceptedFiles]),
    []
  );
  const fileUploadMain = !filesMain.length && <DropZone.FileUpload />;
  let uploadedFilesMain = filesMain.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesMain[filesMain.length - 1].name}
        source={
          validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
            ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesMain[filesMain.length - 1].name}{" "}
        <Caption>{filesMain[filesMain.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const [blobList, setBlobList] = useState([]);
  let url = "";
  const sendImageMain = (filesMain) => {
    setPopupActive(true);
    console.log("filesMain=", filesMain[filesMain.length - 1]);
    axios
      .get(`admin/v1/sas/${filesMain[filesMain.length - 1].name}`)
      .then((result) => {
        console.log(result.data.data);
        url = result.data.data;
        fctAzure(filesMain, url);

        //GET sasURL from API?

        // const blobServiceClient = new BlobServiceClient(url);
        // const containerClient =
        //   blobServiceClient.getContainerClient(CONTAINER_NAME);
        // const filename = file.name.substring(0, file.name.lastIndexOf("."));
        // const ext = file.name.substring(file.name.lastIndexOf("."));
        // const blobName = filename + "_" + new Date().getTime() + ext;
        // const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        // const uploadBlobResponse = await blockBlobClient.uploadBrowserData(file)
        // console.log(`Upload block blob ${file.name} successfully`, uploadBlobResponse.clientRequestId)
      })
      .catch((err) => console.log(""));
    // prepare UI
    // setUploading(true);

    // *** UPLOAD TO AZURE STORAGE ***
    // ////const blobsInContainer = await uploadFileToBlob(filesMain);

    // prepare UI for results
    // setBlobList(blobsInContainer);
    /////  setImagePreview(blobsInContainer[0]);

    // reset state/form
    // setFilesMain(null);
    //setUploading(false);
    // setInputKey(Math.random().toString(36));
  };

  const fctAzure = async (filesMain, url) => {
    console.log("tessst=", filesMain);
    const CONTAINER_NAME = "photos";
    //GET sasURL from API?

    const blobServiceClient = new BlobServiceClient(url);
    const containerClient =
      blobServiceClient.getContainerClient(CONTAINER_NAME);
    const filename = filesMain[filesMain.length - 1].name.substring(
      0,
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const ext = filesMain[filesMain.length - 1].name.substring(
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const blobName = filename + "_" + new Date().getTime() + ext;
    setImageKey(blobName);
    console.log("test=", blobName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
      filesMain[filesMain.length - 1]
    );
    console.log(
      `Upload block blob ${filesMain[filesMain.length - 1].name} successfully`,
      uploadBlobResponse.clientRequestId
    );

    setPopupActive(false);
    setImageError("");
    setImagePreview(
      validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
        ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
  };

  const [optionsCategory, setOptionsCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleSelectChangeCategory = useCallback((newValue) => {
    setSelectedCategory(newValue);
    setCategoryError("");
  }, []);
  const [categoryError, setCategoryError] = useState("");
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responseCategories = "";

    try {
      responseCategories = await axios.get(`admin/v1/categories`);
      responseCategories?.data?.data?.data &&
        setOptionsCategory(
          responseCategories.data.data.data.map((item, index) => {
            return {
              label: item.title[Object.keys(item.title)[0]],
              value: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }
  // const sendImageMain = (filesMain) => {
  //   setIsSaving(true);
  //   const form_data = new FormData();
  //   filesMain[filesMain.length - 1] &&
  //     form_data.append("image", filesMain[filesMain.length - 1]);
  //   axios
  //     .post("admin/v1/upload-image", form_data)
  //     .then((res) => {
  //       console.log(res.data.url);
  //       refImage.current = res.data.url;
  //       setIsSaving(false);
  //       setImagePreview(
  //         validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
  //           ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
  //           : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
  //       );
  //       setImageError("");
  //     })
  //     .catch((err) => console.log(""));
  // };

  return (
    <NavigationLayout>
      <Page
        fullWidth
        breadcrumbs={[
          { onAction: () => navigate("/admin/photos-and-videos/albums") },
        ]}
        title="Add Album"
      >
        {isSaving ? <Loading /> : null}

        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Category</TextStyle>
              </p>
              <Select
                options={optionsCategory}
                onChange={handleSelectChangeCategory}
                value={selectedCategory}
                placeholder="Please Select"
                error={categoryError}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Title En</TextStyle>
              </p>
              <TextField
                value={titleValue}
                onChange={handleChangeTitle}
                error={titleError}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Title Ar</TextStyle>
              </p>
              <TextField
                value={titleArValue}
                onChange={handleChangeTitleAr}
                align="right"
                error={titleArError}
              />
            </FormLayout>
            {/* <FormLayout>
                <p className="greyText">
                  <TextStyle>Date</TextStyle>
                </p>
                <TextField
                  value={dateValue}
                  onChange={handleChangeDate}
                  type="date"
                  error={dateError}
                />
              </FormLayout> */}
          </FormLayout.Group>
          <p className="greyText">
            <TextStyle>Date</TextStyle>
          </p>
          <TextField
            value={dateValue}
            onChange={handleChangeDate}
            type="date"
            error={dateError}
          />
          <p className="greyText">
            <TextStyle>Thumbnail</TextStyle>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone
                onDrop={handleDropZoneMain}
                onDropAccepted={sendImageMain}
                // variableHeight
              >
                {uploadedFilesMain}
                {fileUploadMain}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError}></InlineError>
          {/* <Button primary>Save</Button> */}
        </FormLayout>
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
        />
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
  function handleSave() {
    if (
      !titleValue ||
      !titleArValue ||
      !dateValue ||
      !filesMain[filesMain.length - 1] ||
      selectedCategory === ""
    ) {
      !titleValue && setTitleError("Please enter a title");
      !titleArValue && setTitleArError("Please enter an arabic title");
      !dateValue && setDateError("Please enter a date");
      !filesMain[filesMain.length - 1] &&
        setImageError("Please upload an image");
      selectedCategory === "" && setCategoryError("Please select a category");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      titleValue && form_data.append("title[en]", titleValue);
      titleArValue && form_data.append("title[ar]", titleArValue);
      dateValue && form_data.append("date", dateValue);
      imageKey && form_data.append("thumbnail", imageKey);
      form_data.append("category_id", selectedCategory);
      axios
        .post("admin/v1/albums", form_data)
        .then((res) => {
          navigate("/admin/photos-and-videos/albums");
        })
        .catch((err) => console.log(""));
    }
  }
};

export default AddAlbum;
