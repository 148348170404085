import React, { useState, useEffect } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import Cookies from "js-cookie";
import NavigationLayout from "../../Components/NavigationLayout";
import RegistrationRequestsImage from "../../Assets/Images/registration-requests.svg";
import TableOfRequests from "./TableOfRequests";
import { isDefined, adminRole } from "../../Helpers";

const RequestsTabs = (props) => {
  const [selected, setSelected] = useState(
    isDefined(Cookies.get("vmc-requests-selected-tab"))
      ? parseInt(Cookies.get("vmc-requests-selected-tab"))
      : 0
  );

  const [requestsNumber, setRequestsNumber] = useState({
    sc: "",
    gco: "",
    qna: "",
    hayya: "",
    imo: "",
  });

  const handleTabChange = (selectedTabIndex) => {
    Cookies.set("vmc-requests-filter-state", "");
    Cookies.set("vmc-requests-search-value", "");
    Cookies.set("vmc-requests-selected-tab", String(selectedTabIndex));
    Cookies.set("vmc-requests-filter-page", 1);
    setSelected(selectedTabIndex);
  };
  const [resultTotal, setResultTotal] = useState(
    Cookies.get("sumPendingRequests")
  );

  const handleTotalRequestsChange = (value) => {
    setResultTotal(value);
  };
  const switchTabs = (param) => {
    switch (param) {
      case "Viewer":
      case "Senior":
        return [
          {
            id: "t1",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img src={RegistrationRequestsImage} color="highlight" /> */}
                <span
                  style={{ marginLeft: "10px" }}
                  className={requestsNumber.sc ? "TabTitle" : "TabTitleRegular"}
                >
                  {`LOC Approval (${requestsNumber.sc})`}
                </span>
              </div>
            ),
          },
          {
            id: "t2",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img src={RegistrationRequestsImage} color="highlight" /> */}
                <span
                  style={{ marginLeft: "10px" }}
                  className={
                    requestsNumber.gco ? "TabTitle" : "TabTitleRegular"
                  }
                >
                  {`GCO Approval (${requestsNumber.gco})`}
                </span>
              </div>
            ),
          },
          {
            id: "t3",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img src={RegistrationRequestsImage} color="highlight" /> */}
                <span
                  style={{ marginLeft: "10px"}}
                  className={
                    requestsNumber.imo ? "TabTitle" : "TabTitleRegular"
                  }
                >
                  {`IMO Approval (${requestsNumber.imo})`}
                </span>
              </div>
            ),
          },
          {
            id: "t4",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img src={RegistrationRequestsImage} color="highlight" /> */}
                <span
                  style={{ marginLeft: "10px" }}
                  className={
                    requestsNumber.qna ? "TabTitle" : "TabTitleRegular"
                  }
                >
                  {`QNA Approval (${requestsNumber.qna})`}
                </span>
              </div>
            ),
          },
          {
            id: "t5",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img src={RegistrationRequestsImage} color="highlight" /> */}
                <span
                  style={{ marginLeft: "10px"}}
                  className={
                    requestsNumber.hayya ? "TabTitle" : "TabTitleRegular"
                  }
                >
                  {`Hayya Approval (${requestsNumber.hayya})`}
                </span>
              </div>
            ),
          },
        ];
      case "SC":
        return [
          {
            id: "t1",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img src={RegistrationRequestsImage} color="highlight" /> */}
                <span
                  style={{ marginLeft: "10px"}}
                  className={requestsNumber.sc ? "TabTitle" : "TabTitleRegular"}
                >
                  {`SC Approval (${requestsNumber.sc})`}
                </span>
              </div>
            ),
          },
        ];
      case "GCO":
        return [
          {
            id: "t1",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img src={RegistrationRequestsImage} color="highlight" /> */}
                <span
                  style={{ marginLeft: "10px"}}
                  className={
                    requestsNumber.gco ? "TabTitle" : "TabTitleRegular"
                  }
                >
                  {`GCO Approval (${requestsNumber.gco})`}
                </span>
              </div>
            ),
          },
          // {
          //   id: "t2",
          //   content: (
          //     <div
          //       style={{
          //         display: "flex",
          //         flexDirection: "row",
          //       }}
          //     >
          //       <img src={RegistrationRequestsImage} color="highlight" />
          //       <span
          //         style={{ marginLeft: "10px", color: "#ff4700" }}
          //         className={
          //           requestsNumber.imo ? "TabTitle" : "TabTitleRegular"
          //         }
          //       >
          //         {`IMO Approval (${requestsNumber.imo})`}
          //       </span>
          //     </div>
          //   ),
          // },
        ];
      case "QNA":
        return [
          {
            id: "t1",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img src={RegistrationRequestsImage} color="highlight" /> */}
                <span
                  style={{ marginLeft: "10px"}}
                  className={
                    requestsNumber.qna ? "TabTitle" : "TabTitleRegular"
                  }
                >
                  {`QNA Approval (${requestsNumber.qna})`}
                </span>
              </div>
            ),
          },
        ];
      case "IMO":
        return [
          {
            id: "t1",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img src={RegistrationRequestsImage} color="highlight" /> */}
                <span
                  style={{ marginLeft: "10px"}}
                  className={
                    requestsNumber.imo ? "TabTitle" : "TabTitleRegular"
                  }
                >
                  {`IMO Approval (${requestsNumber.imo})`}
                </span>
              </div>
            ),
          },
        ];
      case "HAYYA":
        return [
          {
            id: "t1",
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {/* <img src={RegistrationRequestsImage} color="highlight" /> */}
                <span
                  style={{ marginLeft: "10px" }}
                  className={
                    requestsNumber.hayya ? "TabTitle" : "TabTitleRegular"
                  }
                >
                  {`HAYYA Approval (${requestsNumber.hayya})`}
                </span>
              </div>
            ),
          },
        ];
      default:
      // code block
    }
  };
  const tabs = switchTabs(adminRole());
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responseAll = "";
    try {
      responseAll = await axios.get(`/admin/v1/total-requests`);
    } catch (error) {
      console.log(error);
    }
    // let responseSC = "";
    // let responseGCO = "";
    // let responseQNA = "";
    // let responseHAYYA = "";
    // let responseImo = "";
    // if (
    //   adminRole() === "Senior" ||
    //   adminRole() === "SC" ||
    //   adminRole() === "Viewer"
    // ) {
    //   try {
    //     responseSC = await axios.get(`/admin/v1/all-requests`);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    // if (
    //   adminRole() === "Senior" ||
    //   adminRole() === "GCO" ||
    //   adminRole() === "Viewer"
    // ) {
    //   try {
    //     responseGCO = await axios.get(`/admin/v1/gco-requests`);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    // if (
    //   adminRole() === "Senior" ||
    //   adminRole() === "IMO" ||
    //   adminRole() === "Viewer"
    // ) {
    //   try {
    //     responseImo = await axios.get(`/admin/v1/gco-requests-imo`);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    // if (
    //   adminRole() === "Senior" ||
    //   adminRole() === "QNA" ||
    //   adminRole() === "Viewer"
    // )
    //   try {
    //     responseQNA = await axios.get(`/admin/v1/qna-requests`);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // if (
    //   adminRole() === "Senior" ||
    //   adminRole() === "HAYYA" ||
    //   adminRole() === "Viewer"
    // )
    //   try {
    //     responseHAYYA = await axios.get(`/admin/v1/hayya-requests`);
    //   } catch (error) {
    //     console.log(error);
    //   }

    setRequestsNumber({
      ...requestsNumber,
      // sc: responseSC?.data?.data?.data
      //   .filter(({ status }) => status.toLowerCase().includes("new"))
      //   .reduce((accumulator) => accumulator + 1, 0),
      sc: responseAll?.data?.data?.SC,
      // gco: responseGCO?.data?.data?.data
      //   .filter(({ status }) => status.toLowerCase().includes("approved_sc"))
      //   .reduce((accumulator) => accumulator + 1, 0),
      gco: responseAll?.data?.data?.GCO,
      qna: responseAll?.data?.data?.QNA,
      // qna: responseQNA?.data?.data?.data
      //   .filter(({ status }) => status.toLowerCase().includes("approved_gco"))
      //   .reduce((accumulator) => accumulator + 1, 0),
      hayya: responseAll?.data?.data?.Hayya,
      imo: responseAll?.data?.data?.IMO,
    });
  }
  return (
    <NavigationLayout>
      <Page fullWidth>
        <Card>
          {(adminRole() === "IT" ||
            adminRole() === "Senior" ||
            adminRole() === "Booking" ||
            adminRole() === "Events Calendar" ||
            adminRole() === "Content Manager" ||
            adminRole() === "Content" ||
            adminRole() === "Viewer") && (
            <Tabs
              tabs={tabs}
              selected={selected}
              onSelect={handleTabChange}

              // fitted
            >
              {selected === 0 && (
                <TableOfRequests
                  onTotalRequestsChange={handleTotalRequestsChange}
                  selectedTab="0"
                  // onChangeRequestsNumber={handleChangeRequestsNumber}
                />
              )}
              {selected === 1 && (
                <TableOfRequests
                  onTotalRequestsChange={handleTotalRequestsChange}
                  selectedTab="1"
                  // onChangeRequestsNumber={handleChangeRequestsNumber}
                />
              )}
              {selected === 2 && (
                <TableOfRequests
                  onTotalRequestsChange={handleTotalRequestsChange}
                  selectedTab="4"
                  // onChangeRequestsNumber={handleChangeRequestsNumber}
                />
              )}
              {selected === 3 && (
                <TableOfRequests
                  onTotalRequestsChange={handleTotalRequestsChange}
                  selectedTab="2"
                  // onChangeRequestsNumber={handleChangeRequestsNumber}
                />
              )}
              {selected === 4 && (
                <TableOfRequests
                  onTotalRequestsChange={handleTotalRequestsChange}
                  selectedTab="3"
                  // onChangeRequestsNumber={handleChangeRequestsNumber}
                />
              )}
            </Tabs>
          )}

          {adminRole() === "SC" && (
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <TableOfRequests
                onTotalRequestsChange={handleTotalRequestsChange}
                selectedTab="0"
                // onChangeRequestsNumber={handleChangeRequestsNumber}
              />
            </Tabs>
          )}
          {adminRole() === "IMO" && (
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <TableOfRequests
                onTotalRequestsChange={handleTotalRequestsChange}
                selectedTab="4"
                // onChangeRequestsNumber={handleChangeRequestsNumber}
              />
            </Tabs>
          )}
          {adminRole() === "GCO" && (
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <TableOfRequests
                onTotalRequestsChange={handleTotalRequestsChange}
                selectedTab="1"
                // onChangeRequestsNumber={handleChangeRequestsNumber}
              />
            </Tabs>
          )}
          {adminRole() === "QNA" && (
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <TableOfRequests
                onTotalRequestsChange={handleTotalRequestsChange}
                selectedTab="2"
                // onChangeRequestsNumber={handleChangeRequestsNumber}
              />
            </Tabs>
          )}
          {adminRole() === "HAYYA" && (
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <TableOfRequests
                onTotalRequestsChange={handleTotalRequestsChange}
                selectedTab="3"
                // onChangeRequestsNumber={handleChangeRequestsNumber}
              />
            </Tabs>
          )}
        </Card>
      </Page>
    </NavigationLayout>
  );
};

export default RequestsTabs;
