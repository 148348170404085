import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Select,
  Stack,
  Checkbox,
  ChoiceList,
  Loading,
  TextStyle,
  Toast,
  Caption,
  TextField,
  FormLayout,
  Page,
  Thumbnail,
  PageActions,
  InlineError,
  DropZone,
  Modal,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import NavigationLayout from "../../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const EditEvent = () => {
  const { BlobServiceClient } = require("@azure/storage-blob");
  const [popupActive, setPopupActive] = useState(false);
  const [imageKey, setImageKey] = useState("");
  let url = "";
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(true);
  const [eventDateValue, setEventDateValue] = useState("");
  const handleChange = (newValue) => {
    setItem({ ...item, bookable: newValue });
  };
  const handleChangeEventDate = (newValue) => {
    setItem({ ...item, date: newValue });
    setDateError("");
  };

  const [dateError, setDateError] = useState("");

  const [eventNameValue, setEventNameValue] = useState("");
  const handleChangeEventName = (newValue) => {
    setItem({ ...item, nameEn: newValue });
    setTitleError("");
  };
  const [eventNameArValue, setEventNameArValue] = useState("");
  const handleChangeEventNameAr = (newValue) => {
    setItem({ ...item, nameAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const [selectedEventType, setSelectedEventType] = useState("");
  const handleSelectChangeEventType = (newValue) => {
    setItem({ ...item, type: newValue });
  };
  const [optionsEventType, setOptionsEventType] = useState("");
  const [selectedEventLocation, setSelectedEventLocation] = useState("");
  const handleSelectChangeEventLocation = (newValue) => {
    setItem({ ...item, location: newValue });
  };
  const [optionsEventLocation, setOptionsEventLocation] = useState("");
  const [eventStartTimeValue, setEventStartTimeValue] = useState("");
  const handleChangeEventStartTime = (newValue) => {
    setItem({ ...item, startTime: newValue });
  };
  const [eventEndTimeValue, setEventEndTimeValue] = useState("");
  const handleChangeEventEndTime = (newValue) => {
    setItem({ ...item, endTime: newValue });
  };
  const [capacityValue, setCapacityValue] = useState("");
  const handleChangeCapacity = (newValue) => {
    setItem({ ...item, capacity: newValue });
    setCapacityError("");
  };
  const [capacityError, setCapacityError] = useState("");

  const handleChangeMaximumRequests = (newValue) => {
    setItem({ ...item, maximumRequests: newValue });
    setMaximumRequestsError("");
  };
  const [maximumRequestsError, setMaximumRequestsError] = useState("");

  const [eventDescriptionValue, setEventDescriptionValue] = useState("");
  const handleChangeEventDescription = (newValue) => {
    setItem({ ...item, descriptionEn: newValue });
  };
  const [eventDescriptionArValue, setEventDescriptionArValue] = useState("");
  const handleChangeEventDescriptionAr = (newValue) => {
    setItem({ ...item, descriptionAr: newValue });
  };

  const handleChangeContentEn = (newValue) => {
    setItem({ ...item, contentEn: newValue });
  };
  const handleChangeContentAr = (newValue) => {
    setItem({ ...item, contentAr: newValue });
  };
  const [item, setItem] = useState({
    nameEn: "",
    nameAr: "",
    date: "",
    location: "",
    type: "",
    startTime: "",
    endTime: "",
    descriptionEn: "",
    descriptionAr: "",
    capacity: "",
    contentEn: "",
    contentAr: "",
    image: "",
    bookable: "",
    maximumRequests: "",
  });
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responseTypes = "";
    let responseLocations = "";
    let responseEvent = "";
    try {
      responseTypes = await axios.get(`admin/v1/events/types`);
      responseTypes?.data?.data?.data &&
        setOptionsEventType(
          responseTypes.data.data.data.map((item, index) => {
            return {
              label: item.title[Object.keys(item.title)[0]],
              value: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseLocations = await axios.get(`admin/v1/events/locations`);
      responseLocations?.data?.data?.data &&
        setOptionsEventLocation(
          responseLocations.data.data.data.map((item, index) => {
            return {
              label: item.title[Object.keys(item.title)[0]],
              value: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseEvent = await axios.get(`admin/v1/events/${id}`);
      console.log(responseEvent?.data?.data);
      responseEvent?.data?.data &&
        setItem({
          image: responseEvent?.data?.data?.image
            ? String(responseEvent?.data?.data?.image)
            : "",
          maximumRequests:
            responseEvent?.data?.data?.max_requests !== null
              ? String(responseEvent?.data?.data?.max_requests)
              : "",
          capacity:
            responseEvent?.data?.data?.capacity !== null
              ? String(responseEvent?.data?.data?.capacity)
              : "",
          nameEn: responseEvent?.data?.data?.title?.en
            ? responseEvent?.data?.data?.title?.en
            : "",
          nameAr: responseEvent?.data?.data?.title?.ar
            ? responseEvent?.data?.data.title?.ar
            : "",
          descriptionEn: responseEvent?.data?.data?.description?.en
            ? responseEvent?.data?.data?.description?.en
            : "",
          descriptionAr: responseEvent?.data?.data?.description?.ar
            ? responseEvent?.data?.data.description?.ar
            : "",
          date: responseEvent?.data?.data?.date
            ? responseEvent?.data?.data?.date
            : "",
          location: responseEvent?.data?.data?.location?.id
            ? String(responseEvent?.data?.data?.location?.id)
            : "",
          type: responseEvent?.data?.data?.types[0]?.id
            ? String(responseEvent?.data?.data?.types[0]?.id)
            : "",
          startTime: responseEvent?.data?.data?.start_time
            ? responseEvent?.data?.data?.start_time
            : "",
          endTime: responseEvent?.data?.data?.end_time
            ? responseEvent?.data?.data?.end_time
            : "",
          contentEn: responseEvent?.data?.data?.content?.en
            ? responseEvent?.data?.data?.content?.en
            : "",
          contentAr: responseEvent?.data?.data?.content?.ar
            ? responseEvent?.data?.data.content?.ar
            : "",
          bookable: responseEvent?.data?.data?.is_bookable ? true : false,
          // color: result?.data?.data?.color ? result.data.data?.color : "",
        });
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }

  const [imageError, setImageError] = useState("");
  const [filesMain, setFilesMain] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const handleDropZoneMain = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesMain((filesMain) => [...filesMain, ...acceptedFiles]),
    []
  );
  const fileUploadMain = !filesMain.length && <DropZone.FileUpload />;
  let uploadedFilesMain = filesMain.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesMain[filesMain.length - 1].name}
        source={
          validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
            ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesMain[filesMain.length - 1].name}{" "}
        <Caption>{filesMain[filesMain.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageMain = (filesMain) => {
    setPopupActive(true);
    console.log("filesMain=", filesMain[filesMain.length - 1]);
    axios
      .get(`admin/v1/sas/${filesMain[filesMain.length - 1].name}`)
      .then((result) => {
        console.log(result.data.data);
        url = result.data.data;
        fctAzure(filesMain, url);
      })
      .catch((err) => console.log(""));
  };
  const fctAzure = async (filesMain, url) => {
    console.log("tessst=", filesMain);
    const CONTAINER_NAME = "photos";
    //GET sasURL from API?

    const blobServiceClient = new BlobServiceClient(url);
    const containerClient =
      blobServiceClient.getContainerClient(CONTAINER_NAME);
    const filename = filesMain[filesMain.length - 1].name.substring(
      0,
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const ext = filesMain[filesMain.length - 1].name.substring(
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const blobName = filename + "_" + new Date().getTime() + ext;
    setImageKey(blobName);
    console.log("test=", blobName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
      filesMain[filesMain.length - 1]
    );
    console.log(
      `Upload block blob ${filesMain[filesMain.length - 1].name} successfully`,
      uploadBlobResponse.clientRequestId
    );

    setPopupActive(false);
    setImageError("");
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
          ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });

    // setImagePreview(
    //   validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
    //     ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
    //     : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    // );
  };
  return (
    <NavigationLayout>
      <Page
        fullWidth
        breadcrumbs={[
          { onAction: () => navigate("/admin/manage-events/events") },
        ]}
        title="Edit Event"
      >
        {isSaving ? <Loading /> : null}
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Name En</TextStyle>
              </p>
              <TextField value={item.nameEn} onChange={handleChangeEventName} />
              <InlineError message={titleError} />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Name Ar</TextStyle>
              </p>
              <TextField
                value={item.nameAr}
                onChange={handleChangeEventNameAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <p className="greyText">
            <TextStyle>Image (Upload size: JPG 400x400px)</TextStyle>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail size="large" source={item.image ? item.image : ""} />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone
                onDrop={handleDropZoneMain}
                onDropAccepted={sendImageMain}
                // variableHeight
              >
                {uploadedFilesMain}
                {fileUploadMain}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError}></InlineError>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Date</TextStyle>
              </p>
              <TextField
                value={item.date}
                onChange={handleChangeEventDate}
                type="date"
                error={dateError}
              />
            </FormLayout>

            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Type</TextStyle>
              </p>
              <Select
                options={optionsEventType}
                onChange={handleSelectChangeEventType}
                value={item.type}
                placeholder="Please select a type"
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Location</TextStyle>
              </p>
              <Select
                options={optionsEventLocation}
                onChange={handleSelectChangeEventLocation}
                value={item.location}
                placeholder="Please select a location"
              />
            </FormLayout>
          </FormLayout.Group>

          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Start Time</TextStyle>
              </p>
              <TextField
                value={item.startTime}
                onChange={handleChangeEventStartTime}
                type="time"
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event End Time</TextStyle>
              </p>
              <TextField
                value={item.endTime}
                onChange={handleChangeEventEndTime}
                type="time"
              />
            </FormLayout>

            <FormLayout>
              <p className="greyText">
                <TextStyle>Capacity of the Event/Number of Seats</TextStyle>
              </p>
              <TextField
                value={item.capacity}
                onChange={handleChangeCapacity}
                type="number"
                error={capacityError}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Maximum Requests</TextStyle>
              </p>
              <TextField
                value={item.maximumRequests}
                onChange={handleChangeMaximumRequests}
                type="number"
                error={maximumRequestsError}
              />
            </FormLayout>
          </FormLayout.Group>

          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Description En</TextStyle>
              </p>
              <TextField
                value={item.descriptionEn}
                onChange={handleChangeEventDescription}
                multiline={4}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Description Ar</TextStyle>
              </p>
              <TextField
                value={item.descriptionAr}
                onChange={handleChangeEventDescriptionAr}
                multiline={4}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>

          {/* <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Content En</TextStyle>
              </p>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.contentEn}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "directionality",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help|ltr rtl|code",
                }}
                onEditorChange={handleChangeContentEn}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Content Ar</TextStyle>
              </p>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.contentAr}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "directionality",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help|ltr rtl|code",
                }}
                onEditorChange={handleChangeContentAr}
              />
            </FormLayout>
          </FormLayout.Group> */}
          <Checkbox
            label="Is Bookable"
            checked={item.bookable}
            onChange={handleChange}
          />
        </FormLayout>
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
          secondaryActions={[
            {
              content: <p style={{ color: "white" }}>Delete</p>,
              destructive: true,
              loading: isSaving && true,
              onClick: () => {
                setIsSaving(true);
                axios
                  .delete(`admin/v1/events/${id}/delete`)
                  .then((result) => {
                    navigate(`/admin/manage-events/events`);
                  })
                  .catch((err) => console.log(err));
              },
            },
          ]}
        />
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
  function handleSave() {
    if (
      (!item.nameEn && !item.nameAr) ||
      item.capacity === "" ||
      item.maximumRequests === ""
    ) {
      !item.nameEn && !item.nameAr && setTitleError("Please enter a name");
      item.capacity === "" && setCapacityError("Please enter a capacity");
      item.maximumRequests === "" &&
        setMaximumRequestsError("Please enter a maximum requests number");
      // !item.date && setDateError("Please select a date");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      item.nameEn && form_data.append("title[en]", item.nameEn);
      item.nameAr && form_data.append("title[ar]", item.nameAr);
      item.date && form_data.append("date", item.date);
      form_data.append("description[en]", item.descriptionEn);
      form_data.append("description[ar]", item.descriptionAr);
      item.startTime && form_data.append("start_time", item.startTime);
      item.endTime && form_data.append("end_time", item.endTime);
      form_data.append("capacity", item.capacity);
      item.type !== "" && form_data.append("types[0]", item.type);
      item.location !== "" && form_data.append("location", item.location);
      form_data.append("_method", "PATCH");
      item.contentEn && form_data.append("content[en]", item.contentEn);
      item.contentAr && form_data.append("content[ar]", item.contentAr);
      imageKey && form_data.append("image", imageKey);
      form_data.append("is_bookable", item.bookable ? 1 : 0);
      form_data.append("max_requests", item.maximumRequests);

      axios
        .post(`admin/v1/events/${id}/update`, form_data)
        .then((res) => {
          navigate("/admin/manage-events/events");
        })
        .catch((err) => console.log(""));
    }
  }
};

export default EditEvent;
