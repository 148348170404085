import React, { useState, useCallback, useEffect } from "react";
import {
  Layout,
  Card,
  FormLayout,
  TextField,
  Page,
  Toast,
  TextStyle,
  PageActions,
  Loading,
  Button,
} from "@shopify/polaris";
import axios from "../Assets/Lib/axios";
import NavigationLayout from "../Components/NavigationLayout";
import history from "./../Assets/Lib/history";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function Settings() {
  const navigate = useNavigate();
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const [toastMsg, setToastMsg] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const toastMarkup = active ? (
    <Toast content={toastMsg} onDismiss={toggleActive} />
  ) : null;
  const handleChangeFirstName = useCallback((newValue) => {
    setFirstNameValue(newValue);
  }, []);
  const handleChangeLastName = useCallback((newValue) => {
    setLastNameValue(newValue);
  }, []);

  const handleChangeEmailValue = useCallback((newValue) => {
    setEmailValue(newValue);
  }, []);
  const handleChangeNewPasswordValue = useCallback((newValue) => {
    setNewPasswordValue(newValue);
    setConfirmPasswordError("");
  }, []);
  const handleChangeConfirmPasswordValue = useCallback((newValue) => {
    setConfirmPasswordValue(newValue);
    setConfirmPasswordError("");
  }, []);

  useEffect(() => {
    axios
      .get("admin/v1/settings")
      .then((result) => {
        console.log(result.data);
        result?.data?.data?.first_name &&
          setFirstNameValue(result.data.data.first_name);
        result?.data?.data?.last_name &&
          setLastNameValue(result.data.data.last_name);
        result?.data?.data?.email && setEmailValue(result.data.data.email);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <NavigationLayout>
      <Page>
        {isSaving ? <Loading /> : null}
        <Layout>
          <Layout.AnnotatedSection title="Account Information" description="">
            <Card>
              <Card.Section title="Details">
                <FormLayout>
                  <FormLayout.Group condensed>
                    <FormLayout>
                      <TextField
                        value={firstNameValue}
                        label="Name"
                        onChange={handleChangeFirstName}
                      />
                    </FormLayout>

                    <FormLayout>
                      <TextField
                        value={lastNameValue}
                        label="Last Name"
                        onChange={handleChangeLastName}
                      />
                    </FormLayout>
                  </FormLayout.Group>
                  <FormLayout.Group condensed>
                    <FormLayout>
                      <TextField
                        value={emailValue}
                        label="Email"
                        onChange={handleChangeEmailValue}
                        disabled
                      />
                    </FormLayout>
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>

              {/* <Card.Section title="Change Password">
                <FormLayout>
                  <TextStyle>
                    Change the password you use to login to VMC.
                  </TextStyle>
                  <FormLayout>
                    <FormLayout.Group condensed>
                      <TextField
                        value={newPasswordValue}
                        label="New Password"
                        onChange={handleChangeNewPasswordValue}
                        error={newPasswordError}
                        helpText="Password must contain at least 6 characters"
                      />
                      <TextField
                        value={confirmPasswordValue}
                        label="Confirm Password"
                        onChange={handleChangeConfirmPasswordValue}
                        error={confirmPasswordError}
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </FormLayout>
              </Card.Section> */}
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
            disabled: String(Cookies.get("vmc-role")) === "11" && true,
          }}
        />
        {toastMarkup}
      </Page>
    </NavigationLayout>
  );

  function handleSave() {
    if (newPasswordValue !== confirmPasswordValue) {
      setConfirmPasswordError("Password and Confirm Password must be match");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("_method", "PATCH");
      firstNameValue && form_data.append("first_name", firstNameValue);
      lastNameValue && form_data.append("last_name", lastNameValue);
      newPasswordValue && form_data.append("password", newPasswordValue);
      confirmPasswordValue &&
        form_data.append("password_confirmation", confirmPasswordValue);

      axios
        .post("admin/v1/settings/update", form_data)
        .then((res) => {
          setToastMsg("Your profile is updated");
          toggleActive();
          setIsSaving(false);
          newPasswordValue && navigate("/");
        })
        .catch(function (error) {});
    }
  }
}
