import React, { useState, useCallback } from "react";
import axios from "../Assets/Lib/axios";
import {
  Button,
  Page,
  Loading,
  Card,
  ButtonGroup,
  TextField,
  FormLayout,
  Layout,
  Toast,
  Spinner,
  InlineError,
} from "@shopify/polaris";

function FnLn() {
  const [valueFirstName, setValueFirstName] = useState("");

  const handleChangeFirstName = useCallback(
    (newValue) => setValueFirstName(newValue),
    []
  );
  const [valueLastName, setValueLastName] = useState("");

  const handleChangeLastName = useCallback(
    (newValue) => setValueLastName(newValue),
    []
  );
  const handleClick = () => {
    const bodyObj = {
      first_name: valueFirstName,
      last_name: valueLastName,
    };
    axios
      .post(`admin/v1/update-user`, bodyObj)
      .then((res) => {})
      .catch((err) => console.log(""));
  };
  return (
    <FormLayout>
      <TextField
        label="First Name"
        value={valueFirstName}
        onChange={handleChangeFirstName}
      />
      <TextField
        label="Last Name"
        value={valueLastName}
        onChange={handleChangeLastName}
      />
      <Button onClick={handleClick}>Submit</Button>
    </FormLayout>
  );
}

export default FnLn;
