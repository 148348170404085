import React, { useCallback, useState, useEffect, useRef } from "react";
import { Button, Spinner, Toast, Modal, TextStyle } from "@shopify/polaris";

import ReactJson from "react-json-view";

const FanIDPopup = (props) => {
  const [active, setActive] = useState(true);

  const handleChange = () => {
    props.handleChangeShowPopup();
  };

  const handleCopy = ({src, name}) => {
      const c = name === 'qmp' ? src : JSON.stringify(src)
      navigator.clipboard.writeText(c)
  };

  const activator = <Button onClick={handleChange}>Open</Button>;

  return (
    <div style={{ height: "500px" }}>
      <Modal
        // activator={activator}
        open={active}
        onClose={handleChange}
        // title="Reach more shoppers with Instagram product tags"
        // primaryAction={{
        //   content: "Add Instagram",
        //   onAction: handleChange,
        // }}
        secondaryActions={[
          {
            content: "Close",
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          {!props.fanIDResponse && !props.fanIDError ? (
            <Spinner></Spinner>
          ) : !props.fanIDError ? (
            <ReactJson
                src={props.fanIDResponse}
                enableClipboard={handleCopy}/>
          ) : (
            <TextStyle>{props.fanIDError}</TextStyle>
          )}
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default FanIDPopup;
