import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Modal,
  Page,
  Loading,
  Card,
  ButtonGroup,
  TextField,
  FormLayout,
  Layout,
  Toast,
  Spinner,
  InlineError,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import axios from "../../Assets/Lib/axios";
import attachementsImage from "../../Assets/Images/attachments.svg";
import Cookies from "js-cookie";
import back from "../../Assets/Images/back.svg";
import flag from "../../Assets/Images/flag.svg";
import flagRed from "../../Assets/Images/flag-red.svg";
import history from "../../Assets/Lib/history";
import moment from "moment";
import previous from "../../Assets/Images/previous.svg";
import next from "../../Assets/Images/next.svg";
import ActionButtons from "../../Components/ActionButtons";
import NavigationLayout from "../../Components/NavigationLayout";
import ActionButtonsPermit from "../../Components/ActionButtonsPermit";
import { useNavigate } from "react-router-dom";
import { isDefined, adminRole } from "../../Helpers";

const PermitDetails = (props) => {
  const navigate = useNavigate();
  const [popupActive, setPopupActive] = useState(false);

  const { id } = useParams();
  const countries = [
    {
      code: "004",
      ar: "افغانستان",
      en: "Afghanistan",
    },
    {
      code: "008",
      ar: "البانيا",
      en: "Albania",
    },
    {
      code: "012",
      ar: "الجزائر",
      en: "Algeria",
    },
    {
      code: "016",
      ar: "ساموا الامريكية",
      en: "American Samoa",
    },
    {
      code: "020",
      ar: "اندورا",
      en: "Andorra",
    },
    {
      code: "024",
      ar: "انجولا",
      en: "Angola",
    },
    {
      code: "660",
      ar: "جزيرة أنجويلا",
      en: "Anguilla",
    },
    {
      code: "028",
      ar: "انتيجوا و باربودا",
      en: "Antigua and Barbuda",
    },
    {
      code: "032",
      ar: "الارجنتين",
      en: "Argentina",
    },
    {
      code: "051",
      ar: "ارمينيا",
      en: "Armenia",
    },
    {
      code: "533",
      ar: "أروبا",
      en: "Aruba",
    },
    {
      code: "036",
      ar: "استراليا",
      en: "Australia",
    },
    {
      code: "040",
      ar: "النمسا",
      en: "Austria",
    },
    {
      code: "031",
      ar: "اذريبيجان",
      en: "Azerbaijan",
    },
    {
      code: "044",
      ar: "بهاماس​",
      en: "Bahamas",
    },
    {
      code: "048",
      ar: "البحرين",
      en: "Bahrain",
    },
    {
      code: "050",
      ar: "بنغلاديش​",
      en: "Bangladesh",
    },
    {
      code: "052",
      ar: "باربادوس​",
      en: "Barbados",
    },
    {
      code: "112",
      ar: "بيلروس​",
      en: "Belarus",
    },
    {
      code: "056",
      ar: "بلجيكا",
      en: "Belgium",
    },
    {
      code: "084",
      ar: "بيليز",
      en: "Belize",
    },
    {
      code: "204",
      ar: "بينين",
      en: "Benin",
    },
    {
      code: "060",
      ar: "برمودا",
      en: "Bermuda",
    },
    {
      code: "064",
      ar: "بوتان",
      en: "Bhutan",
    },
    {
      code: "068",
      ar: "بوليفيا",
      en: "Bolivia (Plurinational State of)",
    },
    {
      code: 544,
      ar: "البوسنة والهرسك",
      en: "Bosnia and Herzegovina",
    },
    {
      code: "072",
      ar: "بوتسوانا",
      en: "Botswana",
    },
    {
      code: "074",
      ar: "جزر بوفت",
      en: "Bouvet Island",
    },
    {
      code: "076",
      ar: "البرازيل",
      en: "Brazil",
    },
    {
      code: "086",
      ar: "جزر المحيط الهندي",
      en: "British Indian Ocean Territory",
    },
    {
      code: "096",
      ar: "بروناي دار السلام",
      en: "Brunei Darussalam",
    },
    {
      code: "100",
      ar: "بلغاريا",
      en: "Bulgaria",
    },
    {
      code: "854",
      ar: "بوركينافاسو",
      en: "Burkina Faso",
    },
    {
      code: "108",
      ar: "بروندي",
      en: "Burundi",
    },
    {
      code: "132",
      ar: "جمهوريةالرأس​الاخضر",
      en: "Cabo Verde",
    },
    {
      code: "116",
      ar: "كمبوديا",
      en: "Cambodia",
    },
    {
      code: "120",
      ar: "الكاميرون",
      en: "Cameroon",
    },
    {
      code: "124",
      ar: "كندا",
      en: "Canada",
    },
    {
      code: "136",
      ar: "جزر كيمان",
      en: "Cayman Islands",
    },
    {
      code: "140",
      ar: "افريقيا الوسطي",
      en: "Central African Republic",
    },
    {
      code: "148",
      ar: "تشاد",
      en: "Chad",
    },
    {
      code: "152",
      ar: "تشيلي",
      en: "Chile",
    },
    {
      code: "156",
      ar: "ج.الصين الشعبية",
      en: "China",
    },
    {
      code: "162",
      ar: "جزر كرسمس​",
      en: "Christmas Island",
    },
    {
      code: "166",
      ar: "جزر كوكوس​",
      en: "Cocos (Keeling) Islands",
    },
    {
      code: "170",
      ar: "كولومبيا",
      en: "Colombia",
    },
    {
      code: "174",
      ar: "جمهورية القمرالمتحدة",
      en: "Comoros",
    },
    {
      code: "178",
      ar: "جمهورية الكونغو",
      en: "Congo",
    },
    {
      code: "180",
      ar: "الكونغو الديمقراطية",
      en: "Congo, Democratic Republic of the",
    },
    {
      code: "184",
      ar: "جزر كوك",
      en: "Cook Islands",
    },
    {
      code: "188",
      ar: "كوستاريكا",
      en: "Costa Rica",
    },
    {
      code: "191",
      ar: "كرواتيا",
      en: "Croatia",
    },
    {
      code: "192",
      ar: "كوبا",
      en: "Cuba",
    },
    {
      code: "531",
      ar: "كوراساو",
      en: "Curaأ§ao",
    },
    {
      code: "196",
      ar: "قبرص​",
      en: "Cyprus",
    },
    {
      code: 200,
      ar: "تشيك",
      en: "Czechia",
    },
    {
      code: "384",
      ar: "كوت ديفوار",
      en: "Cأ´te d'Ivoire",
    },
    {
      code: "208",
      ar: "الدنمارك",
      en: "Denmark",
    },
    {
      code: "262",
      ar: "جيبوتي",
      en: "Djibouti",
    },
    {
      code: "212",
      ar: "دومينيكا",
      en: "Dominica",
    },
    {
      code: "214",
      ar: "جمهورية دومنيكان",
      en: "Dominican Republic",
    },
    {
      code: "218",
      ar: "الاكوادور",
      en: "Ecuador",
    },
    {
      code: "818",
      ar: "مصر",
      en: "Egypt",
    },
    {
      code: "222",
      ar: "السالفادور",
      en: "El Salvador",
    },
    {
      code: "226",
      ar: "غينيا الاستوائية",
      en: "Equatorial Guinea",
    },
    {
      code: "080",
      ar: "اريتريا",
      en: "Eritrea",
    },
    {
      code: "233",
      ar: "استونيا",
      en: "Estonia",
    },
    {
      code: "748",
      ar: "سوازيلاند",
      en: "Eswatini",
    },
    {
      code: 230,
      ar: "اثيوبيا",
      en: "Ethiopia",
    },
    {
      code: "238",
      ar: "جزر فوكلند",
      en: "Falkland Islands (Malvinas)",
    },
    {
      code: "234",
      ar: "فارو",
      en: "Faroe Islands",
    },
    {
      code: "242",
      ar: "فيجي",
      en: "Fiji",
    },
    {
      code: "246",
      ar: "فنلندا",
      en: "Finland",
    },
    {
      code: "250",
      ar: "فرنسا",
      en: "France",
    },
    {
      code: "254",
      ar: "غيانا الفرنسية",
      en: "French Guiana",
    },
    {
      code: "258",
      ar: "بولنيسياالفرنسية",
      en: "French Polynesia",
    },
    {
      code: "266",
      ar: "الغابون",
      en: "Gabon",
    },
    {
      code: "270",
      ar: "غامبيا",
      en: "Gambia",
    },
    {
      code: "268",
      ar: "جورجيا",
      en: "Georgia",
    },
    {
      code: 280,
      ar: "المانيا",
      en: "Germany",
    },
    {
      code: "288",
      ar: "غانا",
      en: "Ghana",
    },
    {
      code: "292",
      ar: "جبل طارق",
      en: "Gibraltar",
    },
    {
      code: "300",
      ar: "اليونان",
      en: "Greece",
    },
    {
      code: "304",
      ar: "جرينلند",
      en: "Greenland",
    },
    {
      code: "308",
      ar: "جرينادا",
      en: "Grenada",
    },
    {
      code: "312",
      ar: "جواديلوب",
      en: "Guadeloupe",
    },
    {
      code: "316",
      ar: "غوامي",
      en: "Guam",
    },
    {
      code: "320",
      ar: "جواتيمالا",
      en: "Guatemala",
    },
    {
      code: "831",
      ar: "جزيرة جيزنزي",
      en: "Guernsey",
    },
    {
      code: "324",
      ar: "غينيا",
      en: "Guinea",
    },
    {
      code: "624",
      ar: "غينيا بيساو",
      en: "Guinea-Bissau",
    },
    {
      code: "328",
      ar: "غيانيا",
      en: "Guyana",
    },
    {
      code: "332",
      ar: "هايتي",
      en: "Haiti",
    },
    {
      code: "334",
      ar: "جزر دونالد",
      en: "Heard Island and McDonald Islands",
    },
    {
      code: "336",
      ar: "الفاتيكان",
      en: "Holy See",
    },
    {
      code: "340",
      ar: "هوندوراس​",
      en: "Honduras",
    },
    {
      code: "344",
      ar: "هونج كونج",
      en: "Hong Kong",
    },
    {
      code: "348",
      ar: "المجر",
      en: "Hungary",
    },
    {
      code: "352",
      ar: "ايسلاندا",
      en: "Iceland",
    },
    {
      code: "356",
      ar: "الهند",
      en: "India",
    },
    {
      code: "360",
      ar: "اندونيسيا",
      en: "Indonesia",
    },
    {
      code: "364",
      ar: "ايران",
      en: "Iran (Islamic Republic of)",
    },
    {
      code: "368",
      ar: "العراق",
      en: "Iraq",
    },
    {
      code: "372",
      ar: "ايرلندا",
      en: "Ireland",
    },
    {
      code: "833",
      ar: "جزيرة مان",
      en: "Isle of Man",
    },
    {
      code: "376",
      ar: "اسرائيل",
      en: "Israel",
    },
    {
      code: "380",
      ar: "ايطاليا",
      en: "Italy",
    },
    {
      code: "388",
      ar: "جمايكا",
      en: "Jamaica",
    },
    {
      code: "392",
      ar: "اليابان",
      en: "Japan",
    },
    {
      code: "832",
      ar: "جزيرة جيرزي",
      en: "Jersey",
    },
    {
      code: "400",
      ar: "الاردن",
      en: "Jordan",
    },
    {
      code: "398",
      ar: "كزخستان",
      en: "Kazakhstan",
    },
    {
      code: "404",
      ar: "كينيا",
      en: "Kenya",
    },
    {
      code: "296",
      ar: "كريباتي",
      en: "Kiribati",
    },
    {
      code: "408",
      ar: "كوريا الشمالية",
      en: "Korea (Democratic People's Republic of)",
    },
    {
      code: "410",
      ar: "كوريا الجنوبية",
      en: "Korea, Republic of",
    },
    {
      code: "414",
      ar: "الكويت",
      en: "Kuwait",
    },
    {
      code: "417",
      ar: "كيرغيستان",
      en: "Kyrgyzstan",
    },
    {
      code: "418",
      ar: "لاو",
      en: "Lao People's Democratic Republic",
    },
    {
      code: "428",
      ar: "لاتفيا",
      en: "Latvia",
    },
    {
      code: "422",
      ar: "لبنان",
      en: "Lebanon",
    },
    {
      code: "426",
      ar: "ليسوتو",
      en: "Lesotho",
    },
    {
      code: "430",
      ar: "ليبيريا",
      en: "Liberia",
    },
    {
      code: "434",
      ar: "ليبيا",
      en: "Libya",
    },
    {
      code: "438",
      ar: "ليشتنشين",
      en: "Liechtenstein",
    },
    {
      code: "440",
      ar: "لتوانيا",
      en: "Lithuania",
    },
    {
      code: "442",
      ar: "لكسمبورج",
      en: "Luxembourg",
    },
    {
      code: "446",
      ar: "ماكاو",
      en: "Macao",
    },
    {
      code: "450",
      ar: "مداغاسكر",
      en: "Madagascar",
    },
    {
      code: "454",
      ar: "مالاوي",
      en: "Malawi",
    },
    {
      code: "458",
      ar: "ماليزيا",
      en: "Malaysia",
    },
    {
      code: "462",
      ar: "جزر المالديف",
      en: "Maldives",
    },
    {
      code: "466",
      ar: "مالي",
      en: "Mali",
    },
    {
      code: "470",
      ar: "مالطا",
      en: "Malta",
    },
    {
      code: "584",
      ar: "جزر المرشال",
      en: "Marshall Islands",
    },
    {
      code: "474",
      ar: "مارتنيك",
      en: "Martinique",
    },
    {
      code: "478",
      ar: "موريتانيا",
      en: "Mauritania",
    },
    {
      code: "480",
      ar: "موريشوس​",
      en: "Mauritius",
    },
    {
      code: "175",
      ar: "جزيرة مايوت",
      en: "Mayotte",
    },
    {
      code: "484",
      ar: "المكسيك",
      en: "Mexico",
    },
    {
      code: "583",
      ar: "مكرونسيا",
      en: "Micronesia (Federated States of)",
    },
    {
      code: "498",
      ar: "ملدوفيا",
      en: "Moldova, Republic of",
    },
    {
      code: "492",
      ar: "موناكو",
      en: "Monaco",
    },
    {
      code: "496",
      ar: "منغوليا",
      en: "Mongolia",
    },
    {
      code: "499",
      ar: "الجبل الأسود",
      en: "Montenegro",
    },
    {
      code: "500",
      ar: "مونتسرات",
      en: "Montserrat",
    },
    {
      code: "504",
      ar: "المغرب",
      en: "Morocco",
    },
    {
      code: "508",
      ar: "موزامبيق",
      en: "Mozambique",
    },
    {
      code: "104",
      ar: "ميانمار",
      en: "Myanmar",
    },
    {
      code: "516",
      ar: "ناميبيا",
      en: "Namibia",
    },
    {
      code: "520",
      ar: "نورو",
      en: "Nauru",
    },
    {
      code: "524",
      ar: "نيبال",
      en: "Nepal",
    },
    {
      code: "528",
      ar: "هولندا",
      en: "Netherlands",
    },
    {
      code: "540",
      ar: "نيو كاليدونيا",
      en: "New Caledonia",
    },
    {
      code: "554",
      ar: "نيوزيلاندا",
      en: "New Zealand",
    },
    {
      code: "558",
      ar: "نيكواراجوا",
      en: "Nicaragua",
    },
    {
      code: "562",
      ar: "النيجر",
      en: "Niger",
    },
    {
      code: "566",
      ar: "نيجيريا",
      en: "Nigeria",
    },
    {
      code: "570",
      ar: "نيو",
      en: "Niue",
    },
    {
      code: "574",
      ar: "جزيرة نورفوك",
      en: "Norfolk Island",
    },
    {
      code: "807",
      ar: "جمهورية شمال مقدونيا",
      en: "North Macedonia",
    },
    {
      code: "580",
      ar: "جزر مريانا",
      en: "Northern Mariana Islands",
    },
    {
      code: "578",
      ar: "النورويج",
      en: "Norway",
    },
    {
      code: "512",
      ar: "عمان",
      en: "Oman",
    },
    {
      code: "586",
      ar: "باكستان",
      en: "Pakistan",
    },
    {
      code: "585",
      ar: "بولا",
      en: "Palau",
    },
    {
      code: 999,
      ar: "فلسطين",
      en: "Palestine, State of",
    },
    {
      code: 590,
      ar: "بنما",
      en: "Panama",
    },
    {
      code: "598",
      ar: "بابوا نيو غينيا",
      en: "Papua New Guinea",
    },
    {
      code: "600",
      ar: "باراجواي",
      en: "Paraguay",
    },
    {
      code: "604",
      ar: "بيرو",
      en: "Peru",
    },
    {
      code: "608",
      ar: "الفلبين",
      en: "Philippines",
    },
    {
      code: "612",
      ar: "بيتكيرن",
      en: "Pitcairn",
    },
    {
      code: "616",
      ar: "بولندا",
      en: "Poland",
    },
    {
      code: "620",
      ar: "البرتغال",
      en: "Portugal",
    },
    {
      code: "630",
      ar: "بورتوريكو",
      en: "Puerto Rico",
    },
    {
      code: "634",
      ar: "قطر",
      en: "Qatar",
    },
    {
      code: "642",
      ar: "رومانيا",
      en: "Romania",
    },
    {
      code: "643",
      ar: "روسيا",
      en: "Russian Federation",
    },
    {
      code: "646",
      ar: "رواندا",
      en: "Rwanda",
    },
    {
      code: "638",
      ar: "ريونيون",
      en: "Rأ©union",
    },
    {
      code: "652",
      ar: "سان بارتليمي",
      en: "Saint Barthأ©lemy",
    },
    {
      code: "654",
      ar: "سنت هيلانا",
      en: "Saint Helena, Ascension and Tristan da Cunha[b]",
    },
    {
      code: "659",
      ar: "سانت كيتس​ونيفيس​",
      en: "Saint Kitts and Nevis",
    },
    {
      code: "662",
      ar: "سنت لوسيا",
      en: "Saint Lucia",
    },
    {
      code: "663",
      ar: "سالت مارتن",
      en: "Saint Martin (French part)",
    },
    {
      code: "666",
      ar: "سنت بير وميكليون",
      en: "Saint Pierre and Miquelon",
    },
    {
      code: "670",
      ar: "سنت فينسنت",
      en: "Saint Vincent and the Grenadines",
    },
    {
      code: "882",
      ar: "ساموا",
      en: "Samoa",
    },
    {
      code: "674",
      ar: "سان مارينو",
      en: "San Marino",
    },
    {
      code: "678",
      ar: "سافوتوم و برنسيت",
      en: "Sao Tome and Principe",
    },
    {
      code: "682",
      ar: "السعودية",
      en: "Saudi Arabia",
    },
    {
      code: "686",
      ar: "السنغال",
      en: "Senegal",
    },
    {
      code: "688",
      ar: "صربيا",
      en: "Serbia",
    },
    {
      code: "690",
      ar: "سيشيل",
      en: "Seychelles",
    },
    {
      code: "694",
      ar: "سيراليون",
      en: "Sierra Leone",
    },
    {
      code: "702",
      ar: "سنغافورة",
      en: "Singapore",
    },
    {
      code: "703",
      ar: "سلوفكيا",
      en: "Slovakia",
    },
    {
      code: "705",
      ar: "سلوفنيا",
      en: "Slovenia",
    },
    {
      code: "090",
      ar: "جزر سليمان",
      en: "Solomon Islands",
    },
    {
      code: "706",
      ar: "الصومال",
      en: "Somalia",
    },
    {
      code: "710",
      ar: "جنوب افريقيا",
      en: "South Africa",
    },
    {
      code: "728",
      ar: "جنوب السودان",
      en: "South Sudan",
    },
    {
      code: "724",
      ar: "اسبانيا",
      en: "Spain",
    },
    {
      code: "144",
      ar: "سريلانكا",
      en: "Sri Lanka",
    },
    {
      code: 736,
      ar: "السودان",
      en: "Sudan",
    },
    {
      code: "740",
      ar: "سورينام",
      en: "Suriname",
    },
    {
      code: "744",
      ar: "سفالبارد وجان ماين",
      en: "Svalbard and Jan Mayen[c]",
    },
    {
      code: "752",
      ar: "السويد",
      en: "Sweden",
    },
    {
      code: "756",
      ar: "سويسرا",
      en: "Switzerland",
    },
    {
      code: "760",
      ar: "سوريا",
      en: "Syrian Arab Republic",
    },
    {
      code: "158",
      ar: "ج. الصين (تايوان)",
      en: "Taiwan, Province of China",
    },
    {
      code: "762",
      ar: "طاجكستان",
      en: "Tajikistan",
    },
    {
      code: "834",
      ar: "تنزانيا",
      en: "Tanzania, United Republic of",
    },
    {
      code: "764",
      ar: "تايلاند",
      en: "Thailand",
    },
    {
      code: "626",
      ar: "تيمور الشرقية",
      en: "Timor-Leste",
    },
    {
      code: "768",
      ar: "توغو",
      en: "Togo",
    },
    {
      code: "772",
      ar: "طوكيلاو",
      en: "Tokelau",
    },
    {
      code: "776",
      ar: "تونجا",
      en: "Tonga",
    },
    {
      code: "780",
      ar: "ترينادا وتوباجو",
      en: "Trinidad and Tobago",
    },
    {
      code: "788",
      ar: "تونس​",
      en: "Tunisia",
    },
    {
      code: "792",
      ar: "تركيا",
      en: "Turkey",
    },
    {
      code: "795",
      ar: "تركمانستان",
      en: "Turkmenistan",
    },
    {
      code: "796",
      ar: "جزرالترك وكايكو",
      en: "Turks and Caicos Islands",
    },
    {
      code: "798",
      ar: "توفالو",
      en: "Tuvalu",
    },
    {
      code: "800",
      ar: "اوغندا",
      en: "Uganda",
    },
    {
      code: "804",
      ar: "اوكرانيا",
      en: "Ukraine",
    },
    {
      code: "784",
      ar: "الامارات",
      en: "United Arab Emirates",
    },
    {
      code: "826",
      ar: "بريطانيا",
      en: "United Kingdom",
    },
    {
      code: "840",
      ar: "الولايات المتحدة",
      en: "United States of America",
    },
    {
      code: "858",
      ar: "الاوروجواي",
      en: "Uruguay",
    },
    {
      code: "860",
      ar: "اوزبكستان",
      en: "Uzbekistan",
    },
    {
      code: "548",
      ar: "فانواتو",
      en: "Vanuatu",
    },
    {
      code: "862",
      ar: "فنيزويلا",
      en: "Venezuela (Bolivarian Republic of)",
    },
    {
      code: "704",
      ar: "فيتنام",
      en: "Viet Nam",
    },
    {
      code: "092",
      ar: "فرجين البريطانية",
      en: "Virgin Islands (British)",
    },
    {
      code: "850",
      ar: "جزر فيرجن",
      en: "Virgin Islands (U.S.)",
    },
    {
      code: "876",
      ar: "جزر والس​وفوتونا",
      en: "Wallis and Futuna",
    },
    {
      code: "732",
      ar: "الصحراء الغربية",
      en: "Western Sahara",
    },
    {
      code: "886",
      ar: "اليمن",
      en: "Yemen",
    },
    {
      code: "894",
      ar: "زامبيا",
      en: "Zambia",
    },
    {
      code: "716",
      ar: "زمبوابوي",
      en: "Zimbabwe",
    },
    {
      code: "248",
      ar: "جزيرة أولند",
      en: "Aland Islands",
    },
  ];

  const format = "YYYY-MM-DD HH:mm:ss";
  const reference = useRef("");
  const [isLoading, setIsLoading] = useState(true);
  const [activeAreYouSure, setActiveAreYouSure] = useState(false);
  const [activeCustomsButtons, setActiveCustomsButtons] = useState(false);
  const [activeEmail, setActiveEmail] = useState(false);
  const [questionValue, setQuestionValue] = useState(false);
  const loadingMarkup = isLoading ? <Loading /> : null;
  const [result, setResult] = useState("");
  const [rejectClicked, setRejectClicked] = useState(false);
  const [activeDelete, setActiveDelete] = useState(false);
  const [isSendingDelete, setIsSendingDelete] = useState(false);
  const [activeDeleteApp, setActiveDeleteApp] = useState(false);
  const [isFlagged, setIsFlagged] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [currentRequestId, setCurrentRequestId] = useState(parseInt(id));
  const toggleActiveToast = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );
  const [toastContent, setToastContent] = useState("");
  const toastMarkup = activeToast ? (
    <Toast
      content={toastContent}
      onDismiss={toggleActiveToast}
      duration="30000"
    />
  ) : null;
  const [reasonOfRejectionValue, setReasonOfRejectionValue] = useState("");

  const [toggleStatus, setToggleStatus] = useState(false);
  const [toggleRegisterToCra, setToggleRegisterToCra] = useState(false);
  const [isChangingStatus, setIsChangingStatus] = useState(false);
  const [reasonOfChangeStatusValue, setReasonOfChangeStatusValue] = useState("");
  const handleChangeReasonOfRejection = (newValue) => {
    setReasonOfRejectionValue(newValue);
    setReasonError("");
  };
  const [commentValue, setCommentValue] = useState("");
  const handleChangeComment = (newValue) => {
    setCommentValue(newValue);
    setCommentError("");
  };
  const [commentError, setCommentError] = useState("");

  const [emailMessageValue, setEmailMessageValue] = useState("");
  const handleChangeEmailMessage = (e) => {
    setEmailMessageValue(e.target.value);
    setEmailError("");
  };
  const [status, setStatus] = useState("");
  const [allRequestsArray, setAllRequestsArray] = useState(null);
  const [ts, setTs] = useState("");

  useEffect(() => {
    let str = "";
    if (
      window.location.href.indexOf("/admin/equipment-clearance/all-permits") >
      -1
    )
      str = "permits-ids";
    else if (
      window.location.href.indexOf("/admin/equipment-clearance/cra-permits") >
      -1
    )
      str = "permits-cra-ids";
    else if (
      window.location.href.indexOf("/admin/equipment-clearance/qna-permits") >
      -1
    )
      str = "permits-qna-ids";
    else if (
      window.location.href.indexOf(
        "/admin/equipment-clearance/customs-permits"
      ) > -1
    )
      str = "permits-customs-ids";
    axios
      .get(`admin/v1/${str}`)
      .then((result) => {
        setAllRequestsArray(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  }, []);
  useEffect(() => {
    let str = "";
    if (
      window.location.href.indexOf("/admin/equipment-clearance/all-permits") >
      -1
    )
      str = "permits";
    else if (
      window.location.href.indexOf("/admin/equipment-clearance/cra-permits") >
      -1
    )
      str = "cra-permits";
    else if (
      window.location.href.indexOf("/admin/equipment-clearance/qna-permits") >
      -1
    )
      str = "qna-permits";
    else if (
      window.location.href.indexOf(
        "/admin/equipment-clearance/customs-permits"
      ) > -1
    )
      str = "customs-permits";

    axios
      .get(`admin/v1/${str}/${currentRequestId}`)
      .then((result) => {
        setResult(result.data.data);
        // setIsFlagged(result.data.data.is_flagged ? true : false);
      })
      .catch((err) => console.log(err));
  }, [currentRequestId, status, ts]);

  const handleRowApprove = (e) => {
    reference.current = e.currentTarget.id + "strtosplit" + "approve";
    setQuestionValue("Are you sure you want to approve this permit?");
    setActiveAreYouSure(true);
  };
  const handleRowReject = (e) => {
    reference.current = e.currentTarget.id + "strtosplit" + "reject";
    setQuestionValue("Are you sure you want to reject this permit?");
    setActiveAreYouSure(true);
  };
  const handleRowUnreject = (e) => {
    reference.current = e.currentTarget.id + "strtosplit" + "unreject";
    setQuestionValue("Are you sure you want to unreject this permit?");
    setActiveAreYouSure(true);
  };
  const handleEmail = (e) => {
    setActiveEmail(true);
  };
  const handleToggleStatus = (e) => {
    reference.current = e?.currentTarget?.id ?? reference.current;
    setToggleStatus(!toggleStatus);
    setIsChangingStatus(false);
    setReasonOfChangeStatusValue("");
  };
  const handleRegisterToCra = (e) => {
    reference.current = e?.currentTarget?.id ?? reference.current;
    setToggleRegisterToCra(!toggleRegisterToCra);
    setIsChangingStatus(false);
  };
  const handleChangeStatus = (e) => {

    setIsChangingStatus(true);
    const form_data = new FormData();
    form_data.append("reason_of_status_move", reasonOfChangeStatusValue);
    form_data.append("_method", "PATCH");

    axios
        .post(`admin/v1/permits/${JSON.parse(reference.current).id}/move-equipment`, form_data)
        .then((res) => {
          handleToggleStatus();
          setToastContent(res?.data?.message);
          setTs(+new Date());
          toggleActiveToast();
        })
        .catch((err) => console.log(""));
  };
  const handleRegisterToCraEmail = (e) => {

    setIsChangingStatus(true);

    axios
        .post(`admin/v1/permits/${JSON.parse(reference.current).id}/register-cra-email`)
        .then((res) => {
          handleRegisterToCra();
          setToastContent(res?.data?.message);
          setTs(+new Date());
          toggleActiveToast();
        })
        .catch((err) => console.log(""));
  };

  const [emailError, setEmailError] = useState("");

  const handleCancelCustomsAction = () => {
    setActiveCustomsButtons(false);
  };
  const handleEntered = (e) => {
    reference.current = e.currentTarget.id + "strtosplit" + "entered";
    setActiveCustomsButtons(true);
  };
  const handleConfiscated = (e) => {
    reference.current = e.currentTarget.id + "strtosplit" + "confiscated";
    setActiveCustomsButtons(true);
  };

  const handleSubmitCustomsAction = () => {
    if (
      reference.current.split("strtosplit")[1] === "confiscated" &&
      !commentValue
    ) {
      setCommentError("Please enter a comment");
    } else {
      setIsSendingEmail(true);
      const form_data = new FormData();
      form_data.append(
        "status",
        reference.current.split("strtosplit")[1] === "entered"
          ? "entered"
          : "confiscated"
      );

      form_data.append("message", commentValue);
      form_data.append("_method", "PATCH");

      axios
        .post(
          `admin/v1/permits/${
            JSON.parse(reference.current.split("strtosplit")[0]).id
          }/status/customs`,
          form_data
        )
        .then((res) => {
          setIsSendingEmail(false);
          setCommentValue("");
          setActiveCustomsButtons(false);
          setToastContent(
            reference.current.split("strtosplit")[1] === "entered"
              ? "Request Approved Entered"
              : "Request Rejected Confiscated"
          );
          setTs(+new Date());
          toggleActiveToast();
        })
        .catch((err) => console.log(""));
    }
  };
  const handleNo = () => {
    setActiveAreYouSure(false);
  };
  const handleCancel = () => {
    setCommentValue("");
    setCommentError("");
    setActiveEmail(false);
  };

  const handleCancelDeleteApplication = () => {
    setActiveDeleteApp(false);
  };
  const handleCancelDeleteAccount = () => {
    setActiveDelete(false);
  };

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const handleSend = () => {
    if (!emailMessageValue) {
      setEmailError("Please enter a message");
    } else {
      setIsSendingEmail(true);
      const form_data = new FormData();

      form_data.append("message", emailMessageValue);
      form_data.append("_method", "PATCH");

      axios
        .post(`admin/v1/registration-requests/${result?.id}/email`, form_data)
        .then((res) => {
          setActiveEmail(false);
          setToastContent("Email Sent Successfully");
          toggleActiveToast();
          setTs(+new Date());
          setIsSendingEmail(false);
        })
        .catch((err) => console.log(""));
    }
  };

  const [reasonError, setReasonError] = useState("");
  const handleYes = () => {
    if (
      reference.current.split("strtosplit")[1] == "reject" &&
      !reasonOfRejectionValue
    ) {
      setReasonError("Please enter a reason");
    } else {
      setIsSendingEmail(true);
      const form_data = new FormData();
      form_data.append(
        "status",
        reference.current.split("strtosplit")[1] === "approve"
          ? "approved"
          : "rejected"
      );
      reference.current.split("strtosplit")[1] === "approve" &&
        form_data.append("reason_of_permits_approval", reasonOfRejectionValue);
      reference.current.split("strtosplit")[1] === "reject" &&
        form_data.append("reason_of_permits_rejection", reasonOfRejectionValue);
      form_data.append("_method", "PATCH");

      axios
        .post(
          `admin/v1/permits/${
            JSON.parse(reference.current.split("strtosplit")[0]).id
          }/status/${JSON.parse(
            reference.current.split("strtosplit")[0]
          ).status_level.toLowerCase()}`,
          form_data
        )
        .then((res) => {
          setIsSendingEmail(false);
          setReasonError("");
          setRejectClicked(false);
          setActiveAreYouSure(false);
          setToastContent(
            reference.current.split("strtosplit")[1] === "approve"
              ? "Request Approved Successfully"
              : "Request Rejected Successfully"
          );
          setTs(+new Date());
          toggleActiveToast();
        })
        .catch((err) => console.log(""));
    }
  };
  const handleFlag = () => {
    // isFlagged ? setIsFlagged(false) : setIsFlagged(true);
    const form_data = new FormData();
    form_data.append("_method", "PATCH");
    form_data.append("is_flagged", !isFlagged ? 1 : 0);
    axios
      .post(
        `admin/v1/registration-requests/${currentRequestId}/flag`,
        form_data
      )
      .then((res) => {
        !isFlagged ? setIsFlagged(true) : setIsFlagged(false);
      })
      .catch((err) => console.log(""));
  };

  const switchStatusColor = (status) => {
    switch (status) {
      case "submitted":
        return "#FFBF00";
      case "approved_cra":
      case "approved_qna":
      case "entered":
        return "#00CFB7";
      case "rejected_cra":
      case "rejected_qna":
      case "confiscated":
        return "#B85555";
      default:
        return "";
    }
  };

  const switchCategory = (param) => {
    switch (String(param)) {
      case "1":
        return "Camera";
      case "2":
        return "Live streaming equipment";
      case "3":
        return "Radio Communication equipment";
      case "4":
        return "Camera's accessories";
      case "5":
        return "Optical Fibre Bundles and Cables";
      case "6":
        return "Aerials or Antennae";
      case "7":
        return "Wirless Microphones";
      case "8":
        return "Other";
      default:
        return "";
      // code block
    }
  };
  const [value, setValue] = useState("");
  const handleDownload = (e) => {
    setPopupActive(true);
    const file = e.currentTarget.id;
    // window.open(
    //   `${process.env.REACT_APP_BASE_URL}admin/v1/permits/${id}/download-pdf`
    // );
    axios
      .get(`admin/v1/permits/${id}/download-pdf`)
      .then((res) => {
        setPopupActive(false);
        setToastContent("Please check your email");
        toggleActiveToast();
      })
      .catch((err) => console.log(""));
  };

  const handlePrevious = () => {
    let str = "";
    if (
      window.location.href.indexOf("/admin/equipment-clearance/all-permits") >
      -1
    )
      str = "all-permits";
    else if (
      window.location.href.indexOf("/admin/equipment-clearance/cra-permits") >
      -1
    )
      str = "cra-permits";
    else if (
      window.location.href.indexOf("/admin/equipment-clearance/qna-permits") >
      -1
    )
      str = "qna-permits";
    else if (
      window.location.href.indexOf(
        "/admin/equipment-clearance/customs-permits"
      ) > -1
    )
      str = "customs-permits";
    if (
      allRequestsArray.indexOf(
        findArrayElementById(allRequestsArray, currentRequestId)
      ) != 0
    ) {
      setCurrentRequestId(
        allRequestsArray[
          allRequestsArray.indexOf(
            findArrayElementById(allRequestsArray, currentRequestId)
          ) - 1
        ]
      );
      history.push(
        `/admin/equipment-clearance/${str}/${
          allRequestsArray[
            allRequestsArray.indexOf(
              findArrayElementById(allRequestsArray, currentRequestId)
            ) - 1
          ]
        }`
      );
    }
  };
  const handleNext = () => {
    let str = "";
    if (
      window.location.href.indexOf("/admin/equipment-clearance/all-permits") >
      -1
    )
      str = "all-permits";
    else if (
      window.location.href.indexOf("/admin/equipment-clearance/cra-permits") >
      -1
    )
      str = "cra-permits";
    else if (
      window.location.href.indexOf("/admin/equipment-clearance/qna-permits") >
      -1
    )
      str = "qna-permits";
    else if (
      window.location.href.indexOf(
        "/admin/equipment-clearance/customs-permits"
      ) > -1
    )
      str = "customs-permits";
    if (
      allRequestsArray.indexOf(
        findArrayElementById(allRequestsArray, currentRequestId)
      ) !=
      allRequestsArray.length - 1
    ) {
      setCurrentRequestId(
        allRequestsArray[
          allRequestsArray.indexOf(
            findArrayElementById(allRequestsArray, currentRequestId)
          ) + 1
        ]
      );

      history.push(
        `/admin/equipment-clearance/${str}/${
          allRequestsArray[
            allRequestsArray.indexOf(
              findArrayElementById(allRequestsArray, currentRequestId)
            ) + 1
          ]
        }`
      );
    }
  };
  const switchAcceditationType = (accreditationType) => {
    return accreditationType?.details?.label;
  };

  return (
    <NavigationLayout>
      <Page fullWidth>
        {loadingMarkup}
        <div className="left my-5" style={{ width: "30%" }}>
            <p className="purpletitle">
              Equipment Clearance (Hand Carry)
           </p>
        </div>
        {isLoading ? (
          <div className="spinnerContainer">
            <div className="vertical-center">
              <Spinner size="large" />
            </div>
          </div>
        ) : (
          <div style={{ backgroundColor: "white" }}>
            {result && allRequestsArray && (
              <div
                className="detailsPage"
                style={{
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                }}
              >
                <div className="headerDetailsDiv">
                  <Layout sectioned>
                    <img
                      src={back}
                      className="backImg"
                      onClick={() => navigate(`/admin/equipment-clearance`)}
                    ></img>
                    {/* <img
                      src={isFlagged ? flagRed : flag}
                      className="flagImg"
                      onClick={handleFlag}
                    ></img> */}
                    <span style={{ marginLeft: "2rem" }}>
                    <span className="application-detail-boxes">Application Ref No. <b>{parseInt(result?.id)}</b></span>
                      <span className="application-detail-boxes">Time of Registration&nbsp;
                      <b>{moment(result.created_at).format(format)}</b></span>
                      <span className="application-detail-boxes">Application Type
                      <b
                        style={{
                          color:
                            parseInt(result?.application?.accreditation_type_id) !== 1  ? '#00671d' : '#550065'
                        }}
                      >{` ${switchAcceditationType(result?.application?.accreditation_type)}`}</b>
                      </span>
                      <span className="application-detail-boxes">Status&nbsp;
                      <b style={{ color: switchStatusColor(result.status) }}>
                        {result?.status.charAt(0).toUpperCase() +
                          result?.status
                            .slice(1)
                            .replace("_", " ")
                            .replace("-", " ")
                            .replace("cra", "CRA")
                            .replace("qna", "QNA")
                            .replace("customs", "Customs")}
                      </b>
                      </span>
                    </span>
                    <div className="headerDetailsRightText">
                      <span style={{ color: "#989897" }}>{`${
                        allRequestsArray.indexOf(
                          findArrayElementById(
                            allRequestsArray,
                            currentRequestId
                          )
                        ) + 1
                      } of ${allRequestsArray.length}`}</span>
                      <img
                        src={previous}
                        className="previousNextImg"
                        onClick={handlePrevious}
                        style={{
                          opacity:
                            allRequestsArray.indexOf(
                              findArrayElementById(
                                allRequestsArray,
                                currentRequestId
                              )
                            ) == 0
                              ? 0.3
                              : 1,
                          cursor:
                            allRequestsArray.indexOf(
                              findArrayElementById(
                                allRequestsArray,
                                currentRequestId
                              )
                            ) != 0 && "pointer",
                        }}
                      />
                      <img
                        src={next}
                        className="previousNextImg"
                        onClick={handleNext}
                        style={{
                          opacity:
                            allRequestsArray.indexOf(
                              findArrayElementById(
                                allRequestsArray,
                                currentRequestId
                              )
                            ) ==
                            allRequestsArray.length - 1
                              ? 0.3
                              : 1,
                          cursor:
                            allRequestsArray.indexOf(
                              findArrayElementById(
                                allRequestsArray,
                                currentRequestId
                              )
                            ) !=
                              allRequestsArray.length - 1 && "pointer",
                        }}
                      />
                    </div>
                  </Layout>
                </div>
                <div className="progressDiv">
                  {/* <p className="purpletitle">Accrediation Status</p> */}

                  <div className="rowDiv">
                    
                    <div style={{ width: "70%" }}>
                      <Layout>
                        <div className="containerProgress">
                          <ul className="progressbarPermits">
                            <li
                              className={
                                result.status.toLowerCase() === "submitted" ||
                                result.status.toLowerCase() ===
                                  "approved_cra" ||
                                result.status.toLowerCase() ===
                                  "rejected_cra" ||
                                result.status.toLowerCase() ===
                                  "approved_customs" ||
                                result.status.toLowerCase() ===
                                  "rejected_customs" ||
                                result.status.toLowerCase() ===
                                  "approved_qna" ||
                                result.status.toLowerCase() ===
                                  "rejected_qna" ||
                                result.status.toLowerCase() === "entered" ||
                                result.status.toLowerCase() === "confiscated"
                                  ? "active"
                                  : ""
                              }
                            >
                              <div className="li-text">Submitted</div>
                            </li>
                            {result?.devices?.filter(
                              ({ is_wireless }) => is_wireless
                            ) &&
                            result?.devices?.filter(
                              ({ is_wireless }) => is_wireless
                            )?.length ? (
                              <li
                                className={
                                  result.status.toLowerCase() === "rejected_cra"
                                    ? "rejected"
                                    : result.status.toLowerCase() ===
                                        "approved_customs" ||
                                      result.status.toLowerCase() ===
                                        "rejected_customs" ||
                                      result.status.toLowerCase() ===
                                        "rejected_qna" ||
                                      result.status.toLowerCase() ===
                                        "approved_qna" ||
                                      result.status.toLowerCase() ===
                                        "approved_cra" ||
                                      result.status.toLowerCase() ===
                                        "entered" ||
                                      result.status.toLowerCase() ===
                                        "confiscated"
                                    ? "active"
                                    : ""
                                }
                              >
                                <div className="li-text">CRA</div>
                              </li>
                            ) : (
                              ""
                            )}

                            <li
                              className={
                                result.status.toLowerCase() === "rejected_qna"
                                  ? "rejected"
                                  : result.status.toLowerCase() ===
                                      "approved_customs" ||
                                    result.status.toLowerCase() ===
                                      "rejected_customs" ||
                                    result.status.toLowerCase() ===
                                      "approved_qna" ||
                                    result.status.toLowerCase() === "entered" ||
                                    result.status.toLowerCase() ===
                                      "confiscated"
                                  ? "active"
                                  : ""
                              }
                            >
                              <div className="li-text">QNA</div>
                            </li>

                            <li
                              className={
                                result.status.toLowerCase() ===
                                  "approved_qna" ||
                                result.status.toLowerCase() ===
                                  "approved_customs" ||
                                result.status.toLowerCase() === "entered" ||
                                result.status.toLowerCase() === "confiscated"
                                  ? "active"
                                  : ""
                              }
                            >
                              <div className="li-text">Cleared</div>
                            </li>
                            <li
                              className={
                                result.status.toLowerCase() === "confiscated"
                                  ? "rejected"
                                  : result.status.toLowerCase() === "entered"
                                  ? "active"
                                  : ""
                              }
                            >
                              <div className="li-text">Customs</div>
                            </li>
                          </ul>
                        </div>
                      </Layout>
                    </div>
                  </div>
                </div>

                <Layout>
                  <Layout.Section>
                    <div
                      style={{
                        borderRight: "0.1rem solid rgba(225, 227, 229, 1)",
                        paddingBottom: "2rem",
                      }}
                    >
                      <div style={{ maxWidth: "1000px" }}>
                        <FormLayout>
                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Applicant Name
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.application?.user?.first_name ||
                                  result?.application?.user?.last_name
                                    ? `${
                                        result?.application?.user?.first_name &&
                                        result?.application?.user?.first_name
                                      } ${
                                        result?.application?.user?.last_name &&
                                        result?.application?.user?.last_name
                                      }`
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  {result?.application?.passport_number
                                    ? `Passport Number`
                                    : "Document Number"}
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.application?.passport_number
                                    ? result?.application?.passport_number
                                    : result?.application?.document_number}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-4 Polaris-FormLayout__Item"></div>
                          </div>

                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Number of Devices
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.devices_quantity !== null
                                    ? result?.devices_quantity
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Date of Arrival to Qatar
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.arrival_date
                                    ? result?.arrival_date
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Date of Departure from Qatar
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.departure_date
                                    ? result?.departure_date
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="row Polaris-FormLayout g-0">
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Arrival Flight Number
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.arrival_flight_number
                                    ? result?.arrival_flight_number
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-4 Polaris-FormLayout__Item">
                              <div>
                                <div className="popupGreyText">
                                  Departure Flight Number
                                </div>
                                <p className="popupBlackText maxWidthText">
                                  {result?.departure_flight_number
                                    ? result?.departure_flight_number
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-4 Polaris-FormLayout__Item"></div>
                          </div>

                          {result?.devices &&
                            result?.devices.length &&
                            result?.devices.map(function (item, i) {
                              return (
                                <div className="permitBorder1 p-3" key={i}>
                                  <div className="orangetitle">
                                    {`Device No. ${i + 1}`}
                                  </div>
                                  <div className="row Polaris-FormLayout g-0">
                                    <div className="col-sm-4 Polaris-FormLayout__Item">
                                      <div>
                                        <div className="popupGreyText">
                                          Quantity
                                        </div>
                                        <p className="popupBlackText maxWidthText">
                                          {item?.quantity !== null
                                            ? item?.quantity
                                            : "N/A"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-sm-4 Polaris-FormLayout__Item">
                                      <div>
                                        <div className="popupGreyText">
                                          Category
                                        </div>
                                        <p className="popupBlackText maxWidthText">
                                          {item?.category !== null
                                            ? switchCategory(item?.category)
                                            : "N/A"}{" "}
                                          {item?.other_category &&
                                            `(${item?.other_category})`}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-sm-4 Polaris-FormLayout__Item">
                                      <div>
                                        <div className="popupGreyText">
                                          Item Name
                                        </div>
                                        <p className="popupBlackText maxWidthText">
                                          {item?.name ? item?.name : "N/A"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row Polaris-FormLayout g-0 mt-2">
                                    <div className="col-sm-4 Polaris-FormLayout__Item">
                                      <div>
                                        <div className="popupGreyText">
                                          Item Description
                                        </div>
                                        <p className="popupBlackText maxWidthText">
                                          {item?.description
                                            ? item?.description
                                            : "N/A"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-sm-4 Polaris-FormLayout__Item">
                                      <div>
                                        <div className="popupGreyText">
                                          Estimated Value
                                        </div>
                                        <p className="popupBlackText maxWidthText">
                                          {item?.estimated_value
                                            ? "$ " + item?.estimated_value
                                            : "N/A"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-sm-4 Polaris-FormLayout__Item">
                                      <div>
                                        <div className="popupGreyText">
                                          Serial Number
                                        </div>
                                        <p className="popupBlackText maxWidthText">
                                          {item?.item_serial_number
                                            ? item?.item_serial_number
                                            : "N/A"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {item?.wireless_details && (
                                    <div>
                                      <div className="purpleLightTitle mt-4">
                                        Wireless Details*
                                      </div>
                                      <div className="permitBorder2 p-3 mt-2">
                                        {/* <div className="orangetitle">Device No. 2</div> */}
                                        <div className="row Polaris-FormLayout g-0">
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Period of Operation From
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.period_of_operation?.from
                                                  ? moment(
                                                      item?.wireless_details
                                                        ?.period_of_operation
                                                        ?.from
                                                    ).format("YYYY-MM-DD")
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Period of Operation To
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.period_of_operation?.to
                                                  ? moment(
                                                      item?.wireless_details
                                                        ?.period_of_operation
                                                        ?.to
                                                    ).format("YYYY-MM-DD")
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Broadcaster Service Provider
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.broadcaster_service_provider
                                                  ? item?.wireless_details
                                                      ?.broadcaster_service_provider
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row Polaris-FormLayout g-0 mt-2">
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Preferred Frequency - Tx (MHz)
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.preferred_frequency?.tx
                                                  ? item?.wireless_details
                                                      ?.preferred_frequency?.tx
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Preferred Frequency - Rx (MHz)
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.preferred_frequency?.rx
                                                  ? item?.wireless_details
                                                      ?.preferred_frequency?.rx
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Tuning Range From: (MHz) *
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.tuning_range?.from
                                                  ? item?.wireless_details
                                                      ?.tuning_range?.from
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row Polaris-FormLayout g-0 mt-2">
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Tuning Range To: (MHz) *
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.tuning_range?.to
                                                  ? item?.wireless_details
                                                      ?.tuning_range?.to
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Channel Tuning step (MHz) *
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.channel_tuning_step !== null
                                                  ? item?.wireless_details
                                                      ?.channel_tuning_step
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Bandwidth (MHz) *
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.bandwidth !== null
                                                  ? item?.wireless_details
                                                      ?.bandwidth
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row Polaris-FormLayout g-0 mt-2">
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                EIRP
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details?.eirp
                                                  ? item?.wireless_details?.eirp
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Watts
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details?.watts
                                                  ? item?.wireless_details
                                                      ?.watts
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Equipment Model
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.equipment_model
                                                  ? item?.wireless_details
                                                      ?.equipment_model
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row Polaris-FormLayout g-0 mt-2">
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Modulation/ Class of Emission
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.modulation
                                                  ? item?.modulation
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Antenna Type
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.antenna_type
                                                  ? item?.wireless_details
                                                      ?.antenna_type
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Antenna Gain (dB)
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.antenna_gain
                                                  ? item?.wireless_details
                                                      ?.antenna_gain
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row Polaris-FormLayout g-0 mt-2">
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Equipment Manufacturer
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.equipment_manufacturer
                                                  ? item?.wireless_details
                                                      ?.equipment_manufacturer
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                Comments
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.comments
                                                  ? item?.wireless_details
                                                      ?.comments
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="col-sm-4 Polaris-FormLayout__Item">
                                            <div>
                                              <div className="popupGreyText">
                                                CRA Authorization No.
                                              </div>
                                              <p className="popupBlackText maxWidthText">
                                                {item?.wireless_details
                                                  ?.cra_authorization_number
                                                  ? item?.wireless_details
                                                      ?.cra_authorization_number
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          {result?.reason_of_permits_rejection && (
                            <FormLayout.Group>
                              <div style={{ marginBottom: "2rem" }}>
                                <div className="purpletitle">
                                  Reason of rejection
                                </div>

                                <p
                                  className="popupBlackText"
                                  style={{ cursor: "pointer" }}
                                >
                                  {result?.reason_of_permits_rejection}
                                </p>
                              </div>
                            </FormLayout.Group>
                          )}
                          {result?.reason_of_permits_approval && (
                            <FormLayout.Group>
                              <div style={{ marginBottom: "2rem" }}>
                                <div className="purpletitle">
                                  Comment by CRA
                                </div>

                                <p
                                  className="popupBlackText"
                                  style={{ cursor: "pointer" }}
                                >
                                  {result?.reason_of_permits_approval}
                                </p>
                              </div>
                            </FormLayout.Group>
                          )}
                          {result?.message && (
                            <FormLayout.Group>
                              <div style={{ marginBottom: "2rem" }}>
                                <div className="purpletitle">
                                  Comment by Customs
                                </div>

                                <p
                                  className="popupBlackText"
                                  style={{ cursor: "pointer" }}
                                >
                                  {result?.message}
                                </p>
                              </div>
                            </FormLayout.Group>
                          )}
                          {result?.reason_of_status_move && result?.status_level === 'CRA' && (
                              <FormLayout.Group>
                                <div style={{ marginBottom: "2rem" }}>
                                  <div className="purpletitle">
                                    Comment by QNA on Status Move
                                  </div>

                                  <p
                                      className="popupBlackText"
                                      style={{ cursor: "pointer" }}
                                  >
                                    {result?.reason_of_status_move}
                                  </p>
                                </div>
                              </FormLayout.Group>
                          )}
                        </FormLayout>
                      </div>
                    </div>
                  </Layout.Section>
                  {adminRole() !== "Viewer" && (
                    <ActionButtonsPermit
                      clickedItem={result}
                      handleRowApprove={handleRowApprove}
                      handleRowReject={handleRowReject}
                      handleEntered={handleEntered}
                      handleConfiscated={handleConfiscated}
                      // handleRowUnreject={handleRowUnreject}
                      handleDownload={handleDownload}
                      reasonOfRejectionValue={reasonOfRejectionValue}
                      handleChangeReasonOfRejection={
                        handleChangeReasonOfRejection
                      }
                      handleEmail={handleEmail}
                      handleToggleStatus={handleToggleStatus}
                      handleRegisterToCra={handleRegisterToCra}
                      // status={switchStatus()}
                      // handleSync={handleSync}
                      // handleDeleteAccount={handleDeleteAccount}
                      // handleDeleteApplication={handleDeleteApplication}
                    />
                  )}
                </Layout>
              </div>
            )}
          </div>
        )}
        {/* <Card sectioned> */}

        {/* </Card> */}
        <Modal open={activeAreYouSure}>
          <Modal.Section>
            <div
              className="popupBlackText"
              style={{ marginBottom: "4rem", fontSize: "1.6rem" }}
            >
              {questionValue}
            </div>
            {(reference.current.split("strtosplit")[1] === "reject" ||
              (reference.current.split("strtosplit")[1] === "approve" &&
                window.location.href.indexOf(
                  "/admin/equipment-clearance/cra-permits"
                ) > -1)) && (
              <FormLayout>
                <div className="popupGreyText">
                  {reference.current.split("strtosplit")[1] === "reject"
                    ? "Reason"
                    : "Comments"}
                </div>
                <TextField
                  multiline={4}
                  value={reasonOfRejectionValue}
                  onChange={handleChangeReasonOfRejection}
                  error={reasonError}
                ></TextField>
              </FormLayout>
            )}
            <div
              className="rowDiv"
              style={{
                float: "right",
                marginTop: "3rem",
                marginBottom: "2rem",
              }}
            >
              <button
                className={`button ${
                  isSendingEmail ? "buttonDisabled" : "buttonApprove"
                }`}
                onClick={handleYes}
              >
                Yes
              </button>
              <button className="button buttonEmail" onClick={handleNo}>
                No
              </button>
            </div>
          </Modal.Section>
        </Modal>

        <Modal open={activeEmail}>
          <Modal.Section>
            <FormLayout>
              <div className="popupGreyText">Type your message here</div>
              {/* <TextField
              multiline={4}
              value={emailMessageValue}
              onChange={handleChangeEmailMessage}
              error={emailError}
              maxLength={200}
            ></TextField> */}

              <div className="Polaris-TextField Polaris-TextField--multiline">
                <textarea
                  id="PolarisTextField6"
                  className="Polaris-TextField__Input"
                  maxLength={200}
                  aria-labelledby="PolarisTextField6Label"
                  aria-invalid="false"
                  aria-multiline="true"
                  style={{ height: 106 }}
                  defaultValue={""}
                  value={emailMessageValue}
                  onChange={handleChangeEmailMessage}
                  dir={`${
                    /[\u0600-\u06FF]/.test(emailMessageValue) ? "rtl" : "ltr"
                  }`}
                />
                <div className="Polaris-TextField__Backdrop" />
                <div aria-hidden="true" className="Polaris-TextField__Resizer">
                  <div className="Polaris-TextField__DummyInput">
                    <br />
                  </div>
                  <div className="Polaris-TextField__DummyInput">
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              {emailError ? (
                <InlineError message={emailError}></InlineError>
              ) : null}
            </FormLayout>

            <div
              className="rowDiv"
              style={{
                float: "right",
                marginTop: "3rem",
                marginBottom: "2rem",
              }}
            >
              <button
                className={`button ${
                  isSendingEmail ? "buttonDisabled" : "buttonApprove"
                }`}
                onClick={!isSendingEmail && handleSend}
              >
                Send
              </button>
              <button className="button buttonEmail" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </Modal.Section>
        </Modal>

        <Modal open={toggleStatus}>
          <Modal.Section>
            <FormLayout>
              <div className="popupGreyText">MOVE: Type your message here</div>
              <div className="Polaris-TextField Polaris-TextField--multiline">
                <textarea
                  id="PolarisTextField6"
                  className="Polaris-TextField__Input"
                  maxLength={200}
                  aria-labelledby="PolarisTextField6Label"
                  aria-invalid="false"
                  aria-multiline="true"
                  style={{ height: 106 }}
                  defaultValue={""}
                  value={reasonOfChangeStatusValue}
                  onChange={(e) => setReasonOfChangeStatusValue(e.target.value)}
                  dir={`${
                    /[\u0600-\u06FF]/.test(reasonOfChangeStatusValue) ? "rtl" : "ltr"
                  }`}
                />
                <div className="Polaris-TextField__Backdrop" />
                <div aria-hidden="true" className="Polaris-TextField__Resizer">
                  <div className="Polaris-TextField__DummyInput">
                    <br />
                  </div>
                  <div className="Polaris-TextField__DummyInput">
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </FormLayout>

            <div
              className="rowDiv"
              style={{
                float: "right",
                marginTop: "3rem",
                marginBottom: "2rem",
              }}
            >
              <button
                className={`button ${
                  isChangingStatus ? "buttonDisabled" : "buttonApprove"
                }`}
                onClick={!isChangingStatus && handleChangeStatus}
              >
                Move
              </button>
              <button className="button buttonEmail" onClick={handleToggleStatus}>
                Cancel
              </button>
            </div>
          </Modal.Section>
        </Modal>

        <Modal open={toggleRegisterToCra}>
          <Modal.Section>
            <FormLayout>
              <div className="popupGreyText">Are you sure you want to send an email?</div>
              <div className="Polaris-TextField Polaris-TextField--multiline">
                <div className="Polaris-TextField__Backdrop" />
                <div aria-hidden="true" className="Polaris-TextField__Resizer">
                  <div className="Polaris-TextField__DummyInput">
                    <br />
                  </div>
                  <div className="Polaris-TextField__DummyInput">
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </FormLayout>

            <div
              className="rowDiv"
              style={{
                float: "right",
                marginTop: "3rem",
                marginBottom: "2rem",
              }}
            >
              <button
                className={`button ${
                  isChangingStatus ? "buttonDisabled" : "buttonApprove"
                }`}
                onClick={!isChangingStatus && handleRegisterToCraEmail}
              >
                Send
              </button>
              <button className="button buttonEmail" onClick={handleRegisterToCra}>
                Cancel
              </button>
            </div>
          </Modal.Section>
        </Modal>

        <Modal open={activeCustomsButtons}>
          <Modal.Section>
            {/* <div
              className="popupBlackText"
              style={{ marginBottom: "4rem", fontSize: "1.6rem" }}
            >
              {questionValue}
            </div> */}

            <FormLayout>
              <div className="popupGreyText">Comment</div>
              <TextField
                multiline={4}
                value={commentValue}
                onChange={handleChangeComment}
                error={commentError}
              ></TextField>
            </FormLayout>

            <div
              className="rowDiv"
              style={{
                float: "right",
                marginTop: "3rem",
                marginBottom: "2rem",
              }}
            >
              <button
                className={`button ${
                  isSendingEmail ? "buttonDisabled" : "buttonApprove"
                }`}
                onClick={handleSubmitCustomsAction}
              >
                Submit
              </button>
              <button
                className="button buttonEmail"
                onClick={handleCancelCustomsAction}
              >
                Cancel
              </button>
            </div>
          </Modal.Section>
        </Modal>
        <Modal open={popupActive} loading={true}></Modal>

        {toastMarkup}
      </Page>
    </NavigationLayout>
  );
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element) === parseInt(id);
    });
  }
  function findArrayElementByCode(array, id) {
    return array.find((element, index) => {
      return parseInt(element.code) === parseInt(id);
    });
  }
};
export default PermitDetails;
