import { Card, Tabs, Page } from "@shopify/polaris";
import { useState, useCallback } from "react";
import NavigationLayout from "../../Components/NavigationLayout";
import IncompleteApplications from "./IncompleteApplications";
import TableOfRequests from "../Requests/TableOfRequests";

function Users() {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "incomplete",
      content: "Incomplete Applications",
    },
    {
      id: "completed",
      content: "Completed Applications",
    },
  ];

  return (
    <NavigationLayout>
      <Page fullWidth>
        <Card>
          <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
            {selected === 0 && <IncompleteApplications />}
            {selected === 1 && <TableOfRequests selectedTab="0" />}
          </Tabs>
        </Card>
      </Page>
    </NavigationLayout>
  );
}

export default Users;
