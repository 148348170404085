import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Select,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  TextStyle,
  DropZone,
  Thumbnail,
  PageActions,
  TextField,
  FormLayout,
  Button,
  Page,
  Card,
  Stack,
  Caption,
  InlineError,
  Modal,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import NavigationLayout from "../../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";

const AddMedia = () => {
  let url = "";
  const { BlobServiceClient } = require("@azure/storage-blob");
  const refImage = useRef("");
  const { id } = useParams();
  const [popupActive, setPopupActive] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const [titleValue, setTitleValue] = useState("");
  const handleChangeTitle = useCallback((newValue) => {
    setTitleValue(newValue);
    setTitleError("");
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleArError("");
  }, []);
  const [titleError, setTitleError] = useState("");
  const [titleArError, setTitleArError] = useState("");
  const [dateValue, setDateValue] = useState("");
  const handleChangeDate = (newValue) => setDateValue(newValue);
  const [sourceValue, setSourceValue] = useState("");
  const handleChangeSource = (newValue) => setSourceValue(newValue);
  const [sourceArValue, setSourceArValue] = useState("");
  const handleChangeSourceAr = (newValue) => setSourceArValue(newValue);
  const [locationValue, setLocationValue] = useState("");
  const handleChangeLocation = (newValue) => setLocationValue(newValue);
  const [locationArValue, setLocationArValue] = useState("");
  const handleChangeLocationAr = (newValue) => setLocationArValue(newValue);
  const [licenseValue, setLicenseValue] = useState("");
  const handleChangeLicense = (newValue) => setLicenseValue(newValue);
  const [licenseArValue, setLicenseArValue] = useState("");
  const handleChangeLicenseAr = (newValue) => setLicenseArValue(newValue);
  const [videoIdValue, setVideoIdValue] = useState("");
  const [imageKey, setImageKey] = useState("");
  const [thumbnailKey, setThumbnailKey] = useState("");
  const handleChangeVideoId = useCallback((newValue) => {
    setVideoIdValue(newValue);
    setImageError("");
  }, []);
  const [filesMain, setFilesMain] = useState([]);
  const [imageError, setImageError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const handleDropZoneMain = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesMain((filesMain) => [...filesMain, ...acceptedFiles]),
    []
  );
  const fileUploadMain = !filesMain.length && <DropZone.FileUpload />;
  let uploadedFilesMain = filesMain.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesMain[filesMain.length - 1].name}
        source={
          validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
            ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesMain[filesMain.length - 1].name}{" "}
        <Caption>{filesMain[filesMain.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageMain = (filesMain) => {
    setPopupActive(true);
    console.log("filesMain=", filesMain[filesMain.length - 1]);
    axios
      .get(`admin/v1/sas/${filesMain[filesMain.length - 1].name}`)
      .then((result) => {
        console.log(result.data.data);
        url = result.data.data;
        const type = "photo";
        fctAzure(filesMain, url, "photo");
      })
      .catch((err) => console.log(""));
  };

  const [filesThumbnail, setFilesThumbnail] = useState([]);
  const [thumbnailError, setThumbnailError] = useState("");
  const [thumbnailPreview, setThumbnailPreview] = useState("");
  const handleDropZoneThumbnail = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesThumbnail((filesThumbnail) => [
        ...filesThumbnail,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadThumbnail = !filesThumbnail.length && <DropZone.FileUpload />;
  let uploadedFilesThumbnail = filesThumbnail.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesThumbnail[filesThumbnail.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesThumbnail[filesThumbnail.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesThumbnail[filesThumbnail.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesThumbnail[filesThumbnail.length - 1].name}{" "}
        <Caption>
          {filesThumbnail[filesThumbnail.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageThumbnail = (filesThumbnail) => {
    setPopupActive(true);
    console.log("filesThumbnail=", filesThumbnail[filesThumbnail.length - 1]);
    axios
      .get(`admin/v1/sas/${filesThumbnail[filesThumbnail.length - 1].name}`)
      .then((result) => {
        console.log(result.data.data);
        url = result.data.data;
        const type = "thumbnail";
        fctAzure(filesThumbnail, url, type);
      })
      .catch((err) => console.log(""));
  };

  const fctAzure = async (files, url, type) => {
    const CONTAINER_NAME = "photos";
    //GET sasURL from API?

    const blobServiceClient = new BlobServiceClient(url);
    const containerClient =
      blobServiceClient.getContainerClient(CONTAINER_NAME);
    const filename = files[files.length - 1].name.substring(
      0,
      files[files.length - 1].name.lastIndexOf(".")
    );
    const ext = files[files.length - 1].name.substring(
      files[files.length - 1].name.lastIndexOf(".")
    );
    const blobName = filename + "_" + new Date().getTime() + ext;
    type === "photo" ? setImageKey(blobName) : setThumbnailKey(blobName);
    console.log("test=", blobName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
      files[files.length - 1]
    );
    console.log(
      `Upload block blob ${files[files.length - 1].name} successfully`,
      uploadBlobResponse.clientRequestId
    );
    type === "photo"
      ? setImagePreview(
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        )
      : setThumbnailPreview(
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        );
    type === "photo" ? setImageError("") : setThumbnailError("");

    setPopupActive(false);
  };
  // const sendImageMain = (filesMain) => {
  //   setIsSaving(true);
  //   const form_data = new FormData();
  //   filesMain[filesMain.length - 1] &&
  //     form_data.append("image", filesMain[filesMain.length - 1]);
  //   axios
  //     .post("admin/v1/upload-image", form_data)
  //     .then((res) => {
  //       console.log(res.data.url);
  //       refImage.current = res.data.url;
  //       setIsSaving(false);
  //       setImagePreview(
  //         validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
  //           ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
  //           : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
  //       );
  //       setImageError("");
  //     })
  //     .catch((err) => console.log(""));
  // };
  const [selectedType, setSelectedType] = useState("1");
  const [typeError, setTypeError] = useState("");
  const handleSelectChangeType = useCallback((newValue) => {
    setSelectedType(newValue);
    setTypeError("");
  }, []);
  const optionsType = [
    { label: "Photo", value: "1" },
    { label: "Video", value: "2" },
  ];
  const [optionsTags, setOptionsTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  useEffect(() => {
    axios
      .get(`admin/v1/tags`)
      .then((result) => {
        console.log(result?.data.data.data);
        result?.data?.data?.data &&
          setOptionsTags(
            result?.data?.data?.data?.map((item, index) => {
              return {
                label: item.title[Object.keys(item.title)[0]],
                value: String(item.id),
                key: String(item.id),
              };
            })
          );
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <NavigationLayout>
      <Page
        fullWidth
        breadcrumbs={[
          {
            onAction: () =>
              navigate(`/admin/photos-and-videos/albums/${id}/medias`),
          },
        ]}
        title="Add Media"
      >
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Media Type</TextStyle>
              </p>
              <Select
                options={optionsType}
                onChange={handleSelectChangeType}
                value={selectedType}
                placeholder="Please Select"
                error={typeError}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Date</TextStyle>
              </p>
              <TextField
                value={dateValue}
                onChange={handleChangeDate}
                type="date"
              />
            </FormLayout>
          </FormLayout.Group>
          <p className="greyText">
            <TextStyle>Thumbnail</TextStyle>
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={thumbnailPreview ? thumbnailPreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone
                onDrop={handleDropZoneThumbnail}
                onDropAccepted={sendImageThumbnail}
                // variableHeight
              >
                {uploadedFilesThumbnail}
                {fileUploadThumbnail}
              </DropZone>
            </div>
          </div>
          <InlineError message={thumbnailError}></InlineError>
          <p className="greyText">
            <TextStyle>{selectedType === "1" ? "Photo" : "Video ID"}</TextStyle>
          </p>
          {selectedType === "1" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div style={{ float: "left" }}>
                <Thumbnail
                  size="large"
                  source={imagePreview ? imagePreview : ""}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                <DropZone
                  onDrop={handleDropZoneMain}
                  onDropAccepted={sendImageMain}
                  // variableHeight
                >
                  {uploadedFilesMain}
                  {fileUploadMain}
                </DropZone>
              </div>
            </div>
          ) : (
            <TextField value={videoIdValue} onChange={handleChangeVideoId} />
          )}
          <InlineError message={imageError}></InlineError>

          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Title En</TextStyle>
              </p>
              <TextField
                value={titleValue}
                onChange={handleChangeTitle}
                error={titleError}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Title Ar</TextStyle>
              </p>
              <TextField
                value={titleArValue}
                onChange={handleChangeTitleAr}
                error={titleArError}
              />
            </FormLayout>
            {/* <FormLayout>
                <p className="greyText">
                  <TextStyle>Date</TextStyle>
                </p>
                <TextField
                  value={dateValue}
                  onChange={handleChangeDate}
                  type="date"
                />
              </FormLayout> */}
          </FormLayout.Group>
          {/* <InlineError message={titleError} /> */}

          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Source En</TextStyle>
              </p>
              <TextField value={sourceValue} onChange={handleChangeSource} />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Source Ar</TextStyle>
              </p>
              <TextField
                value={sourceArValue}
                onChange={handleChangeSourceAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Location En</TextStyle>
              </p>
              <TextField
                value={locationValue}
                onChange={handleChangeLocation}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Location Ar</TextStyle>
              </p>
              <TextField
                value={locationArValue}
                onChange={handleChangeLocationAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>License En</TextStyle>
              </p>
              <TextField value={licenseValue} onChange={handleChangeLicense} />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>License Ar</TextStyle>
              </p>
              <TextField
                value={licenseArValue}
                onChange={handleChangeLicenseAr}
              />
            </FormLayout>
          </FormLayout.Group>

          <p className="greyText">
            <TextStyle>Tags</TextStyle>
          </p>
          <MultiSelect
            options={optionsTags}
            value={selectedTags}
            onChange={setSelectedTags}
            labelledBy="Select"
          />

          {/* <Button primary>Save</Button> */}
        </FormLayout>
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
        />
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
  function handleSave() {
    if (
      !titleValue ||
      !titleArValue ||
      !selectedType ||
      (selectedType === "1" && !imageKey) ||
      (selectedType === "2" && !videoIdValue) ||
      !thumbnailKey
    ) {
      !titleValue && setTitleError("Please enter a title");
      !titleArValue && setTitleArError("Please enter an arabic title");
      !selectedType && setTypeError("Please select a type");
      selectedType === "1" &&
        !imageKey &&
        setImageError("Please upload an image");
      selectedType === "2" &&
        !videoIdValue &&
        setImageError("Please enter a video id");
      !thumbnailKey && setThumbnailError("Please upload a thumbnail");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      selectedType && form_data.append("type", selectedType);
      titleValue && form_data.append("title[en]", titleValue);
      titleArValue && form_data.append("title[ar]", titleArValue);
      dateValue && form_data.append("date", dateValue);
      sourceValue && form_data.append("source[en]", sourceValue);
      sourceArValue && form_data.append("source[ar]", sourceArValue);
      locationValue && form_data.append("location[en]", locationValue);
      locationArValue && form_data.append("location[ar]", locationArValue);
      licenseValue && form_data.append("license[en]", licenseValue);
      licenseArValue && form_data.append("license[ar]", licenseArValue);
      selectedTags.forEach((selected, index) =>
        form_data.append(`tags[${index}]`, selected.value)
      );
      selectedType === "1" && imageKey && form_data.append("path", imageKey);
      selectedType === "2" &&
        videoIdValue &&
        form_data.append("path", videoIdValue);
      thumbnailKey && form_data.append("thumbnail", thumbnailKey);
      axios
        .post(`admin/v1/albums/${id}/media`, form_data)
        .then((res) => {
          navigate(`/admin/photos-and-videos/albums/${id}/medias`);
        })
        .catch((err) => console.log(""));
    }
  }
};

export default AddMedia;
