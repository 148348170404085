import React from "react";
import Grid from "@material-ui/core/Grid";
import { Page, Icon, Card, TextStyle } from "@shopify/polaris";
import {
  ListMajor,
  ImagesMajor,
  CollectionsMajor,
  CategoriesMajor,
  ChatMajor,
} from "@shopify/polaris-icons";
import history from "../../Assets/Lib/history";
import NavigationLayout from "../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";

const Gridinterviewees = () => {
  const navigate = useNavigate();

  return (
    <NavigationLayout>
      <Page>
        <Card>
          <div style={{ padding: 20 }}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <div
                  style={{
                    padding: 10,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  className="gridItem"
                  onMouseDown={(e) =>
                    navigate("/admin/manage-interviewees/interviewees")
                  }
                >
                  <div className="iconDiv">
                    <Icon
                      source={ChatMajor}
                      style={{ margin: "0 5px" }}
                      color="subdued"
                    ></Icon>
                  </div>
                  <div style={{ flexDirection: "column", marginLeft: "10" }}>
                    <div className="titleDev">
                      <TextStyle>Interviewees</TextStyle>
                    </div>
                    <div className="description">
                      <TextStyle></TextStyle>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div
                  style={{
                    padding: 10,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  className="gridItem"
                  onMouseDown={(e) =>
                    navigate("/admin/manage-interviewees/positions")
                  }
                >
                  <div className="iconDiv">
                    <Icon
                      source={CategoriesMajor}
                      style={{ margin: "0 5px" }}
                      color="subdued"
                    ></Icon>
                  </div>
                  <div style={{ flexDirection: "column", marginLeft: "10" }}>
                    <div className="titleDev">
                      <TextStyle>Positions</TextStyle>
                    </div>
                    <div className="description">
                      <TextStyle></TextStyle>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Page>
    </NavigationLayout>
  );
};
export default Gridinterviewees;
