import React, { useCallback, useState } from "react";
import {
  Checkbox,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import NavigationLayout from "../../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";

const AddCategory = () => {
  const navigate = useNavigate();
  const [nameEnValue, setNameEnValue] = useState("");
  const handleChangeNameEn = useCallback((newValue) => {
    setNameEnValue(newValue);
    setNameError("");
  }, []);
  const [nameArValue, setNameArValue] = useState("");
  const handleChangeNameAr = useCallback((newValue) => {
    setNameArValue(newValue);
    setNameError("");
  }, []);
  const [nameError, setNameError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  return (
    <NavigationLayout>
      <Page
        fullWidth
        title="Add Category"
        breadcrumbs={[
          { onAction: () => navigate("/admin/photos-and-videos/categories") },
        ]}
      >
        {isSaving ? <Loading /> : null}
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={nameEnValue}
                onChange={handleChangeNameEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={nameArValue}
                onChange={handleChangeNameAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={nameError} />
        </FormLayout>
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
        />
      </Page>
    </NavigationLayout>
  );

  function handleSave() {
    if (!nameEnValue && !nameArValue) {
      setNameError("Please enter a title");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      nameEnValue && form_data.append("title[en]", nameEnValue);
      nameArValue && form_data.append("title[ar]", nameArValue);

      axios
        .post("admin/v1/categories", form_data)
        .then((res) => {
          navigate("/admin/photos-and-videos/categories");
        })
        .catch((err) => console.log(""));
    }
  }
};
export default AddCategory;
