import React, { useCallback, useState, useEffect } from "react";
import {
  Checkbox,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { useParams } from "react-router-dom";
import history from "../../../Assets/Lib/history";
import NavigationLayout from "../../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";

const EditCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const handleChangeNameEn = (newValue) => {
    setTag({ ...tag, nameEn: newValue });
    setNameError("");
  };
  const handleChangeNameAr = (newValue) => {
    setTag({ ...tag, nameAr: newValue });
    setNameError("");
  };
  const [nameError, setNameError] = useState("");
  const [isSaving, setIsSaving] = useState(true);

  const [tag, setTag] = useState({
    nameEn: "",
    nameAr: "",
  });
  useEffect(() => {
    axios
      .get(`admin/v1/categories/${id}`)
      .then((result) => {
        console.log(result.data.data);
        setTag({
          nameEn: result?.data?.data?.title?.en
            ? result.data.data?.title.en
            : "",
          nameAr: result?.data?.data?.title?.ar
            ? result.data.data?.title.ar
            : "",
        });
        setIsSaving(false);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <NavigationLayout>
      <Page
        fullWidth
        title="Edit Category"
        breadcrumbs={[
          { onAction: () => navigate("/admin/photos-and-videos/categories") },
        ]}
      >
        {isSaving ? <Loading /> : null}
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Title En</TextStyle>
                <TextField
                  value={tag.nameEn}
                  onChange={handleChangeNameEn}
                  align="left"
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Title Ar</TextStyle>
                <TextField
                  value={tag.nameAr}
                  onChange={handleChangeNameAr}
                  align="right"
                />
              </FormLayout>
            </FormLayout.Group>
            <InlineError message={nameError} />
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
          secondaryActions={[
            {
              content: <p style={{ color: "white" }}>Delete</p>,
              destructive: true,
              loading: isSaving && true,
              onClick: () => {
                setIsSaving(true);
                axios
                  .delete(`admin/v1/categories/${id}/delete`)
                  .then((result) => {
                    navigate(`/admin/photos-and-videos/categories`);
                  })
                  .catch((err) => console.log(err));
              },
            },
          ]}
        />
      </Page>
    </NavigationLayout>
  );

  function handleSave() {
    if (!tag.nameEn && !tag.nameAr) {
      !tag.nameEn && !tag.nameAr && setNameError("Please enter a name");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("id", id);
      tag.nameEn && form_data.append("title[en]", tag.nameEn);
      tag.nameAr && form_data.append("title[ar]", tag.nameAr);
      form_data.append("_method", "PATCH");
      axios
        .post(`admin/v1/categories/${id}/update`, form_data)
        .then((res) => {
          navigate("/admin/photos-and-videos/categories");
        })
        .catch((err) => console.log(""));
    }
  }
};
export default EditCategory;
