import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  TextStyle,
  Spinner,
  Toast,
  Modal,
  TextField,
  FormLayout,
  Page,
  Card,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import emptyListImage from "../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import rowMail from "../../Assets/Images/row-mail.svg";
import rowAccept from "../../Assets/Images/row-accept.svg";
import rowReject from "../../Assets/Images/row-reject.svg";
import flag from "../../Assets/Images/flag.svg";
import flagRed from "../../Assets/Images/flag-red.svg";
import Cookies from "js-cookie";
import filterGrey from "../../Assets/Images/filter-grey.svg";
import filterOrange from "../../Assets/Images/filter-orange.svg";
import { useNavigate } from "react-router-dom";

const IncompleteApplications = (props) => {
  const navigate = useNavigate();
  const [ts, setTs] = useState("");

  const ts0 = useRef(null);
  const ts1 = useRef(null);
  const ts2 = useRef(null);
  const ts3 = useRef(null);
  const ts4 = useRef(null);
  const ts5 = useRef(null);
  const ts6 = useRef(null);
  const ts7 = useRef(null);
  const ts8 = useRef(null);
  const format = "YYYY-MM-DD HH:mm:ss";
  const reference = useRef("");
  const mailIdRef = useRef("");
  const perPage = 20;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const [filterState, setFilterState] = useState("");
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    getData();
  }, [page, filterState, searchValue, ts]);
  const [isHovered, setIsHovered] = useState(false);
  const handleCancel = () => {
    setActiveEmail(false);
  };
  const [emailMessageValue, setEmailMessageValue] = useState("");
  const handleChangeEmailMessage = useCallback(
    (newValue) => setEmailMessageValue(newValue),
    []
  );

  const handleSend = () => {
    const form_data = new FormData();

    form_data.append("message", emailMessageValue);
    form_data.append("_method", "PATCH");

    axios
      .post(
        `admin/v1/registration-requests/${mailIdRef.current}/email`,
        form_data
      )
      .then((res) => {
        setToastContent("Email Sent Successfully");
        toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => console.log(""));
  };

  const handleFlag = (e) => {
    // isFlagged ? setIsFlagged(false) : setIsFlagged(true);
    const form_data = new FormData();
    form_data.append("_method", "PATCH");
    form_data.append(
      "is_flagged",
      !JSON.parse(e.currentTarget.id).is_flagged ? 1 : 0
    );
    axios
      .post(
        `admin/v1/registration-requests/${
          JSON.parse(e.currentTarget.id).id
        }/flag`,
        form_data
      )
      .then((res) => {
        setTs(+new Date());
        //getData();
      })
      .catch((err) => console.log(""));
  };
  const switchStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#4B4B4B";
        break;
      case "approved":
        return "#00CFB7";
        break;
      case "rejected":
        return "#B85555";
        break;
      case "updated":
        return "#FFBF00";
        break;
      case "new":
        return "#FF4700";
        break;
      default:
      // code block
    }
  };

  // useEffect(() => {
  //   axios
  //     .get(`/admin/v1/dashboard`)
  //     .then((result) => {
  //       Cookies.set(
  //         "sumPendingRequests",
  //         result.data.data.total_registration_statistics.new
  //       );
  //       props?.onTotalRequestsChange(
  //         result.data.data.total_registration_statistics.new
  //       );
  //       // window.location.reload(true);
  //     })
  //     .catch((err) => console.log(err));
  // }, [ts]);
  const [activeEmail, setActiveEmail] = useState(false);
  const handleEmail = (e) => {
    mailIdRef.current = JSON.parse(e.currentTarget.id).id;
    setActiveEmail(true);
  };

  const getData = () => {
    // axios
    //   .get(`admin/v1/registration-requests?per_page=100000`)
    //   .then((result) => {
    //     let int = 0;
    //     for (var i = 0; i < result.data.data.data.length; i++) {
    //       result.data.data.data[i].status === "new" && int++;
    //     }
    //     Cookies.set("sumPendingRequests", int);
    //     props.onTotalRequestsChange(int);
    //   })
    //   .catch((err) => console.log(err));

    axios
      .get(
        `admin/v1/signed-up-requests?per_page=${perPage}&page=${page}${
          searchValue && `&filter[${filterState}]=${searchValue.toLowerCase()}`
        }`
      )
      .then((result) => {
        setIsLoading(false);
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        let array = [];
        for (var i = 0; i < result?.data?.data?.data.length; i++) {
          const item = result?.data?.data?.data[i];
          array.push([
            item?.reference_number ? (
              <div
                className={`itemClick ${
                  item?.status != "new" ? "bookFont" : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                }}
              >
                {item?.reference_number}
              </div>
            ) : (
              <div
                className={`itemClick ${
                  item?.status != "new" ? "bookFont" : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                  color: "rgba(196, 196, 188, 0)",
                }}
              >
                {"empty"}
              </div>
            ),
            item?.first_name || item?.last_name ? (
              <div
                className={`itemClick ${
                  item?.status != "new" ? "bookFont" : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                }}
              >
                {`${item.first_name && item.first_name} ${
                  item.last_name && item.last_name
                }`}
              </div>
            ) : (
              <div
                className={`itemClick ${
                  item?.status != "new" ? "bookFont" : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                  color: "rgba(196, 196, 188, 0)",
                }}
              >
                {"empty"}
              </div>
            ),
            item?.email ? (
              <div
                className={`itemClick ${
                  item?.status != "new" ? "bookFont" : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                }}
              >
                {item?.email}
              </div>
            ) : (
              <div
                className={`itemClick ${
                  item?.status != "new" ? "bookFont" : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                  color: "rgba(196, 196, 188, 0)",
                }}
              >
                {"empty"}
              </div>
            ),
            item?.organisation?.role?.type?.en ? (
              <div
                className={`itemClick ${
                  item?.status != "new" ? "bookFont" : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                }}
              >
                {item?.organisation?.role?.type?.en}
              </div>
            ) : (
              <div
                className={`itemClick ${
                  item?.status != "pending" ? "bookFont" : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                  color: "rgba(196, 196, 188, 0)",
                }}
              >
                {"empty"}
              </div>
            ),
            item?.organisation?.name ? (
              <div
                className={`itemClick ${
                  item?.status != "new" ? "bookFont" : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                }}
              >
                {item?.organisation.name}
              </div>
            ) : (
              <div
                className={`itemClick ${
                  item?.status != "new" ? "bookFont" : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                  color: "rgba(196, 196, 188, 0)",
                }}
              >
                {"empty"}
              </div>
            ),
            // item?.nationality ? (
            //   <div
            //     className={`itemClick ${
            //       item?.status != "new" ? "bookFont" : "mediumFont"
            //     }`}
            //     id={JSON.stringify(item)}
            //     onClick={(e) => handleRowClick(e)}
            //     style={{
            //       backgroundColor:
            //         item.status === "new" && "rgba(196, 196, 188, 0.3)",
            //     }}
            //   >
            //     {item?.nationality}
            //   </div>
            // ) : (
            //   <div
            //     className={`itemClick ${
            //       item?.status != "new" ? "bookFont" : "mediumFont"
            //     }`}
            //     id={JSON.stringify(item)}
            //     onClick={(e) => handleRowClick(e)}
            //     style={{
            //       backgroundColor:
            //         item.status === "new" && "rgba(196, 196, 188, 0.3)",
            //       color: "rgba(196, 196, 188, 0)",
            //     }}
            //   >
            //     {"empty"}
            //   </div>
            // ),
            // item?.phone && (
            //   <div
            //     className={`itemClick ${
            //       item?.status != "pending" ? "bookFont" : "mediumFont"
            //     }`}
            //     id={JSON.stringify(item)}
            //     onClick={(e) => handleRowClick(e)}
            //     style={{
            //       backgroundColor:
            //         item.status === "pending" && "rgba(196, 196, 188, 0.3)",
            //     }}
            //   >
            //     {item?.phone}
            //   </div>
            // ),
            item?.created_at ? (
              <div
                className={`itemClick ${
                  item?.status != "new" ? "bookFont" : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                }}
              >
                {moment(item.created_at).format(format)}
              </div>
            ) : (
              <div
                className={`itemClick ${
                  item?.status != "new" ? "bookFont" : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                  color: "rgba(196, 196, 188, 0)",
                }}
              >
                {"empty"}
              </div>
            ),

            <div
              className={`itemClick ${
                item?.status != "new" ? "bookFont" : "mediumFont"
              }`}
              id={JSON.stringify(item)}
              onClick={(e) => handleRowClick(e)}
              style={{
                color: switchStatusColor(item.status),

                backgroundColor:
                  item.status === "new" && "rgba(196, 196, 188, 0.3)",
              }}
            >
              {item?.status
                ? item?.status.charAt(0).toUpperCase() + item?.status.slice(1)
                : ""}
            </div>,
            <div
              style={{
                backgroundColor:
                  item.status === "new" && "rgba(196, 196, 188, 0.3)",
              }}
            ></div>,
            item.is_flagged ? (
              <div
                className={`itemClick ${
                  item?.status != "new" ? "bookFont" : "mediumFont"
                }`}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                  marginRight: "2rem",
                }}
              >
                <img
                  src={flagRed}
                  className="flagImg"
                  onClick={(e) => handleFlag(e)}
                  id={JSON.stringify(item)}
                />
              </div>
            ) : (
              <div
                className={`itemClick ${
                  item?.status != "new" ? "bookFont" : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    item.status === "new" && "rgba(196, 196, 188, 0.3)",
                  color: "rgba(196, 196, 188, 0)",
                }}
              >
                {"empty"}
              </div>
            ),
          ]);
        }
        array.unshift([
          <div
            className="itemClick bookFont searchDiv"
            id="firstTableItem0"
            style={{ display: "none" }}
          >
            <div>
              <div className="Polaris-Connected">
                <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                  <div className="Polaris-TextField">
                    <input
                      id="PolarisTextField2"
                      className="Polaris-TextField__Input"
                      aria-labelledby="PolarisTextField2Label"
                      aria-invalid="false"
                      defaultValue=""
                      onChange={handleChangeReferenceSearchValue}
                      ref={ts0}
                    />
                    <button
                      type="button"
                      className="Polaris-TextField__ClearButton"
                      tabIndex={0}
                      onClick={() => handleDeleteTextField(ts0)}
                      style={{
                        visibility:
                          searchValue !== "" &&
                          filterState === "reference_number"
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      <span className="Polaris-VisuallyHidden" />
                      <span className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
                        <svg
                          viewBox="0 0 20 20"
                          className="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM7.707 6.293a1 1 0 0 0-1.414 1.414L8.586 10l-2.293 2.293a1 1 0 1 0 1.414 1.414L10 11.414l2.293 2.293a1 1 0 1 0 1.414-1.414L11.414 10l2.293-2.293a1 1 0 0 0-1.414-1.414L10 8.586 7.707 6.293z"
                          />
                        </svg>
                      </span>
                    </button>

                    <div className="Polaris-TextField__Backdrop" />
                  </div>
                </div>
              </div>
            </div>
          </div>,

          <div
            className="itemClick bookFont searchDiv"
            id="firstTableItem1"
            style={{ display: "none" }}
          >
            <div>
              <div className="Polaris-Connected">
                <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                  <div className="Polaris-TextField">
                    <input
                      id="PolarisTextField2"
                      className="Polaris-TextField__Input"
                      aria-labelledby="PolarisTextField2Label"
                      aria-invalid="false"
                      defaultValue=""
                      onChange={handleChangeNameSearchValue}
                      ref={ts1}
                    />
                    <button
                      type="button"
                      className="Polaris-TextField__ClearButton"
                      tabIndex={0}
                      onClick={() => handleDeleteTextField(ts1)}
                      style={{
                        visibility:
                          searchValue !== "" && filterState === "name"
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      <span className="Polaris-VisuallyHidden" />
                      <span className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
                        <svg
                          viewBox="0 0 20 20"
                          className="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM7.707 6.293a1 1 0 0 0-1.414 1.414L8.586 10l-2.293 2.293a1 1 0 1 0 1.414 1.414L10 11.414l2.293 2.293a1 1 0 1 0 1.414-1.414L11.414 10l2.293-2.293a1 1 0 0 0-1.414-1.414L10 8.586 7.707 6.293z"
                          />
                        </svg>
                      </span>
                    </button>

                    <div className="Polaris-TextField__Backdrop" />
                  </div>
                </div>
              </div>
            </div>
          </div>,
          <div
            className="itemClick bookFont searchDiv"
            id="firstTableItem2"
            style={{ display: "none" }}
          >
            <div>
              <div className="Polaris-Connected">
                <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                  <div className="Polaris-TextField">
                    <input
                      id="PolarisTextField4"
                      autoComplete="off"
                      className="Polaris-TextField__Input"
                      aria-labelledby="PolarisTextField4Label"
                      aria-invalid="false"
                      defaultValue=""
                      onChange={handleChangeEmailSearchValue}
                      ref={ts2}
                    />
                    <button
                      type="button"
                      className="Polaris-TextField__ClearButton"
                      tabIndex={0}
                      onClick={() => handleDeleteTextField(ts2)}
                      style={{
                        visibility:
                          searchValue !== "" && filterState === "email"
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      <span className="Polaris-VisuallyHidden" />
                      <span className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
                        <svg
                          viewBox="0 0 20 20"
                          className="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM7.707 6.293a1 1 0 0 0-1.414 1.414L8.586 10l-2.293 2.293a1 1 0 1 0 1.414 1.414L10 11.414l2.293 2.293a1 1 0 1 0 1.414-1.414L11.414 10l2.293-2.293a1 1 0 0 0-1.414-1.414L10 8.586 7.707 6.293z"
                          />
                        </svg>
                      </span>
                    </button>
                    <div className="Polaris-TextField__Backdrop" />
                  </div>
                </div>
              </div>
            </div>
          </div>,
          <div
            className="itemClick bookFont searchDiv"
            id="firstTableItem3"
            style={{ display: "none" }}
          >
            <div>
              <div className="Polaris-Connected">
                <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                  <div className="Polaris-TextField">
                    <input
                      id="PolarisTextField6"
                      autoComplete="off"
                      className="Polaris-TextField__Input"
                      aria-labelledby="PolarisTextField6Label"
                      aria-invalid="false"
                      defaultValue=""
                      onChange={handleChangeRoleSearchValue}
                      ref={ts3}
                    />
                    <button
                      type="button"
                      className="Polaris-TextField__ClearButton"
                      tabIndex={0}
                      onClick={() => handleDeleteTextField(ts3)}
                      style={{
                        visibility:
                          searchValue !== "" &&
                          filterState === "organisation_role"
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      <span className="Polaris-VisuallyHidden" />
                      <span className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
                        <svg
                          viewBox="0 0 20 20"
                          className="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM7.707 6.293a1 1 0 0 0-1.414 1.414L8.586 10l-2.293 2.293a1 1 0 1 0 1.414 1.414L10 11.414l2.293 2.293a1 1 0 1 0 1.414-1.414L11.414 10l2.293-2.293a1 1 0 0 0-1.414-1.414L10 8.586 7.707 6.293z"
                          />
                        </svg>
                      </span>
                    </button>
                    <div className="Polaris-TextField__Backdrop" />
                  </div>
                </div>
              </div>
            </div>
          </div>,
          <div
            className="itemClick bookFont searchDiv"
            id="firstTableItem4"
            style={{ display: "none" }}
          >
            <div>
              <div className="Polaris-Connected">
                <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                  <div className="Polaris-TextField">
                    <input
                      id="PolarisTextField8"
                      autoComplete="off"
                      className="Polaris-TextField__Input"
                      aria-labelledby="PolarisTextField8Label"
                      aria-invalid="false"
                      defaultValue=""
                      onChange={handleChangeOrganisationSearchValue}
                      ref={ts4}
                    />
                    <button
                      type="button"
                      className="Polaris-TextField__ClearButton"
                      tabIndex={0}
                      onClick={() => handleDeleteTextField(ts4)}
                      style={{
                        visibility:
                          searchValue !== "" && filterState === "organisation"
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      <span className="Polaris-VisuallyHidden" />
                      <span className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
                        <svg
                          viewBox="0 0 20 20"
                          className="Polaris-Icon__Svg"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM7.707 6.293a1 1 0 0 0-1.414 1.414L8.586 10l-2.293 2.293a1 1 0 1 0 1.414 1.414L10 11.414l2.293 2.293a1 1 0 1 0 1.414-1.414L11.414 10l2.293-2.293a1 1 0 0 0-1.414-1.414L10 8.586 7.707 6.293z"
                          />
                        </svg>
                      </span>
                    </button>
                    <div className="Polaris-TextField__Backdrop" />
                  </div>
                </div>
              </div>
            </div>
          </div>,
          // <div
          //   className="itemClick bookFont searchDiv"
          //   id="firstTableItem5"
          //   style={{ display: "none" }}
          // >
          //   <div>
          //     <div className="Polaris-Connected">
          //       <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
          //         <div className="Polaris-TextField">
          //           <input
          //             id="PolarisTextField10"
          //             autoComplete="off"
          //             className="Polaris-TextField__Input"
          //             aria-labelledby="PolarisTextField10Label"
          //             aria-invalid="false"
          //             defaultValue=""
          //             onChange={handleChangeNationalitySearchValue}
          //             ref={ts5}
          //           />
          //           <button
          //             type="button"
          //             className="Polaris-TextField__ClearButton"
          //             tabIndex={0}
          //             onClick={() => handleDeleteTextField(ts5)}
          //             style={{
          //               visibility:
          //                 searchValue !== "" && filterState === "nationality"
          //                   ? "visible"
          //                   : "hidden",
          //             }}
          //           >
          //             <span className="Polaris-VisuallyHidden" />
          //             <span className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
          //               <svg
          //                 viewBox="0 0 20 20"
          //                 className="Polaris-Icon__Svg"
          //                 focusable="false"
          //                 aria-hidden="true"
          //               >
          //                 <path
          //                   fillRule="evenodd"
          //                   d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM7.707 6.293a1 1 0 0 0-1.414 1.414L8.586 10l-2.293 2.293a1 1 0 1 0 1.414 1.414L10 11.414l2.293 2.293a1 1 0 1 0 1.414-1.414L11.414 10l2.293-2.293a1 1 0 0 0-1.414-1.414L10 8.586 7.707 6.293z"
          //                 />
          //               </svg>
          //             </span>
          //           </button>
          //           <div className="Polaris-TextField__Backdrop" />
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </div>,
          // <div
          //   className="itemClick bookFont searchDiv"
          //   id="firstTableItem6"
          //   style={{ display: "none" }}
          // >
          //   <div>
          //     <div className="Polaris-Connected">
          //       <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
          //         <div className="Polaris-TextField">
          //           <input
          //             id="PolarisTextField12"
          //             autoComplete="off"
          //             className="Polaris-TextField__Input"
          //             aria-labelledby="PolarisTextField12Label"
          //             aria-invalid="false"
          //             defaultValue=""
          //             onChange={handleChangeContactNumberSearchValue}
          //             ref={ts6}
          //           />
          //           <button
          //             type="button"
          //             className="Polaris-TextField__ClearButton"
          //             tabIndex={0}
          //             onClick={() => handleDeleteTextField(ts6)}
          //             style={{
          //               visibility:
          //                 searchValue !== "" && filterState === "phone"
          //                   ? "visible"
          //                   : "hidden",
          //             }}
          //           >
          //             <span className="Polaris-VisuallyHidden" />
          //             <span className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
          //               <svg
          //                 viewBox="0 0 20 20"
          //                 className="Polaris-Icon__Svg"
          //                 focusable="false"
          //                 aria-hidden="true"
          //               >
          //                 <path
          //                   fillRule="evenodd"
          //                   d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM7.707 6.293a1 1 0 0 0-1.414 1.414L8.586 10l-2.293 2.293a1 1 0 1 0 1.414 1.414L10 11.414l2.293 2.293a1 1 0 1 0 1.414-1.414L11.414 10l2.293-2.293a1 1 0 0 0-1.414-1.414L10 8.586 7.707 6.293z"
          //                 />
          //               </svg>
          //             </span>
          //           </button>
          //           <div className="Polaris-TextField__Backdrop" />
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </div>,
          <div
            className="itemClick bookFont searchDiv"
            id="firstTableItem7"
            style={{ display: "none" }}
          >
            <div>
              <div className="Polaris-Connected">
                <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                  <div className="Polaris-TextField">
                    <input
                      id="PolarisTextField14"
                      autoComplete="off"
                      className="Polaris-TextField__Input"
                      aria-labelledby="PolarisTextField14Label"
                      aria-invalid="false"
                      defaultValue=""
                      onChange={handleChangeTimeOfRegistrationSearchValue}
                      type="date"
                      ref={ts7}
                    />

                    <div className="Polaris-TextField__Backdrop" />
                  </div>
                </div>
              </div>
            </div>
          </div>,
          <div
            className="itemClick bookFont searchDiv"
            id="firstTableItem8"
            style={{ display: "none" }}
          >
            <div>
              <div className="Polaris-Connected">
                <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                  <div className="Polaris-Select">
                    <select
                      id="PolarisSelect2"
                      className="Polaris-Select__Input"
                      aria-invalid="false"
                      onChange={handleChangeStatusSearchValue}
                      defaultValue={statusSearchValue}
                      ref={ts8}
                    >
                      <option value="All">All</option>
                      <option value="New">New</option>
                      <option value="Updated">Updated</option>
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                      <option value="Rejected">Rejected</option>
                    </select>
                    <div className="Polaris-Select__Content" aria-hidden="true">
                      <span className="Polaris-Select__SelectedOption">
                        {!ts8.current ? "All" : statusSearchValue}
                      </span>
                      <span className="Polaris-Select__Icon">
                        <span className="Polaris-Icon">
                          <svg
                            viewBox="0 0 20 20"
                            className="Polaris-Icon__Svg"
                            focusable="false"
                            aria-hidden="true"
                          >
                            <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div className="Polaris-Select__Backdrop" />
                  </div>
                  {/* <div className="Polaris-TextField">
                    <input
                      id="PolarisTextField16"
                      autoComplete="off"
                      className="Polaris-TextField__Input"
                      aria-labelledby="PolarisTextField16Label"
                      aria-invalid="false"
                      defaultValue=""
                      onChange={handleChangeStatusSearchValue}
                    />
                    <div className="Polaris-TextField__Backdrop" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>,
          <div
            className="itemClick bookFont searchDiv"
            id="firstTableItem9"
            style={{ display: "none" }}
          >
            <div style={{ visibility: "hidden" }}>
              <div>
                <div className="Polaris-Connected">
                  <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                    <div className="Polaris-TextField">
                      <input
                        id="PolarisTextField18"
                        autoComplete="off"
                        className="Polaris-TextField__Input"
                        aria-labelledby="PolarisTextField18Label"
                        aria-invalid="false"
                        defaultValue=""
                      />
                      <div className="Polaris-TextField__Backdrop" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>,
          <div
            className="itemClick bookFont searchDiv"
            id="firstTableItem10"
            style={{ display: "none" }}
          >
            <div style={{ visibility: "hidden" }}>
              <div>
                <div className="Polaris-Connected">
                  <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                    <div className="Polaris-TextField">
                      <input
                        id="PolarisTextField18"
                        autoComplete="off"
                        className="Polaris-TextField__Input"
                        aria-labelledby="PolarisTextField18Label"
                        aria-invalid="false"
                        defaultValue=""
                      />
                      <div className="Polaris-TextField__Backdrop" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>,
        ]);
        setItems(array);
      })
      .catch((err) => setIsLoading(false));
  };
  const handleDeleteTextField = (param) => {
    param.current.value = "";
    setSearchValue("");
  };

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );

  const [clickedItem, setClickedItem] = useState(null);
  const handleRowClick = (e) => {
    navigate(
      `/admin/users/incomplete-applications/${
        JSON.parse(e.currentTarget.id).id
      }`
    );
    setClickedItem(JSON.parse(e.currentTarget.id));
  };
  const [active, setActive] = useState(false);
  const handleChangeReasonOfRejection = useCallback(
    (newValue) => setReasonOfRejectionValue(newValue),
    []
  );

  const [rejectClicked, setRejectClicked] = useState(false);
  const [reasonOfRejectionValue, setReasonOfRejectionValue] = useState("");

  const [activeToast, setActiveToast] = useState(false);
  const toggleActiveToast = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );
  const [toastContent, setToastContent] = useState("");
  const toastMarkup = activeToast ? (
    <Toast
      content={toastContent}
      onDismiss={toggleActiveToast}
      duration="10000"
    />
  ) : null;
  const [activeAreYouSure, setActiveAreYouSure] = useState(false);
  const [questionValue, setQuestionValue] = useState(false);
  const handleNo = () => {
    setActiveAreYouSure(false);
  };
  const handleRowApprove = (e) => {
    reference.current = JSON.parse(e.currentTarget.id).id + "_" + "approve";
    setActiveAreYouSure(true);
    setQuestionValue("Are you sure you want to approve this request?");
  };
  const handleRowReject = (e) => {
    reference.current = JSON.parse(e.currentTarget.id).id + "_" + "reject";
    setActiveAreYouSure(true);
    setQuestionValue("Are you sure you want to reject this request?");
  };
  const handleYes = () => {
    const form_data = new FormData();
    form_data.append(
      "status",
      reference.current.split("_")[1] === "approve" ? "approved" : "rejected"
    );
    reference.current.split("_")[1] === "reject" &&
      form_data.append("reason_of_rejection", reasonOfRejectionValue);
    form_data.append("_method", "PATCH");

    axios
      .post(
        `admin/v1/registration-requests/${
          reference.current.split("_")[0]
        }/status`,
        form_data
      )
      .then((res) => {
        Cookies.set("sumPendingRequests", "");
        setRejectClicked(false);
        setActive(false);
        setActiveAreYouSure(false);
        setToastContent(
          reference.current.split("_")[1] === "approve"
            ? "Request Approved Successfully"
            : "Request Rejected Successfully"
        );
        toggleActiveToast();
        setTs(+new Date());
        // getData();

        // window.location.reload(false);
      })
      .catch((err) => console.log(""));
  };

  const [selectedSubTab, setSelectedSubTab] = useState(0);

  const handleTabChangeSubTab = useCallback(
    (selectedTabIndex) => setSelectedSubTab(selectedTabIndex),
    []
  );

  const subTabs = [
    {
      id: "profile",
      content: (
        <p style={{ marginLeft: "2rem", marginRight: "2rem" }}>Profile</p>
      ),
      accessibilityLabel: "Profile",
    },
    {
      id: "organization",
      content: "Organization",
    },
    {
      id: "media",
      content: "Media",
    },
  ];

  const resetValues = (param) => {
    param !== "ts0" && (ts0.current.value = "");
    param !== "ts1" && (ts1.current.value = "");
    param !== "ts2" && (ts2.current.value = "");
    param !== "ts3" && (ts3.current.value = "");
    param !== "ts4" && (ts4.current.value = "");
    // param !== "ts5" && (ts5.current.value = "");
    // param !== "ts6" && (ts6.current.value = "");
    param !== "ts7" && (ts7.current.value = "");
    param !== "ts8" && (ts8.current = "");
  };
  const handleChangeReferenceSearchValue = (event) => {
    resetValues("ts0");
    setFilterState("reference_number");
    setSearchValue(event.target.value);
  };
  const handleChangeNameSearchValue = (event) => {
    resetValues("ts1");
    setFilterState("name");
    setSearchValue(event.target.value);
  };
  const handleChangeEmailSearchValue = (event) => {
    resetValues("ts2");
    setFilterState("email");
    setSearchValue(event.target.value);
  };
  const handleChangeRoleSearchValue = (event) => {
    resetValues("ts3");
    setFilterState("organisation_role");
    setSearchValue(event.target.value);
  };
  const handleChangeOrganisationSearchValue = (event) => {
    resetValues("ts4");
    setFilterState("organisation");
    setSearchValue(event.target.value);
  };
  const handleChangeNationalitySearchValue = (event) => {
    resetValues("ts5");
    setFilterState("nationality");
    setSearchValue(event.target.value);
  };
  // const handleChangeContactNumberSearchValue = (event) => {
  //   resetValues("ts6");
  //   setFilterState("phone");
  //   setSearchValue(event.target.value);
  // };
  const handleChangeTimeOfRegistrationSearchValue = (event) => {
    resetValues("ts7");
    setFilterState("created_at");
    setSearchValue(event.target.value);
  };

  const [statusSearchValue, setStatusSearchValue] = useState("All");
  const handleChangeStatusSearchValue = (event) => {
    resetValues("ts8");
    ts8.current = event.target.value;
    setFilterState("status");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    setStatusSearchValue(event.target.value);
  };
  const array = [
    "firstTableItem0",
    "firstTableItem1",
    "firstTableItem2",
    "firstTableItem3",
    "firstTableItem4",
    // "firstTableItem5",
    // "firstTableItem6",
    "firstTableItem7",
    "firstTableItem8",
    "firstTableItem9",
    "firstTableItem10",
  ];
  const [filterClicked, setFilterClicked] = useState(false);
  const handleFilter = () => {
    if (!filterClicked) {
      for (var i = 0; i < array.length; i++) {
        document.getElementById(array[i]).style.display = "block";
      }
    } else {
      resetValues("ts0");
      resetValues("ts1");
      resetValues("ts2");
      resetValues("ts3");
      resetValues("ts4");
      resetValues("ts7");
      resetValues("ts8");
      setSearchValue("");
      for (var i = 0; i < array.length; i++) {
        document.getElementById(array[i]).style.display = "none";
      }
      // ts0.current.value = "";
      // ts1.current.value = "";
      // ts2.current.value = "";
      // ts3.current.value = "";
      // ts4.current.value = "";
      // ts7.current.value = "";
      // ts8.current.value = "";
      // setSearchValue("");
    }
    setFilterClicked(!filterClicked);
  };
  const [selected, setSelected] = useState("today");

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  return (
    <div>
      <DataTable
        columnContentTypes={["text", "text", "text", "text"]}
        headings={[
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Reference No.</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Name</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Email</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Role</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Organisation</div>
          </TextStyle>,
          // <TextStyle>
          //   <div style={{ padding: "1.6rem" }}>Nationality</div>
          // </TextStyle>,
          // <TextStyle variation="strong">
          //   <div style={{ padding: "1.6rem" }}>Contact No.</div>
          // </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Time of Registration</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Approval Status</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}></div>
          </TextStyle>,
          <TextStyle>
            <div style={{ float: "right", marginRight: "4rem" }}>
              <img
                className="filterImg"
                src={!filterClicked ? filterGrey : filterOrange}
                onClick={handleFilter}
              ></img>
            </div>
          </TextStyle>,
        ]}
        rows={items}
        // sortable={[true, true, true, true]}
        defaultSortDirection="descending"
        initialSortColumnIndex={0}
        onSort={handleSort}
        verticalAlign="middle"
        style={{ color: "red" }}
        // hoverable={false}
      />
      {totalPages >= 2 && (
        <div
          style={{
            textAlign: "center",
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            size="large"
          />
        </div>
      )}
      <Modal open={activeEmail}>
        <Modal.Section>
          <FormLayout>
            <div className="popupGreyText">Type your message here</div>
            <TextField
              multiline={4}
              value={emailMessageValue}
              onChange={handleChangeEmailMessage}
            ></TextField>
          </FormLayout>

          <div
            className="rowDiv"
            style={{
              float: "right",
              marginTop: "3rem",
              marginBottom: "2rem",
            }}
          >
            <button className="button buttonApprove" onClick={handleSend}>
              Send
            </button>
            <button className="button buttonEmail" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </Modal.Section>
      </Modal>

      <Modal open={activeAreYouSure}>
        <Modal.Section>
          <div
            className="popupBlackText"
            style={{ marginBottom: "4rem", fontSize: "1.6rem" }}
          >
            {questionValue}
          </div>
          {reference.current.split("_")[1] == "reject" && (
            <FormLayout>
              <div className="popupGreyText">Reason of Rejection</div>
              <TextField
                multiline={4}
                value={reasonOfRejectionValue}
                onChange={handleChangeReasonOfRejection}
              ></TextField>
            </FormLayout>
          )}
          <div
            className="rowDiv"
            style={{
              float: "right",
              marginTop: "3rem",
              marginBottom: "2rem",
            }}
          >
            <button className="button buttonApprove" onClick={handleYes}>
              Yes
            </button>
            <button className="button buttonEmail" onClick={handleNo}>
              No
            </button>
          </div>
        </Modal.Section>
      </Modal>
      {emtyState}
      {toastMarkup}
    </div>
  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default IncompleteApplications;
