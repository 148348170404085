import React, { useCallback, useState, useEffect, useRef } from "react";
import {
    Button,
    Page,
    Filters,
    Card,
    TextStyle,
    DataTable,
    Loading,
    Icon,
    Modal,
    TextField,
    Image,
    Spinner,
    DisplayText,
} from "@shopify/polaris";
import { useNavigate } from 'react-router-dom';
import axios from "../../Assets/Lib/axios";
import editImg from "../../Assets/Images/edit.svg";
import Pagination from "@material-ui/lab/Pagination";
import EditOrganisationDetail from "../../Components/EditOrganisationDetail";
import FilterParentDiv from "../../Components/FilterParentDiv";
import Cookies from "js-cookie";
import FilterCloseButton from "../../Components/FilterCloseButton";
import { isDefined, adminRole } from "../../Helpers";
import filterGrey from "../../Assets/Images/filter-grey.svg";
import filterOrange from "../../Assets/Images/filter-orange.svg";
import { countryList } from '../../Assets/countries';
import emptyListImage from "../../Assets/Images/emptyList.svg";

function OrganisationList() {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [showEditPopup, setShowEditPopup] = useState(false);
    const refClickedItem = useRef(null);
    const perPage = 30;
    const [isListEmpty, setIsListEmpty] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [availability, setAvailability] = useState("");
    const [queryValue, setQueryValue] = useState("");
    const [page, setPage] = useState(1);
    const [orgId, setOrgId] = useState("");
    const [sortState, setSortState] = useState("-id");
    const navigate = useNavigate();
    const [active, setActive] = useState(false);
    const [hrUsers, setHrUsers] = useState([]);
    const [associatedOrganisations, setAssociatedOrganisations] = useState([]);
    const [totalUsers, setTotalUsers] = useState();
    function handleAvailabilityChange(availability) {
        setAvailability(availability);
    }
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
    const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
    // const handleFiltersClearAll = useCallback(() => {
    //     handleAvailabilityRemove();
    //     handleQueryValueRemove();
    // }, [handleAvailabilityRemove, handleQueryValueRemove]);

    const refOrgName = useRef(null);
    const refOrgId = useRef(null);
    const refOrgAddress = useRef(null);
    const refOrgEmail = useRef(null);
    const refOrgCountry = useRef(null);

    const refBoolPage = useRef(true);
    const [filterState, setFilterState] = useState(
        isDefined(Cookies.get("vmc-organisations-filter-state"))
            ? Cookies.get("vmc-organisations-filter-state")
            : ""
    );
    const [filterClicked, setFilterClicked] = useState(
        isDefined(Cookies.get("vmc-organisations-filter-clicked")) &&
            Cookies.get("vmc-organisations-filter-clicked") === "true"
            ? true
            : false
    );
    const [searchValue, setSearchValue] = useState(
        isDefined(Cookies.get("vmc-organisations-search-value"))
            ? Cookies.get("vmc-organisations-search-value")
            : ""
    );

    const handleDeleteTextField = (param) => {
        param.current.value = "";
        setSearchValue("");
        refBoolPage.current = true;
        Cookies.set("vmc-organisations-search-value", "");
    };

    const appliedFilters = [];
    const emtyState = isListEmpty ? (
        <div
          style={{
            display: "flex",
            justifyContent: "middle",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ width: "25%" }}>
            <Image src={emptyListImage}></Image>
          </div>
          <DisplayText size="small">No results found</DisplayText>
          <br />
        </div>
      ) : (
        isLoading && (
          <div className="spinnerContainer">
            <div className="vertical-center">
              <Spinner size="large" />
            </div>
          </div>
        )
      );

    if (!isEmpty(availability)) {
        const key = "availability";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, availability),
            onRemove: handleAvailabilityRemove,
        });
    }
    const handleChangeShowEditPopup = (e) => {
        setActive(!active);
        if (showEditPopup === false) {
            setOrgId(e.currentTarget.id);
            // console.log(e.currentTarget.id);
            refClickedItem.current = e.currentTarget.id;
        }
        setShowEditPopup(!showEditPopup);
    };
    const handleCloseModal = () => {
        setShowEditPopup(false);
        setActive(false);
    };
    useEffect(() => {
        axios
            .get(`/admin/v1/hr-users-organisations`)
            .then((result) => {
                setIsLoading(false);
                setHrUsers(result.data.data.data);
            }
            )
            .catch((err) => {
                setIsLoading(false);
                console.log("error fetching HR's Organisations", err)
            }
            );
        setIsLoading(false);
    }, [])
    useEffect(() => {
        const AssociateOrgIds = hrUsers?.map(user => user.organisation_id);
        setAssociatedOrganisations(AssociateOrgIds);
    }, [hrUsers])
    const resetValues = (param) => {
        param !== "refOrgId" && (refOrgId.current.value = "");
        param !== "refOrgName" && (refOrgName.current.value = "");
        param !== "refOrgAddress" && (refOrgAddress.current.value = "");
        param !== "refOrgEmail" && (refOrgEmail.current.value = "");
        param !== "refOrgCountry" && (refOrgCountry.current.value = "");
    }

    const getData = () => {
        axios
            .get(
                `admin/v1/organisations?per_page=${perPage}&page=${refBoolPage.current ? page : 1
                }${searchValue && filterState
                    ? `&filter[${filterState}]=${searchValue.toLocaleLowerCase()}`
                    : ""
                }`
            )
            .then((result) => {
                setIsLoading(false);
                // console.log(result.data.data);
                setTotalUsers(result?.data.data?.total);
                !result.data.data.data.length
                    ? setIsListEmpty(true)
                    : setIsListEmpty(false);
                setTotalPages(Math.ceil(result.data.data.total / perPage));
                let array = [];
                for (var i = 0; i < result?.data?.data?.data.length; i++) {
                    const item = result?.data?.data?.data[i];
                    array.push([
                        <div
                        >
                            {loadingMarkup}
                            {item ? (
                                setItems(
                                    result.data.data.data.map((item, index) => [
                                        <div className={`itemClick`}>{item.id}</div>,
                                        item?.name && (
                                            <div className={`itemClick`}>{item?.name}</div>
                                        ),
                                        item?.country && (
                                            <div className={`itemClick`}>{item?.country}</div>
                                        ),
                                        item?.email && (
                                            <div className={`itemClick`}>{item?.email}</div>
                                        ),
                                        item?.address && (
                                            <div className={`itemClick`}>{item?.address}</div>
                                        ),
                                        item?.id && (
                                            <div className={`itemClick`}>
                                                <img
                                                    src={editImg}
                                                    className="editImg"
                                                    id={item?.id}
                                                    onClick={handleChangeShowEditPopup}
                                                /></div>
                                        ),

                                    ])
                                )
                            ) : (
                                console.log('empyy')
                            )}
                        </div>,
                    ])
                }

            })
            .catch((err) => setIsLoading(false));
    };
    useEffect(() => {
        getData();
    }, [page, filterState, searchValue]);
    const handleChangeOrganisationNameSearchValue = (event) => {
        resetValues("refOrgName");
        setFilterState("name");
        Cookies.set("vmc-organisations-filter-state", "name");
        setSearchValue(event.target.value);
        refBoolPage.current = false;
        Cookies.set("vmc-organisations-search-value", event.target.value);
    };
    const handleChangeOrganisationIdSearchValue = (event) => {
        resetValues("refOrgId");
        setFilterState("id");
        Cookies.set("vmc-organisations-filter-state", "id");
        setSearchValue(event.target.value);
        refBoolPage.current = false;
        Cookies.set("vmc-organisations-search-value", event.target.value);
    };

    const handleChangeOrganisationAddressSearchValue = (event) => {
        resetValues("refOrgAddress");
        setFilterState("address");
        Cookies.set("vmc-organisations-filter-state", "address");
        setSearchValue(event.target.value);
        refBoolPage.current = false;
        Cookies.set("vmc-organisations-search-value", event.target.value);
    };

    const handleChangeOrganisationEmailSearchValue = (event) => {
        resetValues("refOrgEmail");
        setFilterState("email");
        Cookies.set("vmc-organisations-filter-state", "email");
        setSearchValue(event.target.value);
        refBoolPage.current = false;
        Cookies.set("vmc-organisations-search-value", event.target.value);
    };

    const handleChangeOrganisationCountrySearchValue = (event) => {
        resetValues("refOrgCountry");
        setFilterState("country");
        Cookies.set("vmc-organisations-filter-state", "country");
        setSearchValue(event.target.value);
        refBoolPage.current = false;
        Cookies.set("vmc-organisations-search-value", event.target.value);
    };

    const handleFilter = () => {
        if (!filterClicked) {
            document.getElementById('firstTableItem').style.display = "block";
        } else {
            resetValues("refOrgName");
            resetValues("refOrgId");
            resetValues("refOrgAddress");
            resetValues("refOrgEmail");
            resetValues("refOrgCountry");

            setSearchValue("");
            refBoolPage.current = true;

            document.getElementById('firstTableItem').style.display = "none";
        }
        setFilterClicked(!filterClicked);
    };
    return (
        <Page
            fullWidth
            primaryAction={{
                content: <p style={{ color: "white" }}>Add New Organisation</p>,
                url: "/admin/manage-organisation/add",
            }}
        // breadcrumbs={[{ url: "/admin/content" }]}
        >
            <div className="filter-organisation"
                id="firstTableItem"
                style={{ display: filterClicked ? "block" : "none" }}>
                <FilterParentDiv filterClicked={filterClicked}>

                    <div className="Polaris-TextField" style={{ maxWidth: "120px" }}
                    >
                        <input
                            id="PolarisTextFieldId"
                            className="Polaris-TextField__Input"
                            placeholder="Search ID"
                            aria-labelledby="PolarisTextFieldIdLabel"
                            aria-invalid="false"
                            defaultValue={
                                Cookies.get("vmc-organisations-filter-state") === "id"
                                    ? searchValue
                                    : ""
                            }
                            onChange={handleChangeOrganisationIdSearchValue}
                            ref={refOrgId}
                        />
                        <FilterCloseButton
                            searchValue={searchValue}
                            filterState={filterState}
                            filterCondition="id"
                            handleDeleteTextField={handleDeleteTextField}
                            refr={refOrgId}
                        ></FilterCloseButton>
                        <div className="Polaris-TextField__Backdrop" />
                    </div>
                    <div className="Polaris-TextField" >
                        <input
                            id="PolarisTextField2"
                            className="Polaris-TextField__Input"
                            placeholder="Search by name"
                            aria-labelledby="PolarisTextField2Label"
                            aria-invalid="false"
                            defaultValue={
                                Cookies.get("vmc-organisations-filter-state") ===
                                    "name"
                                    ? searchValue
                                    : ""
                            }
                            onChange={handleChangeOrganisationNameSearchValue}
                            ref={refOrgName}
                        />
                        <FilterCloseButton
                            searchValue={searchValue}
                            filterState={filterState}
                            filterCondition="name"
                            handleDeleteTextField={handleDeleteTextField}
                            refr={refOrgName}
                        ></FilterCloseButton>
                        <div className="Polaris-TextField__Backdrop" />
                    </div>

                    {/* Organisation Country */}
                    <div className="Polaris-TextField">
                        <select
                            id="PolarisSelectCountry"
                            className="Polaris-TextField__Input"
                            aria-labelledby="PolarisSelectCountryLabel"
                            aria-invalid="false"
                            value={searchValue}
                            onChange={handleChangeOrganisationCountrySearchValue}
                            ref={refOrgCountry}
                        >
                            <option value="" disabled>Search by country</option>
                            {countryList.map((country, index) => (
                                <option key={index} value={country.name}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                        <FilterCloseButton
                            searchValue={searchValue}
                            filterState={filterState}
                            filterCondition="country"
                            handleDeleteTextField={handleDeleteTextField}
                            refr={refOrgCountry}
                        ></FilterCloseButton>
                        <div className="Polaris-TextField__Backdrop" />
                    </div>
                    {/* Organisation Email */}
                    <div className="Polaris-TextField" >
                        <input
                            id="PolarisTextFieldEmail"
                            className="Polaris-TextField__Input"
                            placeholder="Search by email"
                            aria-labelledby="PolarisTextFieldEmailLabel"
                            aria-invalid="false"
                            defaultValue={
                                Cookies.get("vmc-organisations-filter-state") === "email"
                                    ? searchValue
                                    : ""
                            }
                            onChange={handleChangeOrganisationEmailSearchValue}
                            ref={refOrgEmail}
                        />
                        <FilterCloseButton
                            searchValue={searchValue}
                            filterState={filterState}
                            filterCondition="email"
                            handleDeleteTextField={handleDeleteTextField}
                            refr={refOrgEmail}
                        ></FilterCloseButton>
                        <div className="Polaris-TextField__Backdrop" />
                    </div>

                    {/* Organisation Address */}
                    <div className="Polaris-TextField" >
                        <input
                            id="PolarisTextFieldAddress"
                            className="Polaris-TextField__Input"
                            placeholder="Search by address"
                            aria-labelledby="PolarisTextFieldAddressLabel"
                            aria-invalid="false"
                            defaultValue={
                                Cookies.get("vmc-organisations-filter-state") === "address"
                                    ? searchValue
                                    : ""
                            }
                            onChange={handleChangeOrganisationAddressSearchValue}
                            ref={refOrgAddress}
                        />
                        <FilterCloseButton
                            searchValue={searchValue}
                            filterState={filterState}
                            filterCondition="address"
                            handleDeleteTextField={handleDeleteTextField}
                            refr={refOrgAddress}
                        ></FilterCloseButton>
                        <div className="Polaris-TextField__Backdrop" />
                    </div>

                </FilterParentDiv>
            </div>
            <Card>
                {loadingMarkup}
                <DataTable
                    columnContentTypes={["text", "text", "text", "text", "text", "text"]}
                    headings={[
                        <TextStyle variation="strong">
                            <div style={{ padding: "1.6rem" }}>ID</div>
                        </TextStyle>,
                        <TextStyle variation="strong">
                            <div style={{ padding: "1.6rem" }}>Organisation Name</div>
                        </TextStyle>,
                        <TextStyle variation="strong">
                            <div style={{ padding: "1.6rem" }}>Organisation Country</div>
                        </TextStyle>,
                        <TextStyle variation="strong">
                            <div style={{ padding: "1.6rem" }}>Email</div>
                        </TextStyle>,
                        <TextStyle variation="strong">
                            <div style={{ padding: "1.6rem" }}>Address</div>
                        </TextStyle>,
                        <TextStyle variation="strong">
                            <div style={{ padding: "1.6rem", float: "right" }}>
                                <img src={(filterClicked||searchValue) ? filterOrange : filterGrey} onClick={handleFilter} style={{ cursor: "pointer", width: "22px" }} />
                            </div>
                        </TextStyle>,
                        // <TextStyle variation="strong">
                        //     <div style={{ padding: "1.6rem" }}>Edit</div>
                        // </TextStyle>,
                        // <TextStyle variation="strong"></TextStyle>,
                    ]}
                    rows={items}
                    sortable={[false, false, false, false]}
                    defaultSortDirection="descending"
                    initialSortColumnIndex={0}
                    verticalAlign="middle"
                />

            </Card>
            <Modal open={active} large onClose={handleCloseModal}>
                <Modal.Section>
                    <EditOrganisationDetail
                        orgId={orgId}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        handleCloseModal={handleCloseModal}
                        associatedOrganisations={associatedOrganisations}
                    />
                </Modal.Section>
            </Modal>
            <div className="totalDiv">Total: {totalUsers ? totalUsers: '0'}</div>
            {totalPages >= 2 && (
                <div
                    style={{
                        paddingTop: "10px",
                        textAlign: "center",
                        paddingBottom: "10px",
                    }}
                >
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        size="large"
                    />
                    {emtyState}
                </div>
            )}
        </Page>);
    function disambiguateLabel(key, value) {
        switch (key) {
            case "taggedWith":
                return `Tagged with ${value}`;
            case "availability":
                return `Filter by ${value}`;
            // case "productType":
            //   return value.join(", ");
            default:
                return value;
        }
    }

    function isEmpty(value) {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === "" || value == null;
        }
    }
}

export default OrganisationList