import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  Page,
  Loading,
  ButtonGroup,
  FormLayout,
  Layout, Icon, Button, Spinner,
} from "@shopify/polaris";
import {
  ViewMinor
} from "@shopify/polaris-icons";
import { useParams } from "react-router-dom";
import axios from "../../Assets/Lib/axios";
import attachementsImage from "../../Assets/Images/attachments.svg";
import back from "../../Assets/Images/back.svg";
import moment from "moment";
import previous from "../../Assets/Images/previous.svg";
import next from "../../Assets/Images/next.svg";
import NavigationLayout from "../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";
import { openBase64Image } from "../../Base64Image/base64Image";
import { countryList } from "../../Assets/countries";
import LoadingBtn from "../../Components/LoadingBtn";
import ProfileDetails from "./ProfileDetails";
import Timeline from "./Timeline";

const ApplicationsDetails = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const format = "YYYY-MM-DD HH:mm:ss";
  const [isLoading, setIsLoading] = useState(true);
  const [activeTimeline, setActiveTimeline] = useState(false);
  const loadingMarkup = isLoading ? <Loading /> : null;
  const [result, setResult] = useState("");
  const [currentRequestId, setCurrentRequestId] = useState(id);
  const [allRequestsArray, setAllRequestsArray] = useState(null);
  const [facialModalOpen, setFacialModalOpen] = useState(false);
  const [isLoadingSpinner, setIsloadingSpinner] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [docModalOpen, setDocModalOpen] = useState(false);
  const [docTypeName, setDocTypeName] = useState("");
  const [passportModalOpen, setPassportModalOpen] = useState(false);

  const loadingSpinner =   isLoadingSpinner ? <Spinner/> : null;

  const toggleTimeline = useCallback(
      () => setActiveTimeline((activeTimeline) => !activeTimeline),
      []
  );
  useEffect(() => {
       axios
      .get(`admin/v1/application-ids`)
      .then((result) => {
        setAllRequestsArray(result.data.data);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  }, []);
  const closeModal = () => {
    setFacialModalOpen(false);
    setDocModalOpen(false)
    setPassportModalOpen(false);
  };
  useEffect(() => {
    axios
      .get(`admin/v1/applications/${currentRequestId}`)
      .then((result) => {
        setResult(result.data.data);
      })
      .catch((err) => console.log(err));
  }, [currentRequestId]);

  const handleClickDocument = (e) => {
    const file = e.currentTarget.id;
    axios
      .get("admin/v1/generate-download-token")
      .then((res) => {
        if (res.status === 200) {
          const token = res.data.data;
          const url = `${process.env.REACT_APP_BASE_URL}admin/v1/download-document?application_id=${result.id}&file=${file}&token=${token}`;
          openBase64Image(url, file);
        }
      })
      .catch((err) => console.log(""));
  };

  const handleClickCredentialDocument = async (e) => {
    const fileId = e.currentTarget.id;
    const selectedDoc = result.documents.map((i)=>i.document);
    setDocTypeName(fileId);
    if (selectedDoc) {
      try {
        setIsLoading(true); 
        const res = await axios.get("admin/v1/generate-download-token");
        if (res.status === 200) {
          const token = res.data.data;
          const file = fileId;
  
          if (result.id && file) {
            const url = `${process.env.REACT_APP_BASE_URL}admin/v1/download-document?application_id=${result.id}&file=${file}&token=${token}`;
  
            const response = await axios.get(url);
            const blob = base64toBlob(response?.data?.data, file);
            const blobUrl = URL.createObjectURL(blob);
  
            setSelectedImage(blobUrl);
          } else {
            console.error(`undefined`);
          }
          setIsLoading(false);
        }
      } catch (err) {
        console.error(``, err);
        setIsLoading(false);
      }
      setDocModalOpen(!docModalOpen);
    } else {
      
      console.error(`Document not found for id: ${fileId}`);
    }
  };
  const base64toBlob = (base64Data, file) => {
    let extension = file.split(".").pop()?.toLowerCase();
    let type;
  
    switch (extension) {
      case "jpg":
      case "jpeg":
        type = "image/jpeg";
        break;
      case "png":
        type = "image/png";
        break;
      case "tiff":
        type = "image/tiff";
        break;
      case "pdf":
        type = "application/pdf";
        break;
      case "xlsx":
        type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      default:
        type = "application/octet-stream";
    }
  
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
  
      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
  
    return new Blob(byteArrays, { type });
  };
  const previewBase64toBlob = ({ type, base64 }) => {

    switch (type) {
      case "jpg":
      case "jpeg":
        type = "image/jpg";
        break;
      case "png":
        type = "image/png";
        break;
      case "tiff":
        type = "image/tiff";
        break;
      case "pdf":
        type = "application/pdf";
        break;
      case "xlsx":
        type =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
    }

    const sliceSize = 1024;
    const byteCharacters = atob(base64);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    const blob = new Blob(byteArrays, { type: type });

    return URL.createObjectURL(blob);
  };
  let isEventAccredited = result?.accreditation_type?.is_accreditation === 0 ; 

  const switchStatusColor = (status) => {
    switch (status) {
      case "pending":
      case "pending_sc":
      case "pending_gco":
      case "pending_qna":
        return "#4B4B4B";
        break;

      case "approved":
      case "approved_sc":
      case "approved_gco":
      case "approved_qna":
      case "approved_lusail":
        return "#008000";
        break;
      case "rejected":
      case "rejected_sc":
      case "rejected_gco":
      case "rejected_qna":
      case "rejected_lusail":
        return "#B85555";
        break;
      case "updated":
        return "#FFBF00";
        break;
      case "new":
        return "#FF4700";
        break;
      default:
      // code block
    }
  };

  const switchGender = (param) => {
    switch (String(param)) {
      case "1":
        return "Male";
        break;
      case "2":
        return "Female";
        break;
      default:
      // code block
    }
  };
  const switchDocumentSubType = (param) => {
    switch (String(param)) {
      case "1":
        return "Normal";
        break;
      case "2":
        return "Private/Royal";
        break;
      case "3":
        return "Diplomatic";
        break;
      case "5":
        return "Mission";
        break;
      case "8":
        return "Travel Document";
        break;
      case "9":
        return "Official";
        break;
      default:
      // code block
    }
  };
  const switchDocumentType = (param) => {
    switch (String(param)) {
      case "1":
        return "Qatar Id Card (QID)";
        break;
      case "3":
        return "Passport";
        break;
      default:
      // code block
    }
  };

  const handlePrevious = () => {
    if (allRequestsArray.indexOf(currentRequestId) !== 0) {
      setCurrentRequestId(allRequestsArray[allRequestsArray.indexOf(currentRequestId) - 1]);
      navigate(`/admin/applications/${allRequestsArray[allRequestsArray.indexOf(currentRequestId) - 1]}`);
    }
  };
  const handleNext = () => {
    if (
      allRequestsArray.indexOf(currentRequestId) !==
      allRequestsArray.length - 1
    ) {
      setCurrentRequestId(allRequestsArray[allRequestsArray.indexOf(currentRequestId) + 1]);
      navigate(`/admin/applications/${allRequestsArray[allRequestsArray.indexOf(currentRequestId) + 1]}`);
    }
  };
  const switchAcceditationType = (accreditationType) => {
    return accreditationType?.details?.label;
  };
  const switchHayyaStatusColor = (item) => {
    let str = "";
    if (
      item?.hayya_status === "Data Error" &&
      item?.hayya_response?.response?.data?.fanIdNo
    ) {
      str = "#ff0000";
    } else if (item?.hayya_status === "New") {
      str = "#FF4700";
    } else if (item?.hayya_status === "Pending") {
      str = "#4B4B4B";
    } else if (item?.hayya_status === "Pending Entry Visa") {
      str = "#de9900";
    } else if (item?.hayya_status === "Approved") {
      str = "#00CFB7";
    } else if (item?.hayya_status === "400") {
      str = "#ff0000";
    } else if (item?.hayya_status === "Rejected") {
      str = "#B85555";
    }

    return str;
  };
  const switchHayyaStatus = (item) => {
    let str = "";
    if (
      item?.hayya_status === "Data Error" &&
      item?.hayya_response?.response?.data?.fanIdNo
    ) {
      str = "MOI Error";
    } else if (item?.hayya_status === "New") {
      str = "New";
    } else if (item?.hayya_status === "Pending") {
      str = "Pending MOI";
    } else if (item?.hayya_status === "Pending Entry Visa") {
      str = "Pending Entry Visa";
    } else if (item?.hayya_status === "Approved") {
      str = "MOI Approved";
    } else if (item?.hayya_status === "Rejected") {
      str = "MOI Rejected";
    } else if (item?.hayya_status === "400") {
      str = "Hayya Error";
    } else if (item?.hayya_status === null) {
      str = "-";
    }

    return str;
  };

  return (
    <NavigationLayout>
      <Page fullWidth>
        {loadingMarkup}
        <div className="spinnerContainer-x is-loading">
          <div className="vertical-center">{isLoading && <LoadingBtn />}</div>
        </div>
        <div className="my-5">
            <p className="purpletitle">Accrediation Status</p>
        </div>
        <div style={{ backgroundColor: "white" }}>
          {result && allRequestsArray && (
            <div
              className="detailsPage-x"
              style={{
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              }}
            >
              <div className="headerDetailsDiv">
                <Layout sectioned>
                  <img
                    src={back}
                    className="backImg"
                    onClick={() => navigate(`/admin/applications`)}
                  />
                  <span style={{ marginLeft: "2rem" }}>
                  <span className="application-detail-boxes"> Application Ref No. <b>{result?.reference_number}</b></span>
                  <span className="application-detail-boxes">Time of Registration
                    <b>{moment(result.created_at).format(format)}</b></span>
                    <span className="application-detail-boxes">Application Type
                    <b
                      style={{
                        color:
                          parseInt(result?.accreditation_type_id) !== 1 &&
                          (parseInt(result?.accreditation_type_id) === 2
                            ? "#005391"
                            : parseInt(result?.accreditation_type_id) === 3
                            ? "#550065"
                            : "#00671d"),
                      }}
                    >{` ${switchAcceditationType(
                      result.accreditation_type
                    )}`}</b>
                    </span>
                    {window.location.href.indexOf(
                      "/admin/requests/hayya-requests"
                    ) > -1 ? (
                      <span className="application-detail-boxes">
                        Hayya Status&nbsp;
                        <b style={{ color: switchHayyaStatusColor(result) }}>
                          {switchHayyaStatus(result)}
                        </b>
                      </span>
                    ) : (
                      <span className="application-detail-boxes">
                        Registration Status&nbsp;
                        <b style={{ color: switchStatusColor(result.status) }}>
                          {window.location.href.indexOf(
                            "/admin/requests/imo-requests"
                          ) > -1
                            ? result?.status.charAt(0).toUpperCase() +
                              result?.status
                                .slice(1)
                                .replace("_", " ")
                                .replace("-", " ")
                                .replace("sc", "SC")
                                .replace("gco", "IMO")
                                .replace("qna", "QNA")
                            : result?.status.charAt(0).toUpperCase() +
                              result?.status
                                .slice(1)
                                .replace("_", " ")
                                .replace("-", " ")
                                .replace("sc", "SC")
                                .replace("gco", "GCO")
                                .replace("qna", "QNA")}
                        </b>
                      </span>
                    )}
                  </span>

                  <div className="headerDetailsRightText">
                    <span style={{ color: "#989897" }}>{`${
                      allRequestsArray.indexOf(currentRequestId) + 1
                    } of ${allRequestsArray.length}`}</span>
                    <img
                      src={previous}
                      className="previousNextImg"
                      onClick={handlePrevious}
                      style={{
                        opacity:
                          allRequestsArray.indexOf(currentRequestId) == 0
                            ? 0.3
                            : 1,
                        cursor:
                          allRequestsArray.indexOf(currentRequestId) != 0 &&
                          "pointer",
                      }}
                    />
                    <img
                      src={next}
                      className="previousNextImg"
                      onClick={handleNext}
                      style={{
                        opacity:
                          allRequestsArray.indexOf(currentRequestId) ==
                          allRequestsArray.length - 1
                            ? 0.3
                            : 1,
                        cursor:
                          allRequestsArray.indexOf(currentRequestId) !=
                            allRequestsArray.length - 1 && "pointer",
                      }}
                    />
                  </div>
                </Layout>
              </div>
              <div className="progressDiv">
                {/* <p className="purpletitle">Accrediation Status</p> */}

                <div className="rowDiv">
                  
                  <div style={{ width: "90%" }}>
                    <Layout>
                      <div className="containerProgress">
                        <ul className="progressbar">
                          <li
                            className={
                              result.status === "pending" ||
                              result.status === "updated" ||
                              result.status === "new" ||
                              result.status === "approved" ||
                              result.status === "approved_sc" ||
                              result.status === "rejected_sc" ||
                              result.status === "approved_gco" ||
                              result.status === "rejected_gco" ||
                              result.status === "approved_qna" ||
                              result.status === "rejected_qna" ||
                              result.status === "approved_hayya" ||
                              result.status === "rejected_hayya" ||
                              result.status === "approved_lusail"
                                ? "active"
                                : ""
                            }
                          >
                            <div className="li-text">Registered</div>
                          </li>
                          <li
                            className={`${
                              (result.status === "approved_sc" ||
                                result.status === "approved_gco" ||
                                result.status === "approved_qna" ||
                                result.status === "rejected_gco" ||
                                result.status === "rejected_qna" ||
                                result.status === "approved_hayya" ||
                                result.status === "approved_lusail" ||
                                result.status === "rejected_hayya" ||
                                result.status === "approved" ||
                                (result.status === "approved" &&
                                  parseInt(result.accreditation_type_id) ===
                                    1) ||
                                (result.status === "approved" &&
                                  parseInt(result.accreditation_type_id) ===
                                    2) ||
                                (result.status === "approved" &&
                                  parseInt(result.accreditation_type_id) ===
                                    3) ||
                                (result.status === "approved" &&
                                  parseInt(result.accreditation_type_id) ===
                                    5)) &&
                              "active"
                            } ${result.status === "rejected_sc" && "rejected"}`}
                          >
                            <div className="li-text">LOC</div>
                          </li>
                          {parseInt(result.accreditation_type_id) !== 1 && (
                            <li
                              className={`${
                                (result.status === "approved_gco" ||
                                  result.status === "approved_qna" ||
                                  result.status === "rejected_qna" ||
                                  result.status === "approved_hayya" ||
                                  result.status === "approved_lusail" ||
                                  result.status === "rejected_hayya" ||
                                  result.status === "approved" ||
                                  (result.status === "approved" &&
                                    parseInt(result.accreditation_type_id) ===
                                      2) ||
                                  (result.status === "approved" &&
                                    parseInt(result.accreditation_type_id) ===
                                      3) ||
                                  (result.status === "approved" &&
                                    parseInt(result.accreditation_type_id) ===
                                      5)) &&
                                "active"
                              } ${
                                result.status === "rejected_gco" && "rejected"
                              }`}
                            >
                              <div className="li-text">GCO</div>
                            </li>
                          )}

                          {parseInt(result.accreditation_type_id) !== 1 && (
                            <li
                              className={`${
                                (result.status === "approved_qna" ||
                                  result.status === "approved_hayya" ||
                                  result.status === "approved_lusail" ||
                                  result.status === "rejected_hayya" ||
                                  result.status === "approved" ||
                                  (result.status === "approved" &&
                                    parseInt(result.accreditation_type_id) ===
                                      2) ||
                                  (result.status === "approved" &&
                                    parseInt(result.accreditation_type_id) ===
                                      3) ||
                                  (result.status === "approved" &&
                                    parseInt(result.accreditation_type_id) ===
                                      5)) &&
                                "active"
                              } ${
                                result.status === "rejected_qna" && "rejected"
                              }`}
                            >
                              <div className="li-text">QNA</div>
                            </li>
                          )}

                          {parseInt(result.accreditation_type_id) !== 1 &&
                            parseInt(result.accreditation_type_id) !== 4 && (
                              <li
                                className={`${
                                  result.hayya_status === "Approved" &&
                                  result.status_level.toLowerCase() ===
                                    "hayya" &&
                                  "active"
                                } `}
                              >
                                <div className="li-text">HAYYA</div>
                              </li>
                            )}

                          {parseInt(result.accreditation_type_id) !== 1 &&
                            // parseInt(result.accreditation_type_id) !== 4 &&
                            result.status !== "approved_lusail" && (
                              <li
                                className={
                                  result?.hayya_status === "Approved" &&
                                  result.status_level.toLowerCase() ===
                                    "hayya" &&
                                  "activehc" //activefifa
                                }
                              >
                                <div className={`li-text`}>Accredited</div>
                              </li>
                            )}
                          {(parseInt(result.accreditation_type_id) === 1 ||
                            parseInt(result.accreditation_type_id) === 4) && (
                            <li style={{ visibility: "hidden" }}>
                              <div className="li-text">Accredited</div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </Layout>
                  </div>
                </div>
              </div>
              <Layout>
                <Layout.Section>
                  <div
                    style={{
                      borderRight: "0.1rem solid rgba(225, 227, 229, 1)",
                      paddingBottom: "2rem",
                    }}
                  >
                    <div style={{ maxWidth: "1000px" }}>
                      <FormLayout>
                      {!isEventAccredited && 
                      <ProfileDetails
                        result={result}
                        setIsLoading={setIsLoading}
                      />}  

                        <div className="purpletitle mt-5">Media Role</div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Role</div>

                              {result?.organisation?.role?.type?.en ? (
                                <p className="popupBlackText maxWidthText">
                                  {`${result?.organisation?.role?.type?.en} ${
                                    result?.organisation
                                      ?.organisation_role_other
                                      ? `(${result?.organisation?.organisation_role_other})`
                                      : ""
                                  }`}
                                </p>
                              ) : (
                                <p className="popupBlackText maxWidthText">
                                  N/A
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="purpletitle mt-5">
                          Organisation Information
                        </div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Name</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.organisation &&
                                result?.organisation?.name
                                  ? result?.organisation?.name
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Address</div>
                              <p className="popupBlackText">
                                {result?.organisation &&
                                result?.organisation?.address
                                  ? result?.organisation?.address
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Country</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.organisation &&
                                result?.organisation?.country
                                  ? result?.organisation?.country
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Email</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.organisation &&
                                result?.organisation?.email
                                  ? result?.organisation?.email
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Phone</div>
                              <p
                                className="popupBlackText maxWidthText"
                                // href={`tel:${`${
                                //   result?.organisation?.phoneAreaCode &&
                                //   result?.organisation?.phoneAreaCode
                                // } ${result?.organisation?.phone}`}`}
                                // style={{ textDecoration: "none" }}
                              >
                                {result?.organisation &&
                                result?.organisation?.phone
                                  ? `${
                                      result?.organisation?.phoneAreaCode
                                        ? result?.organisation?.phoneAreaCode
                                        : ""
                                    } ${result?.organisation?.phone}`
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Website</div>
                              <p
                                className="popupBlackText maxWidthText"
                                // style={{ cursor: "pointer" }}
                                // onClick={() =>
                                //   window.open(
                                //     `${result?.organisation?.website}`
                                //   )
                                // }
                              >
                                {result?.organisation &&
                                result?.organisation?.website
                                  ? result?.organisation?.website
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="purpletitle"
                          style={{ marginTop: "3rem" }}
                        >
                          Media Information
                        </div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Media Type</div>
                              <div className="popupBlackText maxWidthText">
                                {result?.media_type &&
                                result?.media_type?.ids.length
                                  ? result?.media_type?.ids.map(function (
                                      item,
                                      i
                                    ) {
                                      return (
                                        <ul key={i}>
                                          {result?.media_type.type_other &&
                                          item.media.type.en === "Other" ? (
                                            <li>{`${item.media.type.en} (${result?.media_type.type_other})`}</li>
                                          ) : (
                                            <li>{item?.media?.type?.en}</li>
                                          )}
                                        </ul>
                                      );
                                    })
                                  : "N/A"}
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Published | On-air
                              </div>
                              {result?.media_type &&
                              result?.media_type?.published_on_air ? (
                                <p className="popupBlackText maxWidthText">
                                  {!result?.media_type?.published_on_air_other
                                    ? result?.media_type?.published_on_air
                                        .charAt(0)
                                        .toUpperCase() +
                                      result?.media_type?.published_on_air.slice(
                                        1
                                      )
                                    : `${
                                        result?.media_type?.published_on_air
                                          .charAt(0)
                                          .toUpperCase() +
                                        result?.media_type?.published_on_air.slice(
                                          1
                                        )
                                      } (${
                                        result?.media_type
                                          ?.published_on_air_other
                                      })`}
                                </p>
                              ) : (
                                <p className="popupBlackText maxWidthText">
                                  N/A
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Language</div>
                              {result?.media_type &&
                              result?.media_type?.language ? (
                                <p className="popupBlackText maxWidthText">
                                  {!result?.media_type?.language_other
                                    ? result?.media_type?.language
                                        .charAt(0)
                                        .toUpperCase() +
                                      result?.media_type?.language.slice(1)
                                    : `${
                                        result?.media_type?.language
                                          .charAt(0)
                                          .toUpperCase() +
                                        result?.media_type?.language.slice(1)
                                      } (${
                                        result?.media_type?.language_other
                                      })`}
                                </p>
                              ) : (
                                <p className="popupBlackText maxWidthText">
                                  N/A
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Reach</div>
                              {result?.media_type &&
                              result?.media_type?.reach ? (
                                <p className="popupBlackText maxWidthText">
                                  {result?.media_type?.reach
                                    .charAt(0)
                                    .toUpperCase() +
                                    result?.media_type?.reach.slice(1)}
                                </p>
                              ) : (
                                <p className="popupBlackText ">N/A</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="purpletitle mt-5">Personal Data</div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Given Name</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.user?.first_name
                                  ? result?.user?.first_name
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Second Name</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.second_name
                                  ? result?.second_name
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Third Name</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.third_name
                                  ? result?.third_name
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Fourth Name</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.fourth_name
                                  ? result?.fourth_name
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Family Name</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.user?.last_name
                                  ? result?.user?.last_name
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Application Email</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.application_email
                                    ? result?.application_email
                                    : "N/A"}
                              </p>
                            </div>
                          </div>

                        </div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Arabic First Name
                              </div>
                              <p className="popupBlackText maxWidthText">
                                {result?.first_name_ar
                                  ? result?.first_name_ar
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Arabic Second Name
                              </div>
                              <p className="popupBlackText maxWidthText">
                                {result?.second_name_ar
                                  ? result?.second_name_ar
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Arabic Third Name
                              </div>
                              <p className="popupBlackText maxWidthText">
                                {result?.third_name_ar
                                  ? result?.third_name_ar
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Arabic Fourth Name
                              </div>
                              <p className="popupBlackText maxWidthText">
                                {result?.fourth_name_ar
                                  ? result?.fourth_name_ar
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Arabic Family Name
                              </div>
                              <p className="popupBlackText maxWidthText">
                                {result?.last_name_ar
                                  ? result?.last_name_ar
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Email</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.user?.email
                                  ? result?.user?.email
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                          {/* <div className="col-sm-4 Polaris-FormLayout__Item"></div> */}
                        </div>

                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Nationality</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.nationality
                                  ? findArrayElementByCode(
                                      countryList,
                                      String(result?.nationality)
                                    )?.name
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Date of Birth</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.user?.date_of_birth
                                  ? result.user?.date_of_birth
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Sex</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.user?.gender !== null &&
                                result?.user?.gender !== ""
                                  ? switchGender(result?.user?.gender)
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Emergency Contact Name
                              </div>
                              <p className="popupBlackText maxWidthText">
                                {result?.emergency_contact_name
                                  ? result?.emergency_contact_name
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Emergency Contact Mobile Number
                              </div>
                              <p
                                className="popupBlackText maxWidthText"
                                // style={{ textDecoration: "none" }}
                                // href={`tel:${`${
                                //   result?.emergency_contact_PhoneAreaCode &&
                                //   result?.emergency_contact_PhoneAreaCode
                                // } ${result?.emergency_contact_number}`}`}
                              >
                                {result?.emergency_contact_number
                                  ? `${
                                      result?.emergency_contact_PhoneAreaCode
                                        ? result?.emergency_contact_PhoneAreaCode
                                        : ""
                                    } ${result?.emergency_contact_number}`
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-4 Polaris-FormLayout__Item"></div>
                        </div>
                        <div className="purpletitle mt-5">
                          Identification Data
                        </div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Document Name</div>
                              {/* only label changed from document type to document name*/}
                              <p className="popupBlackText maxWidthText">
                                {result?.document_type !== null &&
                                result?.document_type !== ""
                                  ? switchDocumentType(result?.document_type)
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Document Number
                              </div>
                              <p className="popupBlackText maxWidthText">
                                {result?.document_number !== null
                                  ? result?.document_number
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Document Type
                                {/* only label changed from document subtype to document type*/}
                              </div>
                              <p className="popupBlackText maxWidthText">
                                {(result?.document_sub_type !== null) &
                                (result?.document_sub_type !== "")
                                  ? switchDocumentSubType(
                                      result?.document_sub_type
                                    )
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Passport Issuing Country
                              </div>
                              <p className="popupBlackText maxWidthText">
                                {result?.passport_issuing_country
                                  ? findArrayElementByCode(
                                      countryList,
                                      String(result?.passport_issuing_country)
                                    ).name
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Document Expiry Date
                              </div>
                              <p className="popupBlackText maxWidthText">
                                {result?.document_expiry_date
                                  ? result?.document_expiry_date
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div></div>
                          </div>
                        </div>
                        <div className="purpletitle mt-5">
                          Accrediation Data
                        </div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Subcategory</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.organisation?.role
                                  ?.media_accreditation_subcategory?.en
                                  ? result?.organisation?.role
                                      ?.media_accreditation_subcategory?.en
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">Function</div>
                              <p className="popupBlackText maxWidthText">
                                {result?.organisation?.role?.type?.en
                                  ? result?.organisation?.role?.type?.en
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Accreditation Terms Acceptance
                              </div>
                              <p className="popupBlackText">Accepted</p>
                            </div>
                          </div>
                        </div>
                        <div className="purpletitle mt-5">Additional Data</div>
                        <div className="row Polaris-FormLayout g-0">
                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Bringing equipment?
                              </div>
                              <p className="popupBlackText">
                                {result?.additional_data?.bringing_equipment !==
                                null
                                  ? result?.additional_data?.bringing_equipment
                                    ? "Yes"
                                    : "No"
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item">
                            <div>
                              <div className="popupGreyText">
                                Bringing wireless equipment?
                              </div>
                              <p className="popupBlackText">
                                {result?.wireless_equipment !== null
                                  ? result?.additional_data?.wireless_equipment
                                    ? "Yes"
                                    : "No"
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-4 Polaris-FormLayout__Item"></div>
                        </div>
                        {result?.passport_scan || result?.facial_photo ? (
                          <div>
                            <div
                              className="purpletitle"
                              style={{ marginTop: "3rem" }}
                            >
                              Identification Uploads
                            </div>

                            <div className="row Polaris-FormLayout g-0">
                              <div className="col-sm-4 Polaris-FormLayout__Item">
                                <div
                                  style={{
                                    marginTop: "1.3rem",
                                    // marginBottom: "2rem",
                                  }}
                                >
                                  <ButtonGroup>
                                    {result.passport_scan && (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          flexWrap: "wrap",
                                          lineHeight: "1",
                                        }}
                                      >
                                        <div
                                          style={
                                            {
                                              // marginBottom: ".5rem",
                                            }
                                          }
                                          // key={item.id}
                                        >
                                          <div className="popupGreyText mb-3">
                                            Passport Scan
                                          </div>
                                          <div
                                            id={result.passport_scan}
                                            onClick={() =>
                                            setPassportModalOpen(!passportModalOpen)
                                            }
                                            className="button buttonCredentials"
                                            style={{ width: "auto" }}
                                          >
                                            <div className="rowDiv">
                                              {/* <img
                                                src={attachementsImage}
                                                width={15}
                                                className="credentialsImage"
                                                id="attachementsImage"
                                              /> */}
                                              <span>
                                                {
                                                  result.passport_scan.split(
                                                    "/"
                                                  )[2]
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </ButtonGroup>
                                  <Modal
                                    open={passportModalOpen}
                                    onClose={closeModal}
                                    title="Passport Image"
                                    primaryAction={{
                                    content: 'Close',
                                    onAction: closeModal,
                                    }}
                                    large={true}
                                    >
                                    <Modal.Section>
                                    {loadingSpinner}
                                    {Object.keys(result?.passport_scan_base64).length > 0  && 
                                    <div style={{ textAlign: "center",display:"block" }}>
                                    <img
                                      src={previewBase64toBlob(result?.passport_scan_base64)}
                                      className="passport"
                                      style={{width: '90%'}}
                                    />
                                    <a
                                      href={previewBase64toBlob(result?.passport_scan_base64)}
                                      download="document.png"
                                      className="fw-bold text-dark"
                                      style={{ display: 'block', marginTop: '10px' }}
                                    >
                                      Download Image
                                    </a>
                                    </div>}
                                    </Modal.Section>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                            <div className="row Polaris-FormLayout g-0">
                              <div className="col-sm-4 Polaris-FormLayout__Item">
                                <div
                                  style={{
                                    marginTop: "1.3rem",
                                    // marginBottom: "2rem",
                                  }}
                                >
                                  <ButtonGroup>
                                    {result.facial_photo && (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          flexWrap: "wrap",
                                          lineHeight: "1",
                                        }}
                                      >
                                        <div
                                          style={
                                            {
                                              // marginBottom: ".5rem",
                                            }
                                          }
                                          // key={item.id}
                                        >
                                          <div className="popupGreyText mb-3">
                                            Facial Photo
                                          </div>
                                          <div
                                            id={result.facial_photo}
                                            onClick={ ()=>
                                              setFacialModalOpen(!facialModalOpen)
                                            }
                                            className="button buttonCredentials"
                                            style={{ width: "auto" }}
                                          >
                                            <div className="rowDiv">
                                              {/* <img
                                                src={attachementsImage}
                                                width={15}
                                                className="credentialsImage"
                                                id="attachementsImage"
                                              /> */}
                                              <span>
                                                {
                                                  result.facial_photo.split(
                                                    "/"
                                                  )[2]
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </ButtonGroup>
                                  {!Object.keys(result?.facial_photo_base64).length > 0 ?
                                   <Spinner size="small"/> : 
                                  <img
                                  src={previewBase64toBlob(result?.facial_photo_base64)}
                                  width={260}/>
                                  }
                                  <Modal
                                    open={facialModalOpen}
                                    onClose={closeModal}
                                    title="Facial Image"
                                    primaryAction={{
                                    content: 'Close',
                                    onAction: closeModal,
                                    }}
                                    large={true}
                                    >
                                    <Modal.Section>
                                      {loadingSpinner}
                                      {Object.keys(result?.facial_photo_base64).length > 0 &&
                                      <div  style={{ textAlign: "center",display:"block" }}>
                                      <img
                                          src={previewBase64toBlob(result?.facial_photo_base64)}
                                          className="facial"
                                          style={{width: '90%'}}
                                    />
                                    </div>}
                                    </Modal.Section>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {result?.documents && result?.documents?.length ? (
                          <div>
                            <div
                              className="purpletitle"
                              style={{ marginTop: "3rem" }}
                            >
                              Credentials
                            </div>

                            <div className="row Polaris-FormLayout g-0">
                              <div className="col-sm-4 Polaris-FormLayout__Item">
                                <div
                                  style={{
                                    marginTop: "1.3rem",
                                    // marginBottom: "2rem",
                                  }}
                                >
                                  <ButtonGroup>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        lineHeight: "1",
                                      }}
                                    >
                                      {result.documents.map((item) => {
                                        return (
                                          <div
                                            style={
                                              {
                                                // marginBottom: ".5rem",
                                              }
                                            }
                                            key={item.id}
                                          >
                                            <div
                                              id={item.document}
                                              onClick={(e) =>
                                                handleClickCredentialDocument(e)
                                              }
                                              className="button buttonCredentials"
                                              style={{ width: "auto" }}
                                            >
                                              <div className="rowDiv">
                                                {/* <img
                                                  src={
                                                    item.document.split(
                                                      "."
                                                    )[1] === "pdf"
                                                      ? attachementsImage
                                                      : attachementsImage
                                                  }
                                                  width={15}
                                                  className="credentialsImage"
                                                  id="attachementsImage"
                                                /> */}
                                                <span>
                                                  {item.document.split("/")[2]}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </ButtonGroup>
                                  {selectedImage && (
                                    <Modal
                                      open={docModalOpen}
                                      title="Document"
                                      onClose={closeModal}
                                      primaryAction={{
                                        content: 'Close',
                                        onAction: closeModal,
                                      }}
                                      large={true}
                                    >
                                      <Modal.Section>
                                      {loadingSpinner}
                                      <div style={{ textAlign: "center", display: "block" }}>
                                      {docTypeName.toLowerCase().endsWith('.pdf') ? (
                                    <span>  <iframe
                                        src={selectedImage}
                                        title="Document Preview"
                                        height
                                        style={{ border: 'none',width: '95%',minHeight: '500px' }}
                                      />
                                      <a
                                      href={selectedImage} 
                                      download="document.pdf"
                                      className="fw-bold text-dark"
                                      style={{ display: 'block', marginTop: '10px' }}
                                    >
                                      Download PDF
                                    </a></span>
                                      ) : (
                                    <span>
                                       <img
                                        src={selectedImage}
                                        alt="Document Preview"
                                        style={{width: '95%'}}
                                      />
                                      <a
                                      href={selectedImage} 
                                      download="document.png"
                                      className="fw-bold text-dark"
                                      style={{ display: 'block', marginTop: '10px' }}
                                    >
                                      Download Image
                                    </a></span>
                                      )}
                                    </div>
                                    </Modal.Section>
                                  </Modal>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {result?.equipments ? (
                          <div>
                            <div
                              className="purpletitle"
                              style={{ marginTop: "3rem" }}
                            >
                              Equipments
                            </div>

                            <div className="row Polaris-FormLayout g-0">
                              <div className="col-sm-4 Polaris-FormLayout__Item">
                                <div
                                  style={{
                                    marginTop: "1.3rem",
                                    // marginBottom: "2rem",
                                  }}
                                >
                                  <ButtonGroup>
                                    {result.equipments && (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          flexWrap: "wrap",
                                          lineHeight: "1",
                                        }}
                                      >
                                        <div
                                          style={
                                            {
                                              // marginBottom: ".5rem",
                                            }
                                          }
                                          // key={item.id}
                                        >
                                          <div
                                            id={result.equipments}
                                            onClick={(e) =>
                                              handleClickDocument(e)
                                            }
                                            className="button buttonCredentials"
                                            style={{ width: "auto" }}
                                          >
                                            <div className="rowDiv">
                                              <img
                                                src={attachementsImage}
                                                width={15}
                                                className="credentialsImage"
                                                id="attachementsImage"
                                              />
                                              <span>
                                                {
                                                  result.equipments.split(
                                                    "/"
                                                  )[2]
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </ButtonGroup>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {result?.reason_of_rejection && (
                          <FormLayout.Group>
                            <div style={{ marginBottom: "2rem" }}>
                              <div className="purpletitle">
                                Reason of Rejection
                              </div>

                              <p
                                className="popupBlackText"
                                style={{ cursor: "pointer" }}
                              >
                                {result?.reason_of_rejection}
                              </p>
                            </div>
                          </FormLayout.Group>
                        )}
                        {result?.email_message && (
                          <FormLayout.Group>
                            <div style={{ marginBottom: "2rem" }}>
                              <div className="purpletitle">Email Message</div>

                              <p
                                className="popupBlackText"
                                style={{
                                  cursor: "pointer",
                                  maxWidth: "90%",
                                  wordWrap: "break-word",
                                }}
                              >
                                {result.email_verified_at
                                  ? `${
                                      result?.email_message
                                    } ( Sent on ${moment(
                                      result.email_verified_at
                                    ).format(format)})`
                                  : result?.email_message}
                              </p>
                            </div>
                          </FormLayout.Group>
                        )}
                      </FormLayout>
                    </div>
                  </div>
                </Layout.Section>

                <Layout.Section secondary>
                <button className="button buttonDownload ms-5" onClick={toggleTimeline}>
                  <span>View Logs</span>
                </button>
                </Layout.Section>
              </Layout>
            </div>
          )}
        </div>
        <Modal
            large
            open={activeTimeline}
            onClose={toggleTimeline}
            secondaryActions={[
              {
                content: 'Close',
                onAction: toggleTimeline,
              },
            ]}
        >
          <Modal.Section>
            <Timeline applicationId={currentRequestId} setIsLoading={setIsLoading}/>
          </Modal.Section>
        </Modal>
      </Page>
    </NavigationLayout>
  );

  function findArrayElementByCode(array, id) {
    return array.find((element, index) => {
      return parseInt(element["country-code"]) === parseInt(id);
    });
  }
};
export default ApplicationsDetails;
