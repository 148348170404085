import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  AppProvider,
  ActionList,
  Card,
  TextField,
  TextContainer,
  ContextualSaveBar,
  FormLayout,
  Modal,
  Frame,
  Layout,
  Loading,
  Navigation,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Toast,
  TopBar,
  Icon
} from "@shopify/polaris";
import {
  AnalyticsMajor,
  LogOutMinor,
  ChecklistAlternateMajor,
  CalendarMajor,
  ChatMajor,
  CustomersMajor,
  ListMajor,
  SettingsMajor,
  CalendarTickMajor,
  NoteMajor,
  ImagesMajor,
  CategoriesMajor,
  TeamMajor,
  SearchMajor,
  FolderMajor,
  HomeMajor
} from "@shopify/polaris-icons";
import Cookies from "js-cookie";
import logo from "../Assets/Images/header-logo.svg";
import header from "../Assets/Images/header.jpg";
import history from "../Assets/Lib/history";
import axios from "../Assets/Lib/axios";
import profile from "../Assets/Images/profile.svg";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { isMobile } from "react-device-detect";
import { adminRole } from "../Helpers";
import PermitDetails from "../Pages/Permits/PermitDetails";

function NavigationLayout(props) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [totalRequests, setTotalRequests] = useState("");
  const [sumPendingRequests, setSumPendingRequests] = useState("");

  function handleChangeTotalRequests(newValue) {
    setTotalRequests(newValue);
  }

  const customIcon = {
    viewBox: "0 0 20 20",
    body: '<g transform="scale(0.5)"><path d="M16 0 C6 0 2 4 2 14 L2 22 L6 24 L6 30 L26 30 L26 24 L30 22 L30 14 C30 4 26 0 16 0 M9 12 A4.5 4.5 0 0 1 9 21 A4.5 4.5 0 0 1 9 12 M23 12 A4.5 4.5 0 0 1 23 21 A4.5 4.5 0 0 1 23 12"></path></g>',
  };
  const defaultState = useRef({
    emailFieldValue: "dharma@jadedpixel.com",
    nameFieldValue: "Jaded Pixel",
  });
  const skipToContentRef = useRef(null);

  const [toastActive, setToastActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [nameFieldValue, setNameFieldValue] = useState(
    defaultState.current.nameFieldValue
  );
  const [emailFieldValue, setEmailFieldValue] = useState(
    defaultState.current.emailFieldValue
  );
  const [storeName, setStoreName] = useState(
    defaultState.current.nameFieldValue
  );
  const [supportSubject, setSupportSubject] = useState("");
  const [supportMessage, setSupportMessage] = useState("");

  const handleSubjectChange = useCallback(
    (value) => setSupportSubject(value),
    []
  );
  const handleMessageChange = useCallback(
    (value) => setSupportMessage(value),
    []
  );
  const handleDiscard = useCallback(() => {
    setEmailFieldValue(defaultState.current.emailFieldValue);
    setNameFieldValue(defaultState.current.nameFieldValue);
    setIsDirty(false);
  }, []);
  const handleSave = useCallback(() => {
    defaultState.current.nameFieldValue = nameFieldValue;
    defaultState.current.emailFieldValue = emailFieldValue;

    setIsDirty(false);
    setToastActive(true);
    setStoreName(defaultState.current.nameFieldValue);
  }, [emailFieldValue, nameFieldValue]);
  const handleNameFieldChange = useCallback((value) => {
    setNameFieldValue(value);
    value && setIsDirty(true);
  }, []);
  const handleEmailFieldChange = useCallback((value) => {
    setEmailFieldValue(value);
    value && setIsDirty(true);
  }, []);
  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue("");
  }, []);
  const handleSearchFieldChange = useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);
  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );
  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const toggleModalActive = useCallback(
    () => setModalActive((modalActive) => !modalActive),
    []
  );

  const toastMarkup = toastActive ? (
    <Toast onDismiss={toggleToastActive} content="Changes saved" />
  ) : null;
  const logOut = () => {
    Cookies.set("vmc-accesstoken", null);
    Cookies.set("sumPendingRequests", "");
  };
  const userMenuActions = [
    {
      items: [
        {
          content: "Settings",
          icon: SettingsMajor,
          url: "/settings",
        },
      ],
    },
    {
      items: [
        {
          content: "Log out",
          icon: LogOutMinor,
          url: "/",
          onAction: logOut,
        },
      ],
    },
  ];

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        onAction: handleSave,
      }}
      discardAction={{
        onAction: handleDiscard,
      }}
    />
  ) : null;

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={Cookies.get("vmc-name") && Cookies.get("vmc-name")}
      initials={
        Cookies.get("vmc-name") &&
        Cookies.get("vmc-name").split(" ")[0].charAt(0) +
        " " +
        Cookies.get("vmc-name").split(" ")[1].charAt(0)
      }
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const searchResultsMarkup = (
    <ActionList
      items={[
        { content: "Shopify help center" },
        { content: "Community forums" },
      ]}
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchFieldChange}
      value={searchValue}
      placeholder="Search"
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      // userMenu={userMenuMarkup}
      // searchResultsVisible={searchActive}
      // searchField={searchFieldMarkup}
      // searchResults={searchResultsMarkup}
      // onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );
  function onLinkClick(e) {
    e.preventDefault();
    // further processing happens here
  }
  const [selectedTab, setSelectedTab] = useState("dashboard");
  const dashboardTab = {
    label: "HMP Dashboard",
    // icon: HomeMajor,
    url: "/",
    selected: window.location.href.indexOf("/admin/dashboard") > -1,
    onClick: () => navigate("/admin/dashboard"),
    exactMatch: true,
  };
  const requestsTab = {
    label: "Requests",
    // icon: ListMajor,
    url: "/",
    selected: window.location.href.indexOf("/admin/requests") > -1,
    onClick: () => {
      Cookies.set("vmc-requests-filter-clicked", "false");
      Cookies.set("vmc-requests-filter-state", "");
      Cookies.set("vmc-requests-search-value", "");
      Cookies.set("vmc-requests-filter-page", 1);
      Cookies.set("vmc-requests-selected-tab", 0);
      navigate("/admin/requests");
    },
    exactMatch: true,
  };
  const manageUsersTab = {
    label: "Manage Users",
    // icon: CustomersMajor,
    url: "/",
    selected: window.location.href.indexOf("/admin/users") > -1,
    onClick: () => navigate("/admin/users"),
  };
  const bookingsTab = {
    label: `Bookings`,
    selected: window.location.href.indexOf("/admin/bookings") > -1,
    // icon: CalendarTickMajor,
    url: "/",
    disabled: true,
  };
  const permitsTab = {
    label: "Equipment Clearance",
    // icon: ChecklistAlternateMajor,
    url: "/",
    selected: window.location.href.indexOf("/admin/equipment-clearance") > -1,
    onClick: () => {
      localStorage.setItem("permits-selected-tab", 0);
      localStorage.setItem("permits-selected-page", 1);
      navigate("/admin/equipment-clearance");
    },
  };
  const intervieweesTab = {
    label: "Manage Interviewees",
    // icon: ChatMajor,
    url: "/",
    selected: window.location.href.indexOf("/admin/manage-interviewees") > -1,
    onClick: () => {
      // localStorage.setItem("permits-selected-tab", 0);
      // localStorage.setItem("permits-selected-page", 1);
      navigate("/admin/manage-interviewees");
    },
    disabled: true,
  };
  const photosTab = {
    label: "Photos and Videos",
    // icon: ImagesMajor,
    url: "/",
    selected: window.location.href.indexOf("/admin/photos-and-videos") > -1,
    onClick: () => navigate("/admin/photos-and-videos/albums"),
    // disabled: true,
  };
  const manageEventsTab = {
    label: "Manage Events",
    // icon: CalendarMajor,
    url: "/",
    selected: window.location.href.indexOf("/admin/manage-events") > -1,
    // disabled: true,
    onClick: () => navigate("/admin/manage-events/events"),
  };
  const manageStudiosTab = {
    label: "Manage Studios",
    // icon: CategoriesMajor,
    url: "/",
    selected: window.location.href.indexOf("/admin/manage-studios") > -1,
    disabled: true,
    onClick: () => navigate("/admin/manage-studios/studios"),
  };
  const manageAdminUsersTab = {
    label: "Manage Admin Users",
    url: "/",
    // icon: CustomersMajor,
    selected: window.location.href.indexOf("/admin/manage-admin-users") > -1,
    onClick: () => navigate("/admin/manage-admin-users"),
  };
  const auditsTab = {
    label: "Audits",
    // icon: SearchMajor,
    // url: null,
    selected: window.location.href.indexOf("/admin/audits") > -1,
    onClick: () => navigate("/admin/audits"),
    url: "/",
  };
  const allApplicationsTab = {
    label: `Applications`,
    url: "/",
    // icon: ListMajor,
    selected: window.location.href.indexOf("/admin/applications") > -1,
    onClick: () => navigate("/admin/applications"),
  };
  const addOrganisationTab = {
    label: `Manage Organisation`,
    url: "/",
    // icon: TeamMajor,
    selected: window.location.href.indexOf("/admin/manage-organisation") > -1,
    onClick: () => navigate("/admin/manage-organisation"),
  };
  const navigationMarkup = (
    <Navigation location="/">
      <div className="headerNavigation">
        <a href="">
          <img src="/logo.png" />
        </a>
      </div>

      {/* <div className="headerNavigation">
        <img
          src={profile}
          alt="Avatar"
          style={{ width: "35px", height: "35px" }}
          className="avatar"
        />
        <div style={{ color: "#f8b404", marginLeft: "1rem" }}>
          {Cookies.get("vmc-firstname") && Cookies.get("vmc-firstname") && (
            <span className="name" style={{ fontSize: "1.6rem" }}>
              {Cookies.get("vmc-firstname") + " " + Cookies.get("vmc-lastname")}
            </span>
          )}
          <br />
          {Cookies.get("vmc-type") && (
            <span
              style={{ color: "#ffffff", fontSize: "1.2rem" }}
              className="role"
            >
              {Cookies.get("vmc-type") === "0" ? "User" : "Admin"}
            </span>
          )}
        </div>
      </div> */}
      {adminRole() === "Senior" && (
        <div style={{ flex: 1 }} className="navigation-tabs">
          <Navigation.Section
            items={[
              dashboardTab,
              requestsTab,
              manageUsersTab,
              // bookingsTab,
              permitsTab,
              // photosTab,
              // manageEventsTab,
              // manageStudiosTab,
              // intervieweesTab,
              manageAdminUsersTab,
              auditsTab,
              allApplicationsTab,
              addOrganisationTab
            ]}
          >
            </Navigation.Section></div>
      )}
      {adminRole() === "HAYYA" && (
        <Navigation.Section items={[requestsTab, permitsTab, allApplicationsTab, addOrganisationTab]}></Navigation.Section>
      )}
      {(adminRole() === "GCO" ||
        adminRole() === "IMO" ||
        adminRole() === "SC") && (
          <Navigation.Section items={[requestsTab]}></Navigation.Section>
        )}

      {(adminRole() === "QNA" || adminRole() === "Viewer") && (
        <Navigation.Section
          items={[requestsTab, permitsTab]}
        ></Navigation.Section>
      )}
      {(adminRole() === "CRA" || adminRole() === "Customs") && (
        <Navigation.Section items={[permitsTab]}></Navigation.Section>
      )}
      {adminRole() === "Content" && (
        <Navigation.Section items={[photosTab]}></Navigation.Section>
      )}
      <Navigation.Section
        items={[
          {
            label: (
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className=""
              >
                <div
                  onClick={() => navigate("/settings")}
                  // style={{
                  //   color:
                  //     window.location.href.indexOf("/settings") > -1
                  //       ? "#ff4700"
                  //       : "#d5cc65",
                  // }}
                  className="d-flex align-items-center settings"
                >
                  <Icon source={SettingsMajor}></Icon>
                  Settings
                </div>

                {/* <div className="vl"></div> */}

              </div>
            ),

            selected: window.location.href.indexOf("/settings") > -1 && true,
          },
        ]}
      />
      <Navigation.Section
        items={[
          {
            label: (
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className=""
              >
               
                <div
                  onClick={() => handleLogout(instance)}
                  className=""
                // style={{ color: "var(--color-yellow)" }}
                >
                  <i class="mx-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <g id="Log Out">
                        <path id="Vector" d="M9.42384 16.234H3.82099C3.63495 16.234 3.48387 16.0799 3.48387 15.8902V2.33495C3.48387 2.14544 3.63508 1.99113 3.82099 1.99113H9.42384C9.55736 1.99113 9.66577 1.88109 9.66577 1.74557C9.66577 1.61004 9.55736 1.5 9.42384 1.5H3.82099C3.36829 1.5 3 1.87466 3 2.33496V15.8902C3 16.3505 3.36829 16.7252 3.82099 16.7252H9.42384C9.55736 16.7252 9.66577 16.6152 9.66577 16.4796C9.66577 16.3441 9.5575 16.2341 9.42384 16.2341V16.234ZM15.1124 8.93896L11.7935 5.57039C11.699 5.47446 11.5459 5.47446 11.4513 5.57039C11.3566 5.66632 11.3568 5.82185 11.4513 5.91777L14.3575 8.86702H6.45934C6.32582 8.86702 6.21741 8.97706 6.21741 9.11259C6.21741 9.24812 6.32582 9.35816 6.45934 9.35816H14.3575L11.4513 12.3077C11.3568 12.4036 11.3568 12.559 11.4513 12.6551C11.4985 12.703 11.5602 12.727 11.6223 12.727C11.6844 12.727 11.7463 12.703 11.7934 12.6551L15.1123 9.2865C15.1575 9.24059 15.1832 9.1781 15.1832 9.11287C15.1833 9.04751 15.1579 8.98516 15.1124 8.93898V8.93896Z" fill="white" stroke="white" />
                      </g>
                    </svg>
                  </i>
                  Logout
                </div>
                {/* <div className="vl"></div> */}

              </div>
            ),

            selected: window.location.href.indexOf("/settings") > -1 && true,
          },
        ]}
      />
    </Navigation>
  );
  const loadingMarkup = isLoading ? <Loading /> : null;

  const skipToContentTarget = (
    <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
  );

  const actualPageMarkup = (
    <Page title="Account">
      <Layout>
        {skipToContentTarget}
        <Layout.AnnotatedSection
          title="Account details"
          description="Jaded Pixel will use this as your account information."
        >
          <Card sectioned>
            <FormLayout>
              <TextField
                label="Full name"
                value={nameFieldValue}
                onChange={handleNameFieldChange}
              />
              <TextField
                type="email"
                label="Email"
                value={emailFieldValue}
                onChange={handleEmailFieldChange}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );

  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  const modalMarkup = (
    <Modal
      open={modalActive}
      onClose={toggleModalActive}
      title="Contact support"
      primaryAction={{
        content: "Send",
        onAction: toggleModalActive,
      }}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label="Subject"
            value={supportSubject}
            onChange={handleSubjectChange}
          />
          <TextField
            label="Message"
            value={supportMessage}
            onChange={handleMessageChange}
            multiline
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const theme = {
    colors: {
      topBar: {
        background: "#2c2c2d",
      },
    },
    // logo: {
    //   width: 124,
    //   topBarSource: logo,
    //   url: "/admin/subscribers",
    // },
  };
  function handleLogout(instance) {
    Cookies.set("vmc-accesstoken", null);
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }

  useEffect(() => {
    // console.log("a=", adminRole);
    // console.log("b=", adminRole());
  }, []);

  return (
    <div style={{ height: "500px" }}>
      <AppProvider
        theme={theme}
        i18n={{
          Polaris: {
            Avatar: {
              label: "Avatar",
              labelWithInitials: "Avatar with initials {initials}",
            },
            ContextualSaveBar: {
              save: "Save",
              discard: "Discard",
            },
            TextField: {
              characterCount: "{count} characters",
            },
            TopBar: {
              toggleMenuLabel: "Toggle menu",

              SearchField: {
                clearButtonLabel: "Clear",
                search: "Search",
              },
            },
            Modal: {
              iFrameTitle: "body markup",
            },
            Frame: {
              skipToContent: "Skip to content",
              Navigation: {
                closeMobileNavigationLabel: "Close navigation",
              },
            },
          },
        }}
      >
        <Frame
          topBar={isMobile ? topBarMarkup : null}
          navigation={navigationMarkup}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
          skipToContentTarget={skipToContentRef.current}
        >
          {!isMobile && <Header tabs={[
            dashboardTab,
            requestsTab,
            manageUsersTab,
            // bookingsTab,
            permitsTab,
            // photosTab,
            // manageEventsTab,
            // manageStudiosTab,
            // intervieweesTab,
            manageAdminUsersTab,
            auditsTab,
            allApplicationsTab,
            addOrganisationTab
          ]} />}
          {props.children}
          <Footer />
        </Frame>
      </AppProvider>
    </div>
  );
}
export default React.memo(NavigationLayout);
