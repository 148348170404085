import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Image,
  DisplayText,
  Loading,
  TextStyle,
  Spinner,
  Toast,
  Modal,
  TextField,
  FormLayout,
  Page,
  Card,
  Button,
  Filters,
  ChoiceList,
  ButtonGroup,
  Thumbnail,
  Link,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import emptyListImage from "../../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import filterGrey from "../../../Assets/Images/filter-grey.svg";
import filterOrange from "../../../Assets/Images/filter-orange.svg";
import FilterCloseButton from "../../../Components/FilterCloseButton";
import FilterParentDivEvents from "../../../Components/FilterParentDivEvents";
import { useNavigate } from "react-router-dom";
import NavigationLayout from "../../../Components/NavigationLayout";
import EllipsisText from "react-ellipsis-text";

const TableOfEvents = (props) => {
  const navigate = useNavigate();
  const [ts, setTs] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const refName = useRef(null);
  const refType = useRef(null);
  const refTime = useRef(null);
  const refLocation = useRef("");
  const refCreatedAt = useRef(null);
  const format = "YY-MM-DD HH:mm";
  const reference = useRef("");
  const reference2 = useRef("");
  const mailIdRef = useRef("");
  const perPage = 20;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const [filterState, setFilterState] = useState("");
  const handleChangePage = (event, value) => {
    refBoolPage.current = true;
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const [searchValue, setSearchValue] = useState("");
  const [selectedEventLocation, setSelectedEventLocation] = useState("All");
  const [selectedEventType, setSelectedEventType] = useState("All");
  const [eventStartTimeValue, setEventStartTimeValue] = useState("");
  const handleChangeEventStartTime = useCallback(
    (newValue) => setEventStartTimeValue(newValue),
    []
  );
  const [locations, setLocations] = useState("");
  const [types, setTypes] = useState("");
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responseTypes = "";
    let responseLocations = "";
    try {
      responseTypes = await axios.get(`admin/v1/events/types`);
      setTypes(responseTypes?.data?.data?.data);
      console.log("types=", responseTypes?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
    try {
      responseLocations = await axios.get(`admin/v1/events/locations`);
      setLocations(responseLocations?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, [page, queryValue, availability, ts]);
  const [isHovered, setIsHovered] = useState(false);
  const handleCancel = () => {
    setActiveEmail(false);
  };
  const [emailMessageValue, setEmailMessageValue] = useState("");
  const handleChangeEmailMessage = useCallback(
    (newValue) => setEmailMessageValue(newValue),
    []
  );

  const handleSend = () => {
    setIsSendingEmail(true);
    const form_data = new FormData();

    form_data.append("message", emailMessageValue);
    form_data.append("_method", "PATCH");

    axios
      .post(
        `admin/v1/registration-requests/${mailIdRef.current}/email`,
        form_data
      )
      .then((res) => {
        setActiveEmail(false);
        setIsSendingEmail(false);
        setToastContent("Email Sent Successfully");
        toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => console.log(""));
  };

  const handleFlag = (e) => {
    // isFlagged ? setIsFlagged(false) : setIsFlagged(true);
    const form_data = new FormData();
    form_data.append("_method", "PATCH");
    form_data.append(
      "is_flagged",
      !JSON.parse(e.currentTarget.id).is_flagged ? 1 : 0
    );
    axios
      .post(
        `admin/v1/registration-requests/${
          JSON.parse(e.currentTarget.id).id
        }/flag`,
        form_data
      )
      .then((res) => {
        setTs(+new Date());
        //getData();
      })
      .catch((err) => console.log(""));
  };

  const [activeEmail, setActiveEmail] = useState(false);
  const handleEmail = (e) => {
    mailIdRef.current = JSON.parse(e.currentTarget.id).id;
    setActiveEmail(true);
  };

  const handleExport = () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}admin/v1/users/export/${
        props.selectedTab === "0"
          ? "sc"
          : props.selectedTab === "1"
          ? "gco"
          : props.selectedTab === "2"
          ? "qna"
          : "hayya"
      }?per_page=${page}&page=${page}${
        searchValue
          ? `&filter[${filterState}]=${
              searchValue.toLowerCase() !== "all"
                ? searchValue.toLowerCase()
                : ""
            }`
          : ""
      }`
    );
  };

  const greyBackgroundCondition = (param) => {
    let bool = false;
    if (moment(param) > new Date()) {
      bool = true;
    }
    return bool;
  };
  const handleEdit = (param) => {
    navigate(`/admin/manage-events/events/${param}`);
  };
  const handleSubscribers = (param) => {
    navigate(`/admin/manage-events/events/${param}/bookings`);
  };
  const greyBackgroundColor = "rgba(196, 196, 188, 0.3)";
  const getData = () => {
    axios
      .get(
        `admin/v1/events?per_page=${perPage}&page=${
          refBoolPage.current ? page : 1
        }&filter[${availability == "" ? "id" : availability}]=${queryValue}`
      )
      .then((result) => {
        setIsLoading(false);
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        let array = [];
        console.log(result.data);
        if (result?.data?.data?.data.length)
          for (var i = 0; i < result?.data?.data?.data.length; i++) {
            const item = result?.data?.data?.data[i];
            array.push([
              // <table height="100%">
              //   <td width="60px" valign="top" align="center">
              //     <div class="eventLine">&nbsp;</div>
              //   </td>
              // </table>,
              <div
                className={`itemClickEvents ${
                  !greyBackgroundCondition(item.date)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.date) && greyBackgroundColor,
                }}
              >
                {item?.image ? (
                  <img src={item.image} width="25rem"></img>
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                className={`itemClickEvents ${
                  !greyBackgroundCondition(item.date)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.date) && greyBackgroundColor,
                }}
              >
                {item?.date ? (
                  item?.date
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                className={`itemClickEvents ${
                  !greyBackgroundCondition(item.date)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.date) && greyBackgroundColor,
                }}
              >
                {item?.title ? (
                  item.title[Object.keys(item.title)[0]]
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                className={`itemClickEvents ${
                  !greyBackgroundCondition(item.date)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.date) && greyBackgroundColor,
                }}
              >
                {item?.start_time && item?.end_time ? (
                  item?.start_time + " - " + item?.end_time
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                className={`itemClickEvents ${
                  !greyBackgroundCondition(item.date)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.date) && greyBackgroundColor,
                }}
              >
                {item?.location?.title ? (
                  item.location.title[Object.keys(item.location.title)[0]]
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              // <div
              //   className={`itemClickEvents ${
              //     !greyBackgroundCondition(item.status)
              //       ? "bookFont"
              //       : "mediumFont"
              //   }`}
              //   id={JSON.stringify(item)}
              //   onClick={(e) => handleRowClick(e)}
              //   style={{
              //     backgroundColor:
              //       greyBackgroundCondition(item.status) && greyBackgroundColor,
              //   }}
              // >
              //   {item?.description ? (
              //     <EllipsisText
              //       text={item.description[Object.keys(item.description)[0]]}
              //       length={40}
              //     />
              //   ) : (
              //     <p style={{ visibility: "hidden" }}>empty</p>
              //   )}
              // </div>,

              <div
                className={`itemClickEvents ${
                  !greyBackgroundCondition(item.date)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.date) && greyBackgroundColor,
                }}
              >
                {item?.types[0]?.title ? (
                  <p
                    className="eventTypeDiv"
                    style={{
                      backgroundColor:
                        item?.types[0]?.color && item?.types[0]?.color,
                    }}
                  >
                    {item.types[0].title[Object.keys(item.types[0].title)[0]]}
                  </p>
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                className={`itemClickEvents ${
                  !greyBackgroundCondition(item.date)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.date) && greyBackgroundColor,
                }}
              >
                {findArrayElementByValue(result?.data?.total_requests, item.id)
                  .count !== null ? (
                  findArrayElementByValue(result?.data?.total_requests, item.id)
                    .count
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                className={`itemClickEvents ${
                  !greyBackgroundCondition(item.date)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.date) && greyBackgroundColor,
                }}
              >
                {item?.capacity !== null &&
                result?.data?.events_approved &&
                result?.data?.events_approved.length
                  ? `${
                      findArrayElementByValue(
                        result?.data?.events_approved,
                        item.id
                      ).approved
                    }/${item?.capacity}`
                  : "-"}
              </div>,
              <div
                className={`itemClickEvents ${
                  !greyBackgroundCondition(item.date)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.date) && greyBackgroundColor,
                }}
              >
                {item?.max_requests !== null ? (
                  item?.max_requests
                ) : (
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                className={`itemClickEvents ${
                  !greyBackgroundCondition(item.date)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.date) && greyBackgroundColor,
                  color: String(item?.is_bookable) === "1" ? "#03c623" : "red",
                }}
              >
                {String(item?.is_bookable) === "1" ? "Yes" : "No"}
              </div>,

              <div
                className={`itemClickEvents ${
                  !greyBackgroundCondition(item.date)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.date) && greyBackgroundColor,
                }}
              >
                {item?.admin?.first_name ? (
                  item?.admin?.first_name
                ) : (
                  // <p>
                  //   <p>{item?.admin?.first_name}</p>
                  //   <p>
                  //     {moment(item.created_at).format(format).split(" ")[0]}
                  //     <span>&nbsp;&nbsp;</span>
                  //     {moment(item.created_at).format(format).split(" ")[1]}
                  //   </p>
                  // </p>
                  <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                className={`itemClickEvents ${
                  !greyBackgroundCondition(item.date)
                    ? "bookFont"
                    : "mediumFont"
                }`}
                id={JSON.stringify(item)}
                onClick={(e) => handleRowClick(e)}
                style={{
                  backgroundColor:
                    greyBackgroundCondition(item.date) && greyBackgroundColor,
                }}
              >
                <a
                  onClick={() => handleSubscribers(item.id)}
                  style={{ cursor: "pointer", color: "#8a1538" }}
                >
                  <u>Bookings</u>
                </a>
                <span style={{ marginLeft: "2rem" }}>
                  <a
                    onClick={() => handleEdit(item.id)}
                    style={{ cursor: "pointer", color: "#8a1538" }}
                  >
                    <u>Edit</u>
                  </a>
                </span>
              </div>,

              // <div
              //   className={`itemClickEvents ${
              //     !greyBackgroundCondition(item.status)
              //       ? "bookFont"
              //       : "mediumFont"
              //   }`}
              //   id={JSON.stringify(item)}
              //   onClick={(e) => handleRowClick(e)}
              //   style={{
              //     backgroundColor:
              //       greyBackgroundCondition(item.status) && greyBackgroundColor,
              //   }}
              // >
              //   <p style={{ visibility: "hidden" }}>empty</p>
              // </div>,
            ]);
          }

        setItems(array);
      })
      .catch((err) => setIsLoading(false));
  };
  const switchEventTypeColor = (param) => {
    switch (String(param.toLowerCase())) {
      case "public events":
        return "No";
      case "media tours":
        return "Yes";
      case "":
        return "press conferences";
      case "All":
        return "All";
      default:
        return null;
      // code block
    }
  };
  const switchIsCamera = (param) => {
    switch (String(param)) {
      case "no":
        return "No";
      case "yes":
        return "Yes";
      case "":
        return "All";
      case "All":
        return "All";
      default:
        return null;
      // code block
    }
  };
  const switchType = (param) => {
    switch (param) {
      case "1":
        return "Non Accredited";
      case "2":
        return "FIFA";
      case "3":
        return "HC";
      case "4":
        return "Lusail";
      case "All":
        return "All";
      default:
        return null;
      // code block
    }
  };
  const handleDeleteTextField = (param) => {
    param.current.value = "";
    setSearchValue("");
  };
  function handleFiltersQueryChange(queryValue) {
    refBoolPage.current = false;
    setQueryValue(queryValue);
    console.log("aaa");
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = () => {
    setQueryValue("");
    refBoolPage.current = true;
  };
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Name", value: "title" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );

  const handleRowClick = (e) => {};
  const [active, setActive] = useState(false);
  const handleChangeReasonOfRejection = useCallback(
    (newValue) => setReasonOfRejectionValue(newValue),
    []
  );

  const [rejectClicked, setRejectClicked] = useState(false);
  const [reasonOfRejectionValue, setReasonOfRejectionValue] = useState("");

  const [activeToast, setActiveToast] = useState(false);
  const toggleActiveToast = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );
  const [toastContent, setToastContent] = useState("");
  const toastMarkup = activeToast ? (
    <Toast
      content={toastContent}
      onDismiss={toggleActiveToast}
      duration="10000"
    />
  ) : null;
  const [activeAreYouSure, setActiveAreYouSure] = useState(false);
  const [questionValue, setQuestionValue] = useState(false);
  const handleNo = () => {
    setActiveAreYouSure(false);
  };
  const handleRowApprove = (e) => {
    reference.current = JSON.parse(e.currentTarget.id).id + "_" + "approve";
    reference2.current =
      JSON.parse(e.currentTarget.id).accreditation_id +
      "_" +
      JSON.parse(e.currentTarget.id).status_level;
    setActiveAreYouSure(true);
    setQuestionValue("Are you sure you want to approve this request?");
  };
  const handleRowReject = (e) => {
    reference.current = JSON.parse(e.currentTarget.id).id + "_" + "reject";
    reference2.current =
      JSON.parse(e.currentTarget.id).accreditation_id +
      "_" +
      JSON.parse(e.currentTarget.id).status_level;
    setActiveAreYouSure(true);
    setQuestionValue("Are you sure you want to reject this request?");
  };
  const handleYes = () => {
    const form_data = new FormData();
    form_data.append(
      "status",
      reference.current.split("_")[1] === "approve" ? "approved" : "rejected"
    );
    reference.current.split("_")[1] === "reject" &&
      form_data.append("reason_of_rejection", reasonOfRejectionValue);
    form_data.append("_method", "PATCH");

    axios
      .post(
        `admin/v1/registration-requests/${
          reference.current.split("_")[0]
        }/status${
          props.selectedTab === "0" &&
          reference2.current.split("_")[0] !== 1 &&
          reference2.current.split("_")[1] === "SC"
            ? "/sc"
            : ""
        }${props.selectedTab === "1" ? "/gco" : ""}${
          props.selectedTab === "2" ? "/qna" : ""
        }`,
        form_data
      )
      .then((res) => {
        setRejectClicked(false);
        setActive(false);
        setActiveAreYouSure(false);
        setToastContent(
          reference.current.split("_")[1] === "approve"
            ? "Request Approved Successfully"
            : "Request Rejected Successfully"
        );
        toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => console.log(""));
  };

  const resetValues = (param) => {
    param !== "refName" && (refName.current.value = "");
    param !== "refLocation" && (refLocation.current = "");
    param !== "refType" && (refType.current = "");
  };

  const handleChangeNameSearchValue = (event) => {
    resetValues("refName");
    setFilterState("name");
    setSearchValue(event.target.value);
  };
  const handleChangeDate = (event) => {
    resetValues("refDate");
    setFilterState("created_at");
    setSearchValue(event.target.value);
  };
  const handleChangeTime = (event) => {
    resetValues("refTime");
    setFilterState("organisation_role");
    setSearchValue(event.target.value);
  };
  const handleChangeTimeOfRegistrationSearchValue = (event) => {
    resetValues("refCreatedAt");
    setFilterState("created_at");
    setSearchValue(event.target.value);
  };

  const handleChangeTypeSearchValue = (event) => {
    resetValues("refType");
    refType.current = event.target.value;
    setFilterState("type");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    setSelectedEventType(event.target.value);
  };
  const array = [
    "firstTableItemDate",
    "firstTableItemName",
    "firstTableItemTime",
    "firstTableItemLocation",
    "firstTableItemDescription",
    "firstTableItemType",
    "firstTableItemCapacity",
    "firstTableItemOccupancy",
    "firstTableItemAdmin",
    "firstTableItemExport",
  ];
  const [filterClicked, setFilterClicked] = useState(false);
  const handleFilter = () => {
    if (!filterClicked) {
      for (var i = 0; i < array.length; i++) {
        if (document.getElementById(array[i])) {
          document.getElementById(array[i]).style.display = "block";
        }
      }
    } else {
      resetValues("refDate");
      resetValues("refName");
      resetValues("refLocation");
      resetValues("refType");
      setSearchValue("");

      for (var i = 0; i < array.length; i++) {
        document.getElementById(array[i]).style.display = "none";
      }
    }

    setFilterClicked(!filterClicked);
  };
  const [selected, setSelected] = useState("today");

  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const refBoolPage = useRef(true);

  return (
    <NavigationLayout>
      <Page
        fullWidth
        title="Events"
        primaryAction={
          <ButtonGroup>
            <Button
              onClick={() => navigate("/admin/manage-events/locations")}
              outline
            >
              Locations
            </Button>
            <Button
              onClick={() => navigate("/admin/manage-events/types")}
              outline
            >
              Types
            </Button>
            <Button
              primary
              onClick={() => navigate("/admin/manage-events/events/new")}
            >
              <p style={{ color: "white" }}>Add Event</p>{" "}
            </Button>
          </ButtonGroup>
        }

        // breadcrumbs={[{ onAction: () => navigate("/admin/manage-events") }]}
      >
        <Card>
          {loadingMarkup}
          <Card.Section>
            <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleFiltersClearAll}
            />
          </Card.Section>

          <DataTable
            columnContentTypes={["text", "text", "text", "text"]}
            headings={[
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Image</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Date</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Name</div>
              </TextStyle>,

              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Time</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Location</div>
              </TextStyle>,
              // <TextStyle>
              //   <div style={{ padding: "1.6rem" }}>Description</div>
              // </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Type</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Requests</div>
              </TextStyle>,

              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Occupancy</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Maximum Requests</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Is Bookable?</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Added by</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ float: "right", marginRight: "4rem" }}>
                  {/* <img
                    className="filterImg"
                    src={!filterClicked ? filterGrey : filterOrange}
                    onClick={handleFilter}
                  ></img> */}
                </div>
              </TextStyle>,
            ]}
            rows={items}
            // sortable={[true, true, true, true]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            onSort={handleSort}
            verticalAlign="middle"
            style={{ color: "red" }}
            // hoverable={false}
          />
          {totalPages >= 2 && (
            <div
              style={{
                textAlign: "center",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChangePage}
                size="large"
              />
            </div>
          )}
          <Modal open={activeEmail}>
            <Modal.Section>
              <FormLayout>
                <div className="popupGreyText">Type your message here</div>
                <TextField
                  multiline={4}
                  value={emailMessageValue}
                  onChange={handleChangeEmailMessage}
                ></TextField>
              </FormLayout>

              <div
                className="rowDiv"
                style={{
                  justifyContent: "center",
                  marginTop: "3rem",
                  marginBottom: "2rem",
                }}
              >
                <button
                  className={`button ${
                    isSendingEmail ? "buttonDisabled" : "buttonApprove"
                  }`}
                  onClick={handleSend}
                >
                  Send
                </button>
                <button className="button buttonEmail" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </Modal.Section>
          </Modal>

          <Modal open={activeAreYouSure}>
            <Modal.Section>
              <div
                className="popupBlackText"
                style={{ marginBottom: "4rem", fontSize: "1.6rem" }}
              >
                {questionValue}
              </div>
              {reference.current.split("_")[1] == "reject" && (
                <FormLayout>
                  <div className="popupGreyText">Reason of Rejection</div>
                  <TextField
                    multiline={4}
                    value={reasonOfRejectionValue}
                    onChange={handleChangeReasonOfRejection}
                  ></TextField>
                </FormLayout>
              )}
              <div
                className="rowDiv"
                style={{
                  justifyContent: "center",
                  marginTop: "3rem",
                  marginBottom: "2rem",
                }}
              >
                <button className="button buttonApprove" onClick={handleYes}>
                  Yes
                </button>
                <button className="button buttonEmail" onClick={handleNo}>
                  No
                </button>
              </div>
            </Modal.Section>
          </Modal>
          {emtyState}
          {toastMarkup}
        </Card>
      </Page>
    </NavigationLayout>
  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function findArrayElementByValue(array, id) {
    return array.find((element, index) => {
      return parseInt(element.event_id) === parseInt(id);
    });
  }
};

export default TableOfEvents;
