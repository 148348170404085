import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import history from "../../../Assets/Lib/history";
import List from "./List";
import Add from "./Add";
import NavigationLayout from "../../../Components/NavigationLayout";
import Edit from "./Edit";
import Footer from "./../../../Components/Footer";

const News = () => {
  return (
    <BrowserRouter history={history}>
      <Routes>
        <Route exact path="/admin/content/news" element={<List />} />
        <Route path="/admin/content/news/new" element={<Add />} />
        <Route path="/admin/content/news/:id" element={<Edit />} />
      </Routes>
    </BrowserRouter>
  );
};
export default News;
