import React, { useCallback, useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
function LoginMicrosoft() {
  console.log("o");
  const { instance } = useMsal();
  useEffect(() => {
    console.log("pp");
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }, []);

  return <div></div>;
}

export default LoginMicrosoft;
