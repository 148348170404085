import React, { useState, useEffect } from "react";
import { Page, Card, Tabs, Loading } from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import NavigationLayout from "../../Components/NavigationLayout";
import RegistrationRequestsImage from "../../Assets/Images/registration-requests.svg";
import { adminRole } from "../../Helpers";
import ApplicationRequests from "./ApplicationRequests";
import ApplicationShortList from "./ApplicationShortList";

const ApplicationTabs = (props) => {
  const [applicationCount, setApplicationCount] = useState(0);
  const [selected, setSelected] = useState(0);

  const handleTabChange = (selectedTabIndex) => {
    setSelected(selectedTabIndex);
  };

  const tabs = [
    {
      id: "t1",
      content: (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={RegistrationRequestsImage} color="highlight" />
          <span style={{ marginLeft: "10px" }} className="TabTitle">
            {`Full view`}
          </span>
        </div>
      ),
    },
    {
      id: "t2",
      content: (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img src={RegistrationRequestsImage} color="highlight" />
          <span style={{ marginLeft: "10px" }} className="TabTitle">
            {`Compact view`}
          </span>
        </div>
      ),
    },
  ];

  return (
    <NavigationLayout>
      <Page fullWidth>
        <Card>
          <Tabs onSelect={handleTabChange} selected={selected} tabs={tabs}>
            {selected === 0 && <ApplicationRequests setApplicationCount={setApplicationCount} />}
            {selected === 1 && <ApplicationShortList setApplicationCount={setApplicationCount} />}
          </Tabs>
        </Card>
      </Page>
    </NavigationLayout>
  );
};

export default ApplicationTabs;
