import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  TextStyle,
  DropZone,
  Thumbnail,
  PageActions,
  FormLayout,
  Page,
  Stack,
  Modal,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import NavigationLayout from "../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { NoteMinor } from "@shopify/polaris-icons";
const AddMultipleFiles = () => {
  let url = "";
  const { BlobServiceClient } = require("@azure/storage-blob");
  const refImage = useRef("");
  const { id } = useParams();
  const [popupActive, setPopupActive] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => {
      setFiles((files) => [...files, ...acceptedFiles]);
      currentFileIndex.current = 0;
    },
    []
  );

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const fileUpload = !files.length && <DropZone.FileUpload />;
  const uploadedFiles = files.length > 0 && (
    <div style={{ padding: "0" }}>
      <Stack horizontal>
        {files.map((file, index) => (
          <Stack alignment="center" key={index}>
            <Thumbnail
              size="small"
              alt={file.name}
              source={
                validImageTypes.includes(file.type)
                  ? window.URL.createObjectURL(file)
                  : NoteMinor
              }
            />
            <div>
              {file.name} <TextStyle>{file.size} bytes</TextStyle>
            </div>
          </Stack>
        ))}
      </Stack>
    </div>
  );
  const [keys, setKeys] = useState([]);
  const currentFileIndex = useRef(0);
  const sendFiles = (files) => {
    setPopupActive(true);
    console.log("filesMain=", files);
    upload(files, currentFileIndex.current);
    // for (var i = 0; i < files.length; i++) {

    // }
  };
  const upload = (files, param) => {
    console.log("currentFileIndex=", param);
    axios
      .get(`admin/v1/sas/${files[param].name}`)
      .then((result) => {
        url = result.data.data;
        fctAzure(files, url);
      })
      .catch((err) => console.log(""));
  };

  const fctAzure = async (files, url) => {
    const CONTAINER_NAME = "photos";
    //GET sasURL from API?

    const blobServiceClient = new BlobServiceClient(url);
    const containerClient =
      blobServiceClient.getContainerClient(CONTAINER_NAME);
    const filename = files[currentFileIndex.current].name.substring(
      0,
      files[currentFileIndex.current].name.lastIndexOf(".")
    );
    const ext = files[currentFileIndex.current].name.substring(
      files[currentFileIndex.current].name.lastIndexOf(".")
    );
    const blobName = filename + "_" + new Date().getTime() + ext;
    setKeys((current) => [...current, blobName]);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
      files[currentFileIndex.current]
    );
    // setImageError("");
    currentFileIndex.current++;
    console.log("tessst=", currentFileIndex.current);
    let i = currentFileIndex.current;
    currentFileIndex.current <= files.length - 1 && upload(files, i);
    currentFileIndex.current >= files.length - 1 && setPopupActive(false);
  };

  return (
    <NavigationLayout>
      <Page
        fullWidth
        breadcrumbs={[
          {
            onAction: () => navigate(`/admin/photos-and-videos/albums`),
          },
        ]}
        title="Add multiple photos"
      >
        <FormLayout.Group>
          {/* {keys && keys} */}
          <FormLayout>
            <DropZone
              onDrop={handleDropZoneDrop}
              onDropAccepted={sendFiles}
              variableHeight
            >
              {uploadedFiles}
              {fileUpload}
            </DropZone>
          </FormLayout>
        </FormLayout.Group>

        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
        />
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
  function handleSave() {
    setIsSaving(true);
    console.log("keys=", keys);
    const form_data = new FormData();
    keys &&
      keys.forEach((selected, index) =>
        form_data.append(`paths[${index}]`, selected)
      );
    axios
      .post(`admin/v1/albums/${id}/media-bulk`, form_data)
      .then((result) => {
        navigate("/admin/photos-and-videos/albums");
      })
      .catch((err) => console.log(err));
  }
};

export default AddMultipleFiles;
