import React, { useEffect, useState } from "react";
import { Select, Navigation } from "@shopify/polaris";
import axios from "../Assets/Lib/axios";
import { defaultAccreditationTypeCode } from "../global";
import Cookies from "js-cookie";
import profile from "../Assets/Images/profile.svg";

const Header = ({ tabs }) => {
  const [accreditationTypes, setAccreditationTypes] = useState([]);
  const [accreditationValue, setAccreditationValue] = useState(
    Cookies.get("accreditation_type_id")
  );
  const setAccreditationCookies = (value) =>
    Cookies.set("accreditation_type_id", value);

  useEffect(() => {
    accreditationTypes.length === 0 && fetchAccreditationTypes();
  }, []);

  const fetchAccreditationTypes = () => {
    axios
      .get(`admin/v1/accreditationTypes`)
      .then((res) => {
        const response = res?.data?.data;
        const defaultAccreditationId = res?.data?.defaultAccreditationId;

        setAccreditationTypes(response);

        if (!defaultAccreditationId.includes(parseInt(accreditationValue))) {
          const result = response.find(
            (item) => item.id === defaultAccreditationId?.[0] || null
          );
          setAccreditationValue(result ? result.id : null);
          setAccreditationCookies(result ? result.id : null);
        }
      })
      .catch((err) => console.log(""));
  };
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const selectedTab = tabs.find(tab => tab.selected);
  return (
    <section className="">
      <div className="bg-white p-4 px-5 d-flex justify-content-between">
        <div>
          {selectedTab && (selectedTab.label === 'Dashboard' ?
            <h1 className="header-page-title">Hayya Media Portal</h1> :
            <h1 className="header-page-title">{selectedTab.label}</h1>)}
        </div>
        <div className="">
          <img
            src={profile}
            alt="Avatar"
            style={{ width: "35px", height: "35px", cursor: "pointer" }}
            className="avatar"
            onClick={toggleVisibility}
          />
          {isVisible && (
            <div className="header-user-info" style={{ color: "#f8b404", marginLeft: "1rem" }}>
              {Cookies.get("vmc-firstname") && Cookies.get("vmc-firstname") && (
                <span className="name" style={{ fontSize: "1.6rem" }}>
                  {Cookies.get("vmc-firstname") + " " + Cookies.get("vmc-lastname")}
                </span>
              )}
              {/* <span className="name">{Cookies.get("vmc-lastname")}</span> */}
              <br />
              {Cookies.get("vmc-type") && (
                <span
                  style={{ fontSize: "1.2rem" }}
                  className="role"
                >
                  {Cookies.get("vmc-type") === "0" ? "User" : "Admin"}
                </span>
              )}
            </div>)}
        </div>
      </div>
      <div className="row Polaris-Page Polaris-Page--fullWidth headerBar">
        <div className="event-dropdown col-sm-4 px-0">
          <h3 className="fw-bolder">Select Event</h3>
          <Select
            options={accreditationTypes?.map(({ id, details }) => ({
              label: details?.label,
              value: String(id),
            }))}
            onChange={(newValue) => {
              setAccreditationValue(newValue);
              setAccreditationCookies(newValue);
              window.location.reload();
            }}
            value={accreditationValue}
          />
        </div>
        <div className="header col-sm-8">
          {/* <a href="">
            <img src="/logo.png" />
          </a> */}
        </div>
      </div>
    </section>
  );
};

export default Header;
