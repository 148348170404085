import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Select,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  TextStyle,
  DropZone,
  Thumbnail,
  PageActions,
  TextField,
  FormLayout,
  Button,
  Page,
  Modal,
  Stack,
  Caption,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import NavigationLayout from "../../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";

const EditMedia = () => {
  const { BlobServiceClient } = require("@azure/storage-blob");
  let url = "";
  const [imageKey, setImageKey] = useState("");
  const [thumbnailKey, setThumbnailKey] = useState("");
  const [popupActive, setPopupActive] = useState(false);

  const refImage = useRef("");
  var { albumId, mediaId } = useParams();
  const [isSaving, setIsSaving] = useState(true);
  const navigate = useNavigate();
  const [titleValue, setTitleValue] = useState("");
  const handleChangeTitle = (newValue) => {
    setItem({ ...item, title: newValue });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, titleAr: newValue });
    setTitleArError("");
  };
  const [titleArError, setTitleArError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [dateValue, setDateValue] = useState("");
  const handleChangeDate = (newValue) => {
    setItem({ ...item, date: newValue });
  };
  const [sourceValue, setSourceValue] = useState("");
  const handleChangeSource = (newValue) => {
    setItem({ ...item, source: newValue });
  };
  const handleChangeSourceAr = (newValue) => {
    setItem({ ...item, sourceAr: newValue });
  };
  const [locationValue, setLocationValue] = useState("");
  const handleChangeLocation = (newValue) => {
    setItem({ ...item, location: newValue });
  };
  const handleChangeLocationAr = (newValue) => {
    setItem({ ...item, locationAr: newValue });
  };
  const [licenseValue, setLicenseValue] = useState("");
  const handleChangeLicense = (newValue) => {
    setItem({ ...item, license: newValue });
  };
  const handleChangeLicenseAr = (newValue) => {
    setItem({ ...item, licenseAr: newValue });
  };
  const [videoIdValue, setVideoIdValue] = useState("");
  const handleChangeVideoId = (newValue) => {
    setItem({ ...item, videoId: newValue });
  };
  const [typeError, setTypeError] = useState("");

  const [filesMain, setFilesMain] = useState([]);
  const [imageError, setImageError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const handleDropZoneMain = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesMain((filesMain) => [...filesMain, ...acceptedFiles]),
    []
  );
  const fileUploadMain = !filesMain.length && <DropZone.FileUpload />;
  let uploadedFilesMain = filesMain.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesMain[filesMain.length - 1].name}
        source={
          validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
            ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesMain[filesMain.length - 1].name}{" "}
        <Caption>{filesMain[filesMain.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageMain = (filesMain) => {
    setPopupActive(true);
    console.log("filesMain=", filesMain[filesMain.length - 1]);
    axios
      .get(`admin/v1/sas/${filesMain[filesMain.length - 1].name}`)
      .then((result) => {
        console.log(result.data.data);
        url = result.data.data;
        const type = "photo";
        fctAzure(filesMain, url, type);
      })
      .catch((err) => console.log(""));
  };

  const [filesThumbnail, setFilesThumbnail] = useState([]);
  const [thumbnailError, setThumbnailError] = useState("");
  const handleDropZoneThumbnail = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesThumbnail((filesThumbnail) => [
        ...filesThumbnail,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadThumbnail = !filesThumbnail.length && <DropZone.FileUpload />;
  let uploadedFilesThumbnail = filesThumbnail.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesThumbnail[filesThumbnail.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesThumbnail[filesThumbnail.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesThumbnail[filesThumbnail.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesThumbnail[filesThumbnail.length - 1].name}{" "}
        <Caption>
          {filesThumbnail[filesThumbnail.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageThumbnail = (filesThumbnail) => {
    setPopupActive(true);
    console.log("filesThumbnail=", filesThumbnail[filesThumbnail.length - 1]);
    axios
      .get(`admin/v1/sas/${filesThumbnail[filesThumbnail.length - 1].name}`)
      .then((result) => {
        console.log(result.data.data);
        url = result.data.data;
        const type = "thumbnail";
        fctAzure(filesThumbnail, url, type);
      })
      .catch((err) => console.log(""));
  };

  const fctAzure = async (files, url, type) => {
    console.log("tessst=", files);
    const CONTAINER_NAME = "photos";
    //GET sasURL from API?

    const blobServiceClient = new BlobServiceClient(url);
    const containerClient =
      blobServiceClient.getContainerClient(CONTAINER_NAME);
    const filename = files[files.length - 1].name.substring(
      0,
      files[files.length - 1].name.lastIndexOf(".")
    );
    const ext = files[files.length - 1].name.substring(
      files[files.length - 1].name.lastIndexOf(".")
    );
    const blobName = filename + "_" + new Date().getTime() + ext;
    type === "photo" ? setImageKey(blobName) : setThumbnailKey(blobName);
    console.log("test=", blobName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
      files[files.length - 1]
    );
    console.log(
      `Upload block blob ${files[files.length - 1].name} successfully`,
      uploadBlobResponse.clientRequestId
    );

    type === "photo"
      ? setItem({
          ...item,
          image:
            validImageTypes.indexOf(files[files.length - 1].type) > 0
              ? window.URL.createObjectURL(files[files.length - 1])
              : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
        })
      : setItem({
          ...item,
          thumbnail:
            validImageTypes.indexOf(files[files.length - 1].type) > 0
              ? window.URL.createObjectURL(files[files.length - 1])
              : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
        });
    type === "photo" ? setImageError("") : setThumbnailError("");

    setPopupActive(false);
  };
  const [selectedType, setSelectedType] = useState("");
  const handleSelectChangeType = (newValue) => {
    setItem({ ...item, type: newValue });
  };
  const optionsType = [
    { label: "Photo", value: "1" },
    { label: "Video", value: "2" },
  ];
  const [optionsTags, setOptionsTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const [item, setItem] = useState({
    type: "",
    title: "",
    titleAr: "",
    date: "",
    source: "",
    location: "",
    license: "",
    videoId: "",
    sourceAr: "",
    locationAr: "",
    licenseAr: "",
    image: "",
    thumbnail: "",
  });
  async function fetchData() {
    let responseTags = "";
    let responseMedia = "";

    try {
      responseTags = await axios.get(`/admin/v1/tags`);
      responseTags?.data?.data?.data &&
        setOptionsTags(
          responseTags?.data?.data?.data?.map((item, index) => {
            return {
              label: item.title[Object.keys(item.title)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      const responseMedia = await axios.get(
        `/admin/v1/albums/${albumId}/media/${mediaId}`
      );
      console.log(responseMedia?.data?.data);
      responseMedia?.data?.data &&
        setItem({
          type:
            responseMedia?.data?.data?.type !== null
              ? String(responseMedia?.data?.data.type)
              : "",
          title: responseMedia?.data?.data?.title?.en
            ? responseMedia?.data?.data?.title?.en
            : "",
          titleAr: responseMedia?.data?.data?.title?.ar
            ? responseMedia?.data?.data?.title?.ar
            : "",
          date: responseMedia?.data?.data?.date
            ? responseMedia?.data?.data?.date
            : "",
          source: responseMedia?.data?.data?.source?.en
            ? responseMedia?.data?.data?.source?.en
            : "",
          location: responseMedia?.data?.data?.location?.en
            ? responseMedia?.data?.data?.location?.en
            : "",
          license: responseMedia?.data?.data?.license?.en
            ? responseMedia?.data?.data?.license?.en
            : "",
          sourceAr: responseMedia?.data?.data?.source?.ar
            ? responseMedia?.data?.data?.source?.ar
            : "",
          locationAr: responseMedia?.data?.data?.location?.ar
            ? responseMedia?.data?.data?.location?.ar
            : "",
          licenseAr: responseMedia?.data?.data?.license?.ar
            ? responseMedia?.data?.data?.license?.ar
            : "",
          videoId: responseMedia?.data?.data?.path
            ? responseMedia?.data?.data?.path
            : "",
          image: responseMedia?.data?.data?.path
            ? responseMedia?.data?.data?.path
            : "",
          thumbnail: responseMedia?.data?.data?.thumbnail
            ? responseMedia?.data?.data?.thumbnail
            : "",
        });

      responseMedia?.data?.data?.tags &&
        setSelectedTags(
          responseMedia?.data?.data?.tags.map((item, index) => {
            return {
              label: item.title?.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }
  return (
    <NavigationLayout>
      <Page
        fullWidth
        breadcrumbs={[
          {
            onAction: () =>
              navigate(`/admin/photos-and-videos/albums/${albumId}/medias`),
          },
        ]}
        title="Edit Media"
      >
        {isSaving ? <Loading /> : null}
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Media Type</TextStyle>
              </p>
              <Select
                options={optionsType}
                onChange={handleSelectChangeType}
                value={item.type}
                placeholder="Please Select"
                error={typeError}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Date</TextStyle>
              </p>
              <TextField
                value={item.date}
                onChange={handleChangeDate}
                type="date"
              />
            </FormLayout>
          </FormLayout.Group>
          <p className="greyText">
            <TextStyle>Thumbnail</TextStyle>
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={item.thumbnail ? item.thumbnail : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone
                onDrop={handleDropZoneThumbnail}
                onDropAccepted={sendImageThumbnail}
                // variableHeight
              >
                {uploadedFilesThumbnail}
                {fileUploadThumbnail}
              </DropZone>
            </div>
          </div>
          <InlineError message={thumbnailError}></InlineError>
          <p className="greyText">
            <TextStyle>{item.type === "1" ? "Photo" : "Video ID"}</TextStyle>
          </p>
          {item.type === "1" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div style={{ float: "left" }}>
                <Thumbnail size="large" source={item.image ? item.image : ""} />
              </div>
              <div
                style={{
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                <DropZone
                  onDrop={handleDropZoneMain}
                  onDropAccepted={sendImageMain}
                  // variableHeight
                >
                  {uploadedFilesMain}
                  {fileUploadMain}
                </DropZone>
              </div>
            </div>
          ) : (
            <TextField value={item.videoId} onChange={handleChangeVideoId} />
          )}
          <InlineError message={imageError}></InlineError>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Title En</TextStyle>
              </p>
              <TextField
                value={item.title}
                onChange={handleChangeTitle}
                error={titleError}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle align="right">Title Ar</TextStyle>
              </p>
              <TextField
                value={item.titleAr}
                onChange={handleChangeTitleAr}
                error={titleArError}
              />
            </FormLayout>
            {/* <FormLayout>
                <p className="greyText">
                  <TextStyle>Date</TextStyle>
                </p>
                <TextField
                  value={dateValue}
                  onChange={handleChangeDate}
                  type="date"
                />
              </FormLayout> */}
          </FormLayout.Group>
          {/* <InlineError message={titleError} /> */}

          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Source En</TextStyle>
              </p>
              <TextField value={item.source} onChange={handleChangeSource} />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Source Ar</TextStyle>
              </p>
              <TextField
                value={item.sourceAr}
                onChange={handleChangeSourceAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Location En</TextStyle>
              </p>
              <TextField
                value={item.location}
                onChange={handleChangeLocation}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Location Ar</TextStyle>
              </p>
              <TextField
                value={item.locationAr}
                onChange={handleChangeLocationAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>License En</TextStyle>
              </p>
              <TextField value={item.license} onChange={handleChangeLicense} />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>License Ar</TextStyle>
              </p>
              <TextField
                value={item.licenseAr}
                onChange={handleChangeLicenseAr}
              />
            </FormLayout>
          </FormLayout.Group>

          <p className="greyText">
            <TextStyle>Tags</TextStyle>
          </p>
          <MultiSelect
            options={optionsTags}
            value={selectedTags}
            onChange={setSelectedTags}
            labelledBy="Select"
          />

          {/* <Button primary>Save</Button> */}
        </FormLayout>
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
          secondaryActions={[
            {
              content: <p style={{ color: "white" }}>Delete</p>,
              destructive: true,
              loading: isSaving && true,
              onClick: () => {
                setIsSaving(true);
                axios
                  .delete(`admin/v1/albums/${albumId}/media/${mediaId}/delete`)
                  .then((result) => {
                    navigate(
                      `/admin/photos-and-videos/albums/${albumId}/medias`
                    );
                  })
                  .catch((err) => console.log(err));
              },
            },
          ]}
        />
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
  function handleSave() {
    if (
      !item.title ||
      !item.titleAr ||
      !item.type ||
      // (item.type === "1" && !refImage.current) ||
      (item.type === "2" && !item.videoId)
    ) {
      !item.title && setTitleError("Please enter a title");
      !item.titleAr && setTitleArError("Please enter an arabic title");

      !item.type && setTypeError("Please select a type");
      // item.type === "1" &&
      //   !refImage.current &&
      //   setImageError("Please upload an image");
      item.type === "2" &&
        !item.videoId &&
        setImageError("Please enter a video id");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      item.type && form_data.append("type", item.type);
      item.title && form_data.append("title[en]", item.title);
      item.titleAr && form_data.append("title[ar]", item.titleAr);
      item.date && form_data.append("date", item.date);
      item.source && form_data.append("source[en]", item.source);
      item.sourceAr && form_data.append("source[ar]", item.sourceAr);
      item.location && form_data.append("location[en]", item.location);
      item.locationAr && form_data.append("location[ar]", item.locationAr);
      item.license && form_data.append("license[en]", item.license);
      item.licenseAr && form_data.append("license[ar]", item.licenseAr);
      selectedTags.forEach((selected, index) =>
        form_data.append(`tags[${index}]`, selected.value)
      );
      item.type === "1" && imageKey && form_data.append("path", imageKey);
      item.type === "2" &&
        item.videoId &&
        form_data.append("path", item.videoId);
      form_data.append("_method", "PATCH");
      thumbnailKey && form_data.append("thumbnail", thumbnailKey);
      axios
        .post(`admin/v1/albums/${albumId}/media/${mediaId}/update`, form_data)
        .then((res) => {
          navigate(`/admin/photos-and-videos/albums/${albumId}/medias`);
        })
        .catch((err) => console.log(""));
    }
  }
};

export default EditMedia;
