import React, { useState, useCallback } from "react";
import axios from "../Assets/Lib/axios";
import {
  Button,
  Page,
  Loading,
  Card,
  ButtonGroup,
  TextField,
  FormLayout,
  Layout,
  Toast,
  Spinner,
  InlineError,
} from "@shopify/polaris";

function IduserPage2() {
  const [value, setValue] = useState("");
  const handleChange = useCallback((newValue) => setValue(newValue), []);
  const handleSubmit = () => {
    axios
      .get(`admin/v1/hayya-sync`)
      .then((res) => {})
      .catch((err) => console.log(""));
  };
  return (
    <FormLayout>
      <Button onClick={handleSubmit}>Refresh</Button>
    </FormLayout>
  );
}

export default IduserPage2;
