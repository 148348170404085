import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Select,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  TextStyle,
  Spinner,
  Toast,
  Modal,
  TextField,
  FormLayout,
  Button,
  Page,
  Card,
  PageActions,
  InlineError,
  DropZone,
  Stack,
  Thumbnail,
  Caption,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import NavigationLayout from "../../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";

const AddStudio = () => {
  const { BlobServiceClient } = require("@azure/storage-blob");
  const [imageKey, setImageKey] = useState("");
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(true);
  const [popupActive, setPopupActive] = useState(false);
  const [studioDateValue, setstudioDateValue] = useState("");
  const handleChangeStudioDate = (newValue) => {
    setstudioDateValue(newValue);
    setDateError("");
  };

  const [dateError, setDateError] = useState("");

  const [studioNameValue, setstudioNameValue] = useState("");
  const handleChangeStudioName = (newValue) => {
    setstudioNameValue(newValue);
    setTitleError("");
  };
  const [studioNameArValue, setstudioNameArValue] = useState("");
  const handleChangeStudioNameAr = (newValue) => {
    setstudioNameArValue(newValue);
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const [selectedStudioType, setSelectedStudioType] = useState("");
  const handleSelectChangeStudioType = (newValue) => {
    setSelectedStudioType(newValue);
    setTypeError("");
  };
  const [typeError, setTypeError] = useState("");

  const [optionsStudioType, setOptionsStudioType] = useState("");
  const [selectedStudioLocation, setSelectedStudioLocation] = useState("");
  const handleSelectChangeStudioLocation = useCallback(
    (newValue) => setSelectedStudioLocation(newValue),
    []
  );
  const [optionsStudioLocation, setOptionsStudioLocation] = useState("");
  const [studioStartTimeValue, setstudioStartTimeValue] = useState("");
  const handleChangeStudioStartTime = useCallback(
    (newValue) => setstudioStartTimeValue(newValue),
    []
  );
  const [studioEndTimeValue, setstudioEndTimeValue] = useState("");
  const handleChangeStudioEndTime = useCallback(
    (newValue) => setstudioEndTimeValue(newValue),
    []
  );
  const [capacityValue, setCapacityValue] = useState("");
  const handleChangeCapacity = useCallback(
    (newValue) => setCapacityValue(newValue),
    []
  );
  const [StudioDescriptionValue, setStudioDescriptionValue] = useState("");
  const handleChangeStudioDescription = useCallback(
    (newValue) => setStudioDescriptionValue(newValue),
    []
  );
  const [studioDescriptionArValue, setstudioDescriptionArValue] = useState("");
  const handleChangeStudioDescriptionAr = useCallback(
    (newValue) => setstudioDescriptionArValue(newValue),
    []
  );
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responseTypes = "";
    try {
      responseTypes = await axios.get(`admin/v1/studios/types`);
      responseTypes?.data?.data?.data &&
        setOptionsStudioType(
          responseTypes.data.data.data.map((item, index) => {
            return {
              label: item.title[Object.keys(item.title)[0]],
              value: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    setIsSaving(false);
  }

  const [imageError, setImageError] = useState("");
  const [filesMain, setFilesMain] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const handleDropZoneMain = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesMain((filesMain) => [...filesMain, ...acceptedFiles]),
    []
  );
  const fileUploadMain = !filesMain.length && <DropZone.FileUpload />;
  let uploadedFilesMain = filesMain.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesMain[filesMain.length - 1].name}
        source={
          validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
            ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesMain[filesMain.length - 1].name}{" "}
        <Caption>{filesMain[filesMain.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const [blobList, setBlobList] = useState([]);
  let url = "";
  const sendImageMain = (filesMain) => {
    setPopupActive(true);
    console.log("filesMain=", filesMain[filesMain.length - 1]);
    axios
      .get(`admin/v1/sas/${filesMain[filesMain.length - 1].name}`)
      .then((result) => {
        console.log(result.data.data);
        url = result.data.data;
        fctAzure(filesMain, url);

        //GET sasURL from API?

        // const blobServiceClient = new BlobServiceClient(url);
        // const containerClient =
        //   blobServiceClient.getContainerClient(CONTAINER_NAME);
        // const filename = file.name.substring(0, file.name.lastIndexOf("."));
        // const ext = file.name.substring(file.name.lastIndexOf("."));
        // const blobName = filename + "_" + new Date().getTime() + ext;
        // const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        // const uploadBlobResponse = await blockBlobClient.uploadBrowserData(file)
        // console.log(`Upload block blob ${file.name} successfully`, uploadBlobResponse.clientRequestId)
      })
      .catch((err) => console.log(""));
    // prepare UI
    // setUploading(true);

    // *** UPLOAD TO AZURE STORAGE ***
    // ////const blobsInContainer = await uploadFileToBlob(filesMain);

    // prepare UI for results
    // setBlobList(blobsInContainer);
    /////  setImagePreview(blobsInContainer[0]);

    // reset state/form
    // setFilesMain(null);
    //setUploading(false);
    // setInputKey(Math.random().toString(36));
  };

  const fctAzure = async (filesMain, url) => {
    console.log("tessst=", filesMain);
    const CONTAINER_NAME = "photos";
    //GET sasURL from API?

    const blobServiceClient = new BlobServiceClient(url);
    const containerClient =
      blobServiceClient.getContainerClient(CONTAINER_NAME);
    const filename = filesMain[filesMain.length - 1].name.substring(
      0,
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const ext = filesMain[filesMain.length - 1].name.substring(
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const blobName = filename + "_" + new Date().getTime() + ext;
    setImageKey(blobName);
    console.log("test=", blobName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
      filesMain[filesMain.length - 1]
    );
    console.log(
      `Upload block blob ${filesMain[filesMain.length - 1].name} successfully`,
      uploadBlobResponse.clientRequestId
    );

    setPopupActive(false);
    setImageError("");
    setImagePreview(
      validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
        ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
  };
  return (
    <NavigationLayout>
      <Page
        fullWidth
        breadcrumbs={[
          { onAction: () => navigate("/admin/manage-studios/studios") },
        ]}
        title="Add Studio"
      >
        {isSaving ? <Loading /> : null}
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Studio Name En</TextStyle>
              </p>
              <TextField
                value={studioNameValue}
                onChange={handleChangeStudioName}
              />
              <InlineError message={titleError} />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Studio Name Ar</TextStyle>
              </p>
              <TextField
                value={studioNameArValue}
                onChange={handleChangeStudioNameAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <p className="greyText">
            <TextStyle>Image</TextStyle>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone
                onDrop={handleDropZoneMain}
                onDropAccepted={sendImageMain}
                // variableHeight
              >
                {uploadedFilesMain}
                {fileUploadMain}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError}></InlineError>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Studio Type</TextStyle>
              </p>
              <Select
                options={optionsStudioType}
                onChange={handleSelectChangeStudioType}
                value={selectedStudioType}
                placeholder="Please select a type"
                error={typeError}
              />
            </FormLayout>
          </FormLayout.Group>

          <p className="greyText">
            <TextStyle>OPERATION TIME</TextStyle>
          </p>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>From</TextStyle>
              </p>
              <TextField
                value={studioStartTimeValue}
                onChange={handleChangeStudioStartTime}
                type="time"
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>To</TextStyle>
              </p>
              <TextField
                value={studioEndTimeValue}
                onChange={handleChangeStudioEndTime}
                type="time"
              />
            </FormLayout>
          </FormLayout.Group>

          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Interviewee Description En</TextStyle>
              </p>
              <TextField
                value={StudioDescriptionValue}
                onChange={handleChangeStudioDescription}
                multiline={4}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Interviewee Description Ar</TextStyle>
              </p>
              <TextField
                value={studioDescriptionArValue}
                onChange={handleChangeStudioDescriptionAr}
                multiline={4}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
        </FormLayout>
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
        />
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
  function handleSave() {
    if (
      (!studioNameValue && !studioNameArValue) ||
      !imageKey ||
      selectedStudioType === ""
    ) {
      !studioNameValue &&
        !studioNameArValue &&
        setTitleError("Please enter a studio name");
      !imageKey && setImageError("Please upload an image");
      selectedStudioType === "" && setTypeError("Please select a type");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      studioNameValue && form_data.append("name[en]", studioNameValue);
      studioNameArValue && form_data.append("name[ar]", studioNameArValue);
      StudioDescriptionValue &&
        form_data.append("description[en]", StudioDescriptionValue);
      studioDescriptionArValue &&
        form_data.append("description[ar]", studioDescriptionArValue);
      studioStartTimeValue &&
        form_data.append("start_time", studioStartTimeValue);
      studioEndTimeValue && form_data.append("end_time", studioEndTimeValue);
      selectedStudioType !== "" &&
        form_data.append("type_id", selectedStudioType);
      imageKey && form_data.append("image", imageKey);
      axios
        .post("admin/v1/studios", form_data)
        .then((res) => {
          navigate("/admin/manage-studios/studios");
        })
        .catch((err) => console.log(""));
    }
  }
};

export default AddStudio;
