import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Image,
  DisplayText,
  Loading,
  TextStyle,
  Spinner,
  Toast,
  Modal,
  TextField,
  FormLayout,
  Button,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import emptyListImage from "../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import flagRed from "../../Assets/Images/flag-red.svg";
import flag from "../../Assets/Images/flag.svg";
import Cookies from "js-cookie";
import filterGrey from "../../Assets/Images/filter-grey.svg";
import filterOrange from "../../Assets/Images/filter-orange.svg";
import FilterCloseButton from "../../Components/FilterCloseButton";
import FilterParentDiv from "../../Components/FilterParentDiv";
import { useNavigate } from "react-router-dom";
import { isDefined, adminRole } from "../../Helpers";

const TableOfPermits = (props) => {
  const [popupActive, setPopupActive] = useState(false);
  const navigate = useNavigate();
  const [ts, setTs] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const refReferenceNumber = useRef(null);
  const refName = useRef(null);
  const refIsWireless = useRef(null);
  const refEmail = useRef(null);
  const refArrivalDate = useRef(null);
  const refDocumentNumber = useRef(null);
  const refOrganisation = useRef(null);
  const refCamera = useRef(null);
  const refAccreditationType = useRef("");
  const refCreatedAt = useRef(null);
  const refStatus = useRef(null);
  const format = "YY-MM-DD HH:mm";
  const reference = useRef("");
  const reference2 = useRef("");
  const mailIdRef = useRef("");
  const perPage = 100;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(
    parseInt(localStorage.getItem("permits-selected-page"))
      ? parseInt(localStorage.getItem("permits-selected-page"))
      : 1
  );
  const [sortState, setSortState] = useState("-id");
  const [filterState, setFilterState] = useState("");
  const handleChangePage = (event, value) => {
    refBoolPage.current = true;
    localStorage.setItem("permits-selected-page", value);
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    getData();
  }, [page, filterState, searchValue, ts]);
  const [isHovered, setIsHovered] = useState(false);
  const handleCancel = () => {
    setActiveEmail(false);
  };
  const [emailMessageValue, setEmailMessageValue] = useState("");
  const handleChangeEmailMessage = useCallback(
    (newValue) => setEmailMessageValue(newValue),
    []
  );

  const handleSend = () => {
    setIsSendingEmail(true);
    const form_data = new FormData();

    form_data.append("message", emailMessageValue);
    form_data.append("_method", "PATCH");

    axios
      .post(
        `admin/v1/registration-requests/${mailIdRef.current}/email`,
        form_data
      )
      .then((res) => {
        setActiveEmail(false);
        setIsSendingEmail(false);
        setToastContent("Email Sent Successfully");
        toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => console.log(""));
  };

  const handleFlag = (e) => {
    // isFlagged ? setIsFlagged(false) : setIsFlagged(true);
    const form_data = new FormData();
    form_data.append("_method", "PATCH");
    form_data.append(
      "is_flagged",
      !JSON.parse(e.currentTarget.id).is_flagged ? 1 : 0
    );
    axios
      .post(
        `admin/v1/registration-requests/${
          JSON.parse(e.currentTarget.id).id
        }/flag`,
        form_data
      )
      .then((res) => {
        setTs(+new Date());
        //getData();
      })
      .catch((err) => console.log(""));
  };
  const switchStatus = (param) => {
    console.log("test=", param);
    switch (param) {
      case "submitted":
        return "Submitted";
      case "approved_cra":
        return "Approved CRA";
      case "rejected_cra":
        return "Rejected CRA";
      case "approved_qna":
        return "Approved QNA";
      case "rejected_qna":
        return "Rejected QNA";
      case "entered":
        return "Entered";
      case "confiscated":
        return "Confiscated";
      case "":
        return "All";
      case "All":
        return "All";
      default:
        return "";
    }
  };
  const switchStatusColor = (status) => {
    switch (status) {
      case "submitted":
        return "#FFBF00";
      case "approved_cra":
      case "approved_qna":
      case "entered":
        return "#00CFB7";
      case "rejected_cra":
      case "rejected_qna":
      case "confiscated":
        return "#B85555";
      default:
        return "";
    }
  };

  const [activeEmail, setActiveEmail] = useState(false);
  const handleEmail = (e) => {
    mailIdRef.current = JSON.parse(e.currentTarget.id).id;
    setActiveEmail(true);
  };

  const switchParam = (param) => {
    switch (param) {
      case "0":
        return "all-requests";
      case "1":
        return "gco-requests";
      case "2":
        return "qna-requests";
      case "3":
        return "hayya-requests";
      default:
      // code block
    }
  };

  const switchAcceditationType = (accreditationType) => {
    return accreditationType?.details?.label;
  };
  const handleExport = () => {
    setPopupActive(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}admin/v1/permits/export/${
          props.selectedTab === "all"
            ? "sc"
            : props.selectedTab === "cra"
            ? "cra"
            : props.selectedTab === "qna"
            ? "qna"
            : "customs"
        }?per_page=${page}&page=${page}${
          searchValue
            ? `&filter[${filterState}]=${
                searchValue.toLowerCase() !== "all"
                  ? searchValue.toLowerCase()
                  : ""
              }`
            : ""
        }`
      )
      .then((result) => {
        setPopupActive(false);
        setToastContent("Please check your email inbox in a few");
        toggleActiveToast();
      })
      .catch((err) => console.log(err));
  };

  const greyBackgroundCondition = (param) => {
    let bool = false;
    if (props.selectedTab === "all") {
      if (param === "submitted") {
        bool = true;
      }
    } else if (props.selectedTab === "cra") {
      if (param === "submitted") {
        bool = true;
      }
    } else if (props.selectedTab === "qna") {
      if (param === "approved_cra" || param === "submitted") {
        bool = true;
      }
    } else if (props.selectedTab === "customs") {
      if (param === "approved_qna" || param === "submitted") {
        bool = true;
      }
    }
    return bool;
  };
  const greyBackgroundColor = "rgba(196, 196, 188, 0.3)";
  const getData = () => {
    let str = "";
    if (props.selectedTab === "all") str = "permits";
    else if (props.selectedTab === "cra") str = "cra-permits";
    else if (props.selectedTab === "qna") str = "qna-permits";
    else if (props.selectedTab === "customs") str = "customs-permits";
    axios
      .get(
        `admin/v1/${str}?per_page=${perPage}&page=${
          refBoolPage.current ? page : 1
        }${
          searchValue && filterState
            ? `&filter[${filterState}]=${
                searchValue.toLowerCase() !== "all"
                  ? searchValue.toLowerCase()
                  : ""
              }`
            : ""
        }`
      )
      .then((result) => {
        setIsLoading(false);
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        let array = [];

        if (result?.data?.data?.data.length) {
          for (var i = 0; i < result?.data?.data?.data.length; i++) {
            const item = result?.data?.data?.data[i];

            array.push([
              <div
                  className={`itemClick ${
                      !greyBackgroundCondition(item.status)
                          ? "bookFont"
                          : "mediumFont"
                  }`}
                  id={JSON.stringify(item)}
                  onClick={(e) => handleRowClick(e)}
                  style={{
                    backgroundColor:
                        greyBackgroundCondition(item.status) && greyBackgroundColor,
                  }}
              >
                {item?.id ? (
                    parseInt(item?.id)
                ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                  className={`itemClick ${
                      !greyBackgroundCondition(item.status)
                          ? "bookFont"
                          : "mediumFont"
                  }`}
                  id={JSON.stringify(item)}
                  onClick={(e) => handleRowClick(e)}
                  style={{
                    backgroundColor:
                        greyBackgroundCondition(item.status) && greyBackgroundColor,
                  }}
              >
                {item?.application?.user?.first_name || item?.application?.user?.last_name ? (
                    `${item.application?.user?.first_name && item.application?.user?.first_name} ${
                        item.application?.user?.last_name && item.application?.user?.last_name
                    }`
                ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                  className={`itemClick ${
                      !greyBackgroundCondition(item.status)
                          ? "bookFont"
                          : "mediumFont"
                  }`}
                  id={JSON.stringify(item)}
                  onClick={(e) => handleRowClick(e)}
                  style={{
                    backgroundColor:
                        greyBackgroundCondition(item.status) && greyBackgroundColor,
                  }}
              >
                {item?.application?.passport_number || item?.application?.document_number ? (
                    item?.application?.passport_number ? (
                        item?.application?.passport_number
                    ) : (
                        item?.application?.document_number
                    )
                ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                  className={`itemClick ${
                      !greyBackgroundCondition(item.status)
                          ? "bookFont"
                          : "mediumFont"
                  }`}
                  id={JSON.stringify(item)}
                  onClick={(e) => handleRowClick(e)}
                  style={{
                    backgroundColor:
                        greyBackgroundCondition(item.status) && greyBackgroundColor,
                  }}
              >
                {item?.arrival_date ? (
                    item?.arrival_date
                ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                  className={`itemClick ${
                      !greyBackgroundCondition(item.status)
                          ? "bookFont"
                          : "mediumFont"
                  }`}
                  id={JSON.stringify(item)}
                  onClick={(e) => handleRowClick(e)}
                  style={{
                    backgroundColor:
                        greyBackgroundCondition(item.status) && greyBackgroundColor,
                  }}
              >
                {item?.application?.organisation?.name ? (
                    item?.application.organisation.name
                ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                  className={`itemClick ${
                      !greyBackgroundCondition(item.status)
                          ? "bookFont"
                          : "mediumFont"
                  }`}
                  id={JSON.stringify(item)}
                  onClick={(e) => handleRowClick(e)}
                  style={{
                    backgroundColor:
                        greyBackgroundCondition(item.status) && greyBackgroundColor,
                  }}
              >
                {item.created_at ? (
                    <div>
                      {moment(item.created_at).format(format).split(" ")[0]}
                      <span>&nbsp;&nbsp;</span>
                      {moment(item.created_at).format(format).split(" ")[1]}
                    </div>
                ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                  className={`itemClick ${
                      !greyBackgroundCondition(item.status)
                          ? "bookFont"
                          : "mediumFont"
                  }`}
                  id={JSON.stringify(item)}
                  onClick={(e) => handleRowClick(e)}
                  style={{
                    backgroundColor:
                        greyBackgroundCondition(item.status) && greyBackgroundColor,
                  }}
              >
                {item?.devices_quantity !== null ? (
                    item?.devices_quantity
                ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                  className={`itemClick ${
                      !greyBackgroundCondition(item.status)
                          ? "bookFont"
                          : "mediumFont"
                  }`}
                  id={JSON.stringify(item)}
                  onClick={(e) => handleRowClick(e)}
                  style={{
                    backgroundColor:
                        greyBackgroundCondition(item.status) && greyBackgroundColor,
                  }}
              >
                {item?.devices && item?.devices?.length ? (
                    "$ " +
                    item.devices.reduce(
                        (accumulator, current) =>
                            accumulator + current.estimated_value,
                        0
                    )
                ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                  className={`itemClick ${
                      !greyBackgroundCondition(item.status)
                          ? "bookFont"
                          : "mediumFont"
                  }`}
                  id={JSON.stringify(item)}
                  onClick={(e) => handleRowClick(e)}
                  style={{
                    backgroundColor:
                        greyBackgroundCondition(item.status) && greyBackgroundColor,
                  }}
              >
                {item?.devices && item?.devices?.length ? (
                    item?.devices?.filter(({ is_wireless }) => is_wireless) &&
                    item?.devices?.filter(({ is_wireless }) => is_wireless)
                        ?.length ? (
                        <div style={{ color: "#03c623", background: "#F0F6FF"
                        ,padding: "0px 20px",
                        width: "fit-content",
                        borderRadius: "20px"
                      }}>Yes</div>
                    ) : (
                        <div style={{ color: "red" , background: "#F0F6FF"
                        ,padding: "0px 20px",
                        width: "fit-content",
                        borderRadius: "20px"}}>No</div>
                    )
                ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                  className={`itemClick ${
                      !greyBackgroundCondition(item.status)
                          ? "bookFont"
                          : "mediumFont"
                  }`}
                  id={JSON.stringify(item)}
                  onClick={(e) => handleRowClick(e)}
                  style={{
                    backgroundColor:
                        greyBackgroundCondition(item.status) && greyBackgroundColor,
                  }}
              >
                {props.selectedTab === "customs" &&
                item?.devices &&
                item?.devices?.length ? (
                    item?.devices?.filter(({ category }) => category === "1") &&
                    item?.devices?.filter(({ category }) => category === "1")
                        ?.length ? (
                        <div style={{ color: "#03c623" }}>Yes</div>
                    ) : (
                        <div style={{ color: "red" }}>No</div>
                    )
                ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                  className={`itemClick ${
                      !greyBackgroundCondition(item.status)
                          ? "bookFont"
                          : "mediumFont"
                  }`}
                  id={JSON.stringify(item)}
                  onClick={(e) => handleRowClick(e)}
                  style={{
                    backgroundColor:
                        greyBackgroundCondition(item.status) && greyBackgroundColor,
                    color:
                        parseInt(item?.application?.accreditation_type_id) === 2
                            ? "#550065"
                            : "#00671d",
                  }}
              >
                {item?.application?.accreditation_type_id !== null &&
                parseInt(item.application?.accreditation_type_id) !== 1 ? (
                    switchAcceditationType(item.application?.accreditation_type)
                ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,

              <div
                  className={`itemClick ${
                      !greyBackgroundCondition(item.status)
                          ? "bookFont"
                          : "mediumFont"
                  }`}
                  id={JSON.stringify(item)}
                  onClick={(e) => handleRowClick(e)}
                  style={{
                    color: switchStatusColor(item.status.toLowerCase()),
                    backgroundColor:
                        greyBackgroundCondition(item.status) && greyBackgroundColor,
                  }}
              >
                {item?.status ? (
                    <div>
                      <div>
                        {item?.status.charAt(0).toUpperCase() +
                            item?.status
                                .slice(1)
                                .replace("_", " ")
                                .replace("-", " ")
                                .replace("cra", "CRA")
                                .replace("qna", "QNA")
                                .replace("customs", "Customs")}
                      </div>
                      {item?.message && (
                          <p className="tableMessage">{item?.message}</p>
                      )}
                    </div>
                ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                )}
              </div>,
              <div
                  className={`itemClick ${
                      !greyBackgroundCondition(item.status)
                          ? "bookFont"
                          : "mediumFont"
                  }`}
                  id={JSON.stringify(item)}
                  onClick={(e) => handleRowClick(e)}
                  style={{
                    backgroundColor:
                        greyBackgroundCondition(item.status) && greyBackgroundColor,
                  }}
              >
                <p style={{ visibility: "hidden" }}>empty</p>
              </div>,
            ]);
          }
        }

        array.unshift([
          <div
            id="firstTableItemReference"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-TextField">
                <input
                  id="PolarisTextField2"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField2Label"
                  aria-invalid="false"
                  defaultValue={""}
                  onChange={handleChangeReferenceSearchValue}
                  ref={refReferenceNumber}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition="id"
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refReferenceNumber}
                ></FilterCloseButton>

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,

          <div
            id="firstTableItemName"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-TextField">
                <input
                  id="PolarisTextField2"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField2Label"
                  aria-invalid="false"
                  defaultValue={""}
                  onChange={handleChangeNameSearchValue}
                  ref={refName}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition="name"
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refName}
                ></FilterCloseButton>

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,

          <div
            id="firstTableItemPassport"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-TextField">
                <input
                  id="PolarisTextField6"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField6Label"
                  aria-invalid="false"
                  defaultValue={""}
                  onChange={handleChangeDocumentNumber}
                  ref={refDocumentNumber}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition="document_number"
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refDocumentNumber}
                ></FilterCloseButton>
                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItemArrivalDate"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-TextField">
                <input
                  id="PolarisTextField8"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField8Label"
                  aria-invalid="false"
                  defaultValue={""}
                  onChange={requeshandleChangeArrivalDate}
                  ref={refArrivalDate}
                  type="date"
                />

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,

          <div
            id="firstTableItemOrganisation"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{ visibility: "hidden" }}
              >
                <input
                  id="PolarisTextField6"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField6Label"
                  aria-invalid="false"
                  defaultValue={""}
                  onChange={requeshandleChangeOrganization}
                  ref={refDocumentNumber}
                />
                <FilterCloseButton
                  searchValue={searchValue}
                  filterState={filterState}
                  filterCondition="organisation_role"
                  handleDeleteTextField={handleDeleteTextField}
                  refr={refOrganisation}
                ></FilterCloseButton>
                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,

          <div
            id="firstTableItemTime"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-TextField">
                <input
                  id="PolarisTextField14"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField14Label"
                  aria-invalid="false"
                  defaultValue={""}
                  onChange={handleChangeTimeOfRegistrationSearchValue}
                  type="date"
                  ref={refCreatedAt}
                />

                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItemQuantity"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{ visibility: "hidden" }}
              >
                <input
                  id="PolarisTextField18"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField18Label"
                  aria-invalid="false"
                  defaultValue={searchValue}
                />
                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItemEstimatedValue"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div
                className="Polaris-TextField"
                style={{ visibility: "hidden" }}
              >
                <input
                  id="PolarisTextField18"
                  autoComplete="off"
                  className="Polaris-TextField__Input"
                  aria-labelledby="PolarisTextField18Label"
                  aria-invalid="false"
                  defaultValue={searchValue}
                />
                <div className="Polaris-TextField__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItemWireless"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-Select" style={{ visibility: "hidden" }}>
                <select
                  id="PolarisSelect3"
                  className="Polaris-Select__Input"
                  aria-invalid="false"
                  onChange={handleChangeIsWireless}
                  defaultValue={""}
                  ref={refIsWireless}
                >
                  <option value="All">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                <div className="Polaris-Select__Content" aria-hidden="true">
                  <span className="Polaris-Select__SelectedOption">
                    {!refIsWireless.current
                      ? "All"
                      : switchIsWireless(isWirelessValue)}
                  </span>
                  <span className="Polaris-Select__Icon">
                    <span className="Polaris-Icon">
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="Polaris-Select__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItemCamera"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div
                className="Polaris-Select"
                style={{
                  visibility:
                    props.selectedTab !== "customs" ? "hidden" : "hidden",
                }}
              >
                <select
                  id="PolarisSelect3"
                  className="Polaris-Select__Input"
                  aria-invalid="false"
                  onChange={handleChangeIsCamera}
                  defaultValue={""}
                  ref={refCamera}
                >
                  <option value="All">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                <div className="Polaris-Select__Content" aria-hidden="true">
                  <span className="Polaris-Select__SelectedOption">
                    {!refIsWireless.current
                      ? "All"
                      : switchIsCamera(isCameraValue)}
                  </span>
                  <span className="Polaris-Select__Icon">
                    <span className="Polaris-Icon">
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="Polaris-Select__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItemUserType"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-Select">
                <select
                  id="PolarisSelect3"
                  className="Polaris-Select__Input"
                  aria-invalid="false"
                  onChange={handleChangeTypeSearchValue}
                  defaultValue={""}
                  ref={refAccreditationType}
                >
                  <option value="All">All</option>
                  <option value="4">Lusail</option>
                  <option value="3">HC</option>
                  <option value="2">FIFA</option>
                  <option value="1">Non Accredited</option>
                </select>
                <div className="Polaris-Select__Content" aria-hidden="true">
                  <span className="Polaris-Select__SelectedOption">
                    {!refAccreditationType.current
                      ? "All"
                      : switchType(typeSearchValue)}
                  </span>
                  <span className="Polaris-Select__Icon">
                    <span className="Polaris-Icon">
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="Polaris-Select__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItemStatus"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <div className="Polaris-Select">
                <select
                  id="PolarisSelect2"
                  className="Polaris-Select__Input"
                  aria-invalid="false"
                  onChange={handleChangeStatusSearchValue}
                  defaultValue={""}
                  ref={refStatus}
                >
                  <option value="All">All</option>
                  <option value="submitted">Submitted</option>
                  <option value="approved_cra">Approved CRA</option>
                  <option value="rejected_cra">Rejected CRA</option>
                  <option value="approved_qna">Approved QNA</option>
                  <option value="rejected_qna">Rejected QNA</option>
                  <option value="entered">Entered</option>
                  <option value="confiscated">Confiscated</option>
                </select>
                <div className="Polaris-Select__Content" aria-hidden="true">
                  <span className="Polaris-Select__SelectedOption">
                    {!refStatus.current
                      ? "All"
                      : switchStatus(statusSearchValue)}
                  </span>
                  <span className="Polaris-Select__Icon">
                    <span className="Polaris-Icon">
                      <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="m10 16-4-4h8l-4 4zm0-12 4 4H6l4-4z" />
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="Polaris-Select__Backdrop" />
              </div>
            </FilterParentDiv>
          </div>,
          <div
            id="firstTableItemExport"
            style={{ display: filterClicked ? "block" : "none" }}
          >
            <FilterParentDiv filterClicked={filterClicked}>
              <Button onClick={handleExport}>Export</Button>
            </FilterParentDiv>
          </div>,
        ]);

        setItems(array);

        props.selectedTab === "0" &&
          props.onChangerequestsNumber(
            result?.data?.data?.data
              .filter(({ status }) => status.toLowerCase().includes("new"))
              .reduce((accumulator) => accumulator + 1, 0)
          );
        props.selectedTab === "1" &&
          props.onChangerequestsNumber(
            result?.data?.data?.data
              .filter(({ status }) =>
                status.toLowerCase().includes("approved_sc")
              )
              .reduce((accumulator) => accumulator + 1, 0)
          );
      })
      .catch((err) => setIsLoading(false));
  };
  const switchIsWireless = (param) => {
    switch (String(param)) {
      case "no":
        return "No";
      case "yes":
        return "Yes";
      case "":
        return "All";
      case "All":
        return "All";
      default:
        return null;
      // code block
    }
  };
  const switchIsCamera = (param) => {
    switch (String(param)) {
      case "no":
        return "No";
      case "yes":
        return "Yes";
      case "":
        return "All";
      case "All":
        return "All";
      default:
        return null;
      // code block
    }
  };
  const switchType = (param) => {
    switch (param) {
      case "1":
        return "Non Accredited";
      case "2":
        return "FIFA";
      case "3":
        return "HC";
      case "4":
        return "Lusail";
      case "All":
        return "All";
      default:
        return null;
      // code block
    }
  };
  const handleDeleteTextField = (param) => {
    param.current.value = "";
    setSearchValue("");
  };

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );

  const handleRowClick = (e) => {
    let str = "";
    if (props.selectedTab === "all") str = "all-permits";
    else if (props.selectedTab === "cra") str = "cra-permits";
    else if (props.selectedTab === "qna") str = "qna-permits";
    else if (props.selectedTab === "customs") str = "customs-permits";
    window.open(
      `/admin/equipment-clearance/${str}/${JSON.parse(e.currentTarget.id).id}`,
      "_blank"
    );
  };
  const [active, setActive] = useState(false);
  const handleChangeReasonOfRejection = useCallback(
    (newValue) => setReasonOfRejectionValue(newValue),
    []
  );

  const [rejectClicked, setRejectClicked] = useState(false);
  const [reasonOfRejectionValue, setReasonOfRejectionValue] = useState("");

  const [activeToast, setActiveToast] = useState(false);
  const toggleActiveToast = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );
  const [toastContent, setToastContent] = useState("");
  const toastMarkup = activeToast ? (
    <Toast
      content={toastContent}
      onDismiss={toggleActiveToast}
      duration="10000"
    />
  ) : null;
  const [activeAreYouSure, setActiveAreYouSure] = useState(false);
  const [questionValue, setQuestionValue] = useState(false);
  const handleNo = () => {
    setActiveAreYouSure(false);
  };
  const handleRowApprove = (e) => {
    reference.current = JSON.parse(e.currentTarget.id).id + "_" + "approve";
    reference2.current =
      JSON.parse(e.currentTarget.id).accreditation_id +
      "_" +
      JSON.parse(e.currentTarget.id).status_level;
    setActiveAreYouSure(true);
    setQuestionValue("Are you sure you want to approve this request?");
  };
  const handleRowReject = (e) => {
    reference.current = JSON.parse(e.currentTarget.id).id + "_" + "reject";
    reference2.current =
      JSON.parse(e.currentTarget.id).accreditation_id +
      "_" +
      JSON.parse(e.currentTarget.id).status_level;
    setActiveAreYouSure(true);
    setQuestionValue("Are you sure you want to reject this request?");
  };
  const handleYes = () => {
    const form_data = new FormData();
    form_data.append(
      "status",
      reference.current.split("_")[1] === "approve" ? "approved" : "rejected"
    );
    reference.current.split("_")[1] === "reject" &&
      form_data.append("reason_of_rejection", reasonOfRejectionValue);
    form_data.append("_method", "PATCH");

    axios
      .post(
        `admin/v1/registration-requests/${
          reference.current.split("_")[0]
        }/status${
          props.selectedTab === "0" &&
          reference2.current.split("_")[0] !== 1 &&
          reference2.current.split("_")[1] === "SC"
            ? "/sc"
            : ""
        }${props.selectedTab === "1" ? "/gco" : ""}${
          props.selectedTab === "2" ? "/qna" : ""
        }`,
        form_data
      )
      .then((res) => {
        setRejectClicked(false);
        setActive(false);
        setActiveAreYouSure(false);
        setToastContent(
          reference.current.split("_")[1] === "approve"
            ? "Request Approved Successfully"
            : "Request Rejected Successfully"
        );
        toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => console.log(""));
  };

  const [selectedSubTab, setSelectedSubTab] = useState(0);

  const handleTabChangeSubTab = useCallback(
    (selectedTabIndex) => setSelectedSubTab(selectedTabIndex),
    []
  );

  const resetValues = (param) => {
    param !== "refReferenceNumber" && (refReferenceNumber.current.value = "");
    param !== "refName" && (refName.current.value = "");
    // param !== "refEmail" && (refEmail.current.value = "");
    param !== "refDocumentNumber" && (refDocumentNumber.current.value = "");
    param !== "refArrivalDate" && (refArrivalDate.current.value = "");
    param !== "refAccreditationType" && (refAccreditationType.current = "");
    // param !== "ref6" && (ref6.current.value = "");
    param !== "refCreatedAt" && (refCreatedAt.current.value = "");
    param !== "refStatus" && (refStatus.current = "");
    param !== "refIsWireless" && (refIsWireless.current = "");
  };
  const handleChangeReferenceSearchValue = (event) => {
    resetValues("refReferenceNumber");
    setFilterState("id");
    setSearchValue(event.target.value);
  };
  const handleChangeNameSearchValue = (event) => {
    resetValues("refName");
    setFilterState("name");
    setSearchValue(event.target.value);
  };
  const handleChangeEmailSearchValue = (event) => {
    resetValues("refEmail");
    setFilterState("email");
    setSearchValue(event.target.value);
  };
  const handleChangeDocumentNumber = (event) => {
    resetValues("refDocumentNumber");
    setFilterState("document_number");
    setSearchValue(event.target.value);
  };
  const requeshandleChangeOrganization = (event) => {
    resetValues("refOrganisation");
    setFilterState("organisation_role");
    setSearchValue(event.target.value);
  };
  const requeshandleChangeArrivalDate = (event) => {
    resetValues("refArrivalDate");
    setFilterState("arrival_date");
    setSearchValue(event.target.value);
  };
  const handleChangeNationalitySearchValue = (event) => {
    resetValues("refAccreditationType");
    setFilterState("nationality");
    setSearchValue(event.target.value);
  };
  // const handleChangeContactNumberSearchValue = (event) => {
  //   resetValues("ref6");
  //   setFilterState("phone");
  //   setSearchValue(event.target.value);
  // };
  const handleChangeTimeOfRegistrationSearchValue = (event) => {
    resetValues("refCreatedAt");
    setFilterState("created_at");
    setSearchValue(event.target.value);
  };

  const [statusSearchValue, setStatusSearchValue] = useState("All");
  const handleChangeStatusSearchValue = (event) => {
    console.log("testttt=", event.target.value);
    console.log("testttt2=", switchStatus(event.target.value));
    resetValues("refStatus");
    refStatus.current = event.target.value;
    setFilterState("status");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    setStatusSearchValue(event.target.value);
  };
  const [isWirelessValue, setIsWirelessValue] = useState("All");
  const handleChangeIsWireless = (event) => {
    resetValues("refIsWireless");
    refIsWireless.current = event.target.value;
    setFilterState("is_wireless");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    setIsWirelessValue(event.target.value !== "All" ? event.target.value : "");
  };
  const [isCameraValue, setIsCameraValue] = useState("All");
  const handleChangeIsCamera = (event) => {
    resetValues("refCamera");
    refCamera.current = event.target.value;
    setFilterState("camera");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    setIsCameraValue(event.target.value !== "All" ? event.target.value : "");
  };
  const [typeSearchValue, setTypeSearchValue] = useState("All");
  const handleChangeTypeSearchValue = (event) => {
    resetValues("refAccreditationType");
    refAccreditationType.current = event.target.value;
    setFilterState("accreditation_id");
    setSearchValue(event.target.value !== "All" ? event.target.value : "");
    setTypeSearchValue(event.target.value);
  };
  const array = [
    "firstTableItemReference",
    "firstTableItemName",
    "firstTableItemPassport",
    "firstTableItemArrivalDate",
    "firstTableItemTime",
    "firstTableItemQuantity",
    "firstTableItemEstimatedValue",
    "firstTableItemWireless",
    "firstTableItemUserType",
    "firstTableItemStatus",
    "firstTableItemCamera",
    "firstTableItemExport",
    "firstTableItemOrganisation",
  ];
  const [filterClicked, setFilterClicked] = useState(false);
  const handleFilter = () => {
    if (!filterClicked) {
      for (var i = 0; i < array.length; i++) {
        if (document.getElementById(array[i])) {
          document.getElementById(array[i]).style.display = "block";
        }
      }
    } else {
      resetValues("refReferenceNumber");
      resetValues("refName");
      resetValues("refEmail");
      resetValues("refDocumentNumber");
      resetValues("refOrganisation");
      resetValues("refAccreditationType");
      resetValues("refCreatedAt");
      resetValues("refStatus");
      resetValues("refIsWireless");
      setSearchValue("");

      for (var i = 0; i < array.length; i++) {
        document.getElementById(array[i]).style.display = "none";
      }
    }

    setFilterClicked(!filterClicked);
  };
  const [selected, setSelected] = useState("today");

  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const refBoolPage = useRef(true);

  return (
    <div>
      {loadingMarkup}

      <DataTable
        columnContentTypes={["text", "text", "text", "text"]}
        headings={[
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Request No.</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Applicant</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Document Number</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Date of Arrival</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Organisation</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Time of Submission</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Quantity</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>TTL Estimated Value</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>Includes Wireless?</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>
              {" "}
              {props.selectedTab === "customs" ? "Includes Camera?" : ""}
            </div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>User Type</div>
          </TextStyle>,
          <TextStyle>
            <div style={{ padding: "1.6rem" }}>
              {props.selectedTab === "customs"
                ? "Equipment Status"
                : "Approval Status"}
            </div>
          </TextStyle>,
          <TextStyle>
            <div style={{ float: "right", marginRight: "4rem" }}>
              <img
                className="filterImg"
                src={!filterClicked ? filterGrey : filterOrange}
                onClick={handleFilter}
              ></img>
            </div>
          </TextStyle>,
        ]}
        rows={items}
        // sortable={[true, true, true, true]}
        defaultSortDirection="descending"
        initialSortColumnIndex={0}
        onSort={handleSort}
        verticalAlign="middle"
        style={{ color: "red" }}
        // hoverable={false}
      />
      {totalPages >= 2 && (
        <div
          style={{
            textAlign: "center",
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            size="large"
          />
        </div>
      )}
      <Modal open={popupActive} loading={true}></Modal>

      <Modal open={activeEmail}>
        <Modal.Section>
          <FormLayout>
            <div className="popupGreyText">Type your message here</div>
            <TextField
              multiline={4}
              value={emailMessageValue}
              onChange={handleChangeEmailMessage}
            ></TextField>
          </FormLayout>

          <div
            className="rowDiv"
            style={{ float: "right", marginTop: "3rem", marginBottom: "2rem" }}
          >
            <button
              className={`button ${
                isSendingEmail ? "buttonDisabled" : "buttonApprove"
              }`}
              onClick={handleSend}
            >
              Send
            </button>
            <button className="button buttonEmail" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </Modal.Section>
      </Modal>

      <Modal open={activeAreYouSure}>
        <Modal.Section>
          <div
            className="popupBlackText"
            style={{ marginBottom: "4rem", fontSize: "1.6rem" }}
          >
            {questionValue}
          </div>
          {reference.current.split("_")[1] == "reject" && (
            <FormLayout>
              <div className="popupGreyText">Reason of Rejection</div>
              <TextField
                multiline={4}
                value={reasonOfRejectionValue}
                onChange={handleChangeReasonOfRejection}
              ></TextField>
            </FormLayout>
          )}
          <div
            className="rowDiv"
            style={{ float: "right", marginTop: "3rem", marginBottom: "2rem" }}
          >
            <button className="button buttonApprove" onClick={handleYes}>
              Yes
            </button>
            <button className="button buttonEmail" onClick={handleNo}>
              No
            </button>
          </div>
        </Modal.Section>
      </Modal>
      {emtyState}
      {toastMarkup}
    </div>
  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default TableOfPermits;
