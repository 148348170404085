import {azureAdClientId, azureAdSignInPolicy, azureAdUrlDomain} from "./global";

export const msalConfig = {
  auth: {
    // clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    // authority: process.env.REACT_APP_MICROSOFT_AUTHORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    clientId: azureAdClientId,
    authority: `https://${azureAdUrlDomain}.b2clogin.com/${azureAdUrlDomain}.onmicrosoft.com/${azureAdSignInPolicy}`,
    redirectUri: "/getToken",
    navigateToLoginRequestUrl: false,
    knownAuthorities: [`${azureAdUrlDomain}.b2clogin.com`],
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  // scopes: ["User.Read"],
  scopes: ['openid', 'offline_access'],
  extraQueryParameters: {ui_locales: "en" }
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
};
