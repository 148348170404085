import React, { useState, useCallback, useEffect } from 'react'
import NavigationLayout from "../../Components/NavigationLayout";
import {
    Modal,
    Page,
    Loading,
    Card,
    ButtonGroup,
    TextField,
    TextStyle,
    FormLayout,
    Layout,
    Select,
    Button,
    Spinner,
    DataTable,
    RadioButton,
    PageActions,
    Toast
} from "@shopify/polaris";
import { countryList } from '../../Assets/countries';
import { useNavigate } from "react-router-dom";
import axios from "../../Assets/Lib/axios";
import closeImage from "../../Assets/Images/close-popup.svg";


function AssociateUserForm() {
    const [isLoading, setIsLoading] = useState(true);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [isSaving, setIsSaving] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [organisationNameValue, setOrganisationNameValue] = useState([]);
    const [hrUsers, setHrUsers] = useState([]);
    const [selectedHrUser, setSelectedHrUser] = useState(null);
    const [selectedOrganisation, setSelectedOrganisation] = useState("");
    const [selectedHrUserId, setSelectedHrUserId] = useState(null);
    const [selectedOrganisationId, setSelectedOrganisationId] = useState("");
    const [toast, setToast] = useState(false);
    const toggleActive = useCallback(() => setToast((toast) => !toast), []);

    const navigate = useNavigate()

    const handleUserSelection = (userId) => {
        setSelectedHrUser(userId);
        setSelectedHrUserId(userId?.id)

    };
    const handleOrganisationChange = (newValue) => {
        // console.log(newValue);
        const selectedOrg = organisationNameValue.find(org => org.name === newValue);
        setSelectedOrganisationId(String(selectedOrg.id));
        setSelectedOrganisation(newValue);
    };
    // console.log(selectedOrganisationId);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);
    const handleSaveAndCloseModal = () => {
        if (selectedHrUser) {
            handleCloseModal()
        }
    }
    useEffect(() => {
        setIsLoading(true);
        axios
            .get(`/admin/v1/hr-users`)
            .then((result) => {
                setHrUsers(
                    result?.data.data.data.map((item) => ({
                        id: item.id,
                        firstName: item.first_name,
                        lastName: item.last_name,
                        email: item.email,
                        organisationId: item.organisation_id
                    }))
                );
            }
            )
            .catch((err) => {
                setIsLoading(false);
                console.log("error fetching HRs", err)
            }
            );
        setIsLoading(false);

    }, [])
    const fetchOrganisations = async () => {
        try {
            const response = await axios.get(`/admin/v1/all-organisations-list`);
            // console.log(response.data.data);
            return response.data.data.map(org => ({ id: org.id, name: org.name }));
        } catch (error) {
            console.error("Error fetching organisations:", error);
            throw error;
        }
    };
    useEffect(() => {
        const loadOrganisations = async () => {
            setIsLoading(true);
            try {
                const organisationNames = await fetchOrganisations();
                setOrganisationNameValue(organisationNames);
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        };

        loadOrganisations();
    }, [])
    const toastMarkup = toast ? (
        <Toast content=" Saved! " onDismiss={toggleActive} />
    ) : null;

    const filterSelectedOrganisationId = selectedOrganisationId + "";
    const organisationIdsSet = new Set();
    organisationIdsSet.add(filterSelectedOrganisationId);
    const hrUsersList = hrUsers?.filter(user => !organisationIdsSet.has(user.organisationId + ""));
    // const hrUsersList = hrUsers?.filter(user => user.organisationId+"" !== selectedOrganisationId);
    return (
        <NavigationLayout>
            <Page
                fullWidth
                title="Associate User"
                breadcrumbs={[{ url: "/admin/manage-organisation" }]}
            >
                {/* {loadingMarkup} */}
                {isSaving ? <Loading /> : null}
                <Card sectioned>
                    <FormLayout>

                        <FormLayout.Group>
                            <FormLayout>
                                <TextStyle variation="strong">Select Organisation</TextStyle>
                                <Select
                                    options={organisationNameValue.map(org => ({
                                        label: org.name,
                                        value: org.name,
                                        key: org.id
                                    }))}
                                    onChange={handleOrganisationChange}
                                    value={selectedOrganisation}
                                    placeholder="Please select"
                                    required
                                />
                            </FormLayout>
                            <FormLayout>
                                <TextStyle variation="strong">Select User</TextStyle>
                                <Button onClick={handleOpenModal}>
                                    {selectedHrUser ? selectedHrUser.firstName + " " + selectedHrUser.lastName : "Select User"}
                                </Button>
                                <Modal
                                    open={modalOpen}
                                    // onClose={handleCloseModal}
                                    large={true}
                                    title="HR List"
                                    primaryAction={{
                                        content: 'Save',
                                        onAction: handleSaveAndCloseModal,
                                    }}
                                >
                                    <Modal.Section >
                                        <div style={{ textAlign: "end" }}>
                                            <img
                                                src={closeImage}
                                                onClick={handleCloseModal}
                                                style={{
                                                    width: "25px",
                                                    marginLeft: "auto",
                                                    marginRight: "0",
                                                    cursor: "pointer",
                                                }}
                                            ></img></div>
                                    </Modal.Section>
                                    <Modal.Section>
                                        <DataTable
                                            columnContentTypes={["text", "text", "text", "text"]}
                                            headings={[
                                                // <TextStyle variation="strong">
                                                //     <div style={{ padding: "1.6rem" }}>ID</div>
                                                // </TextStyle>,
                                                <TextStyle variation="strong">
                                                    <div style={{ padding: "1.6rem" }}>Select</div>
                                                </TextStyle>,
                                                <TextStyle variation="strong">
                                                    <div style={{ padding: "1.6rem" }}>First Name</div>
                                                </TextStyle>,
                                                <TextStyle variation="strong">
                                                    <div style={{ padding: "1.6rem" }}>Last Name</div>
                                                </TextStyle>,
                                                <TextStyle variation="strong">
                                                    <div style={{ padding: "1.6rem" }}>Email</div>
                                                </TextStyle>,
                                            ]}
                                            rows={hrUsersList?.map((user) => [
                                                <div className={`itemClick`}>
                                                    <RadioButton
                                                        label=""
                                                        checked={selectedHrUser && selectedHrUser.id === user.id}
                                                        id={`hr-user-${user.id}`}
                                                        onChange={() => handleUserSelection(user)}
                                                    /></div>,
                                                <div className={`itemClick`}>{user.firstName}</div>,
                                                <div className={`itemClick`}>{user.lastName}</div>,
                                                <div className={`itemClick`}>{user.email}</div>,
                                            ])}
                                            sortable={[false, false, false, false]}
                                            defaultSortDirection="descending"
                                            initialSortColumnIndex={0}
                                            verticalAlign="middle"
                                        />
                                    </Modal.Section>

                                </Modal>
                            </FormLayout>
                        </FormLayout.Group>

                    </FormLayout>
                </Card>
                {toastMarkup}
                <PageActions
                    primaryAction={{
                        content: <p style={{ color: "white" }}>Save</p>,
                        onClick: handleSave,
                        loading: isSaving && true,
                    }}
                />
            </Page>
        </NavigationLayout>
    );
    // function handleSave() {
    //     return
    // }
    function handleSave() {
        setIsLoading(true);
        if (!selectedHrUserId || !selectedOrganisationId) {
            return;
        }
        setSelectedHrUser("");
        setSelectedOrganisation("");
        const form_data = new FormData();
        form_data.append("organisation_id", selectedOrganisationId);
        form_data.append("id", selectedHrUserId);
        form_data.append("_method", "PATCH");
        axios
            .post(`admin/v1/hr-users/${selectedHrUserId}`, form_data)
            .then((res) => {
                // console.log(res + "success")
                setToast(true)
                navigate('/admin/manage-organisation')
                // window.location.reload();
            })
            .catch((err) => console.log("error associate hr", err))
            .finally((e) => {
                setIsLoading(false);
                // setToast(false)
            });
        setToast(false);
    };
}

export default AssociateUserForm;