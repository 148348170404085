import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Image,
  DisplayText,
  Loading,
  TextStyle,
  Spinner,
  Toast,
  Modal,
  TextField,
  FormLayout,
  Page,
  Card,
  Button,
  Filters,
  ChoiceList,
  ButtonGroup,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import emptyListImage from "../../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import filterGrey from "../../../Assets/Images/filter-grey.svg";
import filterOrange from "../../../Assets/Images/filter-orange.svg";
import Cookies from "js-cookie";
import FilterParentDivEvents from "../../../Components/FilterParentDivEvents";
import { useNavigate } from "react-router-dom";
import NavigationLayout from "../../../Components/NavigationLayout";
import { useParams } from "react-router-dom";
import rowAccept from "../../../Assets/Images/row-accept.svg";
import rowReject from "../../../Assets/Images/row-reject.svg";
const TableOfSubscribers = (props) => {
  const { id } = useParams();
  const [popupActive, setPopupActive] = useState(false);
  const navigate = useNavigate();
  const [ts, setTs] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [requestTotal, setRequestTotal] = useState(0);
  const refName = useRef(null);
  const refType = useRef(null);
  const refTime = useRef(null);
  const refLocation = useRef("");
  const refCreatedAt = useRef(null);
  const format = "YY-MM-DD HH:mm";
  const reference = useRef("");
  const reference2 = useRef("");
  const mailIdRef = useRef("");
  const perPage = 20;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const [filterState, setFilterState] = useState("");
  const handleChangePage = (event, value) => {
    refBoolPage.current = true;
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const [searchValue, setSearchValue] = useState("");
  const [selectedEventLocation, setSelectedEventLocation] = useState("All");
  const [selectedEventType, setSelectedEventType] = useState("All");
  const [eventStartTimeValue, setEventStartTimeValue] = useState("");
  const handleChangeEventStartTime = useCallback(
    (newValue) => setEventStartTimeValue(newValue),
    []
  );
  const [locations, setLocations] = useState("");
  const [types, setTypes] = useState("");
  const [allResult, setAllResult] = useState("");
  useEffect(() => {
    //fetchData();
  }, []);
  async function fetchData() {
    let responseTypes = "";
    let responseLocations = "";
    try {
      responseTypes = await axios.get(`admin/v1/events/types`);
      setTypes(responseTypes?.data?.data?.data);
      console.log("types=", responseTypes?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
    try {
      responseLocations = await axios.get(`admin/v1/events/locations`);
      setLocations(responseLocations?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, [page, queryValue, availability, ts]);
  const [isHovered, setgIsHovered] = useState(false);
  const handleCancel = () => {
    setActiveEmail(false);
  };
  const [emailMessageValue, setEmailMessageValue] = useState("");
  const handleChangeEmailMessage = useCallback(
    (newValue) => setEmailMessageValue(newValue),
    []
  );

  const handleSend = () => {
    setIsSendingEmail(true);
    const form_data = new FormData();

    form_data.append("message", emailMessageValue);
    form_data.append("_method", "PATCH");

    axios
      .post(
        `admin/v1/registration-requests/${mailIdRef.current}/email`,
        form_data
      )
      .then((res) => {
        setActiveEmail(false);
        setIsSendingEmail(false);
        setToastContent("Email Sent Successfully");
        toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => console.log(""));
  };

  const handleFlag = (e) => {
    // isFlagged ? setIsFlagged(false) : setIsFlagged(true);
    const form_data = new FormData();
    form_data.append("_method", "PATCH");
    form_data.append(
      "is_flagged",
      !JSON.parse(e.currentTarget.id).is_flagged ? 1 : 0
    );
    axios
      .post(
        `admin/v1/registration-requests/${
          JSON.parse(e.currentTarget.id).id
        }/flag`,
        form_data
      )
      .then((res) => {
        setTs(+new Date());
        //getData();
      })
      .catch((err) => console.log(""));
  };

  const [activeEmail, setActiveEmail] = useState(false);
  const handleEmail = (e) => {
    mailIdRef.current = JSON.parse(e.currentTarget.id).id;
    setActiveEmail(true);
  };

  const handleExport = () => {
    setPopupActive(true);
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }admin/v1/events/${id}/export/subscribers?token=${Cookies.get(
          "vmc-accesstoken"
        )}`
      )
      .then((result) => {
        setPopupActive(false);
        setToastContent("Please check your email inbox in a few");
        toggleActiveToast();
      })
      .catch((err) => console.log(err));
  };

  const greyBackgroundCondition = (param) => {
    let bool = false;
    if (param === "pending") bool = true;
    return bool;
  };
  const handleEdit = (param) => {
    navigate(`/admin/manage-events/events/${param}`);
  };
  const greyBackgroundColor = "rgba(196, 196, 188, 0.3)";
  const switchStatusColor = (param) => {
    switch (param) {
      case "rejected":
        return "#ff0000";
      case "approved":
        return "#6dc67c";
      case "pending":
        return "#7f7f7f";
      default:
        return "";
    }
  };
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const getData = () => {
    axios
      .get(
        `admin/v1/events/${id}/subscribers?per_page=${perPage}&page=${
          refBoolPage.current ? page : 1
        }&filter[${availability == "" ? "id" : availability}]=${queryValue}`
      )
      .then((result) => {
        setIsLoading(false);
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setRequestTotal(result.data.data.total);
        setAllResult(result.data);
        console.log("ppp=", result.data.data.total);

        setTotalPages(Math.ceil(result.data.data.total / perPage));
        let array = [];
        setItems(
          result.data.data.data.map((item, index) => [
            <div
              className={`itemClick2 ${
                !greyBackgroundCondition(item.status)
                  ? "bookFont"
                  : "mediumFont"
              }`}
              style={{
                backgroundColor:
                  greyBackgroundCondition(item.status) && greyBackgroundColor,
              }}
            >
              {item.id}
            </div>,
            <div
              className={`itemClick2 ${
                !greyBackgroundCondition(item.status)
                  ? "bookFont"
                  : "mediumFont"
              }`}
              style={{
                backgroundColor:
                  greyBackgroundCondition(item.status) && greyBackgroundColor,
              }}
            >
              <a
                onClick={() =>
                  window.open(
                    `/admin/requests/sc-requests/${item?.user?.id}`,
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <u>
                  {item?.user?.first_name || item?.user?.last_name ? (
                    `${item?.user?.first_name && item?.user?.first_name} ${
                      item?.user?.last_name && item?.user?.last_name
                    }`
                  ) : (
                    <p style={{ visibility: "hidden" }}>empty</p>
                  )}
                </u>
              </a>
            </div>,

            <div
              className={`itemClick2 ${
                !greyBackgroundCondition(item.status)
                  ? "bookFont"
                  : "mediumFont"
              }`}
              style={{
                backgroundColor:
                  greyBackgroundCondition(item.status) && greyBackgroundColor,
              }}
            >
              {item.user?.email ? (
                item?.user?.email
              ) : (
                <p style={{ visibility: "hidden" }}>empty</p>
              )}
            </div>,
            <div
              className={`itemClick2 ${
                !greyBackgroundCondition(item.status)
                  ? "bookFont"
                  : "mediumFont"
              }`}
              style={{
                backgroundColor:
                  greyBackgroundCondition(item.status) && greyBackgroundColor,
              }}
            >
              {item?.user?.organisation?.role?.type?.en ? (
                item?.user?.organisation?.role?.type?.en
              ) : (
                <p style={{ visibility: "hidden" }}>empty</p>
              )}
            </div>,
            <div
              className={`itemClick2 ${
                !greyBackgroundCondition(item.status)
                  ? "bookFont"
                  : "mediumFont"
              }}`}
              style={{
                backgroundColor:
                  greyBackgroundCondition(item.status) && greyBackgroundColor,
              }}
            >
              {item?.user?.organisation?.name ? (
                item?.user?.organisation?.name
              ) : (
                <p style={{ visibility: "hidden" }}>empty</p>
              )}
            </div>,
            <div
              className={`itemClick2 ${
                !greyBackgroundCondition(item.status)
                  ? "bookFont"
                  : "mediumFont"
              }`}
              style={{
                backgroundColor:
                  greyBackgroundCondition(item.status) && greyBackgroundColor,
              }}
            >
              {item.created_at ? (
                moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")
              ) : (
                <p style={{ visibility: "hidden" }}>empty</p>
              )}
            </div>,
            <div
              className={`itemClick2 ${
                !greyBackgroundCondition(item.status)
                  ? "bookFont"
                  : "mediumFont"
              }`}
              style={{
                backgroundColor:
                  greyBackgroundCondition(item.status) && greyBackgroundColor,
                color: switchStatusColor(item?.status),
              }}
            >
              {item.status ? (
                capitalizeFirst(item?.status)
              ) : (
                <p style={{ visibility: "hidden" }}>empty</p>
              )}
            </div>,
            <div
              className="rowDiv actionButtons"
              style={{
                backgroundColor:
                  greyBackgroundCondition(item.status) && greyBackgroundColor,
              }}
            >
              <div className="parentImageLeft">
                <img
                  src={rowAccept}
                  className="imageLeft"
                  onClick={(e) => handleApprove(e)}
                  style={{ cursor: "pointer" }}
                  id={JSON.stringify(item)}
                />
              </div>
              <div className="parentImageLeft">
                <img
                  src={rowReject}
                  className="imageLeft"
                  onClick={(e) => handleReject(e)}
                  style={{ cursor: "pointer" }}
                  id={JSON.stringify(item)}
                />
              </div>
            </div>,
          ])
        );
      })
      .catch((err) => setIsLoading(false));
  };

  const handleDeleteTextField = (param) => {
    param.current.value = "";
    setSearchValue("");
  };
  function handleFiltersQueryChange(queryValue) {
    refBoolPage.current = false;
    setQueryValue(queryValue);
    console.log("aaa");
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = () => {
    setQueryValue("");
    refBoolPage.current = true;
  };
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Name", value: "name" },
            { label: "Email", value: "email" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );

  const [activeToast, setActiveToast] = useState(false);
  const toggleActiveToast = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );
  const [toastContent, setToastContent] = useState("");
  const toastMarkup = activeToast ? (
    <Toast
      content={toastContent}
      onDismiss={toggleActiveToast}
      duration="10000"
    />
  ) : null;

  const [questionValue, setQuestionValue] = useState("");
  const refBoolPage = useRef(true);
  const [active, setActive] = useState(false);
  const handleChange = useCallback(() => setActive(!active), [active]);
  const handleApprove = (e) => {
    refType.current = JSON.parse(e.currentTarget.id).id + "_" + "approve";
    setQuestionValue("Are you sure you want to approve this booking?");
    setActive(true);
  };
  const handleReject = (e) => {
    refType.current = JSON.parse(e.currentTarget.id).id + "_" + "reject";
    setQuestionValue("Are you sure you want to reject this booking?");
    setActive(true);
  };
  const handleNo = () => {
    setActive(false);
  };
  const handleYes = () => {
    const form_data = new FormData();
    form_data.append(
      "status",
      refType.current.split("_")[1] === "approve" ? "approved" : "rejected"
    );
    // reference.current.split("_")[1] === "reject" &&
    // form_data.append("reason_of_rejection", reasonOfRejectionValue);
    form_data.append("_method", "PATCH");
    axios
      .post(
        `admin/v1/events/${id}/subscribers/${
          refType.current.split("_")[0]
        }/status`,
        form_data
      )
      .then((res) => {
        setActive(false);
        setToastContent(
          refType.current.split("_")[1] === "approve"
            ? "Booking has been successfully approved"
            : "Booking has been successfully rejected"
        );
        toggleActiveToast();
        setTs(+new Date());
      })
      .catch((err) => console.log(""));
  };

  return (
    <NavigationLayout>
      <Page
        fullWidth
        title={`Bookings ${
          allResult?.event?.title?.en ? `- ${allResult?.event?.title?.en}` : ""
        }`}
        breadcrumbs={[
          { onAction: () => navigate(`/admin/manage-events/events`) },
        ]}
        primaryAction={
          <div className="rowDiv">
            {allResult && (
              <p style={{ marginRight: "2rem" }} className="greyText">
                Occupancy: {allResult?.approved}/{allResult?.event?.capacity}
              </p>
            )}
            <Button primary>
              <p style={{ color: "white" }} onClick={handleExport}>
                Export
              </p>
            </Button>
          </div>
        }
        // primaryAction={{
        //   content: <p style={{ color: "white" }}>Export</p>,
        //   onClick: handleExport,
        // }}
      >
        <Card>
          {loadingMarkup}
          <Card.Section>
            <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleFiltersClearAll}
            />
          </Card.Section>

          <DataTable
            columnContentTypes={["text", "text", "text", "text"]}
            headings={[
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>ID</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Name</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Email</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Role</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Organisation</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Time of Registration</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}>Approval Status</div>
              </TextStyle>,
              <TextStyle>
                <div style={{ padding: "1.6rem" }}></div>
              </TextStyle>,
            ]}
            rows={items}
            // sortable={[true, true, true, true]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            onSort={handleSort}
            verticalAlign="middle"
            style={{ color: "red" }}
            // hoverable={false}
          />
          {requestTotal >= 1 && (
            <div>
              <div className="totalDiv">Total: {requestTotal}</div>

              {totalPages >= 2 && (
                <div style={{ float: "right" }}>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handleChangePage}
                      size="large"
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          <Modal open={active}>
            <Modal.Section>
              <div
                className="popupBlackText"
                style={{ marginBottom: "4rem", fontSize: "1.6rem" }}
              >
                {questionValue}
              </div>

              <div
                className="rowDiv"
                style={{
                  float: "right",
                  marginTop: "3rem",
                  marginBottom: "2rem",
                }}
              >
                <button className="button buttonApprove" onClick={handleYes}>
                  Yes
                </button>
                <button className="button buttonEmail" onClick={handleNo}>
                  No
                </button>
              </div>
            </Modal.Section>
          </Modal>
          {emtyState}
          {toastMarkup}
        </Card>
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function findArrayElementByValue(array, id) {
    return array.find((element, index) => {
      return parseInt(element.event_id) === parseInt(id);
    });
  }
};

export default TableOfSubscribers;
