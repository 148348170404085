import React, { useState, useCallback } from 'react'
import NavigationLayout from "../../Components/NavigationLayout";
import {
    Modal,
    Page,
    Loading,
    Card,
    ButtonGroup,
    TextField,
    TextStyle,
    FormLayout,
    Layout,
    Select,
    Spinner,
    PageActions,
} from "@shopify/polaris";
import { countryList } from '../../Assets/countries';
import { useNavigate } from "react-router-dom";
import axios from "../../Assets/Lib/axios";


function AddOrganisations() {
    const [isLoading, setIsLoading] = useState(true);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [value, setValue] = useState()
    // const handleSelectChange = useCallback((value) => setSelected(value), []);
    const [selected, setSelected] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    const [organisationNameValue, setOrganisationNameValue] = useState("");
    const handleChangeOrganisationName = useCallback((newValue) => {
        setOrganisationNameValue(newValue);
        setOrganisationError("");
    }, []);
    const [organisationError, setOrganisationError] = useState("");

    const [organisationArabicNameValue, setOrganisationArabicNameValue] = useState("");
    const handleChangeArabicName = useCallback((newValue) => {
        setOrganisationArabicNameValue(newValue);
        setOrganisationArabicNameError("");
    }, []);
    const [organisationArabicNameError, setOrganisationArabicNameError] = useState("");

    const [emailValue, setEmailValue] = useState("");
    const handleChangeEmail = useCallback((newValue) => {
        setEmailValue(newValue);
        setEmailError("");
    }, []);
    const [emailError, setEmailError] = useState("");

    const [organisationAddressValue, setOrganisationAddressValue] = useState("");
    const handleOrganisationAddressValue = useCallback((newValue) => {
        setOrganisationAddressValue(newValue);
        setOrganisationAddressError("");
    }, []);
    const [organisationAddressError, setOrganisationAddressError] = useState("");

    const [contactValue, setContactValue] = useState("");
    const handleChangeContact = useCallback((newValue) => {
        setContactValue(newValue);
        setContactError("");
        const numericValue = newValue.replace(/\D/g, '');
        setContactValue(numericValue);
    }, []);
    const [contactError, setContactError] = useState("");

    const [organisationWebsiteValue, setOrganisationWebsiteValue] = useState("")
    const handleChangeOrganisationWebsite = useCallback((newValue) => {
        setOrganisationWebsiteValue(newValue);
        setOrganisationWebsiteError("");
    }, []);
    const [organisationWebsiteError, setOrganisationWebsiteError] = useState("");

    const [organisationCountryError, setOrganisationCountryError] = useState("");
    const handleChangeOrganisationCountry = useCallback((newValue) => {
        setOrganisationCountryValue(newValue);
        setValue(newValue)
        setOrganisationCountryError("");
    }, []);
    const [organisationCountryValue, setOrganisationCountryValue] = useState("")
    const options = [
        { label: "IT Admin", value: "1" },
        { label: "Senior Admin", value: "2" },
        { label: "Registration Admin", value: "3" },
        { label: "LOC Admin", value: "4" },
        { label: "GCO Admin", value: "5" },
        { label: "QNA Admin", value: "6" },
        { label: "HAYYA Admin", value: "7" },
        { label: "Booking Admin", value: "8" },
        { label: "Events Calendar Admin", value: "9" },
        { label: "Content Manager Admin", value: "10" },
        { label: "Content Admin", value: "11" },
        { label: "Viewer Admin", value: "12" },
        { label: "HR", value: "13" },
        { label: "IMO Accreditation Admin", value: "14" },
        { label: "CRA Admin", value: "15" },
        { label: "Customs Admin", value: "16" },
        { label: "Tester", value: "17" },
    ];
    return (
        <NavigationLayout>
            <Page
                fullWidth
                title="Add Organisation"
                breadcrumbs={[{ url: "/admin/manage-organisation" }]}
            >
                {/* {loadingMarkup} */}
                {isSaving ? <Loading /> : null}
                <Card sectioned>
                    <FormLayout>
                        <FormLayout.Group>
                            <FormLayout>
                                <TextStyle variation="strong">Organisation Name</TextStyle>
                                <TextField
                                    value={organisationNameValue}
                                    align="left"
                                    onChange={handleChangeOrganisationName}
                                    error={organisationError}
                                />
                            </FormLayout>
                            <FormLayout>
                                <TextStyle variation="strong">Organisation Name (Arabic)</TextStyle>
                                <TextField
                                    value={organisationArabicNameValue}
                                    onChange={handleChangeArabicName}
                                    align="left"
                                    error={organisationArabicNameError}
                                />
                            </FormLayout>
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <FormLayout>
                                <TextStyle variation="strong">Organisation Address</TextStyle>
                                <TextField
                                    value={organisationAddressValue}
                                    onChange={handleOrganisationAddressValue}
                                    align="left"
                                    error={organisationAddressError}
                                />
                            </FormLayout>
                            <FormLayout>
                                <TextStyle variation="strong">Organisation Email</TextStyle>
                                <TextField
                                    value={emailValue}
                                    onChange={handleChangeEmail}
                                    align="left"
                                    error={emailError}
                                    type="email"
                                />
                            </FormLayout>
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <FormLayout>
                                <TextStyle variation="strong">Organisation Phone Number</TextStyle>
                                <TextField
                                    value={contactValue}
                                    onChange={handleChangeContact}
                                    align="left"
                                    error={contactError}
                                />
                            </FormLayout>
                            <FormLayout>
                                <TextStyle variation="strong">Organisation Website</TextStyle>
                                <TextField
                                    value={organisationWebsiteValue}
                                    onChange={handleChangeOrganisationWebsite}
                                    align="left"
                                    error={organisationWebsiteError}
                                />
                            </FormLayout>
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <FormLayout>
                                <TextStyle variation="strong">Organisation Country</TextStyle>
                                <Select
                                    options={countryList.map(
                                        ({ "country-code": code, name }) => ({
                                            label: name,
                                            // saving country name in value rather country code
                                            value: name,
                                        })
                                    )}
                                    onChange={handleChangeOrganisationCountry}
                                    value={value}
                                    placeholder="Please select"
                                    error={organisationCountryError}
                                    required
                                />
                            </FormLayout>
                            {/* <FormLayout>
                                <TextStyle variation="strong">Associate Role</TextStyle>
                                <Select
                                    options={options}
                                    onChange={handleSelectChange}
                                    value={selected}
                                    placeholder="Please select a role"
                                    required
                                />
                            </FormLayout> */}
                        </FormLayout.Group>

                    </FormLayout>
                </Card>

                <PageActions
                    primaryAction={{
                        content: <p style={{ color: "white" }}>Save</p>,
                        onClick: handleSave,
                        loading: isSaving && true,
                    }}
                />
            </Page>
        </NavigationLayout>
    );
    function handleSave() {
        if (
            !organisationNameValue ||
            // !organisationArabicNameValue ||
            !emailValue ||
            !organisationAddressValue ||
            !contactValue ||
            !organisationWebsiteValue ||
            !organisationCountryValue
            // passwordValue !== confirmPasswordValue
        ) {
            !organisationNameValue && setOrganisationError("Please enter orgainsation name");
            // !organisationArabicNameValue && setOrganisationArabicNameError("Please enter orgainsation name in Arabic");
            !emailValue && setEmailError("Please enter an email");
            !organisationAddressValue && setOrganisationAddressError("Please enter a organisation Address");
            !contactValue && setContactError("Please enter a organisation Contact number");
            !organisationWebsiteValue && setOrganisationWebsiteError("Please enter a organisation Webiste");
            !organisationCountryValue && setOrganisationCountryError("Please Select Country");


        } else {
            setIsSaving(true);
            // console.log("worked....")
            const form_data = new FormData();
            organisationNameValue && form_data.append("organisation_name", organisationNameValue);
            // organisationArabicNameValue && form_data.append("organisation_arabic_name", organisationArabicNameValue);
            emailValue && form_data.append("email", emailValue);
            // contactValue && form_data.append("contact", contactValue);
            organisationAddressValue && form_data.append("address", organisationAddressValue);
            organisationWebsiteValue && form_data.append("website", organisationWebsiteValue);

            // form_data.append("role", selected); // associated role not required
            form_data.append("country", value);

            axios
                .post("admin/v1/organisations", form_data)
                .then((res) => {
                    if (res.status == 200) {
                        console.log("org formed")
                        navigate("/admin/manage-organisation");
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        console.log(error.response.status);
                        // if (error.response.status === 422) {
                        //     setEmailError("Email already exists");
                        //     setIsSaving(false);
                        // }
                    }
                    setIsSaving(false);
                });
        }
    }
}

export default AddOrganisations;