import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  Toast,
  Modal,
  TextField,
  Button,
  Tabs,
  Layout,
  Select,
  FormLayout,
  Stack,
  RadioButton,
  DropZone,
  Thumbnail,
  Caption,
} from "@shopify/polaris";
import moment from "moment";
import axios from "../Assets/Lib/axios";
import closeImage from "../Assets/Images/close-popup.svg";
import { countryList } from "../Assets/countries";
import EditProfileDetail from "./EditProfileDetail";
const EditPopup = (props) => {
  const { BlobServiceClient } = require("@azure/storage-blob");
  const [active, setActive] = useState(true);
  const handleClosePopupWithChanges = () => {
    props.handleChangeShowEditPopupWithChanges();
  };
  const handleClose = () => {
    props.handleChangeShowEditPopup();
  };
  const ref = useRef();
  const { result } = props;
  const [isLoading, setIsLoading] = useState(false);
  const title = props.param.split("/")[0];
  const requestId = props.param.split("///")[2];
  const parameter = props.param.split("///")[1];
  const [value, setValue] = useState(
    String(props.param.split("///")[3]) !== "null"
      ? props.param.split("///")[3]
      : ""
  );
  console.log("tessst=", String(props.param.split("///")[3]));
  const handleChange = (newValue) => {
    console.log(newValue);
    setValue(newValue);
  };
  const [valueGender, setValueGender] = useState(
    String(props.param.split("///")[3]) && String(parameter) === "gender"
      ? String(props.param.split("///")[3])
      : ""
  );
  const handleChangeGender = (_checked, newValue) => setValueGender(newValue);

  const [filesMain, setFilesMain] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const handleDropZoneMain = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesMain((filesMain) => [...filesMain, ...acceptedFiles]),
    []
  );
  const fileUploadMain = !filesMain.length && <DropZone.FileUpload />;
  let uploadedFilesMain = filesMain.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesMain[filesMain.length - 1].name}
        source={
          validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
            ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesMain[filesMain.length - 1].name}{" "}
        <Caption>{filesMain[filesMain.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const [blobList, setBlobList] = useState([]);
  let url = "";
  const sendImageMain = (filesMain) => {
    if (true) {
      uploadImage(filesMain);
    } else {
      setIsLoading(true);
      axios
        .get(`admin/v1/sas-front`)
        .then((result) => {
          console.log(result.data.data);
          url = result.data.data;
          fctAzure(filesMain, url);
        })
        .catch((err) => console.log(""));
    }
  };

  const fctAzure = async (filesMain, url) => {
    const CONTAINER_NAME = "qmpstorage";
    const blobServiceClient = new BlobServiceClient(url);
    const containerClient =
      blobServiceClient.getContainerClient(CONTAINER_NAME);
    const filename = filesMain[filesMain.length - 1].name.substring(
      0,
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const ext = filesMain[filesMain.length - 1].name.substring(
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const blobName = filename + "_" + new Date().getTime() + ext;
    setValue(blobName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
      filesMain[filesMain.length - 1]
    );
    setIsLoading(false);
  };

  const uploadImage = (filesMain) => {
    setIsLoading(true);
    const attachment = filesMain[filesMain.length - 1];

    let extension = attachment?.name?.split(".").pop();
    const new_file = new File([attachment], `${parameter}.${extension}`);
    const formData = new FormData();
    formData.append(`${parameter}`, new_file);
    formData.append("id", requestId);
    formData.append("type", parameter);

    axios
      .post(`admin/v1/upload-document`, formData)
      .then((result) => {
        setIsLoading(false);
        handleClosePopupWithChanges();
      })
      .catch((err) => console.log(""));
  };
  const handleSubmit = () => {
    // var axios = require("axios");
    // var FormData = require("form-data");
    // var data = new FormData();
    // data.append("_method", "PATCH");
    // data.append("gender", valueGender);
    // var config = {
    //   method: "post",
    //   url: "https://vmc.testurl-dev.com/admin/v1/registration-requests/5053/edit",
    //   headers: {
    //     Authorization: "Bearer 553|i5morJCuSsAG0upEXYkflpVSJDfRikNYzCo6dqM1",
    //     // ...data.getHeaders(),
    //   },
    //   data: data,
    // };

    // axios(config)
    //   .then(function (response) {
    //     console.log(JSON.stringify(response.data));
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    setIsLoading(true);
    const form_data = new FormData();
    String(parameter) === "gender" &&
      form_data.append("gender", String(valueGender));
    (String(parameter) === "document_expiry_date" ||
      String(parameter) === "date_of_birth") &&
      form_data.append(parameter, moment(value).format("MM/DD/YYYY"));
    String(parameter) !== "document_expiry_date" &&
      String(parameter) !== "date_of_birth" &&
      String(parameter) !== "gender" &&
      form_data.append(parameter, value);
    String(parameter) === "nationality" &&
      form_data.append(
        "nationality_iso",
        countryList.find((c) => c["country-code"] === value)["alpha-2"]
      );
    String(parameter) === "passport_issuing_country" &&
      form_data.append(
        "passport_issuing_country_iso",
        countryList.find((c) => c["country-code"] === value)["alpha-2"]
      );
    String(parameter) === "document_type" &&
      form_data.append(parameter, value);

    form_data.append("_method", "PATCH");
    axios
      .post(`admin/v1/registration-requests/${requestId}/edit`, form_data)
      .then((res) => {
        handleClosePopupWithChanges();
        // setToastContent(`${title} was changed successfully`);
        // toggleActiveToast();
        // setIsLoading(false);
      })
      .catch((err) => console.log(""));
  };
  const [toastActive, setToastActive] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const toggleActiveToast = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );
  const toastMarkup = toastActive ? (
    <Toast content={toastContent} onDismiss={toggleActiveToast} />
  ) : null;

  return (
    <div style={{ height: "1000px", backgroundColor: "white" }}>
      <Modal open={active} large>
        <Modal.Section>
          {parameter === "profile_information" ? (
            <EditProfileDetail
              ref={ref}
              title={title}
              requestId={requestId}
              parameter={parameter}
              result={result}
              value={value}
              setValue={setValue}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              toastMarkup={toastMarkup}
              handleClose={handleClose}
              handleClosePopupWithChanges={handleClosePopupWithChanges}
            />
          ) : (
            <div ref={ref}>
              <FormLayout>
                <div>
                  <div className="rowDiv">
                    <div className="popupText">
                      <TextStyle variation="strong">{title}</TextStyle>
                    </div>

                    <img
                      src={closeImage}
                      onClick={handleClose}
                      style={{
                        width: "25px",
                        marginLeft: "auto",
                        marginRight: "0",
                        cursor: "pointer",
                      }}
                    ></img>
                  </div>
                </div>
                {(String(parameter) === "document_expiry_date" ||
                  String(parameter) === "date_of_birth") && (
                    <TextField
                      value={moment(value).format("YYYY-MM-DD")}
                      onChange={handleChange}
                      autoComplete="off"
                      type="date"
                    />
                  )}
                {String(parameter) !== "document_expiry_date" &&
                  String(parameter) !== "date_of_birth" &&
                  String(parameter) !== "gender" &&
                  String(parameter) !== "country" &&
                  String(parameter) !== "nationality" &&
                  String(parameter) !== "passport_issuing_country" &&
                  String(parameter) !== "document_sub_type" &&
                  String(parameter) !== "document_type" &&
                  String(parameter) !== "facial_photo" &&
                  String(parameter) !== "passport_scan" && (
                    <TextField
                      value={value}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  )}
                {String(parameter) === "gender" && (
                  <Stack horizontal>
                    <RadioButton
                      label="Male"
                      checked={valueGender === "1"}
                      id="1"
                      onChange={handleChangeGender}
                    />
                    <RadioButton
                      label="Female"
                      checked={valueGender === "2"}
                      id="2"
                      onChange={handleChangeGender}
                    />
                  </Stack>
                )}
                {(String(parameter) === "country" ||
                  String(parameter) === "nationality" ||
                  String(parameter) === "passport_issuing_country") && (
                    <Select
                      options={countryList.map(
                        ({ "country-code": code, name }) => ({
                          label: name,
                          value: code,
                        })
                      )}
                      onChange={(newValue) => setValue(newValue)}
                      value={value}
                      placeholder="Please select"
                    />
                  )}
                {String(parameter) === "document_sub_type" && (
                  <Select
                    options={[
                      { label: "Normal", value: "1" },
                      { label: "Private/Royal", value: "2" },
                      { label: "Diplomatic", value: "3" },
                      { label: "Mission", value: "5" },
                      { label: "Travel Document", value: "8" },
                      { label: "Official", value: "9" },
                    ]}
                    onChange={(newValue) => setValue(newValue)}
                    value={value}
                    placeholder="Please select"
                  />
                )}
                {String(parameter) === "document_type" && (
                  <Select
                    options={[
                      { label: "Qatar Id Card (QID)", value: "1" },
                      { label: "Passport", value: "3" },
                    ]}
                    onChange={(newValue) => setValue(newValue)}
                    value={value}
                    placeholder="Please select"
                  />
                )}
                {(String(parameter) === "facial_photo" ||
                  String(parameter) === "passport_scan") && (
                    <DropZone
                      onDrop={handleDropZoneMain}
                      onDropAccepted={sendImageMain}
                    // variableHeight
                    >
                      {uploadedFilesMain}
                      {fileUploadMain}
                    </DropZone>
                  )}
                <Button onClick={handleSubmit}>
                  {isLoading ? <Spinner size="small" /> : "Submit"}
                </Button>
              </FormLayout>
            </div>
          )}
        </Modal.Section>
      </Modal>
      {toastMarkup}
    </div>
  );
};

export default EditPopup;
