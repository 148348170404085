import React, { useCallback, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import NavigationLayout from "../Components/NavigationLayout";
import newNumbers from "../Assets/Images/newNumbers.svg";
import updatedNumbers from "../Assets/Images/updatedNumbers.svg";
import pendingNumbers from "../Assets/Images/pendingNumbers.svg";
import approvedNumbers from "../Assets/Images/approvedNumbers.svg";
import rejectedNumbers from "../Assets/Images/rejectedNumbers.svg";
import video from "../Assets/Images/video-svg.svg";
import Header from "../Components/Header";
import { isMobile } from "react-device-detect";
import ReactApexChart from "react-apexcharts";
import Col from "react-bootstrap/Col";
import {
  Button,
  Page,
  DisplayText,
  AppProvider,
  FormLayout,
  Card,
  ActionList,
  Popover,
  Modal,
  Layout,
  Loading,
} from "@shopify/polaris";
import axios from "./../Assets/Lib/axios";

function Dashboard(props) {
  const [popupActive, setPopupActive] = useState(true);
  // const options = {
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "30%",
  //       dataLabels: {
  //         position: "top", // top, center, bottom
  //       },
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //     formatter: function (val) {
  //       return Number(val).toLocaleString() + "€";
  //     },
  //     offsetY: -20,
  //     style: {
  //       fontSize: "12px",
  //       colors: ["#304758"],
  //     },
  //   },
  //   grid: {
  //     show: false,
  //   },
  //   xaxis: {
  //     categories: ["week 1", "week 2", "week 3", "week 4", "week 5", "week 6"],
  //     position: "bottom",
  //     labels: {
  //       offsetY: 0,
  //     },
  //     axisBorder: {
  //       show: true,
  //     },
  //     axisTicks: {
  //       show: false,
  //     },
  //     crosshairs_: {
  //       fill: {
  //         type: "gradient",
  //         gradient: {
  //           colorFrom: "#FFBF00",
  //           colorTo: "#FFBF00",
  //           stops: [0, 10],
  //           opacityFrom: 0.4,
  //           opacityTo: 0.5,
  //         },
  //       },
  //     },
  //     tooltip: {
  //       enabled: false,
  //       offsetY: -35,
  //     },
  //   },
  //   colors: ["#FFBF00"],
  //   fill: {
  //     gradient: {
  //       shade: "light",
  //       type: "horizontal",
  //       shadeIntensity: 0.25,
  //       gradientToColors: undefined,
  //       inverseColors: true,
  //       opacityFrom: 1,
  //       opacityTo: 1,
  //       stops: [10, 0, 50, 100],
  //     },
  //   },
  //   yaxis: {
  //     axisBorder: {
  //       show: false,
  //     },
  //     axisTicks: {
  //       show: false,
  //     },
  //     labels: {
  //       show: false,
  //       formatter: function (val) {
  //         return Number(val).toLocaleString() + "€";
  //       },
  //     },
  //   },
  //   title: {
  //     text: "Registrations per month",
  //     position: "left",
  //     align: "center",
  //     style: {
  //       fontSize: "12px",
  //       fontWeight: "regular",
  //       fontFamily: undefined,
  //       color: "#B6B6AC",
  //     },
  //   },
  //   chart: {
  //     animations: {
  //       enabled: false,
  //     },
  //   },
  // };
  // const series = [
  //   {
  //     // name: "Chiffre d'affaires",
  //     data: [8976, 6987, 12853, 10986, 17571, 15571],
  //   },
  // ];
  // const seriesline = [
  //   {
  //     data: [10, 41, 35, 51, 49, 62, 69],
  //   },
  //   {
  //     type: "line",
  //     data: [20, 51, 45, 61, 59, 72, 79],
  //   },
  //   {
  //     type: "line",
  //     data: [5, 21, 15, 31, 29, 32, 39],
  //   },
  //   {
  //     type: "line",
  //     data: [30, 61, 55, 71, 69, 82, 89],
  //   },
  // ];
  // const optionsline = {
  //   chart: {
  //     height: 350,
  //     type: "line",
  //     color: "#00CFB7",
  //     zoom: {
  //       enabled: false,
  //     },
  //   },
  //   colors: ["#8A1538", "#00CFB7", "#550065", "#FFC000"],
  //   title: {
  //     text: "Statistics per week",
  //     position: "left",
  //     align: "center",
  //     style: {
  //       fontSize: "12px",
  //       fontWeight: "regular",
  //       fontFamily: undefined,
  //       color: "#B6B6AC",
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: "straight",
  //     width: 1,
  //   },
  //   markers: {
  //     discrete: [
  //       {
  //         seriesIndex: 0,
  //         dataPointIndex: 7,
  //         fillColor: "#8A1538",
  //         strokeColor: "#8A1538",
  //         size: 1,
  //         shape: "circle", // "circle" | "square" | "rect"
  //       },
  //       {
  //         seriesIndex: 1,
  //         dataPointIndex: 11,
  //         fillColor: "#f7f4f3",
  //         strokeColor: "#eee",
  //         size: 4,
  //         shape: "circle", // "circle" | "square" | "rect"
  //       },
  //     ],
  //   },
  //   grid: {
  //     show: false,
  //   },
  //   xaxis: {
  //     categories: [
  //       "week 1",
  //       "week 2",
  //       "week 3",
  //       "week 4",
  //       "week 5",
  //       "week 6",
  //       "week 7",
  //     ],
  //   },
  //   yaxis: {
  //     labels: {
  //       show: false,
  //     },
  //   },
  //   legend: {
  //     show: false,
  //   },
  // };
  // const seriesPie = [5, 25, 12, 22, 36];
  // const optionsPie = {
  //   chart: {
  //     type: "pie",
  //     width: "60%",
  //   },
  //   colors: ["#8A1538", "#00CFB7", "#FF4700", "#FFC000", "#550065"],
  //   labels: ["Qatar", "Middle East", "North Africa", "Europe", "Other"],
  //   responsive: [
  //     {
  //       breakpoint: 600,
  //       options: {
  //         chart: {
  //           width: 300,
  //         },
  //         legend: {
  //           position: "bottom",
  //         },
  //       },
  //     },
  //   ],
  // };
  // const seriesPie1 = [38, 15, 10, 28, 9];
  // const optionsRole = {
  //   chart: {
  //     type: "pie",
  //     width: "60%",
  //   },
  //   colors: ["#8A1538", "#00CFB7", "#FF4700", "#FFC000", "#550065"],
  //   labels: [
  //     "Journalists",
  //     "Reporters",
  //     "Film Makers",
  //     "Social Media Influencers",
  //     "Other",
  //   ],
  //   responsive: [
  //     {
  //       breakpoint: 600,
  //       options: {
  //         chart: {
  //           width: 300,
  //         },
  //         legend: {
  //           position: "bottom",
  //         },
  //       },
  //     },
  //   ],
  // };

  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [stat, setStat] = useState("");
  const [responseEvent, setResponseEvent] = useState("");
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    let responseDashboard = "";
    let responseEvents = "";

    try {
      responseDashboard = await axios.get(`/admin/v1/dashboard`);
      let linesNumber = 3;
      let arrayApproved = [];
      let arrayRejected = [];
      let arrayNewRegistration = [];
      let bigArray = null;
      for (
        let j = 0;
        j <
        responseDashboard.data.data.users_analytics.statistics_per_month.length;
        j++
      ) {
        let item =
          responseDashboard.data.data?.users_analytics.statistics_per_month[j]
            .approved;
        arrayApproved.push(item);
      }
      console.log("arrayApproved=", arrayApproved);
      for (
        let j = 0;
        j <
        responseDashboard.data.data.users_analytics.statistics_per_month.length;
        j++
      ) {
        let item =
          responseDashboard.data.data?.users_analytics.statistics_per_month[j]
            .rejected;
        arrayRejected.push(item);
      }
      for (
        let j = 0;
        j <
        responseDashboard.data.data.users_analytics.statistics_per_monthlength;
        j++
      ) {
        let item =
          responseDashboard.data.data?.users_analytics.statistics_per_month[j]
            .new_registration;
        arrayNewRegistration.push(item);
      }
      bigArray = [
        {
          name: "Approved",
          data: arrayApproved,
        },
        { name: "Rejected", data: arrayRejected },
        { name: "New Registration", data: arrayNewRegistration },
      ];
      setStat(bigArray);
      setResponse(responseDashboard.data.data);
    } catch (error) {
      console.log(error);
    }

    try {
      responseEvents = await axios.get(`/admin/v1/events`);
      console.log(responseEvents?.data?.total_requests);
      responseEvents && setResponseEvent(responseEvents?.data);
    } catch (error) {
      console.log(error);
    }
    setPopupActive(false);
    setIsLoading(false);
  }
  // useEffect(() => {
  //   console.log("popo=", Boolean("false"));
  //   //  statisticsFct();
  //   setPopupActive(true);
  //   setIsLoading(true);
  //   axios
  //     .get(`/admin/v1/dashboard`)
  //     .then((result) => {
  //       let linesNumber = 3;
  //       let arrayApproved = [];
  //       let arrayRejected = [];
  //       let arrayNewRegistration = [];
  //       let bigArray = null;
  //       for (
  //         let j = 0;
  //         j < result.data.data.users_analytics.statistics_per_month.length;
  //         j++
  //       ) {
  //         let item =
  //           result.data.data?.users_analytics.statistics_per_month[j].approved;
  //         arrayApproved.push(item);
  //       }
  //       console.log("arrayApproved=", arrayApproved);
  //       for (
  //         let j = 0;
  //         j < result.data.data.users_analytics.statistics_per_month.length;
  //         j++
  //       ) {
  //         let item =
  //           result.data.data?.users_analytics.statistics_per_month[j].rejected;
  //         arrayRejected.push(item);
  //       }
  //       for (
  //         let j = 0;
  //         j < result.data.data.users_analytics.statistics_per_monthlength;
  //         j++
  //       ) {
  //         let item =
  //           result.data.data?.users_analytics.statistics_per_month[j]
  //             .new_registration;
  //         arrayNewRegistration.push(item);
  //       }
  //       bigArray = [
  //         {
  //           name: "Approved",
  //           data: arrayApproved,
  //         },
  //         { name: "Rejected", data: arrayRejected },
  //         { name: "New Registration", data: arrayNewRegistration },
  //       ];
  //       setStat(bigArray);
  //       setIsLoading(false);
  //       setResponse(result.data.data);
  //       setPopupActive(false);
  //       console.log(
  //         "aaaa=,",
  //         result.data.data?.users_analytics.registration_per_month
  //       );
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const handleDownloadReport = () => { };
  return (
    <NavigationLayout>
      <Page
        fullWidth
      // title="Insights Dashboard"
      // primaryAction={{
      //   content: <p style={{ color: "white" }}>Download Report</p>,
      //   onAction: handleDownloadReport,
      // }}
      >
        {isLoading ? <Loading /> : null}
        {/* <h1 className="dashboard-title mb-4 ps-4 ps-md-0">
          Insights Dashboard
        </h1> */}
        <div className="mb-5 pb-5">
          <div className="d-md-flex">
            <Col md={12} className="pe-md-3 pb-3 pb-md-0">
              <div className=" h-100">
                <div className=" pb-3">
                  <h2 className="col-titles">Total Registration Statics</h2>
                </div>
                <div className=" d-flex py-4 flex-wrap justify-content-md-unset justify-content-between " style={{ gap: '1rem' }}>
                  <div className="boxes col-md-2 d-flex mb-3 flex-column py-4 px-md-5 px-4 ">
                    {/* <div>
                      <img src={newNumbers} className="me-3" />
                    </div> */}
                    <div>
                      <p className="mb-3 title-container">New</p>
                      <div className="new-number">
                        {response?.total_registration_statistics?.new}
                      </div>
                    </div>
                  </div>
                  <div className="boxes col-md-2 mb-3 flex-column py-4 px-md-5 px-4">
                    {/* <div>
                      <img src={updatedNumbers} className="me-3" />
                    </div> */}
                    <div>
                      <p className="mb-3 title-container">Updated</p>
                      <div className="updated-number">
                        {response?.total_registration_statistics?.updated}
                      </div>
                    </div>
                  </div>
                  <div className="boxes col-md-2 mb-3 flex-column py-4 px-md-5 px-4 ">
                    {/* <div>
                      <img src={pendingNumbers} className="me-3" />
                    </div> */}
                    <div>
                      <p className="mb-3 title-container">Pending</p>
                      <div className="pending-number">
                        {response?.total_registration_statistics?.pending}
                      </div>
                    </div>
                  </div>
                  <div className="boxes col-md-2 mb-3 flex-column py-4 px-md-5 px-4">
                    {/* <div>
                      <img src={approvedNumbers} className="me-3" />
                    </div> */}
                    <div>
                      <p className="mb-3 title-container">Approved</p>
                      <div className="approved-number">
                        {response?.total_registration_statistics?.approved}
                      </div>
                    </div>
                  </div>
                  <div className="boxes col-md-2 mb-3 flex-column py-4 px-md-5 px-4">
                    {/* <div>
                      <img src={pendingNumbers} className="me-3" />
                    </div> */}
                    <div>
                      <p className="mb-3 title-container">Incomplete</p>
                      <div className="pending-number">
                        {response?.total_registration_statistics?.uncompleted}
                      </div>
                    </div>
                  </div>
                  <div className="boxes col-md-2 mb-3 flex-column py-4 px-md-5 px-4">
                    {/* <div>
                      <img src={rejectedNumbers} className="me-3" />
                    </div> */}
                    <div>
                      <p className="mb-3 title-container">Rejected</p>
                      <div className="rejected-number">
                        {response?.total_registration_statistics?.rejected}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col md={4} className="ps-md-2">
              <div md={4} className="py-4 px-md-5 px-4 boxes h-100">
                <div className="title-container mb-4 pb-3">
                  <h2 className="col-titles">Today</h2>
                </div>
                <div className=" d-flex justify-content-between today-container pt-5">
                  <div className="col-md-3 col-6 mb-5 mb-md-0">
                    <div className="mb-3 today-numbers">65</div>
                    <div className="today-info">Online</div>
                  </div>
                  <div className="col-md-3 col-6 mb-5 mb-md-0">
                    <div className="mb-3 today-numbers ">36</div>
                    <div className="today-info">Bookings</div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="mb-3 today-numbers ">
                      {response?.today?.new_applicant != null &&
                        response?.today?.new_applicant}
                    </div>
                    <div className="today-info">New Applicants</div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="mb-3 today-numbers">119</div>
                    <div className="today-info">Media Downloads</div>
                  </div>
                </div>
              </div>
            </Col> */}
          </div>

          <div className="d-md-flex my-4">
            <Col md={6} className="pe-md-3 pb-3 pb-md-0">
              <div className=" h-100">
                <div className=" mb-4 pb-3">
                  <h2 className="col-titles">Requests Pending Action</h2>
                </div>
                <div className=" boxes py-4 px-md-5 px-4 d-flex justify-content-between today-container pt-5">
                  <div className="col-md-3 col-6 mb-5 mb-md-0">
                    <p className="title-container today-info">GCO</p>
                    <div className="pt-3 today-numbers ">
                      {
                        response?.total_registration_statistics
                          ?.pending_gco_approval
                      }
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <p className="today-info title-container">IMO</p>
                    <div className="pt-3 today-numbers ">
                      {
                        response?.total_registration_statistics
                          ?.pending_imo_approval
                      }
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <p className="today-info title-container">QNA</p>
                    <div className="pt-3 today-numbers ">
                      {
                        response?.total_registration_statistics
                          ?.pending_qna_approval
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} className="pe-md-3 pb-3 pb-md-0">
              <div className=" h-100">
                <div className=" mb-4 pb-3">
                  <h2 className="col-titles">Hayya Entry Permits</h2>
                </div>
                <div className="py-4 px-md-5 px-4 boxes d-flex justify-content-between today-container pt-5">
                  <div className="col-md-3 col-6 mb-5 mb-md-0">

                    <p className="today-info title-container">Approved</p>
                    <div className="pt-3 today-numbers ">
                      {response?.hayya_entry_permits?.approved}
                    </div>
                  </div>
                  <div className="col-md-3 col-6">

                    <p className="today-info title-container">Rejected</p>
                    <div className="pt-3 today-numbers ">
                      {response?.hayya_entry_permits?.rejected}
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <p className="today-info title-container">Pending</p>
                    <div className="pt-3 today-numbers ">
                      {response?.hayya_entry_permits?.pending}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {
              false &&
              <Col md={4} className="pe-md-3 pb-3 pb-md-0">
                <div className=" h-100">
                  <div className=" mb-4 pb-3">
                    <h2 className="col-titles">FIFA</h2>
                  </div>
                  <div className="py-4 px-md-5 px-4 boxes d-flex justify-content-between today-container pt-5">
                    {/* <div className="col-md-3 col-6 mb-5 mb-md-0">
                    <div className="mb-3 today-numbers ">0</div>
                    <div className="today-info">QMP Pending</div>
                  </div> */}
                    <div className="col-md-3 col-6">

                      <div className="today-info title-container">MOI Pending</div>
                    </div>
                    <div className="pt-3 today-numbers ">
                      {response?.fifa_entry_permits?.pending}
                    </div>
                    <div className="col-md-3 col-6">

                      <div className="today-info title-container">MOI Approved</div>
                      <div className="pt-3 today-numbers ">
                        {response?.fifa_entry_permits?.approved}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            }
          </div>
          <div className="d-md-flex my-4">
            <Col md={12} className="pe-md-3 pb-3 pb-md-0">
              <div className="py-4 px-md-5 px-4 boxes h-100 ">
                <div className="d-flex title-container mb-4 pb-3">
                  <h2 className="col-titles flex-grow-1">Users Analytics</h2>
                  {/* <div className="d-flex align-items-center ">
                    <div className="day px-3 pt-3 pb-2 d-flex align-items-center lh-1">
                      <span className="lh-05">1W</span>
                    </div>
                    <div className="week-month ms-3">1M</div>
                  </div> */}
                </div>
                <div className="d-md-flex user-analytics-container">
                  <Col md={6}>
                    {response && (
                      <ReactApexChart
                        options={{
                          plotOptions: {
                            bar: {
                              columnWidth: "30%",
                              dataLabels: {
                                position: "top", // top, center, bottom
                              },
                            },
                          },

                          //a
                          dataLabels: {
                            enabled: false,
                            // formatter: function (val) {
                            //   return Number(val).toLocaleString() + "€";
                            // },
                            offsetY: -20,
                            style: {
                              fontSize: "12px",
                              colors: ["#304758"],
                            },
                          },
                          grid: {
                            show: false,
                          },
                          xaxis: {
                            categories:
                              response?.users_analytics
                                .registration_per_month &&
                              response?.users_analytics.registration_per_month.map(
                                (item, idx) => {
                                  return `Month ${idx + 1}`;
                                }
                              ),
                            position: "bottom",
                            labels: {
                              offsetY: 0,
                            },
                            axisBorder: {
                              show: true,
                            },
                            axisTicks: {
                              show: false,
                            },
                            crosshairs_: {
                              fill: {
                                type: "gradient",
                                gradient: {
                                  colorFrom: "#FFBF00",
                                  colorTo: "#FFBF00",
                                  stops: [0, 10],
                                  opacityFrom: 0.4,
                                  opacityTo: 0.5,
                                },
                              },
                            },
                            tooltip: {
                              enabled: false,
                              offsetY: -35,
                            },
                          },
                          colors: ["#40C4FF"],
                          fill: {
                            gradient: {
                              shade: "light",
                              type: "horizontal",
                              shadeIntensity: 0.25,
                              gradientToColors: undefined,
                              inverseColors: true,
                              opacityFrom: 1,
                              opacityTo: 1,
                              stops: [10, 0, 50, 100],
                            },
                          },
                          yaxis: {
                            axisBorder: {
                              show: false,
                            },
                            axisTicks: {
                              show: false,
                            },
                            labels: {
                              show: false,
                              // formatter: function (val) {
                              //   return Number(val).toLocaleString() + "€";
                              // },
                            },
                          },
                          title: {
                            text: "Registrations per Month",
                            position: "left",
                            align: "center",
                            style: {
                              fontSize: "12px",
                              fontWeight: "regular",
                              fontFamily: undefined,
                              color: "#B6B6AC",
                            },
                          },
                          chart: {
                            animations: {
                              enabled: false,
                            },
                          },
                        }}
                        series={[
                          {
                            name: "",
                            data:
                              response?.users_analytics
                                .registration_per_month &&
                              response?.users_analytics.registration_per_month.map(
                                (item, idx) => {
                                  return item;
                                }
                              ),
                          },
                        ]}
                        type="bar"
                        height={200}
                      />
                    )}
                  </Col>
                  <Col md={6}>
                    {response && (
                      <ReactApexChart
                        options={{
                          plotOptions: {
                            bar: {
                              columnWidth: "30%",
                              dataLabels: {
                                position: "top", // top, center, bottom
                              },
                            },
                          },

                          //a
                          dataLabels: {
                            enabled: false,
                            // formatter: function (val) {
                            //   return Number(val).toLocaleString() + "€";
                            // },
                            offsetY: -20,
                            style: {
                              fontSize: "12px",
                              colors: ["#00c1ad"],
                            },
                          },
                          grid: {
                            show: false,
                          },
                          xaxis: {
                            categories:
                              response?.users_analytics.registration_per_week &&
                              response?.users_analytics.registration_per_week.map(
                                (item, idx) => {
                                  return `Day ${idx + 1}`;
                                }
                              ),
                            position: "bottom",
                            labels: {
                              offsetY: 0,
                            },
                            axisBorder: {
                              show: true,
                            },
                            axisTicks: {
                              show: false,
                            },
                            crosshairs_: {
                              fill: {
                                type: "gradient",
                                gradient: {
                                  colorFrom: "#00c1ad",
                                  colorTo: "#00c1ad",
                                  stops: [0, 10],
                                  opacityFrom: 0.4,
                                  opacityTo: 0.5,
                                },
                              },
                            },
                            tooltip: {
                              enabled: false,
                              offsetY: -35,
                            },
                          },
                          colors: ["#536DFE"],
                          fill: {
                            gradient: {
                              shade: "light",
                              type: "horizontal",
                              shadeIntensity: 0.25,
                              gradientToColors: undefined,
                              inverseColors: true,
                              opacityFrom: 1,
                              opacityTo: 1,
                              stops: [10, 0, 50, 100],
                            },
                          },
                          yaxis: {
                            axisBorder: {
                              show: false,
                            },
                            axisTicks: {
                              show: false,
                            },
                            labels: {
                              show: false,
                              // formatter: function (val) {
                              //   return Number(val).toLocaleString() + "€";
                              // },
                            },
                          },
                          title: {
                            text: "Registrations per Day",
                            position: "left",
                            align: "center",
                            style: {
                              fontSize: "12px",
                              fontWeight: "regular",
                              fontFamily: undefined,
                              color: "#B6B6AC",
                            },
                          },
                          chart: {
                            animations: {
                              enabled: false,
                            },
                          },
                        }}
                        series={[
                          {
                            name: "",
                            data:
                              response?.users_analytics.registration_per_week &&
                              response?.users_analytics.registration_per_week.map(
                                (item, idx) => {
                                  return item;
                                }
                              ),
                          },
                        ]}
                        type="bar"
                        height={200}
                      />
                    )}
                  </Col>
                  {/* <Col md={4}>
                    {response?.users_analytics && stat && (
                      <ReactApexChart
                        options={{
                          chart: {
                            height: 350,
                            type: "line",
                            color: "#00CFB7",
                            zoom: {
                              enabled: false,
                            },
                          },
                          colors: ["#8A1538", "#00CFB7", "#550065", "#FFC000"],
                          title: {
                            text: "Statistics per month",
                            position: "left",
                            align: "center",
                            style: {
                              fontSize: "12px",
                              fontWeight: "regular",
                              fontFamily: undefined,
                              color: "#B6B6AC",
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: "straight",
                            width: 1,
                          },
                          markers: {
                            discrete: [
                              {
                                seriesIndex: 0,
                                dataPointIndex: 7,
                                fillColor: "#8A1538",
                                strokeColor: "#8A1538",
                                size: 1,
                                shape: "circle", // "circle" | "square" | "rect"
                              },
                              {
                                seriesIndex: 1,
                                dataPointIndex: 11,
                                fillColor: "#f7f4f3",
                                strokeColor: "#eee",
                                size: 4,
                                shape: "circle", // "circle" | "square" | "rect"
                              },
                            ],
                          },
                          grid: {
                            show: false,
                          },
                          xaxis: {
                            categories:
                              response?.users_analytics.statistics_per_month &&
                              response?.users_analytics.statistics_per_month.map(
                                (item, idx) => {
                                  return `Month ${idx + 1}`;
                                }
                              ),
                          },
                          yaxis: {
                            labels: {
                              show: false,
                            },
                          },
                          legend: {
                            show: false,
                          },
                        }}
                        series={stat}
                        // series={[
                        //   response?.users_analytics[1] &&
                        //     response?.users_analytics[1].map((item, idx) => {
                        //       return {
                        //         data: [30, 61, 55, 71, 69, 82],
                        //       };
                        //     }),
                        // ]}
                        type="line"
                        height={200}
                      />
                    )}
                  </Col> */}
                </div>
              </div>
            </Col>
          </div>
          <div className="d-md-flex">
            <Col md={6} className="pb-3 pb-md-0">
              <div className="py-4 px-md-5 px-4 boxes h-100">
                <div className="title-container mb-4 pb-3">
                  <h2 className="col-titles">Roles Statistics</h2>
                </div>
                <div className="d-flex justify-content-center">
                  {response && (
                    <ReactApexChart
                      options={{
                        // dataLabels: {
                        //   enabled: false,
                        // },
                        legend: {
                          show: false,
                          position: "right",
                        },
                        tooltip: {
                          enabled: true,
                          offsetY: -35,
                          y: {
                            formatter: function (value, series) {
                              // use series argument to pull original string from chart data
                              return (value * 100).toFixed(1);
                            },
                          },
                        },
                        chart: {
                          type: "pie",
                          width: "100%",
                        },
                        colors: [
                          "#8A1538",
                          "#00CFB7",
                          "#FF4700",
                          "#FFC000",
                          "#550065",
                          "#5abeb1",
                          "#c2c225",
                        ],
                        dropShadow: {
                          enabled: true,
                          top: 0,
                          left: 0,
                          blur: 3,
                          opacity: 1,
                        },
                        labels:
                          response?.roles_statistics &&
                          Object.keys(response?.roles_statistics).map(
                            (item, idx) => {
                              return item;
                            }
                          ),
                        responsive: [
                          {
                            breakpoint: 600,
                            options: {
                              chart: {
                                width: 300,
                              },
                              legend: {
                                position: "bottom",
                              },
                            },
                          },
                        ],
                      }}
                      series={
                        response?.roles_statistics &&
                        Object.keys(response?.roles_statistics).map(
                          (item, idx) => {
                            return response?.roles_statistics[item];
                          }
                        )
                      }
                      type="pie"
                      className="rolepie"
                      width={360}
                    />
                  )}
                  {/* <ReactApexChart
                    options={optionsPie}
                    series={seriesPie}
                    type="pie"
                    width={340}
                  /> */}
                </div>
              </div>
            </Col>
            <Col md={6} className="ps-md-2 pb-5 mb-5 pb-md-0 mb-md-0">
              <div className="py-4 px-md-5 px-4 boxes h-100">
                <div className="title-container mb-4 pb-3">
                  <h2 className="col-titles">Users Accreditation Statistics</h2>
                </div>
                <div className="d-flex justify-content-center">
                  {response && (
                    <ReactApexChart
                      options={{
                        // dataLabels: {
                        //   enabled: false,
                        // },
                        legend: {
                          show: false,
                          position: "top",
                        },
                        tooltip: {
                          enabled: true,
                          offsetY: -35,
                          y: {
                            formatter: function (value, series) {
                              // use series argument to pull original string from chart data
                              return (value * 1).toFixed(1);
                            },
                          },
                        },
                        chart: {
                          type: "pie",
                          width: "100%",
                        },
                        colors: [
                          "#8A1538",
                          "#00CFB7",
                          "#FF4700",
                          "#FFC000",
                          "#550065",
                          "#5abeb1",
                          "#c2c225",
                        ],
                        labels:
                          response?.users_accreditation &&
                          Object.keys(response?.users_accreditation).map(
                            (item, idx) => {
                              return item.replaceAll("_", " ");
                            }
                          ),
                        responsive: [
                          {
                            breakpoint: 600,
                            options: {
                              chart: {
                                width: 300,
                              },
                              legend: {
                                position: "bottom",
                              },
                            },
                          },
                        ],
                      }}
                      series={
                        response?.users_accreditation &&
                        Object.keys(response?.users_accreditation).map(
                          (item, idx) => {
                            return response?.users_accreditation[item];
                          }
                        )
                      }
                      type="pie"
                      className="rolepie"
                      width={360}
                    />
                  )}
                </div>
              </div>
            </Col>
          </div>

          <div className="d-md-flex my-4">
            {/* <Col md={4} className="pb-3 pb-md-0">
              <div className="py-4 px-md-5 px-4 boxes h-100">
                <div className="d-flex title-container mb-4 pb-3 mb-4">
                  <h2 className="col-titles flex-grow-1">Studios Statistics</h2>
                  <div className="d-flex align-items-center">
                    <div className="day px-3 pt-3 pb-2 d-flex align-items-center lh-1">
                      <span className="lh-05">1D</span>
                    </div>
                    <div className="week-month mx-3">1W</div>
                    <div className="week-month">1M</div>
                  </div>
                </div>

                <div className="pt-4 d-flex justify-content-around">
                  <div className="d-flex">
                    <div className="me-2">
                      <div className="d-flex flex-column me-1">
                        <div className="video-number d-flex align-items-end lh-1">
                          <span className="me-1 fs-2 lh-05">1</span>
                          <img src={video} />
                        </div>
                        <div className="studio">studio</div>
                      </div>
                    </div>
                    <div>
                      <h1 className="video-booking mb-0">0</h1>
                      <div className="booking">Bookings</div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="me-2">
                      <div className="d-flex flex-column me-1">
                        <div className="video-number d-flex align-items-end lh-1">
                          <span className="me-1 fs-2 lh-05">2</span>
                          <img src={video} />
                        </div>
                        <div className="studio">studio</div>
                      </div>
                    </div>
                    <div>
                      <h1 className="video-booking mb-0">0</h1>
                      <div className="booking">Bookings</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-around mt-5">
                  <div className="d-flex">
                    <div className="me-2">
                      <div className="d-flex flex-column me-1">
                        <div className="video-number d-flex align-items-end lh-1">
                          <span className="me-1 fs-2 lh-05">3</span>
                          <img src={video} />
                        </div>
                        <div className="studio">studio</div>
                      </div>
                    </div>
                    <div>
                      <h1 className="video-booking mb-0">0</h1>
                      <div className="booking">Bookings</div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="me-2">
                      <div className="d-flex flex-column me-1">
                        <div className="video-number d-flex align-items-end lh-1">
                          <span className="me-1 fs-2 lh-05">4</span>
                          <img src={video} />
                        </div>
                        <div className="studio">studio</div>
                      </div>
                    </div>
                    <div>
                      <h1 className="video-booking mb-0">0</h1>
                      <div className="booking">Bookings</div>
                    </div>
                  </div>
                </div>
              </div>
            </Col> */}
            {responseEvent?.data?.data && (
              <Col md={12} className="pe-md-3-x pb-3 pb-md-0">
                <div className="py-4 px-md-5 px-4 boxes h-100">
                  <div className="title-container mb-4 pb-3">
                    <h2 className="col-titles">HCMC Events Statistics</h2>
                  </div>
                  <div className="d-flex flex-wrap">
                    {responseEvent &&
                      responseEvent?.data?.data.map((item) => {
                        return (
                          <Col xs={12} md={6} className="p-2">
                            <div className="events-boxes h-100 d-flex justify-content-between align-items-center py-2 px-3">
                              <div>
                                <div className="event-title">
                                  {item?.title?.en}
                                </div>
                                <div className="event-time">{item?.date}</div>
                              </div>
                              <div>
                                <h1 className="mb-0 event-booking">
                                  {responseEvent?.events_pending &&
                                    findArrayElementByValue(
                                      responseEvent?.events_pending,
                                      item?.id
                                    ).pending !== null
                                    ? findArrayElementByValue(
                                      responseEvent?.events_pending,
                                      item?.id
                                    ).pending
                                    : "0"}
                                </h1>
                                <div className="event-booking-text">
                                  Pending Requests
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    {/* <Col xs={12} md={6} className="p-2">
                      <div className="events-boxes h-100 d-flex justify-content-between align-items-center py-2 px-3">
                        <div>
                          <div className="event-title">
                            {responseEvent?.data?.data[0]?.title?.en}
                          </div>
                          <div className="event-time">
                            {responseEvent?.data?.data[0]?.date}
                          </div>
                        </div>
                        <div>
                          <h1 className="mb-0 event-booking">
                            {responseEvent?.data?.data[0]?.total_requests &&
                            findArrayElementByValue(
                              responseEvent?.data?.total_requests,
                              responseEvent?.data?.data[0]?.id
                            ).count !== null
                              ? findArrayElementByValue(
                                  responseEvent?.data?.total_requests,
                                  responseEvent?.data?.data[0]?.id
                                ).count
                              : "0"}
                          </h1>
                          <div className="event-booking-text">Bookings</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={6} className="p-2">
                      <div className="events-boxes h-100 d-flex justify-content-between align-items-center py-2 px-3">
                        <div>
                          <div className="event-title">
                            {responseEvent?.data?.data[1]?.title?.en}
                          </div>
                          <div className="event-time">
                            {responseEvent?.data?.data[1]?.date}
                          </div>
                        </div>
                        <div>
                          <h1 className="mb-0 event-booking">
                            {responseEvent?.data?.data[1]?.total_requests &&
                            findArrayElementByValue(
                              responseEvent?.data?.total_requests,
                              responseEvent?.data?.data[1]?.id
                            ).count !== null
                              ? findArrayElementByValue(
                                  responseEvent?.data?.total_requests,
                                  responseEvent?.data?.data[1]?.id
                                ).count
                              : "0"}
                          </h1>
                          <div className="event-booking-text">Bookings</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={6} className="p-2">
                      <div className="events-boxes h-100 d-flex justify-content-between align-items-center py-2 px-3">
                        <div>
                          <div className="event-title">
                            {responseEvent?.data?.data[2]?.title?.en}
                          </div>
                          <div className="event-time">
                            {responseEvent?.data?.data[2]?.date}
                          </div>
                        </div>
                        <div>
                          <h1 className="mb-0 event-booking">
                            {responseEvent?.data?.data[2]?.total_requests &&
                            findArrayElementByValue(
                              responseEvent?.data?.total_requests,
                              responseEvent?.data?.data[2]?.id
                            ).count !== null
                              ? findArrayElementByValue(
                                  responseEvent?.data?.total_requests,
                                  responseEvent?.data?.data[2]?.id
                                ).count
                              : "0"}
                          </h1>
                          <div className="event-booking-text">Bookings</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={6} className="p-2">
                      <div className="events-boxes h-100 d-flex justify-content-between align-items-center py-2 px-3">
                        <div>
                          <div className="event-title">
                            {responseEvent?.data?.data[3]?.title?.en}
                          </div>
                          <div className="event-time">
                            {responseEvent?.data?.data[3]?.date}
                          </div>
                        </div>
                        <div>
                          <h1 className="mb-0 event-booking">
                            {responseEvent?.data?.data[3]?.total_requests &&
                            findArrayElementByValue(
                              responseEvent?.data?.total_requests,
                              responseEvent?.data?.data[3]?.id
                            ).count !== null
                              ? findArrayElementByValue(
                                  responseEvent?.data?.total_requests,
                                  responseEvent?.data?.data[3]?.id
                                ).count
                              : "0"}
                          </h1>
                          <div className="event-booking-text">Bookings</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={6} className="p-2">
                      <div className="events-boxes h-100 d-flex justify-content-between align-items-center py-2 px-3">
                        <div>
                          <div className="event-title">
                            {responseEvent?.data?.data[4]?.title?.en}
                          </div>
                          <div className="event-time">
                            {responseEvent?.data?.data[4]?.date}
                          </div>
                        </div>
                        <div>
                          <h1 className="mb-0 event-booking">
                            {responseEvent?.data?.data[4]?.total_requests &&
                            findArrayElementByValue(
                              responseEvent?.data?.total_requests,
                              responseEvent?.data?.data[4]?.id
                            ).count !== null
                              ? findArrayElementByValue(
                                  responseEvent?.data?.total_requests,
                                  responseEvent?.data?.data[4]?.id
                                ).count
                              : "0"}
                          </h1>
                          <div className="event-booking-text">Bookings</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={6} className="p-2">
                      <div className="events-boxes h-100 d-flex justify-content-between align-items-center py-2 px-3">
                        <div>
                          <div className="event-title">
                            {responseEvent?.data?.data[5]?.title?.en}
                          </div>
                          <div className="event-time">
                            {responseEvent?.data?.data[5]?.date}
                          </div>
                        </div>
                        <div>
                          <h1 className="mb-0 event-booking">
                            {responseEvent?.data?.data[5]?.total_requests &&
                            findArrayElementByValue(
                              responseEvent?.data?.total_requests,
                              responseEvent?.data?.data[5]?.id
                            ).count !== null
                              ? findArrayElementByValue(
                                  responseEvent?.data?.total_requests,
                                  responseEvent?.data?.data[5]?.id
                                ).count
                              : "0"}
                          </h1>
                          <div className="event-booking-text">Bookings</div>
                        </div>
                      </div>
                    </Col> */}
                  </div>
                </div>
              </Col>
            )}
          </div>
        </div>
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
      {/* <Footer /> */}
    </NavigationLayout>
  );
  function findArrayElementByValue(array, id) {
    return array.find((element, index) => {
      return parseInt(element.event_id) === parseInt(id);
    });
  }
}

export default Dashboard;
