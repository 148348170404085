import React, { useState, useCallback } from "react";
import axios from "../Assets/Lib/axios";
import {
  Button,
  Page,
  Loading,
  Card,
  ButtonGroup,
  TextField,
  FormLayout,
  Layout,
  Toast,
  Spinner,
  InlineError,
  TextStyle,
} from "@shopify/polaris";
import NavigationLayout from "../Components/NavigationLayout";

function Only5() {
  const [value, setValue] = useState("");
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const [toastContent, setToastContent] = useState("");
  const toastMarkup = active ? (
    <Toast content={toastContent} onDismiss={toggleActive} duration={10000} />
  ) : null;
  const handleChange = useCallback((newValue) => setValue(newValue), []);
  // alert("Hello! I am an alert box!!");

  const [result, setResult] = useState("");
  const handleSubmit = () => {
    const bodyObj = { users: value };
    console.log("clicked");
    /// alert("Hello! I am an alert box!!");

    axios
      .get(`admin/v1/registration-requests-accreditation`)
      .then((res) => {
        console.log("res====", JSON.stringify(res));
        setResult(res);
      })
      .catch((err) => console.log(""));
  };

  return (
    // <NavigationLayout>
    <FormLayout>
      {/* <TextField value={value} onChange={handleChange} autoComplete="off" /> */}
      <Button onClick={handleSubmit}>Submit</Button>
      {/* <p>Response</p> */}
      {/* {result && <TextStyle>Result:{JSON.stringify(result)}</TextStyle>} */}
    </FormLayout>
    // {toastMarkup}
    // </NavigationLayout>
  );
}

export default Only5;
