// AZURE AD ENVIRONMENT VARIABLES
export const azureAdUrlDomain = "q22apptest";
export const azureAdClientId = "483a9b2e-c6f2-4b2f-9221-ee0a0ebb831d";
export const azureAdSignInPolicy = "b2c_1_portal_signup_signin";
export const apiEndpoint = "https://media-stg-api.hayya.qa/api/v2";
export const moiSubmitApi = true;
export const frontUrl = "https://media-stg-sc.hayya.qa"
export const forgotPasswordCode = 'AADB2C90118';
export const forgotPasswordUri = `https://${azureAdUrlDomain}.b2clogin.com/`
    + `${azureAdUrlDomain}.onmicrosoft.com/`
    + 'B2C_1_portal_password_reset/'
    + 'oauth2/v2.0/authorize?'
    + `client_id=${azureAdClientId}&`
    + `redirect_uri=${frontUrl}/`
    + 'getToken&response_type=id_token&scope=openid';

export const defaultAccreditationTypeCode = 'AACQ2';
