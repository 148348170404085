import React from "react";

const Footer = () => {
  return (
    <div id="copyright">
      © 2024 Qatar Hayya | All rights reserved
      <div style={{ float: "right" }}>
        <span className="footerTabs">Support</span>
        <span className="footerTabs">About</span>
        <span className="footerTabs">Terms of Use</span>
        <span className="footerTabs">Privacy Policy</span>
        <span className="footerTabs">FAQs</span>

        {/* <ul>
            <li style={{ display: "inline", paddingLeft: "10px" }}>Support</li>
            <li style={{ display: "inline", paddingLeft: "10px" }}>About</li>
            <li style={{ display: "inline", paddingLeft: "10px" }}>
              Terms of Use
            </li>
            <li style={{ display: "inline", paddingLeft: "10px" }}>
              Privacy Policy
            </li>
            <li style={{ display: "inline", paddingLeft: "10px" }}>FAQs</li>
          </ul> */}
      </div>
    </div>
  );
};

export default Footer;
