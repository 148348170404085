import React, { useCallback, useState, useEffect } from "react";
import {
  AppProvider,
  Card,
  FormLayout,
  Button,
  DisplayText,
  Image,
  Page,
  Link,
  Toast,
  Modal,
  Frame,
} from "@shopify/polaris";
import axios from "axios";
import history from "../../Assets/Lib/history";
import Cookies from "js-cookie";
// import fifaLogo from "../../Assets/Images/fifa-logo.svg";
import ReCAPTCHA from "react-google-recaptcha";
// import background from "../../Assets/Images/login-background.jpg";
import headerLogo from "../../Assets/Images/header-logo.svg";
import Lottie from "react-lottie";
import animationData from "../../lotties/lottie1.json";
import animationData2 from "../../lotties/lottie2.json";
import axios2 from "../../Assets/Lib/axios";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

function Login() {
  const { instance } = useMsal();

  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordError, setrPasswordError] = useState("");
  const [active, setActive] = useState(Cookies.get("error") ? false : false);
  const [popupActive, setPopupActive] = useState(false);
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
  const [error, setError] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const handleEmailFieldChange = useCallback((value) => {
    setEmailValue(value);
    setEmailError("");
  }, []);
  const handlePasswordFieldChange = useCallback((value) => {
    setPasswordValue(value);
    setrPasswordError("");
  }, []);

  useEffect(() => {
    console.log("erorrrr=", Cookies.get("error"));

    Cookies.set("token", "null");
  }, []);

  const toastMarkup = active ? (
    <Toast
      content="Sorry, you are not allowed to access the admin panel"
      onDismiss={toggleActive}
    />
  ) : null;
  // const [activeError, setActiveError] = useState(
  //   !Cookies.get("error") ? true : false
  // );
  // const toggleActiveError = useCallback(
  //   () => setActiveError((activeError) => !activeError),
  //   []
  // );
  // const toastMarkupError = activeError ? (
  //   <Toast
  //     content="Sorry, you are not allowed to access the admin panel"
  //     onDismiss={toggleActiveError}
  //   />
  // ) : null;

  function onChange(value) {
    console.log(value);
    setCaptchaValue(value);
    setIsRecaptchaChecked(true);
    setError("");
  }
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: false,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleTest = () => {
    axios2
      .get(`auth/redirect`)
      .then((result) => {
        console.log(result.data);
        window.open(`${result.data.data}`, "_blank");
      })
      .catch((err) => console.log(err));
  };
  return (
    <AppProvider>
      <Frame>
        <div
          className="backgroundImage d-flex"
          style={{
            // backgroundImage: `url('${background}')`,
          }}
        >
          {/* <Button onClick={handleTest}> Test</Button> */}
          {/* <div className="leftLottie">
            <Lottie
              options={defaultOptions}
              height={400}
              // isStopped={this.state.isStopped}
              // isPaused={this.state.isPaused}
            />
          </div> */}
          <div
            className="container d-flex justify-content-center my-auto"
            style={{
              textAlign: "center",
            }}
          >
            {/* <Image src={logo} alt="Logo" /> */}

            <div className="col-md-4 signIn" style={{ marginTop: 20 }}>
              {/* <div style={{ marginBottom: 30 }}>
            <DisplayText size="large">Login</DisplayText>
          </div> */}
              <div className="loginHeader">
                <div>
                  <div
                    className="container pb-2 pt-3"
                    style={{ marginLeft: "1.4rem" }}
                  >
                    <div className="header-login d-flex justify-content-between align-items-center">
                      {/* Qatar Media Portal */}
                      <img src="/logo.png" className="header-login d-flex justify-content-between align-items-center"/>

                    </div>
                    <div className="administration-dashboard">
                      {/* Administration Dashboard */}
                    </div>
                  </div>
                </div>
                {/* <img src={headerLogo} width={250} style={{ float: "left" }} /> */}
              </div>

              <FormLayout>
                <Card sectioned>
                  {/* <img
                    src={fifaLogo}
                    width={180}
                    style={{ marginTop: "20px" }}
                  /> */}
                  <p className="hcvmcText">Hayya Portal - Admin Sign in</p>

                  {/* <p className="loginTitles">Email:</p>
                <div style={{ marginBottom: "2rem" }}>
                  <TextField
                    type="email"
                    value={emailValue}
                    onChange={handleEmailFieldChange}
                    error={emailError}
                    placeholder="Email"
                    focused={false}
                  />
                </div>

                <p className="loginTitles">Password:</p>
                <div
                  onKeyDown={handleKeyPress}
                  style={{ marginBottom: "2rem" }}
                >
                  <TextField
                    value={passwordValue}
                    onChange={handlePasswordFieldChange}
                    type="password"
                    error={passwordError}
                    placeholder="Password"
                  />
                </div>
                <p className="forgotPassword">
                  <u>Forgot password?</u>
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2rem",
                    marginBottom: "2rem",
                  }}
                >
                  <ReCAPTCHA
                    sitekey="6Le-dP4eAAAAAOFQG7saLrX9LQdRx5B0X3CXbd1v"
                    onChange={onChange}
                  />
                </div>
                <TextStyle variation="negative">{error}</TextStyle> */}
                  <div
                  
                  >
                      {/* style={{
                      marginLeft: "auto",
                      marginRight: 0,
                      marginTop: "1rem",
                    }} */}
                    <button className="loginButton mt-5" onClick={handleSignIn}>
                      Sign in
                    </button>
                  </div>
                  {/* <Button fullWidth primary onClick={handleSignIn}>
                  Sign in
                </Button> */}
                </Card>
                <div>
                  <div>
                    <p className="loginCopyright" onClick={handleTest}>
                      Copyrights © Supreme Committee for Delivery & Legacy 2023-24.
                      All Rights Reserved
                    </p>
                    <br />
                  </div>
                  <div className="footerClass"></div>
                </div>
              </FormLayout>
            </div>

            <Modal open={popupActive} loading={true}></Modal>
          </div>
          {/* <div className="rightLottie">
            <Lottie
              options={defaultOptions2}
              height={200}
              width={200}
              // isStopped={this.state.isStopped}
              // isPaused={this.state.isPaused}
            />
          </div> */}
        </div>
        {toastMarkup}
      </Frame>
    </AppProvider>
  );

  function handleSignIn(e) {
    localStorage.setItem("permits-selected-tab", 0);
    localStorage.setItem("permits-selected-page", 1);
    Cookies.set("vmc-requests-filter-clicked", "false");
    Cookies.set("vmc-requests-filter-state", "");
    Cookies.set("vmc-requests-search-value", "");
    Cookies.set("vmc-requests-filter-page", 1);
    Cookies.set("vmc-requests-selected-tab", 0);
    Cookies.set("error", "");
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
    // if (
    //   !emailValue ||
    //   (emailValue &&
    //     !emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ||
    //   !passwordValue ||
    //   !isRecaptchaChecked
    // ) {
    //   if (!emailValue) {
    //     setEmailError("Please enter your email");
    //   }
    //   if (
    //     emailValue &&
    //     !emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    //   ) {
    //     setEmailError("Please enter a valid email");
    //   }
    //   if (!passwordValue) {
    //     setrPasswordError("Please enter your password");
    //   }
    //   if (!isRecaptchaChecked) {
    //     setError("Please verify that you are not a robot.");
    //   }
    // } else {
    //   setPopupActive(true);
    //   const form_data = new FormData();
    //   form_data.append("email", emailValue);
    //   form_data.append("password", passwordValue);
    //   form_data.append("g-recaptcha-response", captchaValue);
    //   axios
    //     .post(`${process.env.REACT_APP_BASE_URL}admin/v1/auth/login`, form_data)
    //     .then((res) => {
    //       console.log(res.data.user);
    //       Cookies.set("vmc-password", passwordValue);
    //       Cookies.set("vmc-accesstoken", res.data.token, { expires: 7 });
    //       res?.data?.user?.first_name &&
    //         Cookies.set("vmc-firstname", res.data.user.first_name);
    //       res?.data?.user?.last_name &&
    //         Cookies.set("vmc-lastname", res.data.user.last_name);
    //       res?.data?.user?.type && Cookies.set("vmc-type", res.data.user.type);
    //       history.push("admin/requests");
    //     })
    //     .catch(function (error) {
    //       setPopupActive(false);
    //       setError("Invalid email or password");
    //     });
    // }
  }

  function toggleActive() {
    setActive((active) => !active);
  }
  function handleKeyPress(event) {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      handleSignIn();
    }
  }
}
export default Login;
