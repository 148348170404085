import React, {Fragment, useEffect, useState} from 'react';
import {DataTable, TextStyle} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
const Timeline = (props) => {

    const [logs, setLogs] = useState();
    const {applicationId, setIsLoading} = props;

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(`/admin/v1/applications/${applicationId}/logs`)
            .then(res => {
                console.log({res})
                getData(res?.data);
                setIsLoading(false);
            })
            .catch(error => console.log(""))
    }, []);

    const getData = (applicationLogs) => {

        let array = [];
        if (applicationLogs && applicationLogs.length) {
            array = applicationLogs.map((item) => [
                <div className="itemClick bookFont">{item.updated_by}</div>,
                <div className="itemClick bookFont">{item.updated_at}</div>,
                <div className="itemClick bookFont">{item.field}</div>,
                <div className="itemClick bookFont">{item.old_value ? item.old_value : 'N/A'}</div>,
                <div className="itemClick bookFont">{item.new_value ? item.new_value : 'N/A'}</div>,
            ]);
            setLogs(array);
        }
    }

    return (
        <Fragment>
            <h2 className="fw-medium text-center fs-2">Application Activity Log</h2>
            <hr/>
            {
                logs ? <DataTable
                    columnContentTypes={["text", "text", "text", "text"]}
                    headings={[
                        <TextStyle>
                            <div style={{ padding: "1.6rem" }}>Updated By</div>
                        </TextStyle>,
                        <TextStyle>
                            <div style={{ padding: "1.6rem" }}>Updated At</div>
                        </TextStyle>,
                        <TextStyle>
                            <div style={{ padding: "1.6rem" }}>Field</div>
                        </TextStyle>,
                        <TextStyle>
                            <div style={{ padding: "1.6rem" }}>Old Value</div>
                        </TextStyle>,
                        <TextStyle>
                            <div style={{ padding: "1.6rem" }}>New Value</div>
                        </TextStyle>,
                    ]}
                    rows={logs}
                    defaultSortDirection="descending"
                    initialSortColumnIndex={0}
                    verticalAlign="middle"
                    style={{ color: "red" }}
                />:<h2 className='text-center fw-bold'>No logs created. Please check back later.</h2>
            }
        </Fragment>
    );
};

export default Timeline;
