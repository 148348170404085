import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Select,
  Image,
  Stack,
  ChoiceList,
  Loading,
  TextStyle,
  Caption,
  Checkbox,
  Modal,
  TextField,
  FormLayout,
  DropZone,
  Page,
  Thumbnail,
  PageActions,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import NavigationLayout from "../../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";

const AddEvent = () => {
  const { BlobServiceClient } = require("@azure/storage-blob");
  const [popupActive, setPopupActive] = useState(false);
  const [imageKey, setImageKey] = useState("");
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(true);
  const [eventDateValue, setEventDateValue] = useState("");
  const handleChangeEventDate = (newValue) => {
    setEventDateValue(newValue);
    setDateError("");
  };

  const [dateError, setDateError] = useState("");

  const [eventNameValue, setEventNameValue] = useState("");
  const handleChangeEventName = (newValue) => {
    setEventNameValue(newValue);
    setTitleError("");
  };
  const [eventNameArValue, setEventNameArValue] = useState("");
  const handleChangeEventNameAr = (newValue) => {
    setEventNameArValue(newValue);
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const [selectedEventType, setSelectedEventType] = useState("");
  const handleSelectChangeEventType = useCallback((newValue) => {
    setSelectedEventType(newValue);
    setTypeError("");
  }, []);
  const [typeError, setTypeError] = useState("");
  const [optionsEventType, setOptionsEventType] = useState("");
  const [selectedEventLocation, setSelectedEventLocation] = useState("");

  const handleSelectChangeEventLocation = useCallback((newValue) => {
    setSelectedEventLocation(newValue);
    setLocationError("");
  }, []);

  const [locationError, setLocationError] = useState("");
  const [optionsEventLocation, setOptionsEventLocation] = useState("");
  const [eventStartTimeValue, setEventStartTimeValue] = useState("");
  const handleChangeEventStartTime = useCallback((newValue) => {
    setEventStartTimeValue(newValue);
    setStError("");
  }, []);
  const [stError, setStError] = useState("");

  const [eventEndTimeValue, setEventEndTimeValue] = useState("");
  const handleChangeEventEndTime = useCallback((newValue) => {
    setEventEndTimeValue(newValue);
    setEtError("");
  }, []);
  const [etError, setEtError] = useState("");

  const [capacityValue, setCapacityValue] = useState("");
  const handleChangeCapacity = useCallback((newValue) => {
    setCapacityValue(newValue);
    setCapacityError("");
  }, []);
  const [capacityError, setCapacityError] = useState("");

  const [maximumRequestsValue, setMaximumRequestsValue] = useState("");
  const handleChangeMaximumRequests = useCallback((newValue) => {
    setMaximumRequestsValue(newValue);
    setMaximumRequestsError("");
  }, []);
  const [maximumRequestsError, setMaximumRequestsError] = useState("");

  const [eventDescriptionValue, setEventDescriptionValue] = useState("");
  const handleChangeEventDescription = useCallback(
    (newValue) => setEventDescriptionValue(newValue),
    []
  );
  const [eventDescriptionArValue, setEventDescriptionArValue] = useState("");
  const handleChangeEventDescriptionAr = useCallback(
    (newValue) => setEventDescriptionArValue(newValue),
    []
  );
  const [contentEnValue, setContentEnValue] = useState("");
  const handleChangeContentEn = (newValue) => {
    setContentEnValue(newValue);
  };
  const [contentArValue, setContentArValue] = useState("");
  const handleChangeContentAr = (newValue) => {
    setContentArValue(newValue);
  };
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responseTypes = "";
    let responseLocations = "";
    try {
      responseTypes = await axios.get(`admin/v1/events/types`);
      responseTypes?.data?.data?.data &&
        setOptionsEventType(
          responseTypes.data.data.data.map((item, index) => {
            return {
              label: item.title[Object.keys(item.title)[0]],
              value: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseLocations = await axios.get(`admin/v1/events/locations`);
      console.log("loca=", responseLocations.data.data.data);
      responseLocations?.data?.data?.data &&
        setOptionsEventLocation(
          responseLocations.data.data.data.map((item, index) => {
            return {
              label: item.title[Object.keys(item.title)[0]],
              value: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }

  const [imageError, setImageError] = useState("");
  const [filesMain, setFilesMain] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const handleDropZoneMain = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesMain((filesMain) => [...filesMain, ...acceptedFiles]),
    []
  );
  const fileUploadMain = !filesMain.length && <DropZone.FileUpload />;
  let uploadedFilesMain = filesMain.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesMain[filesMain.length - 1].name}
        source={
          validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
            ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesMain[filesMain.length - 1].name}{" "}
        <Caption>{filesMain[filesMain.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  let url = "";
  const sendImageMain = (filesMain) => {
    setPopupActive(true);
    console.log("filesMain=", filesMain[filesMain.length - 1]);
    axios
      .get(`admin/v1/sas/${filesMain[filesMain.length - 1].name}`)
      .then((result) => {
        console.log(result.data.data);
        url = result.data.data;
        fctAzure(filesMain, url);
      })
      .catch((err) => console.log(""));
  };

  const fctAzure = async (filesMain, url) => {
    console.log("tessst=", filesMain);
    const CONTAINER_NAME = "photos";
    //GET sasURL from API?

    const blobServiceClient = new BlobServiceClient(url);
    const containerClient =
      blobServiceClient.getContainerClient(CONTAINER_NAME);
    const filename = filesMain[filesMain.length - 1].name.substring(
      0,
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const ext = filesMain[filesMain.length - 1].name.substring(
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const blobName = filename + "_" + new Date().getTime() + ext;
    setImageKey(blobName);
    console.log("test=", blobName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
      filesMain[filesMain.length - 1]
    );
    console.log(
      `Upload block blob ${filesMain[filesMain.length - 1].name} successfully`,
      uploadBlobResponse.clientRequestId
    );

    setPopupActive(false);
    setImageError("");
    setImagePreview(
      validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
        ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
  };

  const [checked, setChecked] = useState(true);
  const handleChange = useCallback((newChecked) => setChecked(newChecked), []);
  return (
    <NavigationLayout>
      <Page
        fullWidth
        breadcrumbs={[
          { onAction: () => navigate("/admin/manage-events/events") },
        ]}
        title="Add Event"
      >
        {isSaving ? <Loading /> : null}
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Name En</TextStyle>
              </p>
              <TextField
                value={eventNameValue}
                onChange={handleChangeEventName}
              />
              <InlineError message={titleError} />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Name Ar</TextStyle>
              </p>
              <TextField
                value={eventNameArValue}
                onChange={handleChangeEventNameAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <p className="greyText">
            <TextStyle>Image (Upload size: JPG 400x400px)</TextStyle>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone
                onDrop={handleDropZoneMain}
                onDropAccepted={sendImageMain}
                // variableHeight
              >
                {uploadedFilesMain}
                {fileUploadMain}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError}></InlineError>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Date</TextStyle>
              </p>
              <TextField
                value={eventDateValue}
                onChange={handleChangeEventDate}
                type="date"
                error={dateError}
              />
            </FormLayout>

            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Type</TextStyle>
              </p>
              <Select
                options={optionsEventType}
                onChange={handleSelectChangeEventType}
                value={selectedEventType}
                placeholder="Please select a type"
                error={typeError}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Location</TextStyle>
              </p>
              <Select
                options={optionsEventLocation}
                onChange={handleSelectChangeEventLocation}
                value={selectedEventLocation}
                placeholder="Please select a location"
                error={locationError}
              />
            </FormLayout>
          </FormLayout.Group>

          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Start Time</TextStyle>
              </p>
              <TextField
                value={eventStartTimeValue}
                onChange={handleChangeEventStartTime}
                type="time"
                error={stError}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event End Time</TextStyle>
              </p>
              <TextField
                value={eventEndTimeValue}
                onChange={handleChangeEventEndTime}
                type="time"
                error={etError}
              />
            </FormLayout>

            <FormLayout>
              <p className="greyText">
                <TextStyle>Capacity of the Event/Number of Seats</TextStyle>
              </p>
              <TextField
                value={capacityValue}
                onChange={handleChangeCapacity}
                type="number"
                error={capacityError}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Maximum Requests</TextStyle>
              </p>
              <TextField
                value={maximumRequestsValue}
                onChange={handleChangeMaximumRequests}
                type="number"
                error={maximumRequestsError}
              />
            </FormLayout>
          </FormLayout.Group>

          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Description En</TextStyle>
              </p>
              <TextField
                value={eventDescriptionValue}
                onChange={handleChangeEventDescription}
                multiline={4}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Event Description Ar</TextStyle>
              </p>
              <TextField
                value={eventDescriptionArValue}
                onChange={handleChangeEventDescriptionAr}
                multiline={4}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>

          {/* <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Content En</TextStyle>
              </p>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={contentEnValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "directionality",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help|ltr rtl|code",
                }}
                onEditorChange={handleChangeContentEn}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Content Ar</TextStyle>
              </p>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={contentArValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "directionality",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help|ltr rtl|code",
                }}
                onEditorChange={handleChangeContentAr}
              />
            </FormLayout>
          </FormLayout.Group> */}
          <Checkbox
            label="Is Bookable"
            checked={checked}
            onChange={handleChange}
          />
        </FormLayout>
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
        />
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
  function handleSave() {
    if (
      (!eventNameValue && !eventNameArValue) ||
      selectedEventType === "" ||
      !eventDateValue ||
      capacityValue === "" ||
      maximumRequestsValue === ""
      // selectedEventLocation === "" ||
      // !eventStartTimeValue ||
      // !eventEndTimeValue ||
      // !filesMain[filesMain.length - 1]
    ) {
      !eventNameValue &&
        !eventNameArValue &&
        setTitleError("Please enter a name");
      selectedEventType === "" && setTypeError("Please select a type");
      !eventDateValue && setDateError("Please select a date");
      capacityValue === "" && setCapacityError("Please enter a capacity");
      maximumRequestsValue === "" &&
        setMaximumRequestsError("Please enter a maximum requests number");
      // selectedEventLocation === "" &&
      //   setLocationError("Please select a location");
      // !eventStartTimeValue && setStError("Please select a start time");
      // !eventEndTimeValue && setEtError("Please select an end time");
      // !filesMain[filesMain.length - 1] &&
      //   setImageError("Please upload an image");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      eventNameValue && form_data.append("title[en]", eventNameValue);
      eventNameArValue && form_data.append("title[ar]", eventNameArValue);
      eventDateValue && form_data.append("date", eventDateValue);
      eventDescriptionValue &&
        form_data.append("description[en]", eventDescriptionValue);
      eventDescriptionArValue &&
        form_data.append("description[ar]", eventDescriptionArValue);
      eventStartTimeValue &&
        form_data.append("start_time", eventStartTimeValue);
      eventEndTimeValue && form_data.append("end_time", eventEndTimeValue);
      capacityValue !== "" && form_data.append("capacity", capacityValue);
      selectedEventType !== "" &&
        form_data.append("types[0]", selectedEventType);
      selectedEventLocation !== "" &&
        form_data.append("location", selectedEventLocation);
      contentEnValue && form_data.append("content[en]", contentEnValue);
      contentArValue && form_data.append("content[ar]", contentArValue);
      imageKey && form_data.append("image", imageKey);
      form_data.append("is_bookable", checked ? 1 : 0);
      form_data.append("max_requests", maximumRequestsValue);

      axios
        .post("admin/v1/events", form_data)
        .then((res) => {
          navigate("/admin/manage-events/events");
        })
        .catch((err) => console.log(""));
    }
  }
};

export default AddEvent;
