import axios from "../Assets/Lib/axios";

export const openBase64Image = (url, file) => {
  axios.get(url).then((response) => {
    var blob = base64toBlob(response?.data?.data, file);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  });
};

const base64toBlob = (base64Data, file) => {
  let extension = file.split(".").pop()?.toLowerCase();
  let type;
  console.log({extension})
  switch (extension) {
    case "jpg":
    case "jpeg":
      type = "image/jpg";
      break;
    case "png":
      type = "image/png";
      break;
    case "tiff":
      type = "image/tiff";
      break;
    case "pdf":
      type = "application/pdf";
      break;
    case "xlsx":
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      break;
  }

  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: type });
};
