import React, { useState, useEffect } from "react";
import NavigationLayout from "../Components/NavigationLayout";
import {
  Button,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  Select,
} from "@shopify/polaris";
import axios from "../Assets/Lib/axios";
import moment from "moment";

const Articles = (props) => {
  const format = "YYYY-MM-DD HH:mm:ss";
  const [result, setResult] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [ts, setTs] = useState("");

  useEffect(() => {
    axios
      .get(`admin/v1/news`)
      .then((result) => {
        setResult(result.data.data);
      })
      .catch((err) => console.log(err));
  }, [ts]);
  const handleUpdate = () => {
    setIsSaving(true);
    axios
      .post(`admin/v1/news`)
      .then((result) => {
        setIsSaving(false);
        setTs(+new Date());
      })
      .catch((err) => console.log(err));
  };
  return (
    <NavigationLayout>
      <Page title="News">
        <Card sectioned>
          <FormLayout>
            {result && (
              <TextStyle>{`Updated at: ${moment(result).format(
                format
              )}`}</TextStyle>
            )}
            <Button onClick={handleUpdate} loading={isSaving}>
              {!isSaving && `Update RSS`}
            </Button>
          </FormLayout>
        </Card>
      </Page>
    </NavigationLayout>
  );
};
export default Articles;
