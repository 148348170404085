import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  Toast,
  Modal,
  TextField,
  FormLayout,
  Tabs,
  Layout,
  Button,
} from "@shopify/polaris";
import email from "../Assets/Images/email.svg";
import approve from "../Assets/Images/approve.svg";
import reject from "../Assets/Images/reject.svg";
import approvedDisabledImage from "../Assets/Images/approve-grey.svg";
import rejectedDisabledImage from "../Assets/Images/reject-grey.svg";
import enteredDisabledImage from "../Assets/Images/entered-grey.svg";
import confiscatedDisabledImage from "../Assets/Images/confiscated-grey.svg";

import resendDisabledImage from "../Assets/Images/resend-grey.svg";
import download from "../Assets/Images/download.svg";
import forward from "../Assets/Images/forward.svg";
import sync from "../Assets/Images/sync.svg";
import resend from "../Assets/Images/resend.svg";
import syncDisabledImage from "../Assets/Images/sync-grey.svg";
import { adminRole } from "../Helpers";
import enteredImg from "../Assets/Images/entered-green.svg";
import confiscatedImg from "../Assets/Images/confiscated.svg";

const ActionButtonsPermit = (props) => {
  useEffect(() => {}, [props]);

  return (
    <Layout.Section>
      {window.location.href.indexOf(
        "/admin/equipment-clearance/customs-permits"
      ) > -1 ? (
        props?.clickedItem?.status?.toLowerCase() === "entered" ||
        props?.clickedItem?.status?.toLowerCase() === "confiscated" ? (
          <div className="request-action-btns">
            <div className="Polaris-FormLayout__Item me-5 pe-3">
              <button
                className="button  ms-5 buttonDisabled"
                id={JSON.stringify(props.clickedItem)}
              >
                <img
                  src={enteredDisabledImage}
                  style={{ marginRight: 10, width: "20px", height: "20px" }}
                  id="approveImg"
                />
                <span>Entered</span>
              </button>
            </div>
            <div className="Polaris-FormLayout__Item me-5 pe-3">
              <button
                className=" buttonDisabled reject ms-5 button"
                id={JSON.stringify(props.clickedItem)}
              >
                <img
                  src={confiscatedDisabledImage}
                  style={{ marginRight: 10, width: "20px", height: "20px" }}
                  id="rejectImg"
                />
                <span>Confiscated</span>
              </button>
            </div>
            <div className="Polaris-FormLayout__Item me-5 pe-3">
              <button
                className="buttonDownload button ms-5"
                onClick={props.handleDownload}
              >
                <img
                  src={download}
                  style={{ marginRight: 10, width: "20px", height: "20px" }}
                  className="credentialsImage"
                  id="downloadImg"
                />
                <span>Download</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="request-action-btns">
            <div className="Polaris-FormLayout__Item me-5 pe-3">
              <button
                className="button buttonEntered ms-5"
                id={JSON.stringify(props.clickedItem)}
                onClick={props.handleEntered}
              >
                <img
                  src={enteredImg}
                  style={{ marginRight: 10, width: "20px", height: "20px" }}
                  id="approveImg"
                />
                <span>Entered</span>
              </button>
            </div>
            <div className="Polaris-FormLayout__Item me-5 pe-3">
              <button
                className="buttonConfiscated button reject ms-5"
                onClick={props.handleConfiscated}
                id={JSON.stringify(props.clickedItem)}
              >
                <img
                  src={confiscatedImg}
                  style={{ marginRight: 10, width: "20px", height: "20px" }}
                  id="rejectImg"
                />
                <span>Confiscated</span>
              </button>
            </div>
            <div className="Polaris-FormLayout__Item me-5 pe-3">
              <button
                className="buttonDownload button ms-5"
                onClick={props.handleDownload}
              >
                <img
                  src={download}
                  style={{ marginRight: 10, width: "20px", height: "20px" }}
                  className="credentialsImage"
                  id="downloadImg"
                />
                <span>Download</span>
              </button>
            </div>
          </div>
        )
      ) : props?.clickedItem?.status?.toLowerCase() === "rejected_qna" ||
        props?.clickedItem?.status?.toLowerCase() === "rejected_cra" ||
        props?.clickedItem?.status?.toLowerCase() === "rejected_customs" ||
        props?.clickedItem?.status?.toLowerCase() === "confiscated" ||
        window.location.href.indexOf("/admin/equipment-clearance/all-permits") >-1 ||
        (window.location.href.indexOf("/admin/equipment-clearance/cra-permits") > -1 &&
          (props?.clickedItem?.status?.toLowerCase() === "approved_cra" ||
            props?.clickedItem?.status?.toLowerCase() === "rejected_cra" ||
            props?.clickedItem?.status?.toLowerCase() === "approved_qna" ||
            props?.clickedItem?.status?.toLowerCase() === "rejected_qna" ||
            props?.clickedItem?.status?.toLowerCase() === "entered" ||
            props?.clickedItem?.status?.toLowerCase() === "confiscated")) ||
        (window.location.href.indexOf("/admin/equipment-clearance/qna-permits") > -1 &&
          (props?.clickedItem?.status?.toLowerCase() === "approved_qna" ||
            props?.clickedItem?.status?.toLowerCase() === "rejected_qna" ||
            props?.clickedItem?.status?.toLowerCase() === "entered" ||
            props?.clickedItem?.status?.toLowerCase() === "confiscated")) ? (
        <div className="request-action-btns">
          <div className="Polaris-FormLayout__Item me-5 pe-3">
            <button className="button buttonDisabled ms-5">
              {/* <img
                src={approvedDisabledImage}
                style={{
                  marginRight: "10px",
                  width: "20px",
                  height: "20px",
                }}
              /> */}
              <span>Approve</span>
            </button>
          </div>
          <div className="Polaris-FormLayout__Item me-5 pe-3">
            <button className="button buttonDisabled ms-5">
              {/* <img
                src={rejectedDisabledImage}
                style={{ marginRight: 10, width: "20px", height: "20px" }}
              /> */}
              <span>Reject</span>
            </button>
          </div>
          <div className="Polaris-FormLayout__Item me-5 pe-3">
            <button
              className="buttonDownload button ms-5"
              onClick={props.handleDownload}
            >
              {/* <img
                src={download}
                style={{ marginRight: 10, width: "20px", height: "20px" }}
                className="credentialsImage"
                id="downloadImg"
              /> */}
              <span>Download</span>
            </button>
          </div>
        </div>
      ) : (
        <div className="request-action-btns">
          <div className="Polaris-FormLayout__Item me-5 pe-3">
            <button
              className="button buttonApprove ms-5"
              id={JSON.stringify(props.clickedItem)}
              onClick={props.handleRowApprove}
            >
              {/* <img
                src={approve}
                style={{ marginRight: 10, width: "20px", height: "20px" }}
                id="approveImg"
              /> */}
              <span>Approve</span>
            </button>
          </div>
          <div className="Polaris-FormLayout__Item me-5 pe-3">
            <button
              className="buttonReject button reject ms-5"
              onClick={props.handleRowReject}
              id={JSON.stringify(props.clickedItem)}
            >
              {/* <img
                src={reject}
                style={{ marginRight: 10, width: "20px", height: "20px" }}
                id="rejectImg"
              /> */}
              <span>Reject</span>
            </button>
          </div>
          <div className="Polaris-FormLayout__Item me-5 pe-3">
            <button
              className="buttonDownload button ms-5"
              onClick={props.handleDownload}
            >
              {/* <img
                src={download}
                style={{ marginRight: 10, width: "20px", height: "20px" }}
                className="credentialsImage"
                id="downloadImg"
              /> */}
              <span>Download</span>
            </button>
          </div>
          {window.location.href.indexOf("/admin/equipment-clearance/qna-permits") > -1 &&
              props.clickedItem.status_level === 'QNA' &&
              props.clickedItem.status !== 'approved_cra' &&
            <div className="Polaris-FormLayout__Item me-5 pe-3">
              <button
                  className="button buttonEmail ms-5"
                  id={JSON.stringify(props.clickedItem)}
                  onClick={props.handleToggleStatus}
              >
                {/* <img
                    src={email}
                    style={{ marginRight: 4, width: "20px", height: "20px" }}
                    id="emailImg"
                /> */}
                <span>Move to CRA</span>
              </button>
            </div>
          }
        </div>
      )}

      {window.location.href.indexOf("/admin/equipment-clearance/cra-permits") > -1 &&
          <div className="Polaris-FormLayout__Item me-5 pe-3">
            <button
                className="button buttonEmail ms-5"
                id={JSON.stringify(props.clickedItem)}
                onClick={props.handleRegisterToCra}
            >
              {/* <img
                  src={email}
                  style={{ marginRight: 4, width: "20px", height: "20px" }}
                  id="emailImg"
              /> */}
              <span>Register to CRA</span>
            </button>
          </div>
      }
    </Layout.Section>
  );
};

export default ActionButtonsPermit;
