import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import { Editor } from "@tinymce/tinymce-react";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();
  const handleChangeDate = (newValue) => {
    setItem({ ...item, date: newValue });
  };
  const handleChangeTitleEn = (newValue) => {
    setItem({ ...item, titleEn: newValue });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, titleAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeDescriptionEn = (newValue) => {
    setItem({ ...item, descriptionEn: newValue });
    setDescriptionError("");
  };
  const handleChangeDescriptionAr = (newValue) => {
    setItem({ ...item, descriptionAr: newValue });
    setDescriptionError("");
  };
  const [descriptionError, setDescriptionError] = useState("");
  const handleChangeTextEn = (newValue) => {
    setItem({ ...item, textEn: newValue });
    setTextError("");
  };
  const handleChangeTextAr = (newValue) => {
    setItem({ ...item, textAr: newValue });
    setTextError("");
  };

  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [afterImagePreview, setAfterImagePreview] = useState("");
  const [beforeImagePreview, setBeforeImagePreview] = useState("");
  const [afterImageError, setAfterImageError] = useState("");
  const [beforeImageError, setBeforeImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      imageAfter:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setAfterImageError("");
  };

  const [filesBefore, setFilesBefore] = useState([]);
  const handleDropZoneBefore = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesBefore((filesBefore) => [...filesBefore, ...acceptedFiles]),
    []
  );
  const fileUploadBefore = !filesBefore.length && <DropZone.FileUpload />;
  let uploadedFilesBefore = filesBefore.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesBefore[filesBefore.length - 1].name}
        source={
          validImageTypes.indexOf(filesBefore[filesBefore.length - 1].type) > 0
            ? window.URL.createObjectURL(filesBefore[filesBefore.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesBefore[filesBefore.length - 1].name}{" "}
        <Caption>{filesBefore[filesBefore.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageBefore = (filesBefore) => {
    setItem({
      ...item,
      imageBefore:
        validImageTypes.indexOf(filesBefore[filesBefore.length - 1].type) > 0
          ? window.URL.createObjectURL(filesBefore[filesBefore.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setBeforeImageError("");
  };
  const handleChangeShow = (newValue) =>
    setItem({ ...item, isActive: newValue });
  const [item, setItem] = useState({
    titleEn: "",
    textEn: "",
    titleAr: "",
    textAr: "",
    descriptionEn: "",
    descriptionAr: "",
    imageBefore: "",
    imageAfter: "",
    isActive: "",
    date: "",
  });
  useEffect(() => {
    axios
      .get(`admin/v1/news/${id}`)
      .then((result) => {
        setIsSaving(false);
        setItem({
          imageBefore:
            result?.data?.data?.thumbnail && result?.data?.data?.thumbnail,
          imageAfter: result?.data?.data?.image && result?.data?.data?.image,
          titleEn: result?.data?.data?.title?.en
            ? result.data.data.title.en
            : "",
          titleAr: result?.data?.data?.title?.ar
            ? result.data.data.title.ar
            : "",
          descriptionEn: result?.data?.data?.sub_title?.en
            ? result.data.data.sub_title.en
            : "",
          descriptionAr: result?.data?.sub_title?.ar
            ? result.data.data.sub_title.ar
            : "",
          textEn: result?.data?.data?.description?.en
            ? result.data.data.description.en
            : "",
          textAr: result?.data?.data?.description?.ar
            ? result.data.data.description.ar
            : "",
          isActive: result?.data?.data?.is_active,
          date: result?.data?.data?.date ? result.data.data.date : "",
        });
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page title="Edit News" breadcrumbs={[{ url: "/admin/content/news" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Date</TextStyle>
          <TextField
            value={item.date}
            onChange={handleChangeDate}
            type="date"
          />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={item.titleEn}
                onChange={handleChangeTitleEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={item.titleAr}
                onChange={handleChangeTitleAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Subtitle En</TextStyle>
              <TextField
                value={item.descriptionEn}
                onChange={handleChangeDescriptionEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Subtitle Ar</TextStyle>
              <TextField
                value={item.descriptionAr}
                onChange={handleChangeDescriptionAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={descriptionError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Text En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.textEn}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Text Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.textAr}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Thumbnail Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={item.imageBefore ? item.imageBefore : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone
                    onDrop={handleDropZoneBefore}
                    onDropAccepted={sendImageBefore}
                  >
                    {uploadedFilesBefore}
                    {fileUploadBefore}
                  </DropZone>
                </div>
              </div>
              <InlineError message={beforeImageError} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={item.imageAfter ? item.imageAfter : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={afterImageError} />
            </FormLayout>
          </FormLayout.Group>

          <Checkbox
            label="Is Active"
            checked={item.isActive}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: <p style={{ color: "white" }}>Save</p>,
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: <p style={{ color: "white" }}>Delete</p>,
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .delete(`admin/v1/news/${id}/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/content/news`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (
      (!item.titleEn && !item.titleAr) ||
      (!item.descriptionEn && !item.descriptionAr) ||
      (!item.textEn && !item.textAr)
    ) {
      !item.titleEn && !item.titleAr && setTitleError("Please enter a title");
      !item.textEn && !item.textAr && setTextError("Please enter a text");
      !item.descriptionEn &&
        !item.descriptionAr &&
        setDescriptionError("Please enter a description");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("_method", "PATCH");
      form_data.append("is_active", item.isActive ? 1 : 0);
      item.titleEn && form_data.append("title[en]", item.titleEn);
      item.titleAr && form_data.append("title[ar]", item.titleAr);
      item.descriptionEn &&
        form_data.append("sub_title[en]", item.descriptionEn);
      item.descriptionAr &&
        form_data.append("sub_title[ar]", item.descriptionAr);
      item.textEn && form_data.append("description[en]", item.textEn);
      item.textAr && form_data.append("description[ar]", item.textAr);
      filesBefore.length &&
        form_data.append("thumbnail", filesBefore[filesBefore.length - 1]);
      files.length && form_data.append("image", files[files.length - 1]);
      item.date && form_data.append("date", item.date);
      axios
        .post(`admin/v1/news/${id}/update`, form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/content/news");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Edit;
