import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Select,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  TextStyle,
  DropZone,
  Thumbnail,
  PageActions,
  TextField,
  FormLayout,
  Button,
  Page,
  Card,
  Stack,
  Caption,
  Modal,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import NavigationLayout from "../../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const EditAlbum = () => {
  const { BlobServiceClient } = require("@azure/storage-blob");
  let url = "";
  const [imageKey, setImageKey] = useState("");
  const [popupActive, setPopupActive] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const refImage = useRef("");
  const [isSaving, setIsSaving] = useState(true);
  const [titleValue, setTitleValue] = useState("");
  const handleChangeTitle = (newValue) => {
    setItem({ ...item, title: newValue });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, titleAr: newValue });
    setTitleArError("");
  };
  const [titleError, setTitleError] = useState("");
  const [titleArError, setTitleArError] = useState("");
  const [dateValue, setDateValue] = useState("");
  const handleChangeDate = (newValue) => {
    setItem({ ...item, date: newValue });
    setDateError("");
  };
  const [dateError, setDateError] = useState("");
  const [imageError, setImageError] = useState("");
  const [filesMain, setFilesMain] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const handleDropZoneMain = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesMain((filesMain) => [...filesMain, ...acceptedFiles]),
    []
  );
  const fileUploadMain = !filesMain.length && <DropZone.FileUpload />;
  let uploadedFilesMain = filesMain.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesMain[filesMain.length - 1].name}
        source={
          validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
            ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesMain[filesMain.length - 1].name}{" "}
        <Caption>{filesMain[filesMain.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageMain = (filesMain) => {
    setPopupActive(true);
    console.log("filesMain=", filesMain[filesMain.length - 1]);
    axios
      .get(`admin/v1/sas/${filesMain[filesMain.length - 1].name}`)
      .then((result) => {
        console.log(result.data.data);
        url = result.data.data;
        fctAzure(filesMain, url);
      })
      .catch((err) => console.log(""));
  };
  const fctAzure = async (filesMain, url) => {
    console.log("tessst=", filesMain);
    const CONTAINER_NAME = "photos";
    //GET sasURL from API?

    const blobServiceClient = new BlobServiceClient(url);
    const containerClient =
      blobServiceClient.getContainerClient(CONTAINER_NAME);
    const filename = filesMain[filesMain.length - 1].name.substring(
      0,
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const ext = filesMain[filesMain.length - 1].name.substring(
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const blobName = filename + "_" + new Date().getTime() + ext;
    setImageKey(blobName);
    console.log("test=", blobName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
      filesMain[filesMain.length - 1]
    );
    console.log(
      `Upload block blob ${filesMain[filesMain.length - 1].name} successfully`,
      uploadBlobResponse.clientRequestId
    );

    setPopupActive(false);
    setImageError("");
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
          ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });

    // setImagePreview(
    //   validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
    //     ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
    //     : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    // );
  };
  const [optionsCategory, setOptionsCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleSelectChangeCategory = (newValue) => {
    setItem({ ...item, category: newValue });
    setCategoryError("");
  };
  const [categoryError, setCategoryError] = useState("");

  const [item, setItem] = useState({
    title: "",
    titleAr: "",
    date: "",
    image: "",
    category: "",
  });
  // useEffect(() => {
  //   axios
  //     .get(`admin/v1/albums/${id}`)
  //     .then((result) => {
  //       setIsSaving(false);
  //       setItem({
  //         title: result?.data?.data?.title?.en && result?.data?.data?.title?.en,
  //         titleAr:
  //           result?.data?.data?.title?.ar && result?.data?.data?.title?.ar,
  //         date: result?.data?.data?.date && result?.data?.data?.date,
  //         image: result?.data?.data?.thumbnail && result?.data?.data?.thumbnail,
  //       });
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responseCategories = "";
    let responseItem = "";

    try {
      responseCategories = await axios.get(`admin/v1/categories`);
      responseCategories?.data?.data?.data &&
        setOptionsCategory(
          responseCategories.data.data.data.map((item, index) => {
            return {
              label: item.title[Object.keys(item.title)[0]],
              value: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseItem = await axios.get(`admin/v1/albums/${id}`);
      responseItem?.data?.data &&
        setItem({
          title:
            responseItem?.data?.data?.title?.en &&
            responseItem?.data?.data?.title?.en,
          titleAr:
            responseItem?.data?.data?.title?.ar &&
            responseItem?.data?.data?.title?.ar,
          date:
            responseItem?.data?.data?.date && responseItem?.data?.data?.date,
          image:
            responseItem?.data?.data?.thumbnail &&
            responseItem?.data?.data?.thumbnail,
          category:
            responseItem?.data?.data?.category_id !== "" &&
            String(responseItem?.data?.data?.category_id),
        });
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }

  return (
    <NavigationLayout>
      <Page
        fullWidth
        breadcrumbs={[
          { onAction: () => navigate("/admin/photos-and-videos/albums") },
        ]}
        title="Edit Album"
      >
        {isSaving ? <Loading /> : null}
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Category</TextStyle>
              </p>
              <Select
                options={optionsCategory}
                onChange={handleSelectChangeCategory}
                value={item.category}
                placeholder="Please Select"
                error={categoryError}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Title En</TextStyle>
              </p>
              <TextField
                value={item.title}
                onChange={handleChangeTitle}
                error={titleError}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Title Ar</TextStyle>
              </p>
              <TextField
                value={item.titleAr}
                onChange={handleChangeTitleAr}
                align="right"
                error={titleArError}
              />
            </FormLayout>
          </FormLayout.Group>
          <p className="greyText">
            <TextStyle>Date</TextStyle>
          </p>
          <TextField
            value={item.date}
            onChange={handleChangeDate}
            type="date"
            error={dateError}
          />
          <p className="greyText">
            <TextStyle>Thumbnail</TextStyle>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail size="large" source={item.image ? item.image : ""} />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone
                onDrop={handleDropZoneMain}
                onDropAccepted={sendImageMain}
                // variableHeight
              >
                {uploadedFilesMain}
                {fileUploadMain}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError}></InlineError>

          {/* <Button primary>Save</Button> */}
        </FormLayout>
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
          secondaryActions={[
            {
              content: <p style={{ color: "white" }}>Delete</p>,
              destructive: true,
              loading: isSaving && true,
              onClick: () => {
                setIsSaving(true);
                axios
                  .delete(`admin/v1/albums/${id}/delete`)
                  .then((result) => {
                    navigate(`/admin/photos-and-videos/albums`);
                  })
                  .catch((err) => console.log(err));
              },
            },
          ]}
        />
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
  function handleSave() {
    if (!item.title || !item.titleAr || !item.date) {
      !item.title && setTitleError("Please enter a title");
      !item.titleAr && setTitleArError("Please enter an arabic title");
      !item.date && setDateError("Please enter a date");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("_method", "PATCH");
      item.title && form_data.append("title[en]", item.title);
      item.titleAr && form_data.append("title[ar]", item.titleAr);
      item.date && form_data.append("date", item.date);
      imageKey && form_data.append("thumbnail", imageKey);
      form_data.append("category_id", item.category);

      axios
        .post(`admin/v1/albums/${id}/update`, form_data)
        .then((res) => {
          navigate("/admin/photos-and-videos/albums");
        })
        .catch((err) => console.log(""));
    }
  }
};

export default EditAlbum;
