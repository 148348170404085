import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  Badge,
  Tooltip,
} from "@shopify/polaris";
import axios from "../Assets/Lib/axios";
import history from "../Assets/Lib/history";
import emptyListImage from "../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import NavigationLayout from "../Components/NavigationLayout";

const Audits = () => {
  const navigate = useNavigate();
  const format = "YYYY-MM-DD HH:mm:ss";

  const perPage = 20;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const handleEdit = (e) => {
    navigate(`/admin/content/news/${e.currentTarget.id}`);
  };
  const handleDown = (e) => {
    const bodyObj = {
      sort: "down",
    };
    axios
      .patch(`admin/v1/news/${e.currentTarget.id}/sort`, bodyObj)
      .then((res) => {
        getData();
      })
      .catch((err) => console.log(""));
  };
  const handleUp = (e) => {
    const bodyObj = {
      sort: "up",
    };
    axios
      .patch(`admin/v1/news/${e.currentTarget.id}/sort`, bodyObj)
      .then((res) => {
        getData();
      })
      .catch((err) => console.log(""));
  };
  const getData = () => {
    axios
      .get(
        `admin/v1/audits?per_page=${perPage}&page=${page}&filter[${
          availability == "" ? "user.first_name" : availability
        }]=${queryValue}`
      )
      .then((result) => {
        // console.log("aaa=", result.data);
        setIsLoading(false);
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        result?.data?.data?.data &&
          setItems(
            result.data.data.data.map((item, index) => [
              item?.user?.first_name && item?.user?.last_name && (
                <div className={`itemClick`}>
                  {item?.user?.first_name + " " + item?.user?.last_name}
                </div>
              ),
              item?.ip_address && (
                <div className={`itemClick`}>{item?.ip_address}</div>
              ),
              item?.old_values && (
                <ul>
                  {Object.keys(item?.old_values).map((key, i) => (
                    <li key={i}>
                      <span>{key}:</span>

                      <span className="auditsText">
                        {item?.old_values[key]}
                      </span>
                    </li>
                  ))}
                </ul>
              ),
              item?.new_values && (
                <ul>
                  {Object.keys(item?.new_values).map((key, i) => (
                    <li key={i}>
                      <span>{key}:</span>
                      <span className="auditsText">
                        {item?.new_values[key]}
                      </span>
                    </li>
                  ))}
                </ul>
              ),

              item?.updated_at && (
                <div className={`itemClick`}>
                  {moment(item.updated_at).format(format)}
                </div>
              ),
              item?.event && (
                <div className={`itemClick`}>
                  <Badge>{item?.event}</Badge>
                </div>
              ),
            ])
          );
      })
      .catch((err) => setIsLoading(false));
  };
  useEffect(() => {
    getData();
  }, [page, sortState, availability, queryValue]);

  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "First Name", value: "user.first_name" },
            { label: "Last Name", value: "user.last_name" },
            { label: "IP address", value: "user.ip_address" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  return (
    <NavigationLayout>
      <Page fullWidth 
      // title="Audits"
      >
        <Card>
          {loadingMarkup}
          <Card.Section>
            <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleFiltersClearAll}
            />
          </Card.Section>
          <DataTable
            columnContentTypes={["text", "text", "text", "text"]}
            headings={[
              <TextStyle variation="strong">
                <div style={{ padding: "1.6rem" }}>User</div>
              </TextStyle>,
              <TextStyle variation="strong">
                <div style={{ padding: "1.6rem" }}>IP Address</div>
              </TextStyle>,
              <TextStyle variation="strong">
                <div style={{ padding: "1.6rem" }}>Old Values</div>
              </TextStyle>,
              <TextStyle variation="strong">
                <div style={{ padding: "1.6rem" }}>New Values</div>
              </TextStyle>,

              <TextStyle variation="strong">
                <div style={{ padding: "1.6rem" }}>Date</div>
              </TextStyle>,
              <TextStyle variation="strong">
                <div style={{ padding: "1.6rem" }}>Action</div>
              </TextStyle>,
              // <TextStyle variation="strong"></TextStyle>,
            ]}
            rows={items}
            sortable={[false, false, false, false]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            onSort={handleSort}
            verticalAlign="middle"
          />

          {emtyState}
        </Card>
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Page>
    </NavigationLayout>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default Audits;
