import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Select,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  TextStyle,
  Toast,
  Modal,
  TextField,
  FormLayout,
  Page,
  Card,
  PageActions,
  InlineError,
  DropZone,
  Stack,
  Caption,
  Thumbnail,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import NavigationLayout from "../../../Components/NavigationLayout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const EditStudio = () => {
  const { BlobServiceClient } = require("@azure/storage-blob");
  const [imageKey, setImageKey] = useState("");
  const [popupActive, setPopupActive] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(true);
  const [StudioDateValue, setStudioDateValue] = useState("");
  const handleChangeStudioDate = (newValue) => {
    setItem({ ...item, date: newValue });
    setDateError("");
  };

  const [dateError, setDateError] = useState("");

  const [StudioNameValue, setStudioNameValue] = useState("");
  const handleChangeStudioName = (newValue) => {
    setItem({ ...item, nameEn: newValue });
    setTitleError("");
  };
  const [StudioNameArValue, setStudioNameArValue] = useState("");
  const handleChangeStudioNameAr = (newValue) => {
    setItem({ ...item, nameAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const [selectedStudioType, setSelectedStudioType] = useState("");
  const handleSelectChangeStudioType = (newValue) => {
    setItem({ ...item, type: newValue });
  };
  const [optionsStudioType, setOptionsStudioType] = useState("");
  const [selectedStudioLocation, setSelectedStudioLocation] = useState("");
  const handleSelectChangeStudioLocation = (newValue) => {
    setItem({ ...item, location: newValue });
  };
  const [optionsStudioLocation, setOptionsStudioLocation] = useState("");
  const [StudioStartTimeValue, setStudioStartTimeValue] = useState("");
  const handleChangeStudioStartTime = (newValue) => {
    setItem({ ...item, startTime: newValue });
  };
  const [StudioEndTimeValue, setStudioEndTimeValue] = useState("");
  const handleChangeStudioEndTime = (newValue) => {
    setItem({ ...item, endTime: newValue });
  };
  const [capacityValue, setCapacityValue] = useState("");
  const handleChangeCapacity = (newValue) => {
    setItem({ ...item, capacity: newValue });
  };
  const [StudioDescriptionValue, setStudioDescriptionValue] = useState("");
  const handleChangeStudioDescription = (newValue) => {
    setItem({ ...item, descriptionEn: newValue });
  };
  const [StudioDescriptionArValue, setStudioDescriptionArValue] = useState("");
  const handleChangeStudioDescriptionAr = (newValue) => {
    setItem({ ...item, descriptionAr: newValue });
  };
  const [item, setItem] = useState({
    nameEn: "",
    nameAr: "",
    date: "",
    location: "",
    type: "",
    startTime: "",
    endTime: "",
    descriptionEn: "",
    descriptionAr: "",
    capacity: "",
    image: "",
  });
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responseTypes = "";
    let responseStudio = "";
    try {
      responseTypes = await axios.get(`admin/v1/studios/types`);
      responseTypes?.data?.data?.data &&
        setOptionsStudioType(
          responseTypes.data.data.data.map((item, index) => {
            return {
              label: item.title[Object.keys(item.title)[0]],
              value: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseStudio = await axios.get(`admin/v1/studios/${id}`);
      console.log(responseStudio?.data?.data);
      responseStudio?.data?.data &&
        setItem({
          nameEn: responseStudio?.data?.data?.name?.en
            ? responseStudio?.data?.data?.name?.en
            : "",
          nameAr: responseStudio?.data?.data?.name?.ar
            ? responseStudio?.data?.data.name?.ar
            : "",
          descriptionEn: responseStudio?.data?.data?.description?.en
            ? responseStudio?.data?.data?.description?.en
            : "",
          descriptionAr: responseStudio?.data?.data?.description?.ar
            ? responseStudio?.data?.data.description?.ar
            : "",

          type: responseStudio?.data?.data?.type_id
            ? String(responseStudio?.data?.data?.type_id)
            : "",
          startTime: responseStudio?.data?.data?.start_time
            ? responseStudio?.data?.data?.start_time
            : "",
          endTime: responseStudio?.data?.data?.end_time
            ? responseStudio?.data?.data?.end_time
            : "",
          image: responseStudio?.data?.data?.image
            ? responseStudio?.data?.data?.image
            : "",
          // color: result?.data?.data?.color ? result.data.data?.color : "",
        });
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }
  const [imageError, setImageError] = useState("");
  const [filesMain, setFilesMain] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const handleDropZoneMain = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesMain((filesMain) => [...filesMain, ...acceptedFiles]),
    []
  );
  const fileUploadMain = !filesMain.length && <DropZone.FileUpload />;
  let uploadedFilesMain = filesMain.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesMain[filesMain.length - 1].name}
        source={
          validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
            ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesMain[filesMain.length - 1].name}{" "}
        <Caption>{filesMain[filesMain.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const [blobList, setBlobList] = useState([]);
  let url = "";
  const sendImageMain = (filesMain) => {
    setPopupActive(true);
    console.log("filesMain=", filesMain[filesMain.length - 1]);
    axios
      .get(`admin/v1/sas/${filesMain[filesMain.length - 1].name}`)
      .then((result) => {
        console.log(result.data.data);
        url = result.data.data;
        fctAzure(filesMain, url);
      })
      .catch((err) => console.log(""));
  };

  const fctAzure = async (filesMain, url) => {
    console.log("tessst=", filesMain);
    const CONTAINER_NAME = "photos";
    //GET sasURL from API?

    const blobServiceClient = new BlobServiceClient(url);
    const containerClient =
      blobServiceClient.getContainerClient(CONTAINER_NAME);
    const filename = filesMain[filesMain.length - 1].name.substring(
      0,
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const ext = filesMain[filesMain.length - 1].name.substring(
      filesMain[filesMain.length - 1].name.lastIndexOf(".")
    );
    const blobName = filename + "_" + new Date().getTime() + ext;
    setImageKey(blobName);
    console.log("test=", blobName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
      filesMain[filesMain.length - 1]
    );
    console.log(
      `Upload block blob ${filesMain[filesMain.length - 1].name} successfully`,
      uploadBlobResponse.clientRequestId
    );
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(filesMain[filesMain.length - 1].type) > 0
          ? window.URL.createObjectURL(filesMain[filesMain.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setPopupActive(false);
    setImageError("");
  };
  return (
    <NavigationLayout>
      <Page
        fullWidth
        breadcrumbs={[
          { onAction: () => navigate("/admin/manage-studios/studios") },
        ]}
        title="Edit Studio"
      >
        {isSaving ? <Loading /> : null}
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Studio Name En</TextStyle>
              </p>
              <TextField
                value={item.nameEn}
                onChange={handleChangeStudioName}
              />
              <InlineError message={titleError} />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Studio Name Ar</TextStyle>
              </p>
              <TextField
                value={item.nameAr}
                onChange={handleChangeStudioNameAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <p className="greyText">
            <TextStyle>Image</TextStyle>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail size="large" source={item.image ? item.image : ""} />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone
                onDrop={handleDropZoneMain}
                onDropAccepted={sendImageMain}
                // variableHeight
              >
                {uploadedFilesMain}
                {fileUploadMain}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError}></InlineError>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Studio Type</TextStyle>
              </p>
              <Select
                options={optionsStudioType}
                onChange={handleSelectChangeStudioType}
                value={item.type}
                placeholder="Please select a type"
              />
            </FormLayout>
          </FormLayout.Group>
          <p className="greyText">
            <TextStyle>OPERATION TIME</TextStyle>
          </p>
          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>From</TextStyle>
              </p>
              <TextField
                value={item.startTime}
                onChange={handleChangeStudioStartTime}
                type="time"
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>To</TextStyle>
              </p>
              <TextField
                value={item.endTime}
                onChange={handleChangeStudioEndTime}
                type="time"
              />
            </FormLayout>
          </FormLayout.Group>

          <FormLayout.Group>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Interviewee Description En</TextStyle>
              </p>
              <TextField
                value={item.descriptionEn}
                onChange={handleChangeStudioDescription}
                multiline={4}
              />
            </FormLayout>
            <FormLayout>
              <p className="greyText">
                <TextStyle>Interviewee Description Ar</TextStyle>
              </p>
              <TextField
                value={item.descriptionAr}
                onChange={handleChangeStudioDescriptionAr}
                multiline={4}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
        </FormLayout>{" "}
        <PageActions
          primaryAction={{
            content: <p style={{ color: "white" }}>Save</p>,
            onClick: handleSave,
            loading: isSaving && true,
          }}
          secondaryActions={[
            {
              content: <p style={{ color: "white" }}>Delete</p>,
              destructive: true,
              loading: isSaving && true,
              onClick: () => {
                setIsSaving(true);
                axios
                  .delete(`admin/v1/studios/${id}/delete`)
                  .then((result) => {
                    navigate(`/admin/manage-studios/studios`);
                  })
                  .catch((err) => console.log(err));
              },
            },
          ]}
        />
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
  function handleSave() {
    if (!item.nameEn && !item.nameAr) {
      !item.nameEn &&
        !item.nameAr &&
        setTitleError("Please enter a studio name");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      item.nameEn && form_data.append("name[en]", item.nameEn);
      item.nameAr && form_data.append("name[ar]", item.nameAr);
      item.date && form_data.append("date", item.date);
      form_data.append("description[en]", item.descriptionEn);
      form_data.append("description[ar]", item.descriptionAr);
      item.startTime && form_data.append("start_time", item.startTime);
      item.endTime && form_data.append("end_time", item.endTime);
      item.type !== "" && form_data.append("type_id", item.type);
      imageKey && form_data.append("image", imageKey);

      form_data.append("_method", "PATCH");
      axios
        .post(`admin/v1/studios/${id}/update`, form_data)
        .then((res) => {
          navigate("/admin/manage-studios/studios");
        })
        .catch((err) => console.log(""));
    }
  }
};

export default EditStudio;
