import React from "react";

const FilterParentDiv = (props) => {
  return (
    <div className="itemClick bookFont searchDiv">
      <div>
        <div className="Polaris-Connected">
          <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterParentDiv;
